// forms.ts
import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";
import { z } from "zod";

export type CustomHookFormError = FieldError | Merge<FieldError, FieldErrorsImpl<any>>;

type CustomInputType = "text" | "email" | "url";

interface CustomFormFieldValidation {
  type?: CustomInputType | ((watch: any) => CustomInputType);
  /** Conditionally require with Hook Form watch()
   * ```
   * { isRequired: (watch) => watch.redirectDestinationSelect === "custom-url" }
   * ```  */
  isRequired?: boolean | ((watch: any) => boolean);
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  includes?: string;
  startsWith?: string;
  endsWith?: string;
  customRequiredMessage?: string;
  customValidationMessage?: string;
}

export interface CustomBaseFormFieldProps {
  component: "input" | "select" | "textarea" | "hidden";
  name: string;
  label: string;
  eyebrow?: string;
  helperText?: string;
  placeholder?: string;
  validations?: CustomFormFieldValidation;
  defaultValue?: string | boolean;
  error?: CustomHookFormError;
  /**
   * Exclude this field from being saved in the form data. Set to true for any field that causes a useful effect but isn't meant to be saved. */
  exclude?: boolean;
  /** 
   * Conditionally show field based on the value of another field. Example use:
   * ```
   * {
      name: "redirectDestination",
      condition: (watch) => watch.redirectDestinationSelect === "custom-url",
      [other props...]
    },
   * ``` */
  condition?: (watch: any) => boolean;
}

type CustomFormFieldProps<T> = Omit<React.HTMLProps<T>, "name" | "label"> &
  CustomBaseFormFieldProps;

interface CustomHiddenFieldProps extends Pick<CustomBaseFormFieldProps, "name" | "defaultValue"> {
  component: "hidden";
}

export interface CustomSelectProps extends CustomFormFieldProps<HTMLSelectElement> {
  component: "select";
  options: {
    name: string;
    value: string;
  }[];
}

export interface CustomTextareaProps extends CustomFormFieldProps<HTMLTextAreaElement> {
  component: "textarea";
}

export interface CustomInputProps extends CustomFormFieldProps<HTMLInputElement> {
  component: "input";
  addonStart?: string;
  addonEnd?: string;
}

export type CustomFormField =
  | CustomHiddenFieldProps
  | CustomSelectProps
  | CustomTextareaProps
  | CustomInputProps;

/**
 * Constructs a Zod schema for a given field based on its validation requirements.
 * Takes a set of validation rules and an initial Zod schema, then modifies the
 * schema to enforce these rules.
 *
 * @param {CustomFormFieldValidation} validation - An object containing validation rules for the field.
 * @param {z.ZodTypeAny} baseSchema - The base Zod schema to modify based on validation rules.
 * @returns {z.ZodTypeAny} The modified Zod schema with validation rules applied.
 */
const buildFieldSchema = (validation: CustomFormFieldValidation, baseSchema: z.ZodTypeAny) => {
  let schema = baseSchema;
  const isStringTypeField = baseSchema instanceof z.ZodString;

  if (validation.isRequired) {
    schema = isStringTypeField
      ? z.string().min(1, {
          message: validation.customRequiredMessage || "This field is required",
        })
      : baseSchema;
  } else {
    schema = isStringTypeField ? z.string().nullable() : baseSchema.nullable();
  }

  if (isStringTypeField) {
    if (validation.type === "email") {
      schema = (schema as z.ZodString).email({
        message: validation.customValidationMessage || "Invalid email address",
      });
    }
    if (validation.type === "url") {
      schema = (schema as z.ZodString).url({
        message: validation.customValidationMessage || "Invalid URL",
      });
    }
    if (validation.minLength !== undefined) {
      schema = (schema as z.ZodString).min(validation.minLength, {
        message:
          validation.customValidationMessage ||
          `Must be at least ${validation.minLength} characters long`,
      });
    }
    if (validation.maxLength !== undefined) {
      schema = (schema as z.ZodString).max(validation.maxLength, {
        message:
          validation.customValidationMessage ||
          `Must be no more than ${validation.maxLength} characters long`,
      });
    }
  }

  return schema;
};

/**
 * Creates a Zod schema for a form based on an array of field definitions. Each field definition
 * includes the field name, component type, and any validation rules. This function constructs a
 * Zod schema for field and then combines the individual schemas into a single object schema
 * representing the form.
 *
 * @param {CustomFormField[]} formFields - An array of objects, each representing a form field with its
 * name, component type, and validation rules.
 * @returns {z.ZodObject} A Zod object schema representing the validations for the entire form.
 */
export const createZodSchemaFromFormFields = (formFields: CustomFormField[]) => {
  const schemaObject: { [key: string]: z.ZodTypeAny } = {};

  formFields.forEach((field) => {
    let baseSchema: z.ZodTypeAny = z.string();

    const isHiddenField = field.component === "hidden";

    if (!isHiddenField) {
      if (field.exclude) {
        return;
      }

      if (field.validations && field.validations.isRequired) {
        baseSchema = z.string(); // required fields
      } else {
        baseSchema = z.string().nullable(); // non-required fields
      }

      if (field.validations) {
        baseSchema = buildFieldSchema(field.validations, baseSchema);
      }
    }

    schemaObject[field.name] = baseSchema;
  });

  return z.object(schemaObject);
};

const isBlankString = (value: any) => typeof value === "string" && value.trim() === "";

const isBooleanAsString = (value: any) =>
  typeof value === "string" && (value === "true" || value === "false");

/**
 * Prepare form values for API submission
 * - Recursively set `""` and `null` values to `undefined`
 * - Make sure stringified booleans are real booleans */
export const normalizeValues = (obj: Record<string, any>): Record<string, any> => {
  const isObject = (obj: any) => typeof obj === "object" && obj !== null;

  for (const key in obj) {
    if (isObject(obj[key])) {
      obj[key] = normalizeValues(obj[key]); // Recurse into nested objects
    }
    if (isBlankString(obj[key])) {
      obj[key] = undefined; // Convert empty strings to undefined
    }
    if (isBooleanAsString(obj[key])) {
      obj[key] = JSON.parse(obj[key]); // Convert string booleans to boolean
    }
  }
  return obj;
};

export const removeExcludedFields = (
  formFields: CustomFormField[],
  formValues: Record<string, any>
) => {
  return Object.entries(formValues).reduce((acc, [key, value]) => {
    const field = formFields.find((field) => field.name === key);
    const isHiddenField = field?.component === "hidden";

    /** hidden fields lack the `exclude` property, but should be included */
    const isIncludedField = isHiddenField || !field?.exclude;

    if (isIncludedField) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
};

/** Make sure that a string contains only letters, hyphens or underscores */
export const enforceValidSubdirectory = (text: string) => {
  return text.replace(/[^a-zA-Z-_]/g, "");
};
