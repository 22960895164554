import { useDispatch } from "react-redux";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { useCurrentUser } from "state/ducks";
import { setCurrentUser } from "state/ducks/users";

import { getAllPermissions } from "utilities";

import { Permission } from "types/auth";

const DevTools = () => {
  const toolTabs = [
    {
      label: "User Permissions",
      tool: <UserPermissionDevTool />,
    },
  ];
  return (
    <Tabs colorScheme="black" variant="line">
      <TabList>
        {toolTabs.map(({ label }, i) => (
          <Tab
            borderTopRadius="md"
            fontSize="lg"
            fontWeight="bold"
            key={i}
            paddingTop={3}
            _focus={{ boxShadow: "none" }}>
            {label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {toolTabs.map(({ tool }, i) => (
          <TabPanel key={i}>{tool}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default DevTools;

const UserPermissionDevTool = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const toggleUserPermission = (permission: Permission) => {
    let newPermissions = user.permissions;

    if (newPermissions.includes(permission)) {
      // Remove permission from user
      newPermissions = newPermissions.filter((perm) => perm !== permission);
    } else {
      // Add permission to user
      newPermissions = [...newPermissions, permission];
    }

    dispatch(setCurrentUser({ ...user.attributes, permissions: newPermissions }));
  };

  const toggleAllPermissions = (state: "on" | "off") => {
    let allPermissions = user.permissions;

    getAllPermissions().forEach((permission) => {
      if (user.permissions.includes(permission) && state === "on") {
        allPermissions = allPermissions.filter((perm) => perm !== permission);
      }
      if (!user.permissions.includes(permission) && state === "off") {
        allPermissions = [...allPermissions, permission];
      }
    });

    dispatch(setCurrentUser({ ...user.attributes, permissions: allPermissions }));
  };

  return (
    <Stack spacing={4}>
      {getAllPermissions().map((permission) => {
        return (
          <Checkbox
            borderColor="gray"
            key={permission}
            isChecked={!user.permissions.includes(permission)}
            onChange={() => toggleUserPermission(permission)}>
            {permission}
          </Checkbox>
        );
      })}
      <ButtonGroup colorScheme="blue">
        <Button onClick={() => toggleAllPermissions("on")} variant="solid">
          Select All
        </Button>
        <Button onClick={() => toggleAllPermissions("off")} variant="outline">
          Clear All
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
