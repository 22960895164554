import { useState, useRef, useEffect } from "react";
import { Flex } from "@chakra-ui/react";

import { Span } from "components/partials/typography/typography";
import { OptionLoader } from "components/partials/option-loader/option-loader";
import { FilterSelectTrigger } from "components/partials/filter-select-trigger/filter-select-trigger";
import { FilterSelectPopover } from "components/partials/filter-select-popover/filter-select-popover";
import {
  FilterSelectCheckbox,
  FilterSelectCheckboxGroup,
} from "components/partials/filter-select-checkbox/filter-select-checkbox";
import { CheckboxOption } from "components/forms/checkbox/checkbox";

interface AsyncCheckboxMultiselectProps {
  label: string;
  selectedOptions: CheckboxOption[];
  setSelectedOptions: (updatedValue: CheckboxOption[]) => void;
  getOptions: () => Promise<CheckboxOption[]>;
  loadMoreOptions?: () => Promise<{
    isEnd: boolean;
    data: CheckboxOption[];
  }>;
}

export default function AsyncCheckboxMultiselect({
  label,
  getOptions,
  selectedOptions,
  setSelectedOptions,
  loadMoreOptions,
}: AsyncCheckboxMultiselectProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [options, setOptions] = useState<CheckboxOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isEndOfOptions, setIsEndOfOptions] = useState(false);
  const [isLoadingMoreOptions, setIsLoadingMoreOptions] = useState(false);

  const handleOptionClick = (option: CheckboxOption) => {
    if (!selectedOptions.find((o) => o.value === option.value)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((o) => o.value !== option.value));
    }
  };

  const toggleIsDropdownOpen = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      if (options.length === 0) {
        setIsLoading(true);
        getOptions()
          .then((optionsFromFetch) => {
            setOptions(optionsFromFetch);
          })
          .catch(() => {
            setIsEndOfOptions(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [isDropdownOpen, getOptions, options.length, loadMoreOptions]);

  const triggerLoadMoreOptions = () => {
    if (options.length <= 0) {
      return Promise.resolve(true);
    }
    if (isEndOfOptions) {
      return Promise.resolve(false);
    }

    if (loadMoreOptions) {
      if (isLoadingMoreOptions) {
        return Promise.resolve(true);
      }
      setIsLoadingMoreOptions(true);
      loadMoreOptions()
        .then((response) => {
          setIsEndOfOptions(response.isEnd);
          setOptions([...options, ...response.data]);
          setIsLoadingMoreOptions(false);
        })
        .finally(() => {
          return Promise.resolve(true);
        });
    }

    return Promise.resolve(true);
  };

  return (
    <FilterSelectPopover
      onClose={() => setIsDropdownOpen(false)}
      data-testid="inbox-multiselect-popover"
      triggerElement={
        <FilterSelectTrigger onClick={toggleIsDropdownOpen} ref={ref} label={label} />
      }
      isOpen={isDropdownOpen}
      setIsOpen={setIsDropdownOpen}>
      {isLoading ? (
        <Flex width="100%" alignItems="center">
          <Span ml={2}>Loading...</Span>
        </Flex>
      ) : (
        <FilterSelectCheckboxGroup>
          {options.map((option, index) => (
            <FilterSelectCheckbox
              key={option.label + index}
              isChecked={!!selectedOptions.find((o) => o.value === option.value)}
              onChange={() => handleOptionClick(option)}
              label={option.label}
            />
          ))}
          {loadMoreOptions && <OptionLoader runOnLoading={triggerLoadMoreOptions} />}
        </FilterSelectCheckboxGroup>
      )}
    </FilterSelectPopover>
  );
}
