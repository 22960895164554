import { extendTheme } from "@chakra-ui/react";

import { toTitleCase, undoDashCase } from "utilities";
import { ThemeBuilderAttributesFormState } from "hooks/use-theme-builder-form-state";

import { TypekitFamily, TypekitFont, TypekitResponse } from "types";

export function parseTypekitResponse(
  currentFonts: TypekitFont[],
  newFonts: TypekitResponse
): TypekitFont[] {
  // Replace old values with new values
  const oldFontsWithReplacements: TypekitFont[] = currentFonts.map((oldFont) => {
    const replacementIndex = newFonts.kit.families.findIndex(
      (newFont) => newFont.id === oldFont.id
    );
    if (replacementIndex >= 0) {
      // Replace old value with new if we found the same id
      return convertTypekitFamilyToTypekitFont(newFonts.kit.families[replacementIndex]);
    } else {
      return oldFont;
    }
  });

  // Remove duplicates after replacement
  const newFontsWithoutRepeats = newFonts.kit.families.filter(
    (newFont) => !currentFonts.some((oldFont) => newFont.id === oldFont.id)
  );

  // Convert api response into useable fonts
  const convertedNewFontsWithoutRepeats: TypekitFont[] = newFontsWithoutRepeats.map((family) => {
    return convertTypekitFamilyToTypekitFont(family);
  });

  // Append the new fonts (minus existing) to the existing fonts
  return [...oldFontsWithReplacements, ...convertedNewFontsWithoutRepeats];
}

export const parseTypekitDisplayNameFromCssStack = (cssStack: string) => {
  const splitStack = cssStack.split(",");
  if (splitStack.length === 0) {
    return "";
  }
  //using .replace() here instead of .replaceAll() bc replaceAll is not universally supported
  //so I use regex with the global operator 'g' to replace all "
  return toTitleCase(undoDashCase(splitStack[0].replace(/"/g, "")));
};

export function convertTypekitFamilyToTypekitFont(family: TypekitFamily): TypekitFont {
  return {
    id: family.id,
    name: family.name,
    defaultFontWeight: DEFAULT_FONT_WEIGHT,
    boldFontWeight: DEFAULT_BOLD_WEIGHT,
    fallbackFont: DEFAULT_FALLBACK_FONT,
    slug: family.slug,
    css_names: family.css_names,
    css_stack: family.css_stack,
    variations: family.variations,
  };
}

export function editTypekitFonts(
  fonts: TypekitFont[],
  field: "defaultFontWeight" | "boldFontWeight" | "fallbackFont",
  font: TypekitFont,
  newValue: string
): TypekitFont[] {
  let editedFont = { ...font };
  switch (field) {
    case "defaultFontWeight":
      editedFont = { ...editedFont, defaultFontWeight: newValue };
      break;
    case "boldFontWeight":
      editedFont = { ...editedFont, boldFontWeight: newValue };
      break;
    case "fallbackFont":
      editedFont = { ...editedFont, fallbackFont: newValue };
      break;
    default:
      return fonts;
  }

  let newFonts = [...fonts];
  newFonts = newFonts.map((newFont) => {
    if (newFont.id === font.id) {
      return editedFont;
    } else {
      return newFont;
    }
  });

  return newFonts;
}

export function parseFontWeightOptions(variations: string[]): string[] {
  let parsedWeights: string[] = [];
  variations.forEach((variation) => {
    // Take each variation and convert the ending number into a hundreds value font weight
    // e.g n7 becomes 700, i2 becomes 200. No duplicates allowed, so n2 and i2 only adds a single 200
    const trailingNumber = variation.slice(variation.length - 1);
    const convertedWeight = trailingNumber + "00";
    if (!parsedWeights.some((val) => val === convertedWeight)) {
      parsedWeights = [...parsedWeights, convertedWeight];
    }
  });
  return parsedWeights;
}

export function createThemeFromFormState(formState: ThemeBuilderAttributesFormState) {
  return extendTheme({
    colors: {
      pageBackgroundColor: formState.pageBackgroundColor,
      primaryColor: formState.primaryColor,
      secondaryColor: formState.secondaryColor,
      buttonAndLinkColor: formState.buttonAndLinkColor,
      formLabelColor: formState.formLabelColor,
      formInputBorderColor: formState.formInputBorderColor,
      formInputBackgroundColor: formState.formInputBackgroundColor,
      bodyColor: formState.bodyColor,
      h1Color: formState.h1Color,
      h2Color: formState.h2Color,
      h3Color: formState.h3Color,
      inputTextColor: formState.inputTextColor,
      buttonFontColor: formState.buttonFontColor,
    },
    fonts: {
      bodyFontFamily: formState.bodyFontFamily,
      h1FontFamily: formState.h1FontFamily,
      h2FontFamily: formState.h2FontFamily,
      h3FontFamily: formState.h3FontFamily,
      inputTextFontFamily: formState.inputTextFontFamily,
    },
    fontWeights: {
      bodyFontWeight: formState.bodyFontWeight,
      h1FontWeight: formState.h1FontWeight,
      h2FontWeight: formState.h2FontWeight,
      h3FontWeight: formState.h3FontWeight,
      inputTextFontWeight: formState.inputTextFontWeight,
    },
    lineHeights: {
      bodyLineHeight: formState.bodyLineHeight,
      h1LineHeight: formState.h1LineHeight,
      h2LineHeight: formState.h2LineHeight,
      h3LineHeight: formState.h3LineHeight,
      buttonLineHeight: formState.buttonLineHeight,
    },
    radii: {
      defaultBorderRadius: formState.defaultBorderRadius,
      buttonBorderRadius: formState.buttonBorderRadius,
    },
    borders: {
      defaultBorderWidth: formState.defaultBorderWidth,
      buttonBorderWidth: formState.buttonBorderWidth,
    },
    space: {
      buttonPaddingHorizontal: formState.buttonPaddingHorizontal,
      buttonPaddingVertical: formState.buttonPaddingVertical,
    },
    shadows: {
      defaultBoxShadow: formState.defaultBoxShadow,
      buttonBoxShadow: formState.buttonBoxShadow,
    },
    components: {
      Button: {
        variants: {
          BeeEditor: {
            display: "inline-block",
            width: "auto",
            height: "auto",
            backgroundColor: formState.buttonAndLinkColor,
            borderRadius: formState.buttonBorderRadius,
            boxShadow: formState.buttonBoxShadow,
            lineHeight: formState.buttonLineHeight,
            px: formState.buttonPaddingHorizontal,
            py: formState.buttonPaddingVertical,
            color: formState.buttonFontColor,
            borderStyle: formState.buttonBorderStyle,
            borderColor: formState.buttonBorderColor,
            borderWidth: formState.buttonBorderWidth,
          },
        },
      },
    },
  });
}

export const DEFAULT_FONT_WEIGHT = "400";
export const DEFAULT_BOLD_WEIGHT = "700";
export const DEFAULT_FALLBACK_FONT = "Arial";
export const WEB_SAFE_FONTS = [
  "Arial",
  "Arial Black",
  "Courier New",
  "Georgia",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana",
];
