import { MdArrowRight } from "react-icons/md";
import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Collapse,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";

import colors from "themes/apollo/colors";

export interface FormErrorsAlertProps extends AlertProps {
  heading: string;
  isClosable?: boolean;
  isCollapsible?: boolean;
  showCloseButton?: boolean;
  status: AlertStatus;
}
const FormAlert = ({
  heading,
  isClosable = false,
  isCollapsible = false,
  children,
  status,
  variant,
  ...rest
}: FormErrorsAlertProps) => {
  const { getDisclosureProps, getButtonProps, isOpen, onClose } = useDisclosure();

  /** Includes aria-expanded, aria-controls, and onClick() */
  const buttonProps = getButtonProps();

  const { id: panelId } = getDisclosureProps();
  const toggleId = `${panelId}-toggle`;

  const styles = getAlertStyles(status, variant);

  const titleProps = isCollapsible
    ? { id: toggleId, as: Button, variant: "unstyled", ...buttonProps }
    : undefined;

  const descriptionProps = isCollapsible ? { id: panelId, "aria-labelledby": toggleId } : undefined;

  return (
    <Alert aria-live="assertive" status={status} variant={variant} {...rest} {...styles.alert}>
      <AlertTitle {...titleProps} {...styles.title}>
        <AlertIcon color="inherit" {...styles.spacer} />
        <span>{heading}</span>
        {isCollapsible && (
          <Icon
            as={MdArrowRight}
            boxSize="6"
            transform={isOpen ? "rotate(90deg)" : undefined}
            transition="transform 0.2s ease"
          />
        )}
      </AlertTitle>

      <Collapse in={isCollapsible ? isOpen : true} animateOpacity>
        <AlertDescription {...descriptionProps} {...styles.description}>
          <Box {...styles.spacer} />
          <Box pr="2">{children}</Box>
        </AlertDescription>
      </Collapse>
      {isClosable && <CloseButton position="absolute" right={2} top={2} onClick={onClose} />}
    </Alert>
  );
};
export default FormAlert;

interface AlertColorScale {
  100: string;
  200: string;
}

const success: AlertColorScale = {
  100: colors.success[100],
  200: colors.success[200],
};

const info: AlertColorScale = {
  100: colors.info[100],
  200: colors.info[200],
};

const warning: AlertColorScale = {
  100: colors.warning[100],
  200: colors.warning[200],
};

const error: AlertColorScale = {
  100: colors.error[100],
  200: colors.error[200],
};

const getAlertStyles = (status: AlertStatus, variant?: AlertProps["variant"]) => {
  const isSolidVariant = variant === "solid";
  const isLeftAccentVariant = variant === "left-accent";

  const statusColorMap: Record<AlertStatus, AlertColorScale> = {
    success,
    error,
    warning,
    info,
    loading: info,
  };

  const colorObj = statusColorMap[status];

  const styles = {
    alert: {
      background: isSolidVariant ? colorObj[200] : "#faeff0",
      borderInlineStartWidth: isLeftAccentVariant ? 8 : undefined,
      borderColor: "currentColor",
      boxShadow: "outer",
      color: isSolidVariant ? "white" : colorObj[200],
      display: "block",
      paddingX: 2,
      paddingY: 3.5,
      sx: {
        // Applies when AlertTitle is accessed by tab, not by click
        "&:has(>button:focus-visible)": {
          outline: "3px solid",
          outlineColor: "forms.focusBorderColor",
        },
      },
    },
    title: {
      alignItems: "center",
      display: "flex",
      height: "auto",
      justifyContent: "start",
      letterSpacing: "wider",
      marginRight: 0,
      width: "full",
      _focus: {
        boxShadow: "none",
      },
      _focusVisible: {
        boxShadow: "none",
      },
    },
    description: {
      color: isSolidVariant ? colorObj[200] : "text.body",
      display: "flex",
      fontSize: "sm",
      letterSpacing: "extended",
      lineHeight: "inherit",
      paddingTop: 1,
      sx: {
        "& ul": {
          marginInlineStart: 6,
        },
      },
    },
    spacer: {
      boxSize: 5,
      padding: "2px",
      marginRight: 2,
    },
  };

  return styles;
};
