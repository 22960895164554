import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { currentUserSelector, setCurrentUser } from "state/ducks/users";

import AuthenticatedApp from "containers/authenticated-app";
import LoginPage from "containers/login-page/login-page";

import User from "models/user";
import Model from "models/model";

import axiosInstance from "config/axios-config";

const AppRoutesWrapper = () => {
  const { inProgress } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  // --------- react hack: calling `setApiReady` forces this to rerender -------
  const [forcedRender, setForceRender] = useState(0);
  const setApiReady = useCallback(() => setForceRender((prev) => prev + 1), [setForceRender]);
  // ---------------------------------------------------------------------------
  const isCurrentUserPresent = !!useSelector(currentUserSelector);
  const isConnectionReady = Model.isConnectionReady();

  useEffect(() => {
    (async () => {
      if (isMsalAuthenticated && !isConnectionReady && inProgress === InteractionType.None) {
        await Model.connect(axiosInstance);
        setApiReady();
      }
    })();
  }, [isMsalAuthenticated, inProgress, isConnectionReady, setApiReady]);

  useEffect(() => {
    (async () => {
      if (isConnectionReady) {
        try {
          const currentUser = await User.find();
          dispatch(setCurrentUser(currentUser.attributes));
        } catch {
          Model.disconnect();
          dispatch(setCurrentUser(null));
        }
      }
    })();
  }, [forcedRender, isConnectionReady, dispatch]);
  const isAuthenticated = isCurrentUserPresent && isMsalAuthenticated;

  return isAuthenticated ? <AuthenticatedApp /> : <LoginPage />;
};

export default AppRoutesWrapper;
