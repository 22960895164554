import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from "@chakra-ui/react";

interface TooltipProps extends ChakraTooltipProps {
  label: string;
  hasArrow?: boolean;
}

const Tooltip = ({ label, hasArrow = true, children, ...rest }: TooltipProps) => {
  return (
    <ChakraTooltip hasArrow={hasArrow} label={label} aria-label={label} {...rest}>
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;

//new Tooltip component that we need to use everywhere in this codebase over time (and getting rid of Chanrka's Tooltip)
export const CustomTooltip = ({ text, children, customClass = "", isOpen = false }: any) => {
  return (
    <div className="group relative flex">
      {children}
      {isOpen && (
        <>
          <span
            className={`absolute z-10 hidden transition-all rounded p-2 text-base bg-white group-hover:flex -top-20 -left-60 ${customClass}`}>
            {text}
          </span>
          <div className="group absolute rotate-45 -top-[55px] right-5 bg-white w-10 h-10 hidden transition-all group-hover:flex"></div>
        </>
      )}
    </div>
  );
};
