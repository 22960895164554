import { LockIcon } from "@chakra-ui/icons";

import { P } from "components/partials/typography/typography";

interface LockTabHeaderProps {
  label?: string | number;
}

const LockTabHeader = ({ label }: LockTabHeaderProps) => {
  return (
    <>
      <LockIcon mt={4} mr={1} />
      <P
        key={label}
        color={"highlight.200"}
        mr={{ sm: 8, md: 12 }}
        data-testid={`navlink-${label}`}
        mt={4}
        fontWeight="bold"
        _hover={{
          cursor: "cursor",
          borderBottomColor: "secondary.100",
          opacity: "0.85",
        }}>
        {label}
      </P>
    </>
  );
};

export default LockTabHeader;
