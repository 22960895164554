import React from "react";
import {
  Flex,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  FormLabel,
} from "@chakra-ui/react";

import { Small } from "components/partials/typography/typography";
import {
  InputControl,
  InputControlProps,
  useInputProps,
} from "components/forms/input-control/input-control";

interface TextareaProps extends ChakraTextareaProps, InputControlProps {
  value: string;
  rows?: number;
  characterLimit?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ value, label, rows = 5, characterLimit, ...props }, ref) => {
    const { controlProps, inputProps } = useInputProps({ ...props, label: undefined });

    const renderHeader = () => {
      if (characterLimit) {
        return (
          <Flex justifyContent="space-between" align="baseline">
            <FormLabel>{label}</FormLabel>
            <Small>
              {(characterLimit - (value?.length || 0)).toLocaleString()} characters remaining
            </Small>
          </Flex>
        );
      }
      return <FormLabel>{label}</FormLabel>;
    };

    return (
      <InputControl {...controlProps}>
        {label && renderHeader()}
        <ChakraTextarea
          ref={ref}
          value={value}
          size="lg"
          rows={rows}
          maxLength={characterLimit}
          {...inputProps}
        />
      </InputControl>
    );
  }
);

export default Textarea;
