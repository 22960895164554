import { BoxProps } from "@chakra-ui/react";
import { Link } from "types";

import TertiaryNavGroup from "components/partials/tertiary-nav-group/tertiary-nav-group";
import { Content, ContentWrapper, Sidebar } from "components/partials/layout/layout";

interface PageContentProps extends BoxProps {
  hideSidebar?: boolean;
  tertiaryNavGroups?: {
    heading: string;
    links: Link[];
  }[];
  showBreadcrumbs?: boolean;
}

const PageContent = ({
  hideSidebar,
  tertiaryNavGroups,
  showBreadcrumbs,
  children,
  ...rest
}: PageContentProps) => {
  return (
    <ContentWrapper data-testid="page-content" {...rest}>
      {!hideSidebar && tertiaryNavGroups && (
        <Sidebar>
          {tertiaryNavGroups.map((navGroup) => (
            <TertiaryNavGroup key={JSON.stringify(navGroup)} navGroup={navGroup} />
          ))}
        </Sidebar>
      )}
      <Content showBreadcrumbs={showBreadcrumbs} withFooter>
        {children}
      </Content>
    </ContentWrapper>
  );
};

export default PageContent;
