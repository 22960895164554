import React, { useState, useRef } from "react";

import { CheckboxOption } from "components/forms/checkbox/checkbox";
import { FilterSelectTrigger } from "components/partials/filter-select-trigger/filter-select-trigger";
import { FilterSelectPopover } from "components/partials/filter-select-popover/filter-select-popover";
import {
  FilterSelectCheckbox,
  FilterSelectCheckboxGroup,
} from "components/partials/filter-select-checkbox/filter-select-checkbox";

interface CheckboxMultiselectProps {
  label: string;
  options: CheckboxOption[];
  selectedOptions: CheckboxOption[];
  setSelectedOptions: (updatedValue: CheckboxOption[]) => void;
}

export default function CheckboxMultiselect({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
}: CheckboxMultiselectProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionClick = (option: CheckboxOption) => {
    if (!selectedOptions.find((o) => o.value === option.value)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((o) => o.value !== option.value));
    }
  };

  const toggleIsDropdownOpen = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <FilterSelectPopover
      data-testid="inbox-multiselect-popover"
      onClose={() => setIsDropdownOpen(false)}
      triggerElement={
        <FilterSelectTrigger onClick={toggleIsDropdownOpen} ref={ref} label={label} />
      }
      isOpen={isDropdownOpen}
      setIsOpen={setIsDropdownOpen}>
      <FilterSelectCheckboxGroup>
        {options.map((option, index) => (
          <FilterSelectCheckbox
            key={option.label + index}
            isChecked={!!selectedOptions.find((o) => o.value === option.value)}
            onChange={() => handleOptionClick(option)}
            label={option.label}
          />
        ))}
      </FilterSelectCheckboxGroup>
    </FilterSelectPopover>
  );
}
