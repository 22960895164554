import { useEffect, useState } from "react";

import { useCurrentClient } from "state/ducks";

import Touchpoint from "models/touchpoint";

import { CustomFormField } from "utilities/forms";

const useEmailSettingsFields = () => {
  const currentClient = useCurrentClient();

  interface EmailTypeOption {
    id: string;
    externalId: number;
    externalName: string;
    defaultForClient: boolean;
  }

  const [emailTypeOptions, setEmailTypeOptions] = useState<EmailTypeOption[]>([]);
  const [contentSettingsFields, setContentSettingsFields] = useState<CustomFormField[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchEmailTypes = async () => {
      try {
        const emailTypes = (await Touchpoint.getEmailTypes({
          clientId: currentClient.id,
        })) as EmailTypeOption[];

        setEmailTypeOptions(emailTypes);
      } catch (e) {
        console.error("Error while fetching email types", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmailTypes();
  }, [currentClient.id]);

  useEffect(() => {
    if (!isLoading) {
      setContentSettingsFields([
        {
          name: "iterableEmailTypeId",
          component: "select",
          label: "Email Type",
          defaultValue: emailTypeOptions.find((option) => option.defaultForClient)?.id,
          placeholder: !emailTypeOptions.length ? "There are no Email Types" : undefined,
          options: emailTypeOptions.map(({ externalName, id }) => ({
            name: externalName,
            value: id,
          })),
        },
        {
          name: "fromName",
          component: "input",
          label: "From name",
          defaultValue: "[Sender Name]",
          validations: { isRequired: true },
          placeholder: "Enter the name of the sender",
        },
        {
          name: "senderEmail",
          component: "input",
          label: "Sender email",
          defaultValue: "no-reply@mg.two-ocean.dev",
          validations: { type: "email", isRequired: true },
        },
        {
          name: "replyToEmail",
          component: "input",
          label: "Reply-to email",
          defaultValue: "reply-address@placeholder.edu",
          validations: { type: "email", isRequired: true },
        },
        {
          name: "subjectLine",
          component: "input",
          label: "Subject line",
          defaultValue: "[Email subject line]",
          validations: { isRequired: true, maxLength: 120 },
        },
        {
          name: "preheader",
          component: "textarea",
          label: "Preheader",
          defaultValue: "[Email preheader text...]",
          helperText:
            "Enter a line of summary text that will be visible after the subject line in a contact's inbox. The ideal length is 40 to 100 characters.",
          validations: { isRequired: true },
        },
        {
          name: "advancedHeadSnippet",
          component: "textarea",
          label: "Head Snippet",
          helperText: "Scripts are applied directly before the closing </head> tag",
        },
        {
          name: "advancedBodySnippet",
          component: "textarea",
          label: "Body Snippet",
          helperText: "Scripts are applied directly before the closing </body> tag",
        },
      ]);
    }
  }, [emailTypeOptions, isLoading]);

  return contentSettingsFields;
};

export default useEmailSettingsFields;
