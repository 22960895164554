// email-builder.tsx
import { useEffect, useState } from "react";
import { Switch, useRouteMatch, Route, Redirect, useLocation, useHistory } from "react-router-dom";

import EmailBuilderContentSettingsTab from "containers/admin/clients/touchpoint/components/email-builder/email-builder-content-settings-tab/email-builder-content-settings-tab";
import EmailBuilderDesignTab from "containers/admin/clients/touchpoint/components/email-builder/email-builder-design-tab/email-builder-design-tab";
import EmailBuilderPreviewTab from "containers/admin/clients/touchpoint/components/email-builder/email-builder-preview-tab/email-builder-preview-tab";
import Builder from "containers/admin/clients/touchpoint/components/builder/builder";
import {
  BuilderNavTab,
  builderRoutes,
  BuilderTabNav,
} from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";
import useEmailSettingsFields from "containers/admin/clients/touchpoint/components/email-builder/use-email-builder-form-fields";
import { useSaveTouchpoint } from "containers/admin/clients/touchpoint/components/builder/use-save-touchpoint";

import { Content, ContentWrapper } from "components/partials/layout/layout";
import Form, { FormProps } from "components/forms/form/form";
import { LoadingSpinner } from "components/partials/loading-screen/loading-screen";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";

import { useTouchpointBuilderContent } from "state/ducks/builder-content";
import { useCurrentTouchpoint } from "state/ducks/touchpoint";

import Email from "models/email";

import { usePagePermission } from "hooks/use-page-permission";

import { addAnnotationIdToElements } from "utilities";
import { BLANK_BEE_TEMPLATE } from "utilities/constants";

import { BeeContentDefaultProps } from "types/bee";
import { Permission } from "types/auth";

interface EmailBuilderProps {
  baseEmailUrl: string;
  previewMode: boolean;
}

const EmailBuilder = ({ baseEmailUrl, previewMode }: EmailBuilderProps) => {
  usePagePermission(Permission.PERM_TOUCHPOINT_READ);

  const touchpointContent = useTouchpointBuilderContent() as Email;
  const currentTouchpoint = useCurrentTouchpoint();

  const formFields = useEmailSettingsFields();
  const [invalidTabs, setInvalidTabs] = useState<BuilderNavTab[]>([]);

  const [isNewTouchpoint, setIsNewTouchpoint] = useState(false);
  const saveTouchpoint = useSaveTouchpoint();
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [navigateTo, setNavigateTo] = useState<string>("");

  const history = useHistory();
  const location = useLocation();

  useEffect(
    function detectNewTouchpoint() {
      if (!touchpointContent.data?.jsonOutput) {
        console.log("New touchpoint detected");
        setIsNewTouchpoint(true);
      }
    },
    [touchpointContent]
  );

  useEffect(
    function triggerSetInitialValues() {
      if (isNewTouchpoint) {
        handleSetInitialValues();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNewTouchpoint]
  );

  const handleConfirm = () => {
    history.push(navigateTo);
    setShowModal(false);
    setInvalidTabs([]);
    setNavigateTo("");
  };

  async function handleSetInitialValues() {
    setIsSaving(true);

    try {
      const defaultFormValues = formFields.reduce((acc, field) => {
        if (field.defaultValue === undefined || field.defaultValue === null) {
          acc[field.name] = "";
        } else {
          acc[field.name] = String(field.defaultValue);
        }
        return acc;
      }, {} as Record<string, string>);

      const newTouchpoint = {
        data: {
          htmlOutput: "<>",
          jsonOutput: addAnnotationIdToElements(BLANK_BEE_TEMPLATE),
        },
        sourceType: "BEE",
        ...defaultFormValues,
      };

      await saveTouchpoint(newTouchpoint);
      setIsSaved(true);
      setIsNewTouchpoint(false);
    } catch (error) {
      console.log("error saving design", error);
      setIsSaved(false);
    }

    setIsSaving(false);
  }

  const { path, url } = useRouteMatch();
  const routes = {
    design: `${path}/${builderRoutes.design}`,
    contentSettings: `${path}/${builderRoutes.contentSettings}`,
    preview: `${path}/${builderRoutes.preview}`,
  };

  const lastPathSegment = location.pathname.split("/").pop();

  const currentTab = Object.values(builderRoutes).find(
    (route) => route === lastPathSegment
  ) as BuilderNavTab;

  return (
    <Builder
      data-testid="email-builder"
      apiValue={touchpointContent}
      contentName={currentTouchpoint.name}
      baseUrl={baseEmailUrl}
      previewMode={previewMode}
      permission={Permission.PERM_TOUCHPOINT_WRITE}
      isSaved={isSaved}
      setIsSaved={setIsSaved}
      isSaving={isSaving}>
      <BuilderTabNav
        data-testid="email-builder--tab-nav"
        isPreviewMode={previewMode}
        url={url}
        invalidTabs={invalidTabs}
        setShowConfirmation={() => setShowModal(true)}
        currentTab={currentTab}
        setNavigateTo={setNavigateTo}
      />

      <ContentWrapper>
        <Content backgroundColor="bg.content" showBreadcrumbs={false}>
          {isNewTouchpoint ? (
            <div className="flex flex-1 justify-center items-center">
              <LoadingSpinner />
            </div>
          ) : (
            <Switch>
              {!previewMode ? (
                <Redirect exact from={path} to={routes.design} />
              ) : (
                <Redirect exact from={path} to={routes.preview} />
              )}
              {!previewMode && (
                <Route path={routes.design}>
                  <EmailBuilderDesignTab
                    onSave={saveTouchpoint}
                    setIsSaved={setIsSaved}
                    setIsSaving={setIsSaving}
                  />
                </Route>
              )}
              <Route path={routes.contentSettings}>
                <EmailBuilderContentSettingsTab
                  previewMode={previewMode}
                  formFields={formFields}
                  onSave={saveTouchpoint}
                  setIsSaved={setIsSaved}
                  setIsSaving={setIsSaving}
                  setInvalidTabs={setInvalidTabs}
                />
              </Route>
              <Route path={routes.preview}>
                <EmailBuilderPreviewTab previewMode={previewMode} />
              </Route>
            </Switch>
          )}
          <ConfirmationModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            headline="Are you sure you want to leave the tab?"
            message="All invalid fields won't be saved. The form will be saved with the last valid values."
            cancelButtonText="Stay on the tab"
            confirmButtonText="Leave the tab"
            onConfirm={handleConfirm}
            modalType="warning"
          />
        </Content>
      </ContentWrapper>
    </Builder>
  );
};

export const TouchpointBuilderTabForm = ({ children, ...rest }: FormProps) => (
  <Form width="2xl" isCentered {...rest}>
    {children}
  </Form>
);

export interface BuilderTabProps {
  previewMode?: boolean;
  themeConfig?: BeeContentDefaultProps;
}

export default EmailBuilder;
