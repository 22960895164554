import { twMerge } from "tailwind-merge";

import { TABLE_CELL_PADDING } from "components/table/table-cell/table-cell";
import { SortButton } from "components/table/table-header/sort-button";

export interface ITableColumn<ColumnId> {
  id: ColumnId;
  label: string;
  width?: string;
  isSortable?: boolean;
}

interface TableHeaderProps<ColumnId> {
  column: ITableColumn<ColumnId> | string;
  sortBy?: string[];
  handleTableSort?: (columnId: ColumnId) => void;
}

export const TableHeader = <ColumnId,>({
  column,
  sortBy = [],
  handleTableSort,
}: TableHeaderProps<ColumnId>) => {
  const [sortColumn, sortDirection] = sortBy;

  const isColumnObject = typeof column !== "string";
  const columnId = isColumnObject ? column.id : column;
  const columnLabel = isColumnObject ? column.label : column;
  const columnWidth = isColumnObject ? column.width : undefined;
  const isSortable = isColumnObject ? column.isSortable !== false : false;

  const isSortedDesc = sortColumn === columnId && sortDirection === "desc";

  const thClassNames = twMerge(
    "text-sm tracking-wide whitespace-nowrap",
    TABLE_CELL_PADDING,
    "pt-1"
  );

  return (
    <th scope="col" className={thClassNames} style={{ width: columnWidth }}>
      <div className="flex items-center">
        <span>{columnLabel}</span>
        {isSortable && handleTableSort && (
          <SortButton
            columnId={columnId as ColumnId}
            isSortedDesc={isSortedDesc}
            handleTableSort={handleTableSort}
            className="ml-2"
          />
        )}
      </div>
    </th>
  );
};
