import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import ThemePage from "containers/admin/clients/client/tab-components/themes-tab/theme/theme-page";
import BuilderProvider from "containers/admin/clients/touchpoint/components/builder/builder-provider/builder-provider";
import ThemeBuilderWrapper from "containers/admin/clients/client/tab-components/themes-tab/components/theme-builder/theme-builder-wrapper/theme-builder-wrapper";

import { useCurrentClient } from "state/ducks";

import Theme from "models/theme";

import { Route as AppRoute } from "utilities/app-routes";

const ThemeRoutes = () => {
  const currentClient = useCurrentClient();
  const { path, url } = useRouteMatch();

  const findTheme = (id: string) => {
    return Theme.find({ clientId: currentClient.id, id });
  };

  return (
    <Switch>
      <Redirect exact from={path} to={path + AppRoute.edit} />
      <Route path={path + AppRoute.themeBuilder + "/:builderId"}>
        <BuilderProvider findBuilderValue={findTheme}>
          <ThemeBuilderWrapper baseThemeUrl={`${url}/versions`} />
        </BuilderProvider>
      </Route>
      <Route path={path + AppRoute.themePreview + "/:builderId"}>
        <BuilderProvider findBuilderValue={findTheme}>
          <ThemeBuilderWrapper baseThemeUrl={`${url}/versions`} previewMode />
        </BuilderProvider>
      </Route>
      <Route path={[path + AppRoute.edit, path + AppRoute.versions]}>
        <ThemePage baseThemeUrl={url} />
      </Route>
    </Switch>
  );
};

export default ThemeRoutes;
