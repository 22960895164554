import { Box, useTheme } from "@chakra-ui/react";
import { IBeeConfig } from "@mailupinc/bee-plugin/dist/types/bee";

import Bee from "containers/admin/clients/touchpoint/components/bee-editor/bee/bee";
import { contentGutter } from "components/partials/layout/layout";

import { useCurrentClient } from "state/ducks/clients";

import { BeeContentDefaultProps } from "types/bee";
import { TouchpointType } from "types/touchpoint";

/**
 * https://docs.beefree.io/tracking-message-changes/#callback-parameters */
export interface BeeChangeResponse {
  code: string;
  description: string;
  value: string;
  patches: Record<string, any>[];
}

interface BeeEditorProps extends Omit<IBeeConfig, "container"> {
  editorType: TouchpointType;
  initialJson?: string;
  themeConfig?: BeeContentDefaultProps;
}

const BeeEditor = ({ editorType, initialJson, onChange, themeConfig }: BeeEditorProps) => {
  const client = useCurrentClient();
  const theme = useTheme();

  const beeStyles = {
    height: `calc(100% + ${theme.sizes[contentGutter]})`,
    marginLeft: `-${theme.sizes[contentGutter]}`,
    width: `calc(100% + ${theme.sizes[contentGutter * 2]})`,
  };

  let parsedTemplate = undefined;
  if (initialJson) {
    try {
      parsedTemplate = JSON.parse(initialJson);
    } catch (error) {
      console.log(error);
    }
  }

  const attachSandboxAttributeToIframe = () => {
    document
      .querySelectorAll("iframe")
      .forEach((iframeNode) => iframeNode.setAttribute("sandbox", ""));
  };

  return (
    <Box id="bee-content-creator" flex="1">
      <Bee
        isEmail={editorType === TouchpointType.EMAIL}
        style={beeStyles}
        onChange={onChange}
        onSend={(htmlFile: any) => console.log("--onSend--", htmlFile, "--/onSend--")}
        onSaveAsTemplate={(jsonFile: any) =>
          console.log("--onSaveAsTemplate--", jsonFile, "--/onSaveAsTemplate--")
        }
        onError={(errorMessage: any) => console.log(errorMessage)}
        beeConfig={{
          contentDefaults: themeConfig,
          onLoad: attachSandboxAttributeToIframe,
        }}
        baseTemplate={parsedTemplate}
        clientId={client.id}
      />
    </Box>
  );
};

export default BeeEditor;
