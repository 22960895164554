import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  FlexProps,
  Collapse,
  useMediaQuery,
  IconButton,
  StackProps,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

import { Logo } from "components/partials/logo/logo";
import NavLink from "components/partials/nav-link/nav-link";
import UserMenu from "components/partials/user-menu/user-menu";
import DevToolsModal from "components/modals/dev-tools-modal/dev-tools-modal";
import { CountBadge } from "components/partials/count-badge/count-badge";
import { usePaginationState } from "components/partials/paginated-table/paginated-table";

import { useCurrentUser } from "state/ducks";
import { Client } from "models";

import { Route as AppRoute } from "utilities/app-routes";
import { dashCase } from "utilities";
import { hasPermission } from "utilities/user";

import { IS_PRODUCTION } from "utilities/constants";

import { maxMobile } from "themes/apollo/breakpoints";

import { Permission } from "types/auth";
import { InboxFilter } from "types/inbox";

const AppHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);
  const devToolsModal = useDisclosure();

  const toggle = () => setIsOpen((isOpen) => !isOpen);

  const openDevToolsModal = () => devToolsModal.onOpen();

  return (
    <NavbarWrapper bg="primary" data-testid="app-header" py={{ base: 2, md: 0 }} zIndex={0}>
      <Box order={{ md: 1 }} flexBasis={{ base: "50%", md: "auto" }}>
        <Logo isClientUser={isClientUser} />
      </Box>

      <HStack
        order={{ md: 3 }}
        spacing={4}
        flexBasis={{ base: "50%", md: "auto" }}
        marginLeft="auto"
        justify="flex-end">
        <HStack spacing={2}>
          {!IS_PRODUCTION && (
            <Box
              cursor="pointer"
              fontWeight="bold"
              color="white"
              mr="2"
              onClick={openDevToolsModal}>
              Dev tools
            </Box>
          )}
          {isClientUser && currentUser.clients.length > 0 && (
            <Box fontWeight="bold" color="white" mr="2">
              {currentUser.clients[0].name}
            </Box>
          )}
          <Box>
            <UserMenu />
          </Box>
        </HStack>
        <NavToggle isOpen={isOpen} toggle={toggle} />
      </HStack>

      <DevToolsModal {...devToolsModal} />

      <NavCollapse order={{ md: 2 }} paddingLeft="3.25rem" isOpen={isOpen}>
        <NavLinks />
      </NavCollapse>
    </NavbarWrapper>
  );
};

export default AppHeader;

// ---------------------------------------------
export interface NavbarWrapperProps extends FlexProps {}
export const NavbarWrapper = ({ ...rest }: NavbarWrapperProps) => {
  return (
    <Flex
      data-testid="navbar-wrapper"
      align="center"
      justify="space-between"
      wrap="wrap"
      px={6}
      boxShadow="xs"
      zIndex="dropdown"
      {...rest}
    />
  );
};

// ---------------------------------------------

interface NavCollapseProps extends FlexProps {
  isOpen: boolean;
}

const NavCollapse = ({ isOpen, children, ...rest }: NavCollapseProps) => {
  const [isMobile] = useMediaQuery(`(max-width: ${maxMobile})`);

  return (
    <Box data-testid="nav-collapse" {...rest}>
      <Collapse in={isMobile ? isOpen : true} unmountOnExit animateOpacity>
        {children}
      </Collapse>
    </Box>
  );
};

// ---------------------------------------------

type Link = {
  to: AppRoute;
  text: string;
  assignmentCount?: number;
};

const NavLinks = ({ ...rest }: StackProps) => {
  const [isApprovedPageShown, setIsApprovedPageShown] = useState(false);

  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);
  const paginationState = usePaginationState();

  useEffect(() => {
    //get Approved page elements amount to make Approved nav item visible or not
    const filters: InboxFilter = {
      touchpointVersionStatus: ["PUBLISHED", "APPROVED"],
      touchpointType: [],
    };

    isClientUser &&
      Client.getReviews(
        { ...paginationState, sort: "lastModifiedDate,desc", page: 0 },
        filters
      ).then((res) => {
        setIsApprovedPageShown(!!res.data.totalElements);
      });
    // eslint-disable-next-line
  }, []);

  const clientUserLinks: Link[] = [
    {
      to: AppRoute.reviews,
      text: "Reviews",
    },
  ];

  !!isApprovedPageShown &&
    clientUserLinks.push({
      to: AppRoute.approved,
      text: "Approved",
    });

  const staffUserLinks: Link[] = [
    { to: AppRoute.clients, text: "Clients" },
    {
      to: AppRoute.inbox,
      text: "Inbox",
    },
  ];

  const links = isClientUser ? clientUserLinks : staffUserLinks;

  return (
    <HStack as="nav" data-testid="nav-links" spacing={{ base: 4, md: 8, lg: 12 }} {...rest}>
      {links.map(({ assignmentCount, text, to }) => {
        const badgeCount = assignmentCount || 0;
        const dashText = dashCase(text);

        return (
          <NavLink
            activeColor="white"
            borderWidth={8}
            color="white"
            data-testid={`nav-link-${dashText}`}
            key={dashText}
            marginBottom={0}
            opacity="1!important"
            textAlign="center"
            to={to}>
            {text}
            {badgeCount > 0 && <CountBadge boxShadow="3d" count={badgeCount} ml={1} mt={-1} />}
          </NavLink>
        );
      })}
    </HStack>
  );
};

// ---------------------------------------------

const NavToggle = ({ toggle, isOpen }: { toggle: () => void; isOpen: boolean }) => (
  <IconButton
    display={["block", "none"]}
    aria-label={`${isOpen ? "Close Menu" : "Open Menu"}`}
    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
    onClick={toggle}
    color="black"
  />
);

// ---------------------------------------------

// interface NotificationsIconProps extends AvatarProps {
//   count?: number;
// }

// const NotificationsIcon: React.FC<NotificationsIconProps> = ({ count = 0, ...rest }) => (
//   <Avatar
//     bg={count === 0 ? "transparent" : "gray.400"}
//     color={count === 0 ? "gray.400" : "white"}
//     icon={<BellIcon fontSize="2.5rem" />}
//     {...rest}>
//     {count > 0 && (
//       <AvatarBadge bg="accent.100" boxSize="1em" borderWidth="2px">
//         <ExtraSmall as="span">{count}</ExtraSmall>
//       </AvatarBadge>
//     )}
//   </Avatar>
// );
