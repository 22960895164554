import { Badge, BadgeProps } from "@chakra-ui/react";

interface CountBadgeProps extends Omit<BadgeProps, "children"> {
  count: number;
}

export const CountBadge = ({ count, ...rest }: CountBadgeProps) => {
  return (
    <Badge
      alignItems="center"
      backgroundColor="error.200"
      color="white"
      display="inline-flex"
      fontSize="xs"
      fontWeight="bold"
      justifyContent="center"
      lineHeight="none"
      minWidth={6}
      height={6}
      paddingX={2}
      rounded="full"
      {...rest}>
      {count}
    </Badge>
  );
};
