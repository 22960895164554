import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, SimpleGrid, HStack, Stack, Code } from "@chakra-ui/react";

import Button, { ButtonProps } from "components/forms/button/button";
import { ExtraSmall, H5 } from "components/partials/typography/typography";

const BUTTON_VARIANTS = ["primary", "secondary", "tertiary", "link"];
const BUTTON_SIZES = [/*"lg",*/ "md", "sm" /*,"xs"*/];

const UIKitButtons = () => {
  interface ButtonGroupProps {
    variant?: ButtonProps["variant"];
    size: string;
  }
  const ButtonGroup = ({ variant, size }: ButtonGroupProps) => (
    <Stack spacing={4} _first={{ mr: 2 }}>
      <ExtraSmall as={Code}>size="{size}"</ExtraSmall>
      <Button variant={variant} size={size} leftIcon={<ChevronLeftIcon />}>
        Label
      </Button>
      <Button variant={variant} size={size}>
        Label
      </Button>
      <Button variant={variant} size={size} rightIcon={<ChevronRightIcon />}>
        Label
      </Button>
    </Stack>
  );

  return (
    <SimpleGrid columns={4} spacing={8}>
      {(BUTTON_VARIANTS as ButtonProps["variant"][]).map((variant) => (
        <Flex direction="column">
          <H5 mb={4}>{variant} Buttons</H5>
          <Code mb={4}>variant="{variant}"</Code>
          <HStack key={variant} align="top">
            {BUTTON_SIZES.map((size) => (
              <ButtonGroup key={variant + size} variant={variant} size={size} />
            ))}
          </HStack>
        </Flex>
      ))}
    </SimpleGrid>
  );
};

export default UIKitButtons;
