import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { twMerge } from "tailwind-merge";

interface SortButtonProps<ColumnId> extends React.HTMLAttributes<HTMLButtonElement> {
  columnId: ColumnId;
  isSortedDesc: boolean;
  handleTableSort: (columnId: ColumnId) => void;
}

export const SortButton = <ColumnId,>({
  columnId,
  isSortedDesc,
  handleTableSort,
  className,
}: SortButtonProps<ColumnId>) => (
  <button
    type="button"
    title={isSortedDesc ? "Click to Sort Ascending" : "Click to Sort Descending"}
    className={twMerge("cursor-pointer", className)}
    onClick={() => handleTableSort(columnId)}>
    {isSortedDesc ? (
      <MdArrowDropDown className="text-azure size-4" />
    ) : (
      <MdArrowDropUp className="text-aqua-dark size-4" />
    )}
  </button>
);
