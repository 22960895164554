import React, { useEffect } from "react";
import Bee from "@mailupinc/bee-plugin";
import { IToken, IBeeConfig } from "@mailupinc/bee-plugin/dist/types/bee";

import { Email, LandingPage } from "models";

interface BeeProps extends Omit<IBeeConfig, "container"> {
  baseTemplate?: string | object;
  beeConfig?: Omit<IBeeConfig, "container">;
  clientId?: string;
  isEmail?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const BEE_CONTAINER_ID = "bee-plugin-container";
const BASE_TEMPLATE =
  "https://raw.githubusercontent.com/BEE-Plugin/BEE-FREE-templates/master/v2%20Email%20Templates/BF-blank-template.json";

const DEFAULT_CONFIGURATION = {
  container: BEE_CONTAINER_ID,
  language: "en-US",
  autosave: false,
  trackChanges: true,
};

const BeeComponent: React.FC<BeeProps> = ({
  baseTemplate,
  onAutoSave,
  onChange,
  onSend,
  onSaveAsTemplate,
  beeConfig,
  clientId,
  isEmail = true,
  style,
  className,
}) => {
  const fetchTemplate = async (templateUrl: string) => {
    try {
      const response = await fetch(templateUrl);
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch template:", error);
      throw error;
    }
  };

  useEffect(() => {
    const initializeBee = async () => {
      try {
        const getBeeToken = isEmail
          ? (clientId: string) => Email.getToken(clientId)
          : (clientId: string) => LandingPage.getToken(clientId);

        const token: IToken = await getBeeToken(clientId as string);

        const beeConfigWithDefaults = {
          ...DEFAULT_CONFIGURATION,
          ...beeConfig,
          onAutoSave,
          onChange,
          onSaveAsTemplate,
          onSend,
          uid: clientId,
          autosave: typeof beeConfig?.autosave === "number" ? beeConfig.autosave : 0,
        };

        const builder = new Bee(token);

        // Fetch the template if not provided.
        const template = baseTemplate ?? (await fetchTemplate(BASE_TEMPLATE));

        builder.start(beeConfigWithDefaults, template);
      } catch (error) {
        console.error("Failed to initialize BEE Plugin:", error);
      }
    };

    initializeBee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={BEE_CONTAINER_ID} style={style} className={className} />;
};

export default BeeComponent;
