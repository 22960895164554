import { useHistory } from "react-router-dom";
import { ChevronLeftIcon } from "@chakra-ui/icons";

import RouterLinkButton from "components/partials/router-link-button/router-link-button";
import Button, { ButtonProps } from "components/forms/button/button";
import { Span } from "components/partials/typography/typography";

interface BackButtonProps extends ButtonProps {
  text?: string;
  path?: string;
  withSeparator?: boolean;
  onClick?: () => void;
}

const BackButton = ({
  text = "Back",
  path,
  withSeparator = false,
  onClick,
  ...rest
}: BackButtonProps) => {
  const history = useHistory();

  function handleClick() {
    return onClick ? onClick() : history.goBack();
  }

  if (path) {
    return (
      <RouterLinkButton to={path}>
        <ChevronLeftIcon /> {text}
      </RouterLinkButton>
    );
  }

  return (
    <Button
      data-testid="back-button"
      variant="link"
      onClick={handleClick}
      padding="0"
      lineHeight="none"
      {...rest}>
      <ChevronLeftIcon /> {text}
      {withSeparator && (
        <Span data-testid="back-button-separator" color="gray.300" px={4}>
          |
        </Span>
      )}
    </Button>
  );
};

export default BackButton;
