import React, { useState } from "react";
import classNames from "classnames";

import { LabelValuePair } from "types";

interface RadioGroupProps {
  options: LabelValuePair[];
  groupName: string;
  onChange: (value: string) => void;
  groupLabel: string;
  defaultSelectedValue?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  groupName,
  onChange,
  groupLabel,
  defaultSelectedValue = "",
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultSelectedValue);

  const handleSelectionChange = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <div className="flex items-center mb-4 space-x-4">
      <label className="font-bold text-sm text-neutral-1 mr-4">{groupLabel}</label>
      <div className="flex space-x-4">
        {options.map((option) => {
          const isSelected = selectedValue === option.value;
          return (
            <label
              key={option.value}
              className="flex items-center space-x-2 cursor-pointer text-neutral-2">
              <input
                type="radio"
                name={groupName}
                value={option.value}
                checked={isSelected}
                onChange={() => handleSelectionChange(option.value)}
                className={classNames("h-4 w-4 rounded-full cursor-pointer", {
                  "appearance-none border-[5px] border-aqua-dark": isSelected,
                })}
              />
              <span className="text-sm text-neutral-2">{option.label}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default RadioGroup;
