// prettier-ignore
export enum Role {
    ROLE_ACCOUNT_MANAGER  = "ROLE_ACCOUNT_MANAGER",
    ROLE_CAMPAIGN_MANAGER = "ROLE_CAMPAIGN_MANAGER",
    ROLE_CLIENT_ADMIN     = "ROLE_CLIENT_ADMIN",
    ROLE_CLIENT_USER      = "ROLE_CLIENT_USER",
    ROLE_CONTRACTOR_USER  = "ROLE_CONTRACTOR_USER",
    ROLE_PRODUCT_ADMIN    = "ROLE_PRODUCT_ADMIN",
    ROLE_STAFF_USER       = "ROLE_STAFF_USER",
    ROLE_SUPER_ADMIN      = "ROLE_SUPER_ADMIN",
  }

// prettier-ignore
export enum Permission {
    PERM_THEME_PUBLISH = "PERM_THEME_PUBLISH",
    PERM_ALL_CLIENTS   = "PERM_ALL_CLIENTS",
    PERM_CLIENT_READ   = "PERM_CLIENT_READ",
    PERM_CLIENT_WRITE  = "PERM_CLIENT_WRITE",
    PERM_CLIENT_USER   = "PERM_CLIENT_USER",
    PERM_THEME_READ    = "PERM_THEME_READ",
    PERM_THEME_WRITE   = "PERM_THEME_WRITE",
    PERM_USER_MANAGE   = "PERM_USER_MANAGE",
    PERM_CAMPAIGN_READ  = "PERM_CAMPAIGN_READ",
    PERM_CAMPAIGN_WRITE = "PERM_CAMPAIGN_WRITE",
    PERM_TOUCHPOINT_READ = "PERM_TOUCHPOINT_READ",
    PERM_TOUCHPOINT_WRITE = "PERM_TOUCHPOINT_WRITE",
    PERM_TOUCHPOINT_PUBLISH = "PERM_TOUCHPOINT_PUBLISH",
    PERM_TOUCHPOINT_ADVANCED = "PERM_TOUCHPOINT_ADVANCED",
    PERM_CLIENT_ADVANCED = "PERM_CLIENT_ADVANCED",
    PERM_COMMENT_WRITE = "PERM_COMMENT_WRITE",
    PERM_CLIENT_APPROVAL = "PERM_CLIENT_APPROVAL",
  }
