import { useState, useRef, useContext, useEffect } from "react";
import {
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Box,
  Stack,
  PopoverTrigger,
} from "@chakra-ui/react";

import { AnnotationCommentDisplay } from "containers/admin/clients/touchpoint/components/comments/annotation-comment-display/annotation-comment-display";
import { AnnotationCommentInput } from "containers/admin/clients/touchpoint/components/comments/annotation-comment-input/annotation-comment-input";
import {
  CommentPopoverButton,
  CommentPopoverButtonProps,
} from "containers/admin/clients/touchpoint/components/comments/comment-popover-button/comment-popover-button";

import CommentContext from "contexts/comment-context";
import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";
import useOnScreen from "hooks/use-on-screen";

import { Comment, CommentReply, NewComment } from "types/comments";
import { Permission } from "types/auth";

interface CommentPopoverProps extends ChakraPopoverProps {
  moduleId: string; // Move this to the input
  comment?: Comment;
  commentTriggerProps?: CommentPopoverButtonProps;
  isCommentPopoverOpen: boolean;
  isModuleHovered: boolean;
  closeCommentPopover(): void;
  toggleCommentPopover(): void;
}
export const CommentPopover = ({
  moduleId,
  comment,
  commentTriggerProps,
  isCommentPopoverOpen,
  isModuleHovered,
  toggleCommentPopover,
  closeCommentPopover,
  ...rest
}: CommentPopoverProps) => {
  const currentUser = useCurrentUser();

  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const [isCommentInputExpanded, setIsCommentInputExpanded] = useState(true);
  const [commentReplies, setCommentReplies] = useState<CommentReply[]>([]);

  const commentInputRef = useRef<HTMLTextAreaElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const { loadCommentReplies, submitNewComment, submitNewCommentReply } =
    useContext(CommentContext);

  useEffect(
    function appendCommentReplies() {
      if (comment) {
        loadCommentReplies(comment)
          .then((replies) => setCommentReplies(replies.content))
          .catch((err) => console.error(err));
      }
    },
    [comment, loadCommentReplies]
  );

  function handleSubmit(submission: NewComment) {
    if (!comment) {
      const newComment: NewComment = { ...submission, ...{ annotationId: moduleId } };
      return submitNewComment(newComment).then(() => {
        // setIsCommentInputExpanded(false);
      });
    }
    const newReply: NewComment = { ...submission, ...{ parentId: comment.id } };
    return submitNewCommentReply(newReply)
      .then((reply) => {
        if (reply) {
          // setIsCommentInputExpanded(false);
          setCommentReplies((prevReplies) => [...prevReplies, reply]);
          scrollRef.current?.scrollTo({
            top: scrollRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => console.error("error submitting comment reply", err));
  }

  const triggerRef = useRef<HTMLButtonElement>(null);
  const isTriggerWithinViewport = useOnScreen(triggerRef);
  const isTriggerDisplayed = !!comment || isModuleHovered || isCommentPopoverOpen;

  useEffect(() => {
    /**
     * Hide popover when it's scrolled out of viewport  */
    if (isCommentPopoverOpen && !isTriggerWithinViewport) {
      closeCommentPopover();
    }
  }, [closeCommentPopover, isCommentPopoverOpen, isTriggerWithinViewport]);

  return (
    <ChakraPopover
      arrowSize={16}
      isLazy
      isOpen={isCommentPopoverOpen}
      initialFocusRef={commentInputRef}
      onOpen={toggleCommentPopover}
      onClose={closeCommentPopover}
      {...rest}>
      <PopoverTrigger>
        <CommentPopoverButton
          comment={comment}
          ref={triggerRef}
          opacity={isTriggerDisplayed ? 1 : 0}
          {...commentTriggerProps}
        />
      </PopoverTrigger>

      <Portal>
        <Box position="relative" zIndex="popover">
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Stack width="100%">
                {!!comment && (
                  <Box
                    textAlign="left"
                    maxHeight="364px"
                    overflowY="auto"
                    paddingBottom={1}
                    ref={scrollRef}
                    border="1px solid #e6e6e6">
                    <AnnotationCommentDisplay
                      comment={comment}
                      isClientUser={isClientUser}
                      replies={commentReplies}
                      // toggleReplyInput={() => setIsCommentInputExpanded(true)}
                    />
                  </Box>
                )}
                <AnnotationCommentInput
                  isDisabled={!!comment && !comment.open}
                  parentId={!!comment ? comment.id : undefined}
                  commentType={!!comment ? "reply" : "main"}
                  isClientUser={isClientUser}
                  submitNewComment={handleSubmit}
                  setReplies={setCommentReplies}
                  onCancel={closeCommentPopover}
                  commentInputRef={commentInputRef}
                  isExpanded={isCommentInputExpanded}
                  setIsExpanded={setIsCommentInputExpanded}
                />
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </ChakraPopover>
  );
};
