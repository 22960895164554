import { useEffect, useState } from "react";
import { RiLayout5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { MdContentCopy, MdEmail } from "react-icons/md";

import Button from "components/forms/button/button";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";
import {
  RouteMatchTypes,
  ParamsType,
} from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";

import {
  useCurrentCampaign,
  useCurrentClient,
  useCurrentTouchpoint,
  useCurrentUser,
} from "state/ducks";
import { touchpointVersionsSelector } from "state/ducks/touchpoint";
import User from "models/user";
import Client from "models/client";
import Campaign from "models/campaign";
import { Touchpoint as TouchpointInteface } from "models";

import { useTouchpointVersionsDictionary } from "hooks/use-touchpoint-versions-dictionary";

import { Route as AppRoute } from "utilities/app-routes";
import { replaceEndingToUrl } from "utilities";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { TouchpointType } from "types/touchpoint";
import { ChildPageProps, ContentStatus } from "types";

interface BreadcrumbsType {
  client: Client;
  campaign: Campaign;
  touchpoint: TouchpointInteface;
  touchpointCurrentTab: string;
  params?: ParamsType;
  url: string;
  currentUser: User;
}

const getBreadcrumbsForCurrentPage = ({
  client,
  campaign,
  touchpoint,
  touchpointCurrentTab,
  params,
  url,
  currentUser,
}: BreadcrumbsType) => {
  return !hasPermission(currentUser, Permission.PERM_CLIENT_USER)
    ? [
        { label: client?.name, href: `/clients/${params?.clientId}/campaigns` },
        {
          label: campaign?.name,
          href: `/clients/${params?.clientId}/campaigns/${params?.campaignId}/creative`,
        },
      ]
    : [
        { label: "Reviews", href: "/reviews" },
        { label: client?.name, isActive: true },
      ];
};

const TouchpointPageHeader = ({ parentUrl }: ChildPageProps) => {
  const currentTouchpoint = useCurrentTouchpoint();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentUser = useCurrentUser();
  const history = useHistory();
  const versions = useSelector(touchpointVersionsSelector);
  const [touchpointCurrentTab, setTouchpointCurrentTab] = useState<string>("Preview");
  const [draftCount, setDraftCount] = useState<number>(0);
  const { url, params }: RouteMatchTypes = useRouteMatch();

  const copyUrlToClipboard = () => {
    const currentTouchpointUrl = window.location.href;
    const urlToCopy = replaceEndingToUrl(currentTouchpointUrl, AppRoute.preview);
    // the old version of the below command is document.execCommand("copy")
    // but that has a deprecation warning. The new command also has the same warning
    // but that it has YET to be implemented so... the new one will be more future proof
    navigator.clipboard.writeText(urlToCopy);
  };

  const changeTab = (option: string) => {
    setTouchpointCurrentTab(option);

    switch (option) {
      case "Details":
        history.push(parentUrl + AppRoute.details);
        break;
      case "Preview":
        history.push(parentUrl + AppRoute.preview);
        break;
      case "Version history":
        history.push(parentUrl + AppRoute.versions);
        break;
      default:
        break;
    }
  };

  const getSelectedTab = () => {
    if (window.location.href.endsWith(AppRoute.details)) {
      return "Details";
    }
    if (window.location.href.endsWith(AppRoute.preview)) {
      return "Preview";
    }
    if (window.location.href.endsWith(AppRoute.versions)) {
      return "Version history";
    }
  };

  const { touchpointVersionsDictionary } = useTouchpointVersionsDictionary();

  useEffect(
    // Count how many items from this touchpoint version are in
    // a draft state and set the count to display in the header.
    () => {
      const draftStatusNames = ["Draft", "System draft"];

      if (touchpointVersionsDictionary.length && versions.items) {
        /**
         * Returns a Set like `{'APPROVED', 'CLIENT_REVIEW', 'DRAFT', 'SYSTEM_DRAFT'}` */
        const draftStatuses = new Set(
          touchpointVersionsDictionary
            .filter(({ versionStatusName }) => draftStatusNames.includes(versionStatusName))
            .map(({ status }) => status)
        );
        /**
         * If `item.status` matches a value in `draftStatuses`, increment the count. */
        const numDrafts = versions.items.reduce((draftCount, item) => {
          if (item.status && draftStatuses.has(item.status)) return draftCount + 1;
          return draftCount;
        }, 0);

        setDraftCount(numDrafts);
      }
    },
    [touchpointVersionsDictionary, versions.items]
  );

  return (
    <Box backgroundColor="gray.200" pt={1} mx={-8} px={8} mb={5}>
      <Breadcrumbs
        customClass="mt-0 !mb-1.5"
        breadcrumbs={getBreadcrumbsForCurrentPage({
          client: currentClient,
          campaign: currentCampaign,
          touchpoint: currentTouchpoint,
          touchpointCurrentTab,
          params,
          url,
          currentUser,
        })}
      />
      <Flex paddingBottom="2" justifyContent="space-between" alignItems="center">
        <Flex alignItems={"center"}>
          <Icon
            as={currentTouchpoint.type === TouchpointType.EMAIL ? MdEmail : RiLayout5Fill}
            fontSize="xl"
            color="gray.2"
            mr={2}
            data-testid={currentTouchpoint.type + "-type"}
          />
          <span className="text-navy font-bold text-xl" data-testid="touchpoint-name">
            {currentTouchpoint.name}
          </span>
        </Flex>

        <Button
          size="sm"
          leftIcon={<Icon as={MdContentCopy} fontSize="lg" mb={1} mr={-1} />}
          variant="link"
          onClick={copyUrlToClipboard}
          padding="0"
          data-testid="copy-record-url-button">
          Copy record URL
        </Button>
      </Flex>
      <Flex justify="between" paddingBottom="2" fontSize="sm">
        {versions.items.find((v) => v.status === ContentStatus.PUBLISHED)?.version ? (
          <span>{`v${
            versions.items.find((v) => v.status === ContentStatus.PUBLISHED)?.version
          }`}</span>
        ) : (
          <span>No published version</span>
        )}
        {/* Drafts exist and user is not a CLIENT_USER */}
        {draftCount > 0 && !hasPermission(currentUser, Permission.PERM_CLIENT_USER) && (
          <>
            <span className="mx-3">|</span>
            <span>{draftCount} drafts</span>
          </>
        )}
      </Flex>
      {/* Hide toggle from CLIENT_USER */}
      {!hasPermission(currentUser, Permission.PERM_CLIENT_USER) && (
        <Box mt="3">
          <ToggleButtonGroup
            variant="primary"
            groupName="touchpoint-tab-toggle"
            options={["Preview", "Details", "Version history"]}
            onChange={(option) => changeTab(option)}
            value={getSelectedTab()}
            mb={0}
          />
        </Box>
      )}
    </Box>
  );
};

export default TouchpointPageHeader;
