// import { Styles } from "@chakra-ui/theme-tools";

const styles = {
  global: {
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
      bg: "bg.content",
      color: "text.body",
      overflow: "hidden",
    },
    "#root": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
};

export default styles;
