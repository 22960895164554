import ThemeBuilder from "containers/admin/clients/client/tab-components/themes-tab/components/theme-builder/theme-builder";

import { useCurrentBuilderContent } from "state/ducks/builder-content";

import { Theme } from "models";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

interface ThemeBuilderPageProps {
  baseThemeUrl: string;
  previewMode?: boolean;
}

const ThemeBuilderWrapper = ({ baseThemeUrl, previewMode = false }: ThemeBuilderPageProps) => {
  usePagePermission(Permission.PERM_THEME_READ);

  const currentBuilderContent = useCurrentBuilderContent();

  return (
    <ThemeBuilder
      theme={currentBuilderContent as Theme}
      baseThemeUrl={baseThemeUrl}
      previewMode={previewMode}
    />
  );
};

export default ThemeBuilderWrapper;
