import type { Theme, TypeOptions } from "react-toastify";

import ToastButton from "./toast-button";
import Icons from "assets";

type CloseButtonProps = {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  type: TypeOptions;
  theme: Theme;
  ariaLabel?: string;
};

const ToastCloseButton = ({
  closeToast,
  theme,
  type, // The `type` prop is extracted to prevent a mismatch with Toastify
  ...restProps
}: CloseButtonProps) => {
  return (
    <ToastButton
      color="white"
      variant="link"
      aria-label="Close Toast"
      onClick={closeToast}
      className="size-8 m-1 aspect-square"
      {...restProps}>
      <Icons.Close className="size-2.5" aria-hidden="true" focusable="false" />
    </ToastButton>
  );
};

export default ToastCloseButton;
