import { Redirect, Route, Switch, useRouteMatch, useParams, generatePath } from "react-router-dom";

import EmailNewPage from "containers/admin/clients/touchpoint/email/email-new-page/email-new-page";
import LPNewPage from "containers/admin/clients/touchpoint/lp/lp-new-page/lp-new-page";
import TouchpointPage from "containers/admin/clients/touchpoint/touchpoint-page";
import CampaignCreativePage from "containers/admin/clients/campaign/campaign-creative-page/campaign-creative-page";
import CreativeProvider from "containers/admin/clients/touchpoint/components/touchpoint-provider/touchpoint-provider";

import { Route as AppRoute } from "utilities/app-routes";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const CampaignCreativeRoutes = () => {
  const { path, url } = useRouteMatch();
  const { clientId, campaignId } = useParams<{ clientId: string; campaignId: string }>();

  const emailsRoute = `${path}${AppRoute.emails}`;
  const landingPagesRoute = `${path}${AppRoute.landingPages}`;

  usePagePermission(Permission.PERM_TOUCHPOINT_READ);

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={generatePath(emailsRoute, { clientId, campaignId })} />
      </Route>

      <Route exact path={[emailsRoute, landingPagesRoute]}>
        <CampaignCreativePage parentUrl={url} />
      </Route>

      <Route path={`${emailsRoute}${AppRoute.new}`}>
        <EmailNewPage baseCreativeUrl={url} />
      </Route>

      <Route path={`${landingPagesRoute}${AppRoute.new}`}>
        <LPNewPage baseCreativeUrl={url} />
      </Route>

      <Route path={`${path}/:touchpointId`}>
        <CreativeProvider>
          <TouchpointPage creativeIndexUrl={url} />
        </CreativeProvider>
      </Route>
    </Switch>
  );
};

export default CampaignCreativeRoutes;
