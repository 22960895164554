import { forwardRef, ReactElement } from "react";
import { twMerge } from "tailwind-merge";

import { CustomRadioInputType } from "./custom-radio-input.types";

const CustomRadioInput = forwardRef<HTMLInputElement, CustomRadioInputType>(
  (
    { label, radioValue, valueForEdit, setAction, onChange, customStylesLabel, labelFor, ...rest },
    ref
  ): ReactElement<any> => (
    <label
      className={twMerge(
        "flex items-center mb-3 mr-2 md:mr-4 text-gray-04 cursor-pointer",
        customStylesLabel
      )}>
      <input
        type="radio"
        className="peer w-2\/3 md:w-4 h-4 mr-2 accent-aqua-dark cursor-pointer"
        onChange={() => {
          setAction(radioValue);
          onChange(radioValue);
        }}
        checked={radioValue === rest.value || valueForEdit === rest.value}
        {...rest}
        value={radioValue}
        ref={ref}
      />
      <span className="peer-checked:font-semibold text-gray-03 text-sm md:text-base">{label}</span>
    </label>
  )
);

export default CustomRadioInput;
