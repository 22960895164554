import React from "react";
import uniqid from "uniqid";

import Breadcrumb from "./breadcrumb/breadcrumb";

import { BreadcrumbType, BreadcrumbsType } from "components/new/beadcrumbs/breadcrumbs.types";

const Breadcrumbs = ({ breadcrumbs, customClass = "" }: BreadcrumbsType) => {
  return (
    <div className={`${customClass} flex mt-5 mb-0`}>
      {!!breadcrumbs.length &&
        breadcrumbs.map((breadcrumb: BreadcrumbType, i: number) => (
          <Breadcrumb
            {...{
              key: uniqid(),
              isLast: i === breadcrumbs.length - 1,
              isFirst: i === 0,
              ...breadcrumb,
            }}
          />
        ))}
    </div>
  );
};

export default Breadcrumbs;
