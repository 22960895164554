import AlertBox from "components/partials/alert-box/alert-box";
import { TouchpointBuilderTabForm } from "containers/admin/clients/touchpoint/components/email-builder/email-builder";

const EmptyDesignPreview = ({ redirectTo }: { redirectTo: string }) => {
  return (
    <TouchpointBuilderTabForm>
      <AlertBox
        marginTop={8}
        alert={{
          status: "info",
          title: "Ready for Content",
          button: { path: redirectTo, label: "Get Started", variant: "secondary" },
        }}
        showCloseButton={false}
        maxW="2xl"
        marginX="auto"
      />
    </TouchpointBuilderTabForm>
  );
};

export default EmptyDesignPreview;
