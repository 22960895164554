import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, useDisclosure, Flex, Icon } from "@chakra-ui/react";
import { MdEmail } from "react-icons/md";
import { RiLayout5Fill } from "react-icons/ri";
import { HiAcademicCap } from "react-icons/hi2";

import { NavbarWrapper, NavbarWrapperProps } from "components/partials/header/header";
import BackButton from "components/partials/back-button/back-button";
import { H4, Small, Span } from "components/partials/typography/typography";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import { StatusDisplay } from "components/partials/status-display/status-display";

import { useCurrentClient } from "state/ducks";
import { Email } from "models";

import { displaySavedStatus } from "utilities";
import { Route as AppRoutes } from "utilities/app-routes";

import { Permission } from "types/auth";
import { BuilderContent } from "types";
import { TouchpointType } from "types/touchpoint";

interface BuilderHeaderProps extends NavbarWrapperProps {
  apiValue: BuilderContent;
  contentName?: string;
  baseUrl: string;
  readonly?: boolean;
  permission: Permission;
  isSaved: boolean;
  setIsSaved: (isSaved: boolean) => void;
  isSaving: boolean;
}

export const BuilderHeader = ({
  apiValue,
  contentName,
  baseUrl,
  readonly = false,
  permission,
  isSaved,
  setIsSaved,
  isSaving,
  ...rest
}: BuilderHeaderProps) => {
  const confirmExitModal = useDisclosure();
  const history = useHistory();

  const [shouldAlertBeforeExit, setShouldAlertBeforeExit] = useState(false);

  const [saveStatusMessage, setSaveStatusMessage] = useState<string>(
    displaySavedStatus(apiValue.lastModifiedDate)
  );

  useEffect(() => {
    const saveInProgress = "Saving changes...";
    let lastSaved = displaySavedStatus(apiValue.lastModifiedDate);

    if (isSaving) {
      setShouldAlertBeforeExit(true);
      setSaveStatusMessage(saveInProgress);
    }

    if (isSaved && !isSaving) {
      setShouldAlertBeforeExit(false);
      setSaveStatusMessage(lastSaved);
    }

    let pingInterval: ReturnType<typeof setInterval>;
    if (!readonly) {
      pingInterval = setInterval(() => {
        lastSaved = displaySavedStatus(apiValue.lastModifiedDate);
        setSaveStatusMessage(lastSaved);
      }, 5000);
    }

    // Cleanup function to clear interval
    return () => {
      if (pingInterval) {
        clearInterval(pingInterval);
      }
    };
  }, [isSaved, isSaving, apiValue.lastModifiedDate, readonly, saveStatusMessage]);

  function handleBackButton() {
    return shouldAlertBeforeExit ? confirmExitModal.onOpen() : goBack();
  }

  const goBack = () => {
    const designTabRedirect = baseUrl.replace(AppRoutes.versions, AppRoutes.preview);

    history.push(designTabRedirect);
  };

  return (
    <NavbarWrapper data-testid="touchpoint-builder-header" bg="bg.content" {...rest}>
      <Box width="20%">
        <BackButton data-testid="back-button" onClick={handleBackButton} text="Back" />
      </Box>

      <TouchpointDetails width="60%" apiValue={apiValue} contentName={contentName} />

      <Box width="20%" textAlign="right">
        <Small data-testid="autosave-message">{saveStatusMessage}</Small>
      </Box>

      {shouldAlertBeforeExit ? (
        <ConfirmationModal
          {...confirmExitModal}
          modalType="danger"
          headline={"Please wait. Saving changes..."}
          message={"Leaving now will result in the loss of unsaved data."}
          confirmButtonText="Exit Without Saving"
          onConfirm={goBack}
        />
      ) : (
        <ConfirmationModal
          {...confirmExitModal}
          modalType="success"
          headline="Changes have been saved."
          message="Changes have been saved. You're safe to go back."
          confirmButtonText="Continue"
          onConfirm={goBack}
        />
      )}
    </NavbarWrapper>
  );
};

const TouchpointDetails = ({
  apiValue,
  contentName,
  width,
}: {
  apiValue: BuilderContent;
  contentName?: string;
  width: string;
}) => {
  const currentClient = useCurrentClient();

  const name = apiValue?.name || contentName || undefined;
  const version = apiValue?.version ? `V${apiValue.version}` : undefined;
  const status = apiValue?.status || "DRAFT";

  const isEmail = (apiValue.attributes as Email).type === TouchpointType.EMAIL;

  return (
    <Flex align="center" justify="center" gap={4} width={width} paddingY={2}>
      <Flex color="muted.400">
        <Icon as={HiAcademicCap} mt="2px" mr={1} data-testid="academic-icon" />
        <Span>{currentClient.name}</Span>
      </Flex>
      {name && (
        <Flex color="primary">
          <Icon
            as={isEmail ? MdEmail : RiLayout5Fill}
            mt="1px"
            mr={1}
            data-testid="academic-icon"
          />
          <H4>{name}</H4>
        </Flex>
      )}
      {version && <Span>{version}</Span>}
      <StatusDisplay color="text.muted" status={status} />
    </Flex>
  );
};
