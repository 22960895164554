import { useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import { FiUpload } from "react-icons/fi";
import { Box, FormControl } from "@chakra-ui/react";
import { CloseButton } from "@chakra-ui/react";

import HelperText from "components/forms/helper-text/helper-text";
import Link from "components/partials/link/link";
import toast from "components/partials/toast/toast";

import { useCurrentClient } from "state/ducks";
import { Theme } from "models";

import { getErrorMessage } from "utilities";

import "utilities/styles/file-drop.css";

/**
 * Component to handle file upload. Works for image
 * uploads, but can be edited to work for any file.
 */

//check the src/utilities/styles/file-drop.css for styling questions

interface FileUploadProps {
  helperText?: string;
  currentFile?: any;
  fieldName?: string;
  form?: any;
}

interface LogoImageProps {
  name: string;
  type: string;
  size: string;
}

function FileUploader({ helperText, form, currentFile, fieldName, ...rest }: FileUploadProps) {
  const client = useCurrentClient();
  const [file, setFile] = useState<LogoImageProps>();
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFile(currentFile);
    // eslint-disable-next-line
  }, [currentFile]);

  const onTargetClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };

  // I could entertain an argument for making these checks passed in as an optional
  // parameter so that this file uploader is more modular but as the app stands now
  // this is a perfectly acceptable place to have these checks.
  function handleUpload(e: any, file = e.target.files[0], dragAndDrop = false) {
    const fileContents = dragAndDrop ? file[0] : file;
    //need to make sure that 'fileContents' exists in each possible case
    if (fileContents && fileContents.size / 1000 > 500) {
      toast.error({
        data: {
          title: "Failed to upload file",
          message: "Error - Logo files must be 500KB or smaller in size.",
        },
      });
    } else if (fileContents && fileContents.type !== "image/svg+xml") {
      // not sure if this is the best check for file type but it works
      toast.error({
        data: {
          title: "Failed to upload file",
          message: "Error - Only .SVG files are supported.",
        },
      });
    } else if (fileContents) {
      const formData = new FormData();

      setFile(fileContents);
      formData.append("uploadFile", fileContents);

      Theme.upload({ clientId: client.id, file: formData })
        .then((res) => {
          form.handleComplexChange(fieldName, res);
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to upload file",
              message: getErrorMessage(err),
            },
          });
        });
    }
  }

  const ImageThumb = ({ image, currentFile, form, fieldName }: any) => {
    function removeImage() {
      // @ts-ignore
      fileInputRef.current.value = null;
      form.handleComplexChange(fieldName, {});
    }
    return (
      <>
        <div className="logo-image-container">
          <img src={currentFile.url} alt={currentFile.name} />
          <CloseButton
            position="absolute"
            top={0}
            right={0}
            color="highlight.200"
            size={"lg"}
            onClick={removeImage}></CloseButton>
        </div>
      </>
    );
  };

  return (
    <FormControl data-testid="upload-box">
      <Box border="1px dashed #858B93" mb={2}>
        <FileDrop
          onTargetClick={onTargetClick}
          onDrop={(files, event) => {
            handleUpload(event, files, true);
          }}>
          <FiUpload color="highlight.200" size={28} />
          <input onChange={handleUpload} ref={fileInputRef} type="file" hidden />
          <Link>Choose a file</Link> or drag it here
        </FileDrop>
      </Box>
      {file && file.name && (
        <ImageThumb image={file} currentFile={currentFile} form={form} fieldName={fieldName} />
      )}
      {helperText && <HelperText message={helperText as string} />}
    </FormControl>
  );
}

export default FileUploader;
