import React from "react";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

// For non-router links
const Link: React.FC<LinkProps> = ({ isExternal = true, ...rest }) => {
  return (
    <ChakraLink
      data-testid="link"
      color="text.link"
      fontWeight="semibold"
      isExternal={isExternal}
      {...rest}
    />
  );
};

export default Link;
