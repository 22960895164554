import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Client, { ClientAttributes } from "models/client";

interface ClientsSlice {
  current: ClientAttributes | null;
}

const initialState: ClientsSlice = { current: null };

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setCurrentClient: (state, action: PayloadAction<ClientAttributes | null>) => {
      state.current = action.payload;
    },
  },
});

export const currentClientSelector = (state: { clients: ClientsSlice }) => state.clients.current;

export const { setCurrentClient } = clientsSlice.actions;
export default clientsSlice.reducer;

export function useCurrentClient(): Client {
  const attributes = useSelector(currentClientSelector);

  if (!attributes) {
    throw new Error("useCurrentClient: called before a client was stored in redux");
  }

  return useMemo(() => new Client(attributes), [attributes]);
}

export type { ClientsSlice };
