import classNames from "classnames";

import { BreadcrumbType } from "components/new/beadcrumbs/breadcrumbs.types";
import CustomLink from "components/new/custom-link/custom-link";

const Breadcrumb = ({ label, href, isActive, isLast, isFirst }: BreadcrumbType) => {
  const baseStyles = classNames("font-family font-normal first-letter:uppercase", {
    "px-1": !isFirst && !isLast,
    "pr-1": isFirst,
    "pl-1": isLast,
    "text-indigo-02 font-semibold": isActive,
    "text-aqua-dark": !isActive,
  });

  return (
    <>
      {isActive ? (
        <p className={baseStyles}>{label}</p>
      ) : (
        <CustomLink
          //solution for type of 'to' attribute
          to={typeof href === "string" ? href : ""}
          className={baseStyles}
          label={label}
        />
      )}
      {!isLast && "/"}
    </>
  );
};

export default Breadcrumb;
