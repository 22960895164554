import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Theme, { ThemeAttributes } from "models/theme";
import Email, { EmailAttributes } from "models/email";
import LandingPage, { LandingPageAttributes } from "models/landing-page";

import { BuilderContent, TouchpointBuilderContent } from "types";
import { isEmail, isLandingPage, isTheme } from "types/typeguards";

interface BuilderContentSlice {
  current: ThemeAttributes | EmailAttributes | LandingPageAttributes | null;
}

const initialState: BuilderContentSlice = { current: null };

export const builderContentSlice = createSlice({
  name: "builderContent",
  initialState,
  reducers: {
    setCurrentBuilderContent: (
      state,
      action: PayloadAction<ThemeAttributes | EmailAttributes | LandingPageAttributes | null>
    ) => {
      state.current = action.payload;
    },
  },
});

export const currentBuilderContentSelector = (state: { builderContent: BuilderContentSlice }) =>
  state.builderContent.current;

export const { setCurrentBuilderContent } = builderContentSlice.actions;
export default builderContentSlice.reducer;

/**
 * @deprecated Use `useTouchpointBuilderContent` instead
 */
export function useCurrentBuilderContent(): BuilderContent {
  const attributes = useSelector(currentBuilderContentSelector);

  // if (!attributes) {
  //   throw new Error("useCurrentBuilderContent: called before content was stored in redux");
  // }

  return useMemo(() => {
    if (isTheme(attributes)) {
      return new Theme(attributes as ThemeAttributes);
    }
    if (isEmail(attributes)) {
      return new Email(attributes as EmailAttributes);
    }
    if (isLandingPage(attributes)) {
      return new LandingPage(attributes as LandingPageAttributes);
    }
    throw new Error("Cannot create builder content of unknown type");
  }, [attributes]);
}

export function useTouchpointBuilderContent(): TouchpointBuilderContent {
  const attributes = useSelector(currentBuilderContentSelector);

  return useMemo(() => {
    if (isEmail(attributes)) {
      return new Email(attributes as EmailAttributes);
    }
    if (isLandingPage(attributes)) {
      return new LandingPage(attributes as LandingPageAttributes);
    }
    throw new Error("Cannot create builder content of unknown type");
  }, [attributes]);
}

export type { BuilderContentSlice };
