import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";

import { setCurrentUser } from "state/ducks/users";
import Model from "models/model";

import { Route } from "utilities/app-routes";

const Logout = () => {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();
    // logout is only local
    instance.logoutRedirect({
      account: currentAccount,
      onRedirectNavigate: (url) => {
        return false;
      },
    });
    window.location.replace(Route.login);
    Model.disconnect();
    dispatch(setCurrentUser(null));
  }, [dispatch, instance]);

  return null;
};

export default Logout;
