import { Route, Switch, useRouteMatch } from "react-router-dom";

import ProfileTab from "containers/admin/clients/client/tab-components/profile-tab/profile-tab";
import ProfileEditPage from "containers/admin/clients/client/tab-components/profile-tab/profile-edit-page/profile-edit-page";

import { Route as AppRoutes } from "utilities/app-routes";
import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";

const ProfileRoutes = () => {
  let { path, url } = useRouteMatch();

  usePagePermission(Permission.PERM_CLIENT_READ);
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);

  return (
    <Switch>
      <Route exact path={path}>
        <ProfileTab />
      </Route>

      <Route path={path + "/edit"}>
        <ProfileEditPage baseUrl={url + AppRoutes.edit} />
      </Route>
    </Switch>
  );
};

export default ProfileRoutes;
