import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setTouchpointVersionsDictionary } from "state/ducks";
import { touchpointVersionsDictionarySelector } from "state/ducks/touchpoint";

import TouchpointVersion, { TouchpointVersionsType } from "models/touchpoint-version";

import { ClientReviewStatus } from "types";

export const useTouchpointVersionsDictionary = (touchpointVersionStatus?: string) => {
  const dispatch = useDispatch();
  const touchpointVersionsDictionary = useSelector(touchpointVersionsDictionarySelector);

  const INITIAL_CLIENT_VERSION_STATUSES: TouchpointVersionsType = {
    status: ClientReviewStatus.DRAFT,
    clientReviewStatusName: "",
    versionStatusName: "",
  };

  const [clientVersionStatuses, setClientVersionStatuses] = useState<TouchpointVersionsType>(
    INITIAL_CLIENT_VERSION_STATUSES
  );

  useEffect(() => {
    const fetchTouchpointVersionsDictionary = async () => {
      if (!touchpointVersionsDictionary || touchpointVersionsDictionary.length === 0) {
        try {
          const response = await TouchpointVersion.getStatusesDictionary();
          dispatch(setTouchpointVersionsDictionary(response));
        } catch (error) {
          console.error("Failed to fetch touchpoint versions dictionary:", error);
        }
      }
    };

    fetchTouchpointVersionsDictionary();
  }, [dispatch, touchpointVersionsDictionary]);

  useEffect(() => {
    if (touchpointVersionStatus && touchpointVersionsDictionary.length > 0) {
      const clientVersionStatusesData = touchpointVersionsDictionary.find(
        (el) => el.status === touchpointVersionStatus
      );

      if (clientVersionStatusesData?.status) {
        setClientVersionStatuses(clientVersionStatusesData);
      }
    }
  }, [touchpointVersionsDictionary, touchpointVersionStatus]);

  const getClientVersionStatuses = (status?: string) => {
    return (
      touchpointVersionsDictionary.find(
        (el) => el.status === (status || INITIAL_CLIENT_VERSION_STATUSES.status)
      ) || INITIAL_CLIENT_VERSION_STATUSES
    );
  };

  const memoizedResult = useMemo(
    () => ({
      touchpointVersionsDictionary,
      clientVersionStatuses,
      getClientVersionStatuses,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [touchpointVersionsDictionary, clientVersionStatuses]
  );

  return memoizedResult;
};
