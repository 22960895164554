import _package from "../../package.json";

import { env } from "env";

export const APP_BUILD_NUMBER = _package.config.runner_number;
export const APP_BUILD_BRANCH = _package.config.devops_ref.split("/").slice(-1).toString();
export const APP_VERSION = _package.version;
export const IS_MAIN_BRANCH = APP_BUILD_BRANCH.toUpperCase() === "MAIN";
export const ADFS_LOGIN_URL = "/saml/login/azure?redirect_uri=";
export const ADFS_LOGOUT_URL = "/saml/logout?redirect_uri=";
export const BLANK_BEE_TEMPLATE =
  '{"page":{"body":{"container":{"style":{"background-color":"#FFFFFF"}},"content":{"computedStyle":{"linkColor":"#0068A5","messageBackgroundColor":"transparent","messageWidth":"500px"},"style":{"color":"#000000","font-family":"Arial, Helvetica Neue, Helvetica, sans-serif"}},"type":"mailup-bee-page-proprerties"},"description":"","rows":[{"columns":[{"grid-columns":12,"modules":[],"style":{"background-color":"transparent","border-bottom":"0px dotted transparent","border-left":"0px dotted transparent","border-right":"0px dotted transparent","border-top":"0px dotted transparent","padding-bottom":"5px","padding-left":"0px","padding-right":"0px","padding-top":"5px"}}],"container":{"style":{"background-color":"transparent"}},"content":{"style":{"background-color":"transparent","color":"#000000","width":"500px"}},"type":"one-column-empty"}],"template":{"name":"template-base","type":"basic","version":"0.0.1"},"title":""}}';
export const BEE_LP_SELECTOR = ".bee-page-container";
export const BEE_EMAIL_SELECTOR = "#bee-email";
export const BEE_COMMENT_WRAPPER_CLASSNAME = "comment-wrapper";
export const BEE_COMMENT_WRAPPER_SELECTOR = "." + BEE_COMMENT_WRAPPER_CLASSNAME;
export const BEE_SIDEBAR_WIDTH_SM = "351px";
export const BEE_SIDEBAR_WIDTH_LG = "411px";
export const IS_PRODUCTION = env.NODE_ENV === "production";
