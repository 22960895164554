import { TouchpointType } from "types/touchpoint";

export function isTheme(obj?: any) {
  return !!obj.rootThemeId;
}

export function isEmail(obj?: any) {
  return !!obj && obj.type === TouchpointType.EMAIL;
}

export function isLandingPage(obj: any) {
  return !!obj && obj.type === TouchpointType.LANDING_PAGE;
}
