import { TouchpointTypeLabel } from "types/touchpoint";

export const CLIENTS_STATUSES_FORM_FIELDS = [
  {
    label: "All types",
    radioValue: "",
    valueForEdit: "All",
  },
  {
    label: "Email",
    radioValue: "EMAIL",
    valueForEdit: "Email",
  },
  {
    label: TouchpointTypeLabel.LP_OTHER, // Other
    radioValue: "LANDING_PAGE",
    valueForEdit: TouchpointTypeLabel.LP_TOUCHPOINT, // Touchpoint
  },
];
