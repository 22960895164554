import { useState, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { RouteMatchTypes } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import ThemeDetails from "containers/admin/clients/client/tab-components/themes-tab/components/theme-details/theme-details";
import ThemeVersionHistoryTable from "containers/admin/clients/client/tab-components/themes-tab/components/theme-version-history-table/theme-version-history-table";
import { H2 } from "components/partials/typography/typography";
import ToggleButtonGroup from "components/partials/toggle-button-group/toggle-button-group";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";

import { setCurrentTheme, useCurrentTheme } from "state/ducks/themes";
import { useCurrentClient } from "state/ducks";

import Theme from "models/theme";

import { Route as AppRoute } from "utilities/app-routes";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import { ContentStatus } from "types";

const ThemePage = ({ baseThemeUrl }: { baseThemeUrl: string }) => {
  const currentTheme = useCurrentTheme();
  const currentClient = useCurrentClient();
  const history = useHistory();
  let { url, params }: RouteMatchTypes = useRouteMatch();

  useEffect(() => {
    // Always fresh load the theme
    Theme.find({ clientId: currentClient.id, id: currentTheme.id }).then((res) => {
      setCurrentTheme(res.attributes);
    });

    // eslint-disable-next-line
  }, [currentClient.id, currentTheme.id]);

  // While this page is both edit and read, permission has to be READ not EDIT
  usePagePermission(Permission.PERM_THEME_READ);

  // The form values need to live here so that the theme builder button
  // knows to save unsaved changes first
  const [localValues, setLocalValues] = useState({
    name: "",
    description: "",
    status: ContentStatus.DRAFT,
  });

  useEffect(() => {
    setLocalValues({
      name: currentTheme.name,
      description: currentTheme.description,
      status: currentTheme.status,
    });
  }, [currentTheme]);

  const changeTab = (str: string) => {
    if (str === "Details") {
      history.push(baseThemeUrl + AppRoute.edit);
    }
    if (str === "Version History") {
      history.push(baseThemeUrl + AppRoute.versions);
    }
  };

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { label: "Clients", href: "/clients" },
          { label: currentClient?.name, href: `/clients/${params?.clientId}/campaigns` },
          { label: "Theme Configuration", href: `/clients/${params?.clientId}/themes` },
          { label: currentTheme?.name, isActive: true },
        ]}
      />
      <PageContentHeader>
        <H2>{currentTheme ? `Edit theme` : "Add new theme"}</H2>
      </PageContentHeader>

      <Box>
        <ToggleButtonGroup
          groupName="campaign-creative-toggle"
          options={["Details", "Version History"]}
          value={url.endsWith("edit") ? "Details" : "Version History"}
          onChange={(option) => changeTab(option)}
          mb={6}
        />
      </Box>
      <Switch>
        <Route exact path={baseThemeUrl + AppRoute.edit}>
          <ThemeDetails
            theme={currentTheme}
            localValues={localValues}
            setLocalValues={setLocalValues}
          />
        </Route>
        <Route path={baseThemeUrl + AppRoute.versions}>
          <ThemeVersionHistoryTable baseThemeUrl={baseThemeUrl} />
        </Route>
      </Switch>
    </>
  );
};

export default ThemePage;
