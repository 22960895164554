import { Flex, FlexProps } from "@chakra-ui/react";
import Flash from "components/partials/flash/flash";

export const ContentWrapper = ({ children, ...rest }: FlexProps) => (
  <Flex
    as="main"
    data-testid="content-wrapper"
    flex="1"
    overflow="hidden"
    mt="0!important"
    {...rest}>
    {children}
  </Flex>
);

export const Sidebar = ({ width = "2xs", children, ...rest }: FlexProps) => (
  <Flex
    as="aside"
    data-testid="sidebar"
    width={width}
    flexDirection="column"
    height="100%"
    py={4}
    boxShadow="sm"
    zIndex={1}
    {...rest}>
    {children}
  </Flex>
);

interface ContentProps extends FlexProps {
  gutter?: number;
  withFooter?: boolean;
  fixedContent?: boolean;
  showBreadcrumbs?: boolean;
}

export const contentGutter = 8; // imported into BeeEditor.tsx

export const Content = ({
  gutter = contentGutter,
  withFooter,
  fixedContent = false,
  showBreadcrumbs = true,
  children,
  ...rest
}: ContentProps) => (
  <Flex
    as="article"
    data-testid="content"
    overflowY="auto"
    direction="column"
    width="100%"
    mt="0!important"
    {...rest}>
    <Flex
      direction="column"
      flex="1"
      paddingX={gutter}
      overflow={fixedContent ? "hidden" : undefined}>
      <Flex
        paddingTop={showBreadcrumbs ? 0 : gutter}
        paddingBottom={gutter}
        overflow={fixedContent ? "hidden" : undefined}
        flex="1"
        flexDirection="column">
        <Flash mb={4} mt={4} />
        {children}
      </Flex>
    </Flex>
    {/* {withFooter && <AppFooter padding={gutter} />} */}
  </Flex>
);
