import { useDispatch } from "react-redux";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";
import { useTouchpointBuilderContent, setCurrentBuilderContent } from "state/ducks/builder-content";
import TouchpointVersion, { TouchpointIds } from "models/touchpoint-version";
import { EmailAttributes } from "models/email";
import { LandingPageAttributes } from "models/landing-page";

import { normalizeValues } from "utilities/forms";

export const useTouchpointIds = (): TouchpointIds => {
  const client = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const touchpointContent = useTouchpointBuilderContent();

  return {
    campaignId: currentCampaign.id,
    clientId: client.id,
    id: touchpointContent.id,
    touchpointId: currentTouchpoint.id,
  };
};

export const useSaveTouchpoint = () => {
  const touchpointContent = useTouchpointBuilderContent();
  const touchpointIds = useTouchpointIds();
  const dispatch = useDispatch();

  const saveTouchpoint = async (updatedValue: Record<string, any>) => {
    const updatedAttributes = {
      attributes: {
        ...normalizeValues(touchpointContent.attributes),
        ...normalizeValues(updatedValue),
      } as EmailAttributes | LandingPageAttributes,
    };

    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        const updatedTouchpoint = await TouchpointVersion.replace({
          ...touchpointIds,
          ...updatedAttributes,
        });
        dispatch(setCurrentBuilderContent(updatedTouchpoint.attributes));
        return;
      } catch (error: any) {
        if (error.response && error.response.status === 409) {
          console.error("Conflict error while saving touchpoint. Retrying...", error);
          retries++;
          // eslint-disable-next-line no-loop-func
          await new Promise((resolve) => setTimeout(resolve, 1000 * retries));
        } else {
          console.error("Failed to save touchpoint", error);
          throw error;
        }
      }
    }

    throw new Error("Failed to save touchpoint after maximum retries");
  };

  return saveTouchpoint;
};
