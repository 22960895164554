import Model from "models/model";

interface ClientContactAttributes {
  id?: number;

  email?: string;

  jobTitle?: string;
  name?: string;
}

class ClientContact extends Model<ClientContactAttributes> implements ClientContactAttributes {
  // -------- persistence methods ---------

  // --------- behavior (abstract implementations or custom) --------
  get attributes() {
    return {
      id: this.id,
      email: this.email,
      jobTitle: this.jobTitle,
      name: this.name,
    };
  }

  // -------- proxies to attributes ---------
  get id() {
    return this._attributes["id"];
  }

  get email() {
    return this._attributes["email"];
  }

  get jobTitle() {
    return this._attributes["jobTitle"];
  }

  get name() {
    return this._attributes["name"];
  }
}

export default ClientContact;
export type { ClientContactAttributes };
