import { useContext } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import CommentableModule from "containers/admin/clients/touchpoint/components/comments/commentable-module/commentable-module";

import CommentContext from "contexts/comment-context";

interface CommentableHeaderProps extends BoxProps {
  moduleId: "fromName" | "replyToEmail" | "subjectLine" | "preheader" | "senderEmail";
  isCommentMode: boolean;
}
export const CommentableHeader = ({
  children,
  moduleId,
  isCommentMode,
  ...rest
}: CommentableHeaderProps) => {
  const { commentData } = useContext(CommentContext);

  const comment = commentData.content.filter((comment) => comment.annotationId === moduleId)[0];

  const commentNumber = parseInt(comment?.badgeNumber ?? "0");
  const hasComment = commentNumber > 0;

  const getCommentButtonPadding = () => {
    if (!isCommentMode) {
      return "";
    }
    if (hasComment) {
      return commentNumber > 10 ? "pl-9" : "pl-7";
    }
    return "pl-5";
  };

  return (
    <Box position="relative" cursor={isCommentMode ? "pointer" : "default"} {...rest}>
      <span className={`flex ${getCommentButtonPadding()}`}>{children}</span>
      {isCommentMode && (
        <CommentableModule
          moduleId={moduleId}
          comment={comment}
          commentTriggerProps={{ top: -1 }}
        />
      )}
    </Box>
  );
};
