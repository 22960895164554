import Theme from "models/theme";
import User from "models/user";

import { Permission, Role } from "types/auth";

export function hasPermission(user: User, permission: Permission) {
  return user.permissions.includes(permission);
}

export function hasRole(user: User, role: Role) {
  return user.roles.includes(role);
}

export function canEditTheme(theme: Theme, currentUser: User) {
  if (theme.attributes.lockedBy !== null) {
    return theme.attributes.lockedBy.id === currentUser.id;
  }
  return true;
}
