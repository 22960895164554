import { AxiosResponse } from "axios";
import { IToken } from "@mailupinc/bee-plugin/dist/types/bee";

import Model from "models/model";
import { TouchpointVersionAttributes } from "models/touchpoint-version";
import { DEFAULT_TOUCHPOINT_DATA } from "models/touchpoint";

// N.B
// If you add a new field that can be updated via the builder,
// you MUST add it to the isEqualEmailFormPartial util function
// otherwise, that field change wont trigger autosave
interface EmailAttributes extends TouchpointVersionAttributes {
  fromName?: string;
  iterableTemplateId?: number;
  iterableChildTemplateIds?: string[];
  iterableEmailTypeId?: string;
  preheader?: string;
  replyToEmail?: string;
  senderEmail?: string;
  subjectLine?: string;
  visibleToClient?: boolean;
}

export const DEFAULT_EMAIL_DATA = DEFAULT_TOUCHPOINT_DATA;
export const DEFAULT_SENDER_EMAIL = "no-reply@mg.two-ocean.dev";

class Email extends Model<EmailAttributes> implements EmailAttributes {
  // --------- behavior (abstract implementations or custom) --------
  get attributes(): EmailAttributes {
    return {
      advancedBodySnippet: this.advancedBodySnippet,
      advancedHeadSnippet: this.advancedHeadSnippet,
      archiveLocation: this.archiveLocation,
      data: this.data || DEFAULT_EMAIL_DATA,
      draftedFromVersion: this.draftedFromVersion,
      finalHtml: this.finalHtml,
      fromName: this.fromName,
      id: this.id,
      iterableEmailTypeId: this.iterableEmailTypeId || "",
      iterableTemplateId: this.iterableTemplateId,
      iterableChildTemplateIds: this.iterableChildTemplateIds,
      lastModifiedDate: this.lastModifiedDate,
      linkParams: this.linkParams,
      lockedBy: this.lockedBy,
      name: this.name,
      preheader: this.preheader,
      preprocessedHtml: this.preprocessedHtml,
      publishedAt: this.publishedAt,
      replyToEmail: this.replyToEmail,
      senderEmail: this.senderEmail,
      subjectLine: this.subjectLine,
      sourceType: this.sourceType,
      status: this.status,
      theme: this.theme,
      themeId: this.themeId,
      touchpointId: this.touchpointId,
      type: this.type,
      version: this.version,
      versionNotes: this.versionNotes,
      visibleToClient: this.visibleToClient,
    };
  }

  /**
   * Fetches a BEE token specifically for landing pages.
   * @returns A promise that resolves with the full token object.
   */
  static getToken(clientId: string): Promise<IToken> {
    const endpoint = `/bee/clients/${clientId}/token/email`;

    return Email.connection
      .get(endpoint)
      .then((response: AxiosResponse<IToken>) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to fetch email token:", error);
        throw error;
      });
  }

  // -------- proxies to attributes ---------
  get fromName() {
    return this._attributes["fromName"];
  }
  get replyToEmail() {
    return this._attributes["replyToEmail"];
  }
  get preheader() {
    return this._attributes["preheader"];
  }
  get senderEmail() {
    return this._attributes["senderEmail"];
  }
  get subjectLine() {
    return this._attributes["subjectLine"];
  }

  get advancedBodySnippet() {
    return this._attributes["advancedBodySnippet"];
  }

  get advancedHeadSnippet() {
    return this._attributes["advancedHeadSnippet"];
  }

  get archiveLocation() {
    return this._attributes["archiveLocation"];
  }

  get data() {
    return this._attributes["data"];
  }

  get draftedFromVersion() {
    return this._attributes["draftedFromVersion"];
  }

  get finalHtml() {
    return this._attributes["finalHtml"];
  }

  get id() {
    return this._attributes["id"];
  }

  get iterableEmailTypeId() {
    return this._attributes["iterableEmailTypeId"];
  }

  get iterableTemplateId() {
    return this._attributes["iterableTemplateId"];
  }

  get iterableChildTemplateIds() {
    return this._attributes["iterableChildTemplateIds"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get linkParams() {
    return this._attributes["linkParams"];
  }

  get lockedBy() {
    return this._attributes["lockedBy"];
  }

  get name() {
    return this._attributes["name"];
  }

  get preprocessedHtml() {
    return this._attributes["preprocessedHtml"];
  }

  get publishedAt() {
    return this._attributes["publishedAt"];
  }

  get sourceType() {
    return this._attributes["sourceType"];
  }

  get status() {
    return this._attributes["status"];
  }

  get theme() {
    return this._attributes["theme"];
  }

  get themeId() {
    return this._attributes["themeId"];
  }

  get touchpointId() {
    return this._attributes["touchpointId"];
  }

  get type() {
    return this._attributes["type"];
  }

  get version() {
    return this._attributes["version"];
  }

  get versionNotes() {
    return this._attributes["versionNotes"];
  }

  get visibleToClient() {
    return this._attributes["visibleToClient"];
  }
}

export { Email as default };
export type { EmailAttributes };
