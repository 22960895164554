import React from "react";
import { LayoutProps, Stack, SystemProps } from "@chakra-ui/react";

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  width?: LayoutProps["width"];
  spacing?: SystemProps["margin"];
  marginBottom?: SystemProps["margin"];
  isCentered?: boolean;
}
const Form = ({
  onSubmit,
  width = "xl",
  spacing = 8,
  marginBottom = 0,
  isCentered = false,
  children,
  ...rest
}: FormProps) => {
  return (
    <form onSubmit={onSubmit} {...rest}>
      <Stack
        maxWidth={width}
        spacing={spacing}
        marginBottom={marginBottom}
        marginX={isCentered ? "auto" : undefined}>
        {children}
      </Stack>
    </form>
  );
};

export default Form;
