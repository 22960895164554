import { useMsal } from "@azure/msal-react";

import CustomButton from "components/new/custom-button/custom-button";

import useSetPageTitle from "hooks/use-set-page-title";

import { loginRequest } from "config/auth-config";

import Icons from "assets";

const LoginPage = () => {
  const { instance } = useMsal();

  function adfsLogin() {
    instance.loginRedirect(loginRequest);
  }

  const isClientUser = true;
  const pageTitle = "Sign In";

  useSetPageTitle({ isClientUser, pageTitle });

  return (
    <div className="flex flex-col items-center align-center justify-center h-full">
      <div className="flex flex-col items-center align-center justify-center h-[85%]">
        <Icons.ApolloLogo customClass="text-navy w-96 mr-4" />
        <CustomButton onClick={adfsLogin} variant="primary" customClass="px-20 mt-10">
          Login
        </CustomButton>
      </div>
      <Icons.TwoOceanLogo customClass="text-navy w-44 mr-10" />
    </div>
  );
};

export default LoginPage;
