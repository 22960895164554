import { useSelector } from "react-redux";
import { BoxProps, Circle, HStack, Stack } from "@chakra-ui/react";

import Card, { CardBody, CardHeader, CardRow } from "components/partials/card/card";
import Link from "components/partials/link/link";
import { Span } from "components/partials/typography/typography";
import { TableCopyButton } from "components/table/table-icon-button/table-icon-button";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";
import { touchpointVersionsSelector } from "state/ducks/touchpoint";
import { Email, LandingPage, Touchpoint } from "models";
import { EmailAttributes } from "models/email";

import {
  convertTouchpointToUrl,
  getDisplayCreativeContext,
  getDisplayContentType,
  getDisplayTouchpointType,
} from "utilities";
import { Route as AppRoute } from "utilities/app-routes";

import { ContentStatus, CreativeContext } from "types";
import { ContentType, TouchpointType } from "types/touchpoint";

interface TouchpointDetailsTabProps {
  parentUrl: string;
}

const DetailsTab = ({ parentUrl }: TouchpointDetailsTabProps) => {
  const currentTouchpoint = useCurrentTouchpoint();

  const isEmail = currentTouchpoint.type === TouchpointType.EMAIL;

  return (
    <HStack align="start" spacing={6}>
      <Stack flex="1" spacing={3}>
        <ContentDetailsCard touchpoint={currentTouchpoint} parentUrl={parentUrl} mb={3} />
        {isEmail && (
          <EmailVersionContentSettingsCard touchpoint={currentTouchpoint} parentUrl={parentUrl} />
        )}
      </Stack>
      <Stack flex="1">
        {isEmail ? (
          <IterableTemplateIdsCard touchpoint={currentTouchpoint} />
        ) : (
          <LPVersionContentSettingsCard touchpoint={currentTouchpoint} parentUrl={parentUrl} />
        )}
      </Stack>
    </HStack>
  );
};

export default DetailsTab;

interface TouchpointCardProps extends BoxProps {
  touchpoint: Touchpoint;
  parentUrl?: string;
}

const ContentDetailsCard = ({ touchpoint, parentUrl, ...rest }: TouchpointCardProps) => {
  const contextCreatives = [
    touchpoint.contextAcademics && CreativeContext.ACADEMICS,
    touchpoint.contextApply && CreativeContext.APPLY,
    touchpoint.contextCampusLife && CreativeContext.CAMPUS_LIFE,
    touchpoint.contextEducationOutcomes && CreativeContext.EDUCATION_OUTCOMES,
    touchpoint.contextFinancialAid && CreativeContext.FINANCIAL_AID,
    touchpoint.contextVisit && CreativeContext.VISIT,
  ].filter((topic) => !!topic);

  return (
    <Card {...rest}>
      <CardHeader
        heading={`${getDisplayTouchpointType(touchpoint.type)} content details`}
        editLink={parentUrl + AppRoute.edit}
      />
      <CardBody>
        <CardRow
          title="Content type"
          value={
            touchpoint.contentType && getDisplayContentType(touchpoint.contentType as ContentType)
          }
        />
        <CardRow
          title="Content topic"
          value={
            contextCreatives.length > 0 &&
            contextCreatives.map((topic) => getDisplayCreativeContext(topic as CreativeContext))
          }
        />
      </CardBody>
    </Card>
  );
};

const IterableTemplateIdsCard = ({ touchpoint, ...rest }: TouchpointCardProps) => {
  const touchpointVersions = useSelector(touchpointVersionsSelector);
  const publishedTouchpoint = touchpointVersions.items.find(
    (t) => t.status === ContentStatus.PUBLISHED
  );

  const publishedEmail = publishedTouchpoint && (publishedTouchpoint as EmailAttributes);

  return (
    <Card {...rest}>
      <CardHeader heading="Iterable TemplateIDs" />
      <CardBody>
        <CardRow title="Client TemplateID" value={touchpoint.id} />
        <CardRow title="Base TemplateID" value={publishedEmail?.iterableTemplateId} />
        <CardRow
          title="Workflow Email Template IDs"
          value={publishedEmail?.iterableChildTemplateIds}
        />
      </CardBody>
    </Card>
  );
};

const EmailVersionContentSettingsCard = ({
  touchpoint,
  parentUrl,
  ...rest
}: TouchpointCardProps) => {
  const touchpointVersions = useSelector(touchpointVersionsSelector);
  const publishedTouchpoint = touchpointVersions.items.find(
    (t) => t.status === ContentStatus.PUBLISHED
  );

  const publishedEmail = publishedTouchpoint && (publishedTouchpoint as Email);

  if (publishedEmail) {
    return (
      <Card {...rest}>
        <CardHeader
          heading={`Version content settings`}
          statusLiveText={`v${publishedEmail?.version} (Published)`}
          status={publishedEmail?.status}
        />
        <CardBody>
          <CardRow title="Email Type" value={publishedEmail?.iterableEmailTypeId} />
          <CardRow title="Theme" value={publishedEmail?.theme?.name} />
          <CardRow title="From name" value={publishedEmail?.fromName} />
          <CardRow title="From email" value={publishedEmail?.senderEmail} />
          <CardRow title="Reply-to email" value={publishedEmail?.replyToEmail} />
          <CardRow title="Subject line" value={publishedEmail?.subjectLine} />
          <CardRow title="Preheader" value={publishedEmail?.preheader} />
        </CardBody>
      </Card>
    );
  }
  return <></>;
};

const LPVersionContentSettingsCard = ({ touchpoint, parentUrl, ...rest }: TouchpointCardProps) => {
  const currentCampaign = useCurrentCampaign();
  const currentClient = useCurrentClient();
  const touchpointVersions = useSelector(touchpointVersionsSelector);
  const publishedTouchpoint = touchpointVersions.items.find(
    (t) => t.status === ContentStatus.PUBLISHED
  );
  const publishedLP = publishedTouchpoint && (publishedTouchpoint as LandingPage);

  if (publishedLP) {
    return (
      <Card {...rest}>
        <CardHeader
          heading={`Version content settings`}
          statusLiveText={`v${publishedLP?.version} (Published)`}
          status={publishedLP?.status}
        />
        <CardBody>
          <CardRow title="Theme" value={publishedLP?.theme?.name} />
          <CardRow title="Page Title" value={publishedLP?.pageTitle} />
          <CardRow
            title="Page URL"
            value={StringToCopyableLink(
              convertTouchpointToUrl(currentClient, currentCampaign, publishedLP?.url)
            )}
          />
          <CardRow title="Page description" value={publishedLP?.pageDescription} />
          <CardRow
            title="Search engine visibility"
            value={InvertedBooleanStatus(publishedLP?.searchVisibility)}
          />
          <CardRow
            title="User data prefill"
            value={InvertedBooleanStatus(publishedLP?.disablePrefill)}
          />
          <CardRow
            title="Redirect Destination"
            value={StringToCopyableLink(publishedLP?.redirectDestination)}
          />
        </CardBody>
      </Card>
    );
  }
  return <></>;
};

const InvertedBooleanStatus = (isOff = false) => (
  <HStack>
    <Span>{isOff ? "OFF" : "ON"}</Span>
    <Circle aria-hidden size={4} bgColor={isOff ? "error.100" : "success.100"} />
  </HStack>
);

const StringToCopyableLink = (link?: string) => {
  if (link) {
    return (
      <HStack>
        <Link onClick={() => window.open(link, "_blank")} textDecoration="underline">
          {link}
        </Link>
        <TableCopyButton
          title="Copy Link"
          aria-label="Copy Link"
          size="sm"
          onClick={() => {
            navigator.clipboard.writeText(link);
          }}
        />
      </HStack>
    );
  } else {
    return undefined;
  }
};
