import { Button, ButtonProps, Flex, Icon, forwardRef } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

interface FilterSelectTriggerProps extends ButtonProps {
  label: string;
}
export const FilterSelectTrigger = forwardRef<FilterSelectTriggerProps, "button">(
  ({ label, ...rest }, ref) => {
    return (
      <Button
        boxShadow="outer"
        backgroundColor="white!important"
        width="100%"
        ref={ref}
        sx={{ "& svg": { transition: "all .1s" } }}
        _expanded={{ boxShadow: "inner", "& svg": { transform: "rotate(-180deg)" } }}
        {...rest}>
        <Flex flex="1" justifyContent="space-between">
          {label}
          <Icon as={FiChevronDown} ml={2} />
        </Flex>
      </Button>
    );
  }
);
