import { TextareaProps } from "@chakra-ui/react";

import { TouchpointBuilderTabForm } from "containers/admin/clients/touchpoint/components/email-builder/email-builder";
import Textarea from "components/forms/textarea/textarea";
import { FormProps } from "components/forms/form/form";

interface BuilderHtmlPreviewProps extends FormProps {
  value?: string;
  textareaTestId?: string;
  textareaProps?: Omit<TextareaProps, "value">;
}
export const BuilderHtmlPreview = ({
  value = "",
  textareaTestId,
  textareaProps = {},
  ...rest
}: BuilderHtmlPreviewProps) => {
  return (
    // Probably don't need this to be a Form. Could just be Box.
    <TouchpointBuilderTabForm {...rest}>
      <Textarea
        data-testid={textareaTestId}
        bgColor="white"
        isReadOnly
        rows={25}
        value={value}
        {...textareaProps}
      />
    </TouchpointBuilderTabForm>
  );
};
