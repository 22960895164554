import React from "react";
import { Box, Code, SimpleGrid, Stack } from "@chakra-ui/react";

import { H3, H4, Lead } from "components/partials/typography/typography";
import Card, { CardBody, CardShowcase } from "components/partials/card/card";

const grayscale = [
  { keys: ["gray.500"], title: "Gray 5", desc: "Used for body copy text" },
  { keys: ["gray.400"], title: "Gray 4", desc: "Used for label, disclaimer & placeholder text" },
  { keys: ["gray.300"], title: "Gray 3", desc: "Used in graphics, lines & borders" },
  { keys: ["gray.200"], title: "Gray 2", desc: "Used as a darker background" },
  { keys: ["gray.100"], title: "Gray 1", desc: "Used for element backgrounds" },
  { keys: ["white"], title: "Off-white", desc: "Off-white to avoid eye strain" },
];

const colors = [
  { keys: ["primary"], title: "Primary (Navy)", desc: "Used for headings" },
  {
    keys: ["secondary.100", "secondary.200"],
    title: "Secondary (Azure)",
    desc: "Used as the secondary brand color and to indicate selected items",
  },
  {
    keys: ["accent.100", "accent.200"],
    title: "Accent (Scarlet)",
    desc: "Used (sparingly) as an accent color",
  },
  {
    keys: ["highlight.100", "highlight.200"],
    title: "Highlight (Aqua)",
    desc: "Used to indicate links, buttons and other clickable elements",
  },
];

const utilityColors = [
  { keys: ["success.100", "success.200"], title: "Success", desc: "Used for success states" },
  { keys: ["info.100", "info.200"], title: "Info", desc: "Used for info messages" },
  { keys: ["warning.100", "warning.200"], title: "Warning", desc: "Used to represent caution" },
  { keys: ["error.100", "error.200"], title: "Error", desc: "Used for error states" },
];

interface SwatchCardProps {
  color: string[];
  title: string;
  desc: string;
  height?: number;
}
export const SwatchCard = ({ color, title, desc, height = 220 }: SwatchCardProps) => (
  <Card>
    <CardShowcase
      bg={color[0]}
      height={height + "px"}
      align="flex-end"
      title={`color="${color[0]}"`}>
      {color[1] && (
        <Box bg={color[1]} height={height / 2 + "px"} width="full" title={`color="${color[1]}"`} />
      )}
    </CardShowcase>
    <Stack as={CardBody} spacing={2}>
      <H4>{title}</H4>
      <Lead>{desc}</Lead>
      <Code>{`color="${color.join('" color="')}"`}</Code>
    </Stack>
  </Card>
);

const UIKitColorPalette = () => {
  return (
    <Stack spacing={4}>
      <H3 id="grayscale">Grayscale</H3>
      <SimpleGrid columns={6} spacing={4}>
        {grayscale.map((color) => {
          const { keys, title, desc } = color;
          return <SwatchCard key={keys[0]} color={keys} title={title} desc={desc} height={160} />;
        })}
      </SimpleGrid>
      <hr />
      <H3 id="colors">Colors</H3>
      <SimpleGrid columns={4} spacing={8}>
        {colors.map((color) => {
          const { keys, title, desc } = color;
          return <SwatchCard key={keys[0]} color={keys} title={title} desc={desc} />;
        })}
      </SimpleGrid>
      <hr />
      <H3 id="utility-colors">Utility Colors</H3>
      <SimpleGrid columns={4} spacing={8}>
        {utilityColors.map((color) => {
          const { keys, title, desc } = color;
          return <SwatchCard key={keys[0]} color={keys} title={title} desc={desc} />;
        })}
      </SimpleGrid>
    </Stack>
  );
};

export default UIKitColorPalette;
