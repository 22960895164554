import { Route, Switch, useRouteMatch } from "react-router-dom";

import ThemeTab from "containers/admin/clients/client/tab-components/themes-tab/themes-tab";
import ThemeNewPage from "containers/admin/clients/client/tab-components/themes-tab/theme-new-page/theme-new-page";
import ThemeRoutes from "containers/admin/clients/client/tab-components/themes-tab/theme/theme-routes";
import ThemeProvider from "containers/admin/clients/client/tab-components/themes-tab/components/theme-provider/theme-provider";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

// import theme from "themes/sample-client";
const ThemesRoutes = () => {
  const { path } = useRouteMatch();

  usePagePermission(Permission.PERM_THEME_READ);

  return (
    <Switch>
      <Route exact path={path}>
        <ThemeTab />
      </Route>

      <Route path={path + "/new"}>
        <ThemeNewPage />
      </Route>

      <Route path={path + "/:themeId"}>
        <ThemeProvider>
          <ThemeRoutes />
        </ThemeProvider>
      </Route>
    </Switch>
  );
};

export default ThemesRoutes;
