import React from "react";
import {
  Heading as ChakraHeading,
  HeadingProps,
  Text as ChakraText,
  TextProps,
} from "@chakra-ui/react";

const Heading = ({ ...rest }: HeadingProps) => {
  return <ChakraHeading fontFamily="heading" fontWeight="bold" color="text.heading" {...rest} />;
};

const Text = ({ ...rest }: TextProps) => {
  return <ChakraText color="text.body" {...rest} />;
};

export const H1 = ({ ...rest }: HeadingProps) => (
  <Heading as="h1" fontSize={["2xl", "3xl"]} {...rest} />
);

export const H2 = ({ ...rest }: HeadingProps) => (
  <Heading as="h2" fontSize={["xl", "2xl"]} {...rest} />
);

export const H3 = ({ ...rest }: HeadingProps) => (
  <Heading as="h3" fontSize={["lg", "xl"]} {...rest} />
);

export const H4 = ({ ...rest }: HeadingProps) => <Heading as="h4" fontSize="md" {...rest} />;

export const H5 = ({ ...rest }: HeadingProps) => (
  <Heading
    as="h5"
    fontSize="sm"
    color="text.muted"
    textTransform="uppercase"
    letterSpacing="2px"
    {...rest}
  />
);

export const Lead = ({ ...rest }: TextProps) => <Text fontSize="lg" {...rest} />;

export const P = ({ ...rest }: TextProps) => <Text {...rest} />;

export const Span = ({ color = "inherit", ...rest }: TextProps) => (
  <Text as="span" color={color} {...rest} />
);

export const Strong = ({ ...rest }: TextProps) => <Span fontWeight="bold" {...rest} />;

export const Small = ({ ...rest }: TextProps) => (
  <Text as="small" fontSize="sm" color="text.muted" {...rest} />
);

export const ExtraSmall = ({ ...rest }: TextProps) => (
  <Text as="small" fontSize="xs" color="text.muted" {...rest} />
);
