import { AddIcon } from "@chakra-ui/icons";
import { Link, useRouteMatch } from "react-router-dom";

import { RouteMatchTypes } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import Button from "components/forms/button/button";
import { H2 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import ThemeCard from "containers/admin/clients/client/tab-components/themes-tab/components/theme-card/theme-card";
import PaginatedGrid from "components/partials/paginated-grid/paginated-grid";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";
import { useCurrentClient } from "state/ducks/clients";
import { useCurrentUser } from "state/ducks/users";
import Theme from "models/theme";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

const ThemeTab = () => {
  const { url, params }: RouteMatchTypes = useRouteMatch();
  const currentUser = useCurrentUser();
  const currentClient = useCurrentClient();

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { label: "Clients", href: "/clients" },
          { label: currentClient?.name, href: `/clients/${params?.clientId}/campaigns` },
          { label: "Theme Configuration", isActive: true },
        ]}
      />
      <PageContentHeader>
        <H2>Theme Configuration</H2>

        <Link to={url + "/new"}>
          {hasPermission(currentUser, Permission.PERM_THEME_WRITE) && (
            <Button
              leftIcon={<AddIcon />}
              data-testid="add-theme-button"
              sx={{ py: "20px", px: "28px" }}>
              Add new
            </Button>
          )}
        </Link>
      </PageContentHeader>

      <PaginatedGrid
        headers={[]}
        fetchPage={(options) =>
          Theme.all({
            options: { ...options, sort: "name" },
            clientId: currentClient.id,
          })
        }>
        {(theme: Theme) => {
          return <ThemeCard key={theme.id} theme={theme} baseThemeUrl={`${url}/${theme.id}`} />;
        }}
      </PaginatedGrid>
    </>
  );
};

export default ThemeTab;
