import { FormErrorMessageProps, FormHelperText } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

import { Span } from "components/partials/typography/typography";
import { HelpMessage } from "components/forms/helper-text/helper-text";

interface WarningMessageProps extends FormErrorMessageProps {
  message?: HelpMessage;
}
const WarningMessage = ({ message, ...rest }: WarningMessageProps) => {
  if (!message) {
    return null;
  }
  return (
    <FormHelperText
      data-testid="warning-message"
      color="warning.200"
      display="flex"
      alignItems="center"
      lineHeight={1}
      {...rest}>
      <WarningTwoIcon mr={1} />
      <Span>{message}</Span>
    </FormHelperText>
  );
};

export default WarningMessage;
