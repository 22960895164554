import { useCallback, useEffect, useState } from "react";

import InboxHeader from "containers/admin/inbox/components/inbox-header/inbox-header";
import PageContent from "components/partials/page-content/page-content";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import { usePaginationState } from "components/partials/paginated-table/paginated-table";
import PaginatedTableWrapper from "components/partials/paginated-table-wrapper/paginated-table-wrapper";
import { InboxTable } from "components/table/inbox-table/inbox-table";

import { useCurrentUser } from "state/ducks/users";
import { useAllFilters, useQuickFilters } from "state/ducks";
import { Client } from "models";

import { hasPermission } from "utilities/user";

import { emptyPaginatedResponse, PaginatedResponse } from "types/pagination";
import { Permission } from "types/auth";

import {
  InboxItem,
  InboxTableColumns,
  InboxTableColumnId,
  ClientFilterTypes,
  FilterTypes,
  QuickFilterTypes,
  InboxFilter,
} from "types/inbox";
import { VStack } from "@chakra-ui/react";
import { H3, P } from "components/partials/typography/typography";
import { setQuickFilters } from "state/ducks/inbox-filters";
import { useDispatch } from "react-redux";

const defaultSortingParams = "lastModifiedDate,desc";

const InboxPage = () => {
  // User State ---------------------------------------------------------------
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Filter State -------------------------------------------------------------
  const activeAllFilters = useAllFilters();
  const activeQuickFilters = useQuickFilters();
  // Search State -------------------------------------------------------------
  const [currentSearchResults, setCurrentSearchResults] = useState<InboxItem[]>([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  // Pagination ---------------------------------------------------------------
  const [paginationResponse, setPaginationResponse] =
    useState<PaginatedResponse<InboxItem>>(emptyPaginatedResponse);
  const paginationState = usePaginationState();
  // Sort State ---------------------------------------------------------------
  const [tableSortedBy, setTableSortedBy] = useState<InboxTableColumnId>("lastModifiedDate");
  const [tableSortedAsc, setTableSortedAsc] = useState<boolean>(false);
  const [currentSortOrder, setCurrentSortOrder] = useState(defaultSortingParams);

  // Columns State -
  const inboxTableColumns: InboxTableColumns = [
    {
      label: "Creative name",
      id: "touchpointName",
      width: "38%",
    },
    {
      label: "Client name",
      id: "clientName",
    },
    {
      label: "Last updated",
      id: "lastModifiedDate",
    },
    {
      label: "Client Review Status",
      id: "touchpointVersionStatus",
    },
    {
      label: "Publish state",
      id: "publishLabel",
      isSortable: false, // Table does not sort correctly by this column
    },
    {
      label: "Open comments",
      id: "openCommentCount",
    },
  ];

  const refreshColumnSort = useCallback(() => {
    return `${tableSortedBy},${tableSortedAsc ? "asc" : "desc"}`;
  }, [tableSortedAsc, tableSortedBy]);

  interface HandleTableSort {
    (columnId: InboxTableColumnId): void;
  }

  const handleTableSort: HandleTableSort = useCallback(
    (columnId) => {
      if (columnId !== tableSortedBy) {
        setTableSortedBy(columnId);
      } else {
        setTableSortedAsc((prevSortAsc) => !prevSortAsc);
      }
    },
    [tableSortedBy]
  );

  interface HandleSearchTermChange {
    (searchTerm: string): void;
  }

  const handleSearchTermChange: HandleSearchTermChange = useCallback((searchTerm) => {
    setCurrentSearchTerm(searchTerm);
  }, []);

  useEffect(() => {
    paginationState.setPage(0); // Reset to first page when filters change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAllFilters, activeQuickFilters, currentSearchTerm]);

  useEffect(() => {
    // initial state check before we start searching
    if (isClientUser) {
      dispatch(setQuickFilters([QuickFilterTypes.toReview]));
    }

    const getValuesFromAppliedFilters = (filterType: ClientFilterTypes | FilterTypes) => {
      const filtersByFilterType = activeAllFilters.filter((f) => f.filterType === filterType);
      const appliedFiltersByFilterType = filtersByFilterType[0]?.appliedFilters;

      if (appliedFiltersByFilterType) {
        return appliedFiltersByFilterType.map((f) => f.value);
      }
      return [];
    };

    const filters: InboxFilter = {
      touchpointVersionStatus: getValuesFromAppliedFilters(FilterTypes.clientReviewStatus),
      touchpointType: getValuesFromAppliedFilters(FilterTypes.creativeType),
      touchpointContentType: getValuesFromAppliedFilters(FilterTypes.contentTypes),
      clientId: getValuesFromAppliedFilters(FilterTypes.clients),
      hasOpenComments: getValuesFromAppliedFilters(FilterTypes.comments).includes(
        "has-open-comments"
      ),
      hasOpenActionComments: getValuesFromAppliedFilters(FilterTypes.comments).includes(
        "has-action-items"
      ),
    };
    activeQuickFilters.forEach((filter) => {
      switch (filter) {
        case QuickFilterTypes.hasOpenActionItems:
          filters.hasOpenActionComments = true;
          filters.hasOpenComments = true;
          break;
      }
    });

    setIsLoading(true);

    if (isClientUser) {
      filters.clientIds = currentUser.clients.map((client) => client.id) as string[];
    }

    Client.getReviews(
      { ...paginationState, sort: currentSortOrder },
      { ...filters, q: currentSearchTerm }
    )
      .then((res) => {
        setCurrentSearchResults(res.data.content);
        setPaginationResponse({
          items: res.data.content,
          last: res.data.last,
          page: res.data.number,
          size: res.data.size,
          totalItems: res.data.totalElements,
          totalPages: res.data.totalPages,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    // TODO: What happens if we don't unmount here?
    return () => {};
    // eslint-disable-next-line
  }, [
    activeAllFilters,
    activeQuickFilters,
    currentSearchTerm,
    currentSortOrder,
    currentUser,
    dispatch,
    isClientUser,
    paginationState,
  ]);

  useEffect(() => {
    // Refresh columns after clicking to sort or change direction
    setCurrentSortOrder(refreshColumnSort);
  }, [tableSortedBy, tableSortedAsc, refreshColumnSort]);

  const hasFiltersOrSearch =
    activeAllFilters.length > 0 || activeQuickFilters.length > 0 || !!currentSearchTerm;

  return (
    <>
      <PageContentHeader backgroundColor="#e3e5e7">
        <InboxHeader
          paginationState={paginationState}
          setCurrentSearchTerm={handleSearchTermChange}
          isLoading={isLoading}
        />
      </PageContentHeader>
      <PageContent>
        {currentSearchResults && (currentSearchResults.length > 0 || isLoading!) ? (
          <PaginatedTableWrapper
            response={paginationResponse}
            paginationState={paginationState}
            isLoading={isLoading}>
            <InboxTable
              currentSearchResults={currentSearchResults}
              currentSortOrder={currentSortOrder}
              handleTableSort={handleTableSort}
              isClientUser={isClientUser}
              tableColumns={inboxTableColumns}
              isLoading={isLoading}
            />
          </PaginatedTableWrapper>
        ) : (
          <EmptyTable hasFiltersOrSearch={hasFiltersOrSearch} />
        )}
      </PageContent>
    </>
  );
};

export default InboxPage;

interface EmptyTableProps {
  hasFiltersOrSearch: boolean;
}

const EmptyTable = ({ hasFiltersOrSearch }: EmptyTableProps) => {
  return (
    <VStack>
      <H3 mt={4}>{hasFiltersOrSearch ? "No results" : "No items"}</H3>
      <P>
        {hasFiltersOrSearch
          ? "Try changing your filters to view more items."
          : "You don't have access to any creatives. Check back later or reach out to Two Ocean."}
      </P>
    </VStack>
  );
};
