import React, { useReducer, useState } from "react";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import * as zod from "zod";

import Input from "components/forms/input/input";
import FormGroup from "components/forms/form-group/form-group";

import { H2 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import MultiSelect from "components/forms/multi-select-dropdown/multi-select-dropdown";
import Select from "components/forms/select/select";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import Form from "components/forms/form/form";
import FormErrors from "components/partials/form-errors/form-errors";

import { Unpersisted, WithMaybePersisted } from "models/model";
import Campaign, { CampaignAttributes, CampaignAudience, CampaignType } from "models/campaign";

import {
  deeplyTransformEmptyStringToUndefined,
  formatAudienceValueToMatchApi,
  getCampaignType,
  getDisplayCampaignType,
  getContentStatus,
  toSpacedTitleCase,
  toUpperCaseSpacesToUnderScores,
  addHyphenToAudienceSelection,
  toLowerCaseNoSpaces,
  toTitleCase,
} from "utilities";

import { CampaignObjective, CampaignPopulationType } from "types/touchpoint";
import { ZodFormErrors } from "types";
import { Formify } from "types/utility";

interface CreateCampaignFormProps {
  campaign?: Campaign;
  onSubmit: (attributes: Unpersisted<CampaignAttributes>) => void;
  onCancel: () => void;
}

interface UpdateCampaignFormProps {
  campaign: Campaign;
  onSubmit: (attributes: CampaignAttributes) => void;
  onCancel: () => void;
}

type CampaignFormProps = CreateCampaignFormProps | UpdateCampaignFormProps;

function isUpdatingCampaign(props: CampaignFormProps): props is UpdateCampaignFormProps {
  return (props as UpdateCampaignFormProps).campaign !== undefined;
}

function CampaignForm(props: CampaignFormProps) {
  const form = useCampaignFormState(props.campaign);
  const confirmCancelModal = useDisclosure();

  function handleClassMultiSelectChange(selectedOptions: any) {
    const selectedValues = selectedOptions.map((val: any) => {
      return val.label;
    });
    form.handleComplexChange("classes", selectedValues);
  }

  function handleAudienceMultiSelectChange(selectedOptions: any) {
    const selectedValues = selectedOptions.map((val: any) => {
      return val.label;
    });
    form.handleComplexChange("audiences", selectedValues);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const campaignAttributes = schema.parse(
        deeplyTransformEmptyStringToUndefined({
          ...form.values,
        })
      );

      form.setErrors({});
      const formattedCampaign = {
        ...campaignAttributes,
        campaignObjective:
          campaignAttributes.campaignObjective &&
          (toUpperCaseSpacesToUnderScores(campaignAttributes.campaignObjective) as string),
        classes: form.values.classes
          .map((tempClass) => {
            return toUpperCaseSpacesToUnderScores(tempClass);
          })
          .filter((str) => str !== null) as string[],
        audiences: form.values.audiences
          .map((audienceChoice) => {
            return toUpperCaseSpacesToUnderScores(audienceChoice);
          })
          .filter((str) => str !== null) as string[],
        status: campaignAttributes.status ? getContentStatus(campaignAttributes.status) : undefined,
        programType: campaignAttributes.programType
          ? getCampaignType(campaignAttributes.programType)
          : undefined,
        lastModifiedDate: "",
        cdnSubdirectoryName: campaignAttributes.cdnSubdirectoryName
          ? toLowerCaseNoSpaces(campaignAttributes.cdnSubdirectoryName)
          : "",
        populationType: campaignAttributes.populationType,
      };

      if (isUpdatingCampaign(props)) {
        props.onSubmit(
          (function addBackData(
            attributes: any
          ): WithMaybePersisted<CampaignAttributes, "clientId"> {
            attributes.id = props.campaign.id;
            attributes.lastModifiedDate = props.campaign.lastModifiedDate;

            return attributes;
          })(formattedCampaign)
        );
      } else {
        props.onSubmit(formattedCampaign);
      }
    } catch (error) {
      if (error instanceof zod.ZodError) {
        form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
      }
    }
  }

  const pageTitle = props.campaign ? "Edit Settings" : "Add new campaign";

  return (
    <Box id="client-form" data-testid="campaign-form">
      {Object.entries(form.errors).length > 0 && <FormErrors form={form} />}

      <H2 className="mb-8 mt-5" data-testid="campaign-form-title">
        {pageTitle}
      </H2>

      <Form
        onSubmit={handleSubmit}
        data-testid={props.campaign ? "campaign-form-edit-form" : "campaign-form-new-form"}>
        <FormGroup legend="Basic information">
          <Select
            data-testid="program-type-dropdown"
            label="Program Type"
            placeholder="Select program type"
            helperText="This can't be edited once a campaign is created"
            value={form.values.programType}
            onChange={form.handleChange("programType")}
            isDisabled={!!props.campaign}>
            <option key={"a2a"} value={CampaignType.A2A}>
              {`${getDisplayCampaignType(CampaignType.A2A)} (A2A)`}
            </option>
            <option key={"a2e"} value={CampaignType.A2E}>
              {`${getDisplayCampaignType(CampaignType.A2E)} (A2E)`}
            </option>
          </Select>
          <Input
            isRequired
            id="campaign-name"
            label="Campaign name"
            value={form.values.name}
            onChange={form.handleChange("name")}
          />
          <Input
            id="campaign-cdnSubdirectoryName"
            label="Campaign CDN Profile"
            placeholder="campaigndirectoryname"
            helperText="Enter a profile name with no spaces. This name will be used to organize this campaign’s digital assets and landing pages at its destination URL."
            value={form.values.cdnSubdirectoryName}
            onChange={form.handleChange("cdnSubdirectoryName")}
          />
        </FormGroup>

        <FormGroup legend="Audience & strategy">
          <Select
            data-testid="campaign-objective-dropdown"
            label="Campaign objective"
            placeholder="Select campaign objective"
            value={form.values.campaignObjective}
            onChange={form.handleChange("campaignObjective")}>
            {Object.values(CampaignObjective).map((item) => (
              <option key={item} value={CampaignObjective[item]}>
                {toSpacedTitleCase(CampaignObjective[item])}
              </option>
            ))}
          </Select>
          <MultiSelect
            data-testid="campaign-audience-dropdown"
            label="Audience"
            options={audienceOptions}
            value={form.values.audiences.map((audienceChoice) => {
              return {
                value: formatAudienceValueToMatchApi(audienceChoice),
                label: addHyphenToAudienceSelection(toSpacedTitleCase(audienceChoice)),
              };
            })}
            onChange={handleAudienceMultiSelectChange}
          />
          <Select
            data-testid="campaign-population-dropdown"
            label="Population type"
            placeholder="Select campaign population type"
            value={form.values.populationType}
            onChange={form.handleChange("populationType")}>
            <option key={"parent"} value={CampaignPopulationType.PARENT}>
              {toTitleCase(CampaignPopulationType.PARENT)}
            </option>
            <option key={"student"} value={CampaignPopulationType.STUDENT}>
              {toTitleCase(CampaignPopulationType.STUDENT)}
            </option>
          </Select>
          <MultiSelect
            id="campaign-class-dropdown"
            label="Class"
            menuPlacement="top"
            options={classOptions}
            value={form.values.classes.map((tempClass) => {
              return {
                value: tempClass.toLowerCase(),
                label: tempClass,
              };
            })}
            onChange={handleClassMultiSelectChange}
          />
        </FormGroup>

        <HStack>
          <Button type="submit" mr={2} data-testid="campaign-form-submit-button">
            Submit
          </Button>
          <Button
            variant="link"
            onClick={confirmCancelModal.onOpen}
            data-testid="campaign-form-cancel-button">
            Cancel
          </Button>
        </HStack>
        <ConfirmationModal
          {...confirmCancelModal}
          message="Are you sure you want to exit? All unsaved changes will be lost"
          cancelButtonText="No"
          confirmButtonText="Yes"
          onConfirm={props.onCancel}
          modalType="warning"
        />
      </Form>
    </Box>
  );
}

export default CampaignForm;

const schema = zod.object({
  audiences: zod.string().array().optional(),
  campaignObjective: zod.string().optional(),
  populationType: zod.string().optional(),
  classes: zod.string().array().optional(),
  defaultThemeId: zod.string().optional(),
  domainUrl: zod.string().url().optional(),
  keyObjectives: zod.string().optional(),
  name: zod.string(),
  primayMetric: zod.string().optional(),
  status: zod.string().optional(),
  programType: zod.string().optional(),
  cdnSubdirectoryName: zod.string().optional(),
});
type CampaignAttributesSchema = zod.infer<typeof schema>;
type CampaignAttributesFormState = Formify<CampaignAttributesSchema>;
type CampaignAttributesFormErrors = Partial<{ [k in keyof CampaignAttributesSchema]: string }>;

function initialCampaignFormValues(campaign?: Campaign): CampaignAttributesFormState {
  // prettier-ignore
  return {
    audiences: campaign?.audiences || [],
    campaignObjective: campaign?.campaignObjective || CampaignObjective.INQUIRY_GENERATION,
    populationType: campaign?.populationType || CampaignPopulationType.PARENT,
    classes: campaign?.classes || [],
    defaultThemeId: campaign?.defaultThemeId || '',
    domainUrl: campaign?.domainUrl || '',
    keyObjectives: campaign?.keyObjectives || '',
    name: campaign?.name || '',
    primayMetric: campaign?.primayMetric || '',
    status: campaign?.status || '',
    programType: campaign?.programType || CampaignType.A2A,
    cdnSubdirectoryName: campaign?.cdnSubdirectoryName || ''
  }
}

function useCampaignFormState(campaign?: Campaign) {
  const [values, dispatch] = useReducer(
    function reducer(state: CampaignAttributesFormState, action: { field: string; value: string }) {
      switch (action.field) {
        case "audiences":
        case "campaignObjective":
        case "classes":
        case "defaultThemeId":
        case "domainUrl":
        case "keyObjectives":
        case "name":
        case "primayMetric":
        case "status":
        case "programType":
        case "cdnSubdirectoryName":
        case "populationType":
          return { ...state, [action.field]: action.value };
        default:
          throw new Error();
      }
    },
    campaign,
    initialCampaignFormValues
  );

  function handleChange(field: string) {
    return function (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
      dispatch({ field, value: event.target.value });
    };
  }

  function handleComplexChange(field: string, value: any) {
    dispatch({ field, value });
  }

  const [errors, setErrors] = useState<CampaignAttributesFormErrors>({});

  function handleErrors(errors: ZodFormErrors): void {
    const invalidFields = Object.keys(errors) as Array<keyof CampaignAttributesSchema>;
    setErrors(
      invalidFields.reduce((memo, field) => {
        memo[field] = errors[field].join(", ");
        return memo;
      }, {} as CampaignAttributesFormErrors)
    );
  }

  return { values, handleChange, handleComplexChange, errors, setErrors: handleErrors };
}

export const audienceOptions: { id: CampaignAudience; value: string; label: string }[] = [
  {
    id: CampaignAudience.TRANSFER_PROSPECTS,
    value: "transferProspects",
    label: "Transfer - Prospects",
  },
  {
    id: CampaignAudience.INTERNATIONAL_PROSPECTS,
    value: "internationalProspects",
    label: "International - Prospects",
  },
  {
    id: CampaignAudience.FIRST_YEAR_PROSPECTS,
    value: "firstYearProspects",
    label: "First Year - Prospects",
  },
  {
    id: CampaignAudience.FIRST_YEAR_INQUIRIES,
    value: "firstYearInquiries",
    label: "First Year - Inquiries",
  },
  {
    id: CampaignAudience.FIRST_YEAR_NEW_NAMES,
    value: "firstYearNewNames",
    label: "First Year - New Names",
  },
  {
    id: CampaignAudience.TRANSFER_INQUIRIES,
    value: "transferInquiries",
    label: "Transfer - Inquiries",
  },
  {
    id: CampaignAudience.TRANSFER_NEW_NAMES,
    value: "transferNewNames",
    label: "Transfer - New Names",
  },
  {
    id: CampaignAudience.INTERNATIONAL_EXISTING_INQUIRIES,
    value: "internationalExistingInquiries",
    label: "International - Existing Inquiries",
  },
  {
    id: CampaignAudience.INTERNATIONAL_NEW_NAMES,
    value: "internationalNewNames",
    label: "International - New Names",
  },
  { id: CampaignAudience.APPLICANTS, value: "applicants", label: "Applicants" },
  {
    id: CampaignAudience.ADMITTED_STUDENTS,
    value: "admittedStudents",
    label: "Admitted Students",
  },
  {
    id: CampaignAudience.DEPOSITED_STUDENTS,
    value: "depositedStudents",
    label: "Deposited Students",
  },
  { id: CampaignAudience.PARENTS, value: "parents", label: "Parents" },
];

const classOptions = [
  { value: "freshman", label: "Freshman" },
  { value: "sophomore", label: "Sophomore" },
  { value: "junior", label: "Junior" },
  { value: "senior", label: "Senior" },
];
