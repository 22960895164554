import { useEffect, useState } from "react";

import { useCurrentClient } from "state/ducks";

import { Campaign } from "models";

import { DEFAULT_PAGINATED_REQUEST_OPTIONS, PaginatedRequestOptions } from "types/pagination";

interface FetchTriggerProps {
  delayFetch: boolean;
  trigger: boolean;
}

export const useClientCampaigns = (
  options: PaginatedRequestOptions = DEFAULT_PAGINATED_REQUEST_OPTIONS,
  fetchTrigger?: FetchTriggerProps
) => {
  const [clientCampaigns, setClientCampaigns] = useState<Campaign[]>([]);
  const currentClient = useCurrentClient();

  useEffect(() => {
    if (fetchTrigger?.delayFetch && !fetchTrigger.trigger) return;

    const fetchCampaigns = async () => {
      try {
        const clientCampaigns = await Campaign.all({
          options,
          clientId: currentClient.id,
        });
        setClientCampaigns(clientCampaigns.items);
      } catch (error) {
        console.error("Failed to fetch campaigns", error);
      }
    };
    fetchCampaigns();
  }, [currentClient.id, fetchTrigger?.delayFetch, fetchTrigger?.trigger, options]);

  return clientCampaigns;
};

export const useClientCampaignsInModal = (
  isModalOpen = false,
  options: PaginatedRequestOptions = DEFAULT_PAGINATED_REQUEST_OPTIONS
) => useClientCampaigns(options, { delayFetch: true, trigger: isModalOpen });
