import { Select as ChakraReactSelect } from "chakra-react-select";
import { Props as SelectProps } from "react-select";
import theme from "styles/theme";
import {
  InputControl,
  InputControlProps,
  useInputProps,
} from "components/forms/input-control/input-control";

interface CustomSelectProps<Option>
  extends Omit<SelectProps<Option, true>, "isMulti">,
    InputControlProps {}

export function MultiSelect<Option>({ ...props }: CustomSelectProps<Option>) {
  const { controlProps, inputProps } = useInputProps(props);

  return (
    <InputControl {...controlProps}>
      <ChakraReactSelect
        isMulti
        selectedOptionStyle="check"
        chakraStyles={{
          multiValue: (provided) => ({
            ...provided,
            background: theme.colors.neutrals["offWhite"],
            borderRadius: theme.borderRadius["lg"],
            boxShadow: theme.boxShadow["01"],
            display: "inline-flex",
            gap: theme.spacing[2],
            marginRight: theme.spacing[1.5],
            paddingLeft: theme.spacing[3],
            paddingRight: theme.spacing[2],
            paddingY: theme.spacing[0.5],
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: theme.colors.neutrals[1],
            fontSize: theme.fontSize.xs[0],
            lineHeight: 1.16,
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            color: theme.colors.primary.aqua[600],
            fontSize: theme.fontSize.base[0],
            marginInlineStart: 0,
            opacity: 1,
            ":hover": {
              background: theme.colors.neutrals[4],
              color: theme.colors.neutrals[1],
              opacity: 1,
            },
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingX: theme.spacing[2],
          }),
        }}
        {...inputProps}
      />
    </InputControl>
  );
}

MultiSelect.displayName = "MultiSelect";

export default MultiSelect;
