import { forwardRef, ReactElement } from "react";

import {
  CustomButtonProps,
  SizeKey,
  VariantKey,
} from "components/new/custom-button/custom-button.types";

const CustomButton = forwardRef<HTMLInputElement, CustomButtonProps>(
  (
    {
      customClass = "",
      iconBeforeCustomClass = "",
      iconAfterCustomClass = "",
      variant = "primary",
      size = "md",
      iconBefore,
      iconAfter,
      onClick,
      disabled,
      children,
      type = "button",
    },
    ref
  ): ReactElement<any> => {
    const sizeClass: Record<SizeKey, string> = {
      xs: "text-sm px-2 py-1",
      sm: "text-sm px-3 py-2 h-8",
      md: "text-base py-4 h-11",
      lg: "text-lg py-3 px-5 h-12",
      smSquare: "text-sm px-2 py-2 h-8",
    };
    const variantClass: Partial<Record<VariantKey, string>> = {
      primary: "text-white bg-navy hover:bg-navy-1 disabled:opacity-50",
    };
    const defaultClass =
      "flex justify-center font-semibold items-center rounded-md transition ease-in-out duration-150";
    const btnClassName = `${defaultClass} ${variantClass[variant]} ${sizeClass[size]} ${customClass}`;

    return (
      <button
        className={btnClassName}
        onClick={onClick}
        disabled={disabled}
        ref={ref as any}
        type={type}>
        {!!iconBefore && (
          <span className={`pr-1 pt-px ${iconBeforeCustomClass}`}>{iconBefore}</span>
        )}
        {children}
        {!!iconAfter && <span className={`pr-1 pt-px ${iconAfterCustomClass}`}>{iconAfter}</span>}
      </button>
    );
  }
);

export default CustomButton;
