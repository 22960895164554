import { NavLink } from "react-router-dom";
import { Link, LinkProps } from "@chakra-ui/react";

export interface RouterLinkProps extends LinkProps {
  to: string;
  color?: string;
  textDecoration?: string;
  fontWeight?: string;
  id?: string;
}

const RouterLink = ({
  to,
  color = "text.link",
  textDecoration = "underline",
  fontWeight = "semibold",
  id,
  ...rest
}: RouterLinkProps) => (
  <Link
    as={NavLink}
    data-testid={`router-link-${id}`}
    to={to}
    color={color}
    textDecoration={textDecoration}
    fontWeight={fontWeight}
    {...rest}
  />
);

export default RouterLink;
