export enum TouchpointType {
  EMAIL = "EMAIL",
  LANDING_PAGE = "LANDING_PAGE",
  THEME = "THEME",
}

export enum TouchpointTypeLabel {
  EMAIL = "Email",
  EMAILS = "Emails",
  LP_OTHER = "Other", // Formerly "Landing Page" or "Landing Pages"
  LP_TOUCHPOINT = "Touchpoint", // Formerly "Landing Page"
  LP_TOUCHPOINTS = "Touchpoints", // Formerly "Landing Pages"
}

export enum TouchpointBuilderType {
  EMAIL = "EMAIL",
  LANDING_PAGE = "LANDING_PAGE",
}

export enum TouchpointStatus {
  DESIGN = "Design",
  DEVELOPMENT = "Development",
  DONE = "Done",
  NEW = "New",
  QA = "QA",
}

// TODO: Remove the "" option, but fix functions that require it.
export type LpRedirectionType = "TOUCHPOINT" | "URL" | "";

export const ContentTypeLabels = {
  AD_HOC: "Ad Hoc",
  APP_NUDGE: "App Nudge",
  APPLICATION_DEADLINE: "Application Deadline",
  CLICKER: "Clicker",
  CONTENT_TOPIC: "Content Topic",
  DEPOSIT_DEADLINE: "Deposit Deadline",
  DEPOSIT_PUSH: "Deposit Push",
  DOMAIN_REMEDIATION: "Domain Remediation",
  DOMAIN_WARMUP: "Domain Warmup",
  ENGAGEMENT: "Engagement",
  ENROLL: "Enroll",
  EVENT: "Event",
  FAFSA: "FAFSA",
  INTRO_PROGRAM: "Intro Program",
  INVITE_TO_APPLY_ONGOING: "Invite to Apply Ongoing",
  MAIL_SUPPORT_CAMPAIGN: "Mail Support Campaign",
  OFFER: "Offer",
  OTHER: "Other",
  PREPARE_TO_APPLY: "Prepare to Apply",
  PULSE_CHECK: "Pulse Check",
  PULSE_CHECK_RESPONSE: "Pulse Check Response",
  SUPPLEMENTAL: "Supplemental",
} as const;

export type ContentType = keyof typeof ContentTypeLabels;

export enum ContentInteraction {
  STATIC = "STATIC",
  INTERACTIVE = "INTERACTIVE",
  VIDEO = "VIDEO",
}

export enum CampaignObjective {
  APP_NUDGE = "APP_NUDGE",
  APPLY = "APPLY",
  APPLICATION_GENERATION = "APPLICATION_GENERATION",
  CONTENT_TOPIC = "CONTENT_TOPIC",
  DEPOSIT = "DEPOSIT",
  DOMAIN_REMEDIATION = "DOMAIN_REMEDIATION",
  DOMAIN_WARMUP = "DOMAIN_WARMUP",
  ENROLL = "ENROLL",
  FAFSA = "FAFSA",
  FAFSA_SUBMIT = "FAFSA_SUBMIT",
  INQUIRY_CULTIVATION = "INQUIRY_CULTIVATION",
  INQUIRY_GENERAL = "INQUIRY_GENERAL",
  INQUIRY_GENERATION = "INQUIRY_GENERATION",
  INQUIRY_GENERATION_CULTIVATION = "INQUIRY_GENERATION_CULTIVATION",
  INTRO_PROGRAM = "INTRO_PROGRAM",
  MAIL_SUPPORT_CAMPAIGN = "MAIL_SUPPORT_CAMPAIGN",
  OTHER = "OTHER",
}

export enum CampaignPopulationType {
  PARENT = "PARENT",
  STUDENT = "STUDENT",
}

export interface TouchpointPreviewProps {
  emptyMessage?: string;
  htmlContent: any;
  htmlJSON: string;
  isCommentMode?: boolean;
  mobileView?: boolean;
}
