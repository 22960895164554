import { useState } from "react";
import { Flex } from "@chakra-ui/react";

import Link from "components/partials/link/link";
import { Span } from "components/partials/typography/typography";

const TruncatedText = ({
  text,
  noOfLines = 4,
  initialTruncate = true,
  textAlign = "left",
}: {
  text: string;
  noOfLines: number;
  initialTruncate: boolean;
  textAlign?: "left" | "right";
}) => {
  const [isTruncated, setIsTruncated] = useState(initialTruncate);

  return (
    <Flex
      className="content"
      flexDirection={"column"}
      mb="1"
      fontSize="xs"
      data-testid="truncate-text">
      {text.length < 125 ? (
        <Span textAlign={textAlign}>{text}</Span>
      ) : isTruncated ? (
        <>
          <Span noOfLines={noOfLines}>{text}</Span>
          <Link textDecoration="underline" mt="1" onClick={() => setIsTruncated(false)}>
            Show More
          </Link>
        </>
      ) : (
        <>
          <Span isTruncated={isTruncated}>{text}</Span>
          <Link textDecoration="underline" mt="1" onClick={() => setIsTruncated(true)}>
            Show Less
          </Link>
        </>
      )}
    </Flex>
  );
};

export default TruncatedText;
