import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Campaign, { CampaignAttributes } from "models/campaign";

interface CampaignsSlice {
  current: CampaignAttributes | null;
}

const initialState: CampaignsSlice = { current: null };

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCurrentCampaign: (state, action: PayloadAction<CampaignAttributes | null>) => {
      state.current = action.payload;
    },
  },
});

export const currentCampaignSelector = (state: { campaigns: CampaignsSlice }) =>
  state.campaigns.current;

export const { setCurrentCampaign } = campaignsSlice.actions;
export default campaignsSlice.reducer;

export function useCurrentCampaign(): Campaign {
  const attributes = useSelector(currentCampaignSelector);

  if (!attributes) {
    throw new Error("useCurrentCampaign: called before a campaign was stored in redux");
  }

  return useMemo(() => new Campaign(attributes), [attributes]);
}

export type { CampaignsSlice };
