import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { classMerge } from "utilities/css";

const buttonClassNames = cva(
  "flex items-center justify-center gap-x-1.5 font-bold border-2 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-600 shadow active:shadow-none transition duration-150 ease-in-out disabled:bg-neutral-5 disabled:border-neutral-5 disabled:text-neutral-3 disabled:cursor-not-allowed",
  {
    variants: {
      color: {
        aqua: "border-aqua-dark hover:border-aqua-500 active:border-aqua-500",
        white:
          "border-white hover:border-neutral-4 active:border-neutral-4 text-default hover:text-gray-900 active:text-gray-900",
      },
      shape: {
        rounded: "rounded-md",
      },
      size: {
        sm: "text-sm px-3 py-1.5 leading-5.5",
        md: "text-sm px-4 py-2.5 leading-5.5",
        lg: "text-base px-5 py-3",
      },
      variant: {
        solid: "",
        outline: "bg-white",
        ghost: "bg-off-white border-none",
        link: "bg-transparent border-none !shadow-none px-0",
      },
    },
    compoundVariants: [
      {
        color: "aqua",
        variant: "solid",
        className: "bg-aqua-dark hover:bg-aqua-500 active:bg-aqua-500 text-white",
      },
      {
        color: "aqua",
        variant: ["outline", "ghost", "link"],
        className: "text-aqua-dark hover:text-aqua-500 active:text-aqua-500",
      },
      {
        color: "white",
        variant: "solid",
        className: "bg-white hover:bg-neutral-4 active:bg-neutral-4",
      },
      {
        color: "white",
        variant: "link",
        className: "active:bg-neutral-5",
      },
    ],
    defaultVariants: {
      color: "aqua",
      shape: "rounded",
      size: "sm",
      variant: "solid",
    },
  }
);

type NativeButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ToastButtonProps
  extends Omit<NativeButtonProps, "color">,
    VariantProps<typeof buttonClassNames> {}

const ToastButton = ({ color, shape, size, variant, className, ...rest }: ToastButtonProps) => {
  return (
    <button
      type="button"
      className={classMerge(buttonClassNames({ color, shape, size, variant, className }))}
      {...rest}
    />
  );
};

export default ToastButton;
