const sharedFormStyles = {
  backgroundColor: "forms.inputBg",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "forms.inputBorderColor",
  color: "forms.inputColor",
  outlineWidth: "1px",
  outlineOffset: "1px",
  _invalid: {
    borderColor: "forms.errorBorderColor",
    boxShadow: "none",
  },
  _hover: {
    borderColor: "forms.hoverBorderColor",
    _invalid: {
      borderColor: "forms.errorBorderColor",
    },
  },
  _focus: {
    boxShadow: "inputFocusShadow",
    borderColor: "forms.focusBorderColor",
    _invalid: {
      boxShadow: "errorFocusShadow",
      borderColor: "forms.errorBorderColor",
    },
  },
  _placeholder: { color: "text.muted" },
  _disabled: {
    backgroundColor: "forms.inputDisabledBg",
    color: "text.disabled",
    cursor: "not-allowed",
    opacity: 1,
    _hover: {
      borderColor: "forms.inputBorderColor",
    },
  },
};

const Input = {
  variants: {
    outline: {
      field: sharedFormStyles,
    },
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
};

const Textarea = {
  variants: {
    outline: sharedFormStyles,
  },
  defaultProps: {
    size: "md",
    variant: "outline",
  },
};

const FormLabel = {
  baseStyle: {
    color: "text.body",
    fontWeight: "bold",
    opacity: "1!important",
  },
};

const Popover = {
  variants: {
    responsive: {
      popper: {
        maxWidth: "unset",
        width: "unset",
      },
    },
  },
};

const components = {
  Input,
  FormLabel,
  NumberInput: {
    ...Input,
  },
  Popover,
  Select: {
    ...Input,
  },
  Textarea,
};

export default components;
