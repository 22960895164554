import { ClientReviewStatusType } from "components/partials/status-indicator/status-indicator.type";

import { ClientReviewStatus } from "types";

const StatusIndicator = ({ clientVersionStatuses }: ClientReviewStatusType) => {
  const getStatusIconColor = () => {
    switch (clientVersionStatuses?.status) {
      case ClientReviewStatus.CLIENT_REVIEW:
      case ClientReviewStatus.REJECTED:
      case ClientReviewStatus.SYSTEM_DRAFT:
        return "bg-warning";
      case ClientReviewStatus.PUBLISHED:
      case ClientReviewStatus.APPROVED:
        return "bg-success";
      default:
        return "bg-neutral-3 ";
    }
  };
  return (
    <div className="flex items-center whitespace-nowrap">
      <div className={`w-3 h-3 rounded-full mr-2 ${getStatusIconColor()}`} />
      <span>{clientVersionStatuses?.clientReviewStatusName}</span>
    </div>
  );
};
export default StatusIndicator;
