import { Select as ChakraSelect, SelectProps as ChakraSelectProps } from "@chakra-ui/react";

import {
  InputControl,
  InputControlProps,
  useInputProps,
} from "components/forms/input-control/input-control";

interface SelectProps extends ChakraSelectProps, InputControlProps {}

const Select = ({ ...props }: SelectProps) => {
  const { controlProps, inputProps } = useInputProps(props);

  return (
    <InputControl {...controlProps}>
      <ChakraSelect
        width={props.width || "100%"}
        fontSize={inputProps.size === "sm" ? "sm" : "md"}
        {...inputProps}
      />
    </InputControl>
  );
};

export default Select;
