import { twMerge } from "tailwind-merge";

const tableHeadFootBorderColor = "divide-gray-400";
const tableBodyRowBorderColor = "divide-gray-300";

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {}
export const Table = ({ children, className, ...rest }: TableProps) => {
  const mergedClasses = twMerge("w-full divide-y", tableHeadFootBorderColor, className);
  return (
    <table className={mergedClasses} {...rest}>
      {children}
      <tfoot className="h-6">
        <TableRow />
      </tfoot>
    </table>
  );
};

interface TableHeadProps extends React.HTMLAttributes<HTMLTableSectionElement> {}
export const TableHead = ({ children, ...rest }: TableHeadProps) => {
  return (
    <thead {...rest}>
      <TableRow>{children}</TableRow>
    </thead>
  );
};

interface TableBodyProps extends React.HTMLAttributes<HTMLTableSectionElement> {}
export const TableBody = ({ children, className, ...rest }: TableBodyProps) => {
  const mergedClasses = twMerge("divide-y", tableBodyRowBorderColor, className);
  return (
    <tbody className={mergedClasses} {...rest}>
      {children}
    </tbody>
  );
};

export interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {}
export const TableRow = (props: TableRowProps) => {
  return <tr {...props} />;
};
