import { Icon, Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import { MdAddComment, MdModeComment } from "react-icons/md";

import { AiFillFlag } from "react-icons/ai";

import { Span } from "components/partials/typography/typography";

import { Comment } from "types/comments";

export interface CommentPopoverButtonProps extends ButtonProps {
  comment?: Comment;
  isTriggerActive?: boolean;
}
export const CommentPopoverButton = forwardRef<CommentPopoverButtonProps, "button">(
  ({ comment, isTriggerActive, ...rest }, ref) => {
    const scaledDown = 0.95;
    const scaledUp = 1.05;

    const buttonColor = comment?.actionItem ? "scarlett" : "aqua";

    const buttonStyles: ButtonProps = {
      alignItems: "center",
      colorScheme: buttonColor,
      boxShadow: "outer",
      fontSize: ".8125rem",
      fontWeight: "bold",
      height: "auto",
      left: !!comment ? -6 : -3,
      justifyContent: "space-between",
      position: "absolute",
      paddingX: 2,
      paddingY: 1,
      rounded: "full",
      size: "xs",
      transform: `scale(${isTriggerActive ? scaledUp : 1})`,
      _active: {
        backgroundColor: `${buttonColor}.700`,
        transform: `scale(${scaledDown})`,
      },
      _focus: {
        boxShadow: "outline",
        transform: `scale(${scaledUp})`,
      },
      _hover: {
        backgroundColor: `${buttonColor}.600`,
        transform: `scale(${scaledUp})`,
      },
      top: -3,
    };

    return (
      <Button ref={ref} {...buttonStyles} {...rest}>
        {!!comment ? (
          <>
            <Icon as={comment.actionItem ? AiFillFlag : MdModeComment} mr={1} />
            <Span>#{comment.badgeNumber}</Span>
          </>
        ) : (
          <Icon as={MdAddComment} />
        )}
      </Button>
    );
  }
);
