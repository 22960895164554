import { FieldErrors } from "react-hook-form";
import { ListItem, UnorderedList } from "@chakra-ui/react";

import FormAlert, { FormErrorsAlertProps } from "components/partials/form-alert/form-alert";

interface FormErrorAlertAccordionProps extends FormErrorsAlertProps {
  errors: FieldErrors;
  /** An array of field labels */
  fieldsWithErrors?: string[];
  heading: string;
}
const FormErrorAlertAccordion = ({
  fieldsWithErrors,
  ...rest
}: Omit<FormErrorAlertAccordionProps, "status">) => {
  if (!fieldsWithErrors || fieldsWithErrors.length === 0) {
    return null;
  }

  return (
    <FormErrorsAlertWrapper status="error" isCollapsible {...rest}>
      {fieldsWithErrors.length === 1 ? (
        <p>
          Unable to save content settings due to missing information in the
          {<span className="font-medium"> {fieldsWithErrors[0]}</span>} field.
        </p>
      ) : (
        <>
          <p>Unable to save content settings due to missing information in the following fields:</p>
          <UnorderedList>
            {fieldsWithErrors.map((field) => (
              <ListItem key={field} fontWeight="medium">
                {field}
              </ListItem>
            ))}
          </UnorderedList>
        </>
      )}
    </FormErrorsAlertWrapper>
  );
};

export { FormErrorAlertAccordion };

interface FormErrorsAlertWrapperProps extends FormErrorsAlertProps {
  /**
   * An alert may "overlay" content or "push" content beneath it
   */
  contentInteraction?: "overlay" | "push";
}
const FormErrorsAlertWrapper = ({
  contentInteraction = "overlay",
  ...rest
}: FormErrorsAlertWrapperProps) => {
  return (
    <div className="relative mb-1">
      <FormAlert
        variant="left-accent"
        alignSelf="center"
        borderRadius="lg"
        maxWidth="2xl"
        position={contentInteraction === "overlay" ? "absolute" : "relative"}
        zIndex={contentInteraction === "overlay" ? "dropdown" : undefined}
        sx={{
          "& p": {
            color: "inherit",
          },
        }}
        {...rest}
      />
    </div>
  );
};
