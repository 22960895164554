import { Icon, Text } from "@chakra-ui/react";
import { MdInsertEmoticon } from "react-icons/md";

import { Strong } from "components/partials/typography/typography";

import { TeamMember } from "types";

export const CommentDisplayByline = ({ user }: { user: Partial<TeamMember> }) => (
  <Text py={2} display="flex" alignItems="center">
    <Icon as={MdInsertEmoticon} mr={2} data-testid="comment-display-byline-icon" />
    <Strong fontSize="sm" data-testid="comment-display-byline-user-name">
      {user.firstName} {user.lastName}
    </Strong>
  </Text>
);
