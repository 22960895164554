import { FlexProps } from "@chakra-ui/react";
import { useHistory, useRouteMatch, RouteComponentProps } from "react-router-dom";
import classNames from "classnames";

import Toggle from "components/partials/toggle/toggle";
import { default as BuilderViewToggle } from "components/partials/toggle-button-group/toggle-button-group";
import Button from "components/forms/button/button";

import { useCurrentUser } from "state/ducks";
import { TouchpointVersionAttributes } from "models/touchpoint-version";

import { Route as AppRoute } from "utilities/app-routes";
import { hasPermission } from "utilities/user";

import { TouchpointType } from "types/touchpoint";
import { ContentStatus } from "types";
import { ParamsType } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import { Permission } from "types/auth";

export const builderPreviewMenuOptions = {
  mobileView: "Mobile",
  desktopView: "Desktop",
  htmlView: "HTML",
} as const;

export type BuilderPreviewViews =
  (typeof builderPreviewMenuOptions)[keyof typeof builderPreviewMenuOptions];

interface BuilderPreviewHeaderProps extends FlexProps {
  currentView: BuilderPreviewViews;
  selectedVersion?: TouchpointVersionAttributes;
  setCurrentView: (string: BuilderPreviewViews) => void;
  isCommentMode: boolean;
  setIsCommentMode: (isCommentMode: boolean) => void;
  isTouchpointPreview: boolean;
}

/**
 * Sits above Touchpoint Builder Preview Pane, displays toggle (`Toggle`) and tabs for navigation (`BuilderViewToggle`) between Desktop, Mobile and HTML views (`BuilderPreviewViews`) .
 */

interface OpenInEditorProps extends FlexProps {
  params: ParamsType;
  selectedVersion?: TouchpointVersionAttributes;
  history: RouteComponentProps["history"];
}

const handleOpenInEditor = ({ params, selectedVersion, history }: OpenInEditorProps) => {
  const { clientId, campaignId, touchpointId } = params;

  const endRoute = selectedVersion?.status === ContentStatus.PUBLISHED ? "/preview" : "/design";

  const touchpointRouteType =
    selectedVersion?.type === TouchpointType.EMAIL ? AppRoute.emailBuilder : AppRoute.lpBuilder;

  const touchpointBaseRoute = `/clients/${clientId}/campaigns/${campaignId}/creative/${touchpointId}/versions/${selectedVersion?.id}`;

  const touchpointFinalRoute = `${touchpointBaseRoute}${touchpointRouteType}${endRoute}`;

  history.push(touchpointFinalRoute);
};

export const BuilderPreviewPaneHeader = ({
  currentView,
  selectedVersion,
  setCurrentView,
  isCommentMode,
  setIsCommentMode,
  isTouchpointPreview = false,
  ...rest
}: BuilderPreviewHeaderProps) => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const baseStyles = classNames("flex items-center w-full justify-between ", {
    "mb-2": isTouchpointPreview,
    "justify-center mb-8": !isTouchpointPreview,
  });

  const { mobileView, desktopView, htmlView } = builderPreviewMenuOptions;

  const viewToggleOptions: BuilderPreviewViews[] = [mobileView, desktopView];

  if (!isClientUser) {
    // Clients don't see the HTML view, everyone else does
    viewToggleOptions.push(htmlView);
  }

  return (
    <div className={baseStyles} data-testid="builder-preview-pane__header">
      <Button
        className={!isTouchpointPreview || isClientUser ? "invisible" : ""}
        size="sm"
        variant="secondary"
        data-testid="open-in-editor-button"
        onClick={() => handleOpenInEditor({ params, selectedVersion, history })}>
        Open in editor
      </Button>
      <BuilderViewToggle
        groupName="preview-toggle"
        options={viewToggleOptions}
        defaultValue={currentView}
        onChange={(option) => setCurrentView(option)}
      />

      <Toggle
        customClass={currentView === htmlView ? "invisible" : ""}
        isChecked={isCommentMode}
        setIsChecked={setIsCommentMode}
      />
    </div>
  );
};
