import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  CheckboxGroup as ChakraCheckboxGroup,
  CheckboxGroupProps as ChakraCheckboxGroupProps,
  FormControl,
  Stack,
  Box,
} from "@chakra-ui/react";

import ErrorMessage from "components/forms/error-message/error-message";
import HelperText from "components/forms/helper-text/helper-text";
import Label from "components/forms/label/label";
import { InputControlProps } from "components/forms/input-control/input-control";

import { LabelValuePair } from "types";

interface CheckboxProps extends ChakraCheckboxProps, InputControlProps {}

const Checkbox = ({ label, size, fontWeight = "normal", fontSize, ...rest }: CheckboxProps) => {
  const isLarge = size === "lg";
  const isSmall = size === "sm";

  const checkboxAppearance = {
    "& span:first-of-type": {
      padding: isSmall ? "0.625rem" : isLarge ? "1.125rem" : ".875rem",
      borderRadius: "lg",
      background: "white",
      borderColor: "transparent",
      boxShadow: "inner",
      "&[data-invalid]": {
        boxShadow: "error",
      },
      "&[data-focus]": {
        borderWidth: "2px",
        borderColor: "forms.focusBorderColor",
      },
      "& svg": {
        width: isSmall ? "0.625rem" : isLarge ? "1.125rem" : ".875rem",
      },
    },
  };

  return (
    <ChakraCheckbox
      data-testid="checkbox"
      colorScheme="white"
      fontWeight={fontWeight}
      padding="0"
      iconColor="black"
      iconSize={4}
      spacing={isLarge ? 4 : 2}
      sx={{ ...checkboxAppearance }}
      {...rest}>
      <Box fontSize={fontSize || (isLarge ? "md" : "sm")}>{label}</Box>
    </ChakraCheckbox>
  );
};

export default Checkbox;

export type CheckboxOption = LabelValuePair;
interface CheckboxGroupProps extends ChakraCheckboxGroupProps {
  checkboxes?: CheckboxOption[];
  children?: React.ReactNode;
  label?: string;
  helperText?: string;
  errorMessage?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

export const CheckboxGroup = ({
  label,
  helperText,
  errorMessage,
  isInvalid = false,
  isDisabled = false,
  size,
  checkboxes,
  children,
  ...rest
}: CheckboxGroupProps) => {
  return (
    <FormControl data-testid="checkbox-group" isInvalid={isInvalid} isDisabled={isDisabled}>
      {label && <Label value={label} fontWeight="bold" />}
      <ChakraCheckboxGroup {...rest}>
        <Stack>{children}</Stack>
      </ChakraCheckboxGroup>
      {helperText && <HelperText message={helperText as string} />}
      {isInvalid && errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <ErrorMessage message="Invalid selection" />
      )}
    </FormControl>
  );
};
