import { combineReducers, configureStore } from "@reduxjs/toolkit";

import clientsReducer, { ClientsSlice } from "state/ducks/clients";
import touchpointsReducer, { TouchpointsSlice } from "state/ducks/touchpoint";
import localStorageReducer, { LocalStorageSlice } from "state/ducks/local-storage";
import campaignsReducer, { CampaignsSlice } from "state/ducks/campaigns";
import themesReducer, { ThemesSlice } from "state/ducks/themes";
import usersReducer, { UsersSlice } from "state/ducks/users";
import builderContentReducer, { BuilderContentSlice } from "state/ducks/builder-content";
import inboxFiltersReducer, { InboxFiltersSlice } from "state/ducks/inbox-filters";

export interface RootState {
  clients: ClientsSlice;
  localStorage: LocalStorageSlice;
  user: UsersSlice;
  campaigns: CampaignsSlice;
  themes: ThemesSlice;
  creatives: TouchpointsSlice;
  builderContent: BuilderContentSlice;
  inboxFilters: InboxFiltersSlice;
}

const rootReducer = combineReducers({
  clients: clientsReducer,
  localStorage: localStorageReducer,
  users: usersReducer,
  campaigns: campaignsReducer,
  themes: themesReducer,
  touchpoints: touchpointsReducer,
  builderContent: builderContentReducer,
  inboxFilters: inboxFiltersReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export { rootReducer };
export default store;
