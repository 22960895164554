import { useRouteMatch, Switch, Route } from "react-router-dom";

import CampaignNewPage from "containers/admin/clients/campaign/campaign-new-page/campaign-new-page";
import CampaignsTab from "containers/admin/clients/client/tab-components/campaigns-tab/campaigns-tab";
import CampaignRoutes from "containers/admin/clients/campaign/campaign-routes";
import CampaignProvider from "containers/admin/clients/campaign/components/campaign-provider/campaign-provider";

import { usePagePermission } from "hooks/use-page-permission";

import { ChildPageProps } from "types";
import { Permission } from "types/auth";

const ClientCampaignsRoutes = ({ parentUrl }: ChildPageProps) => {
  const { path } = useRouteMatch();

  usePagePermission(Permission.PERM_CAMPAIGN_READ);

  return (
    <Switch>
      <Route exact path={path}>
        <CampaignsTab parentUrl={parentUrl} />
      </Route>

      <Route path={path + "/new"}>
        <CampaignNewPage parentUrl={parentUrl} />
      </Route>

      <Route path={`${path}/:campaignId`}>
        <CampaignProvider>
          <CampaignRoutes />
        </CampaignProvider>
      </Route>
    </Switch>
  );
};

export default ClientCampaignsRoutes;
