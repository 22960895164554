import { Box, Flex, FlexProps } from "@chakra-ui/react";
import {
  EmailHeaderPreview,
  EmailHeaders,
} from "containers/admin/clients/touchpoint/components/email-builder/email-header-preview/email-header-preview";
import { BEE_EMAIL_SELECTOR, BEE_LP_SELECTOR } from "utilities/constants";

interface BuilderPreviewSandboxProps extends FlexProps {
  isCommentMode: boolean;
  isMobileView: boolean;
  emailHeaders?: EmailHeaders;
}
export const DesignPreviewSandbox = ({
  isCommentMode,
  isMobileView,
  emailHeaders,
  children,
  ...rest
}: BuilderPreviewSandboxProps) => {
  // Preview HTML and CSS will be namedspaced to avoid conflicts with the app.
  const designPreviewStyles = {
    [`& ${BEE_LP_SELECTOR} .bee-image, 
    & ${BEE_LP_SELECTOR} .bee-divider`]: {
      // Prevent the default overflow: auto from cropping comment trigger
      overflow: "inherit",
    },
    [`& ${BEE_LP_SELECTOR}, & ${BEE_EMAIL_SELECTOR}`]: {
      width: "100%",
      "ul, ol": { margin: "revert", padding: "revert", listStyle: "initial" },
    },
    ...(isMobileView && {
      [`& ${BEE_EMAIL_SELECTOR} table`]: {
        minWidth: "100%",
        width: "auto !important",
      },
    }),
  };

  const borderColorClass = isCommentMode ? "border-blue-500" : "border-neutral-4";

  return (
    <Flex
      className={`border ${borderColorClass} flex-1 flex-col`}
      sx={{ ...designPreviewStyles }}
      {...rest}>
      {emailHeaders && (
        <EmailHeaderPreview
          emailHeaders={emailHeaders}
          isCommentMode={isCommentMode}
          isMobileView={isMobileView}
          className={`border-b ${borderColorClass}`}
        />
      )}
      <Box data-testid="builder__device-window">{children}</Box>
    </Flex>
  );
};
