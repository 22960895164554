import { FC } from "react";

import { WarningIcon } from "@chakra-ui/icons";
import NavLink from "components/partials/nav-link/nav-link";
import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import LockTabHeader from "containers/admin/clients/touchpoint/components/lock-tab-header/lock-tab-header";

import { dashCase } from "utilities";

export const builderNavTabs = ["Design", "Content Settings", "Preview"] as const;

export type BuilderNavTab = (typeof builderNavTabs)[number];

export const builderRoutes = {
  design: dashCase(builderNavTabs[0]),
  contentSettings: dashCase(builderNavTabs[1]),
  preview: dashCase(builderNavTabs[2]),
};

interface BuilderNavTabProps {
  isPreviewMode: boolean;
  url: string;
  invalidTabs: BuilderNavTab[];
  setShowConfirmation?: () => void;
  currentTab?: string;
  setNavigateTo?: (value: string) => void;
}

export const BuilderTabNav: FC<BuilderNavTabProps> = ({
  isPreviewMode,
  url,
  invalidTabs,
  setShowConfirmation,
  currentTab,
  setNavigateTo,
  ...rest
}) => {
  const handleNavClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tab: BuilderNavTab
  ) => {
    if (
      currentTab === dashCase(builderRoutes.contentSettings) &&
      invalidTabs.map((item) => dashCase(item)).includes(builderRoutes.contentSettings as string)
    ) {
      event.preventDefault();

      if (setShowConfirmation) {
        setShowConfirmation();
      }

      if (setNavigateTo) {
        const nextTab = `${url}/${dashCase(tab)}`;

        setNavigateTo(nextTab);
      }
    }
  };

  return (
    <SecondaryNav data-testid="builder-tab-nav" py={0} {...rest}>
      {builderNavTabs.map((tab) =>
        isPreviewMode && tab === "Design" ? (
          <LockTabHeader key="locked-design" label={tab} />
        ) : (
          <NavLink
            key={tab}
            to={`${url}/${dashCase(tab)}`}
            onClick={(e) => handleNavClick(e, tab)}
            mr={{ sm: 8, md: 12 }}
            data-testid={`navlink-${tab}`}>
            {tab}
            {invalidTabs.includes(tab) && (
              <span className="text-wrong">
                <WarningIcon boxSize={5} className="ml-1 p-0.5" />
              </span>
            )}
          </NavLink>
        )
      )}
    </SecondaryNav>
  );
};
