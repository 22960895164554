import { Route, Switch, useRouteMatch } from "react-router-dom";

import CampaignSettingsTab from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import CampaignSettingsEditTab from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-edit-tab/campaign-settings-edit-tab";

import { Route as AppRoute } from "utilities/app-routes";

import { usePagePermission } from "hooks/use-page-permission";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { Permission } from "types/auth";

const CampaignSettingsRoutes = () => {
  const { path } = useRouteMatch();

  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);
  usePagePermission(Permission.PERM_CAMPAIGN_READ);

  return (
    <Switch>
      <Route path={path + AppRoute.edit}>
        <CampaignSettingsEditTab />
      </Route>
      <Route path={path}>
        <CampaignSettingsTab />
      </Route>
    </Switch>
  );
};

export default CampaignSettingsRoutes;
