import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { VStack, Flex } from "@chakra-ui/react";

import PageContent from "components/partials/page-content/page-content";
import PageContentHeader from "components/partials/page-content-header/page-content-header";
import { usePaginationState } from "components/partials/paginated-table/paginated-table";
import PaginatedTableWrapper from "components/partials/paginated-table-wrapper/paginated-table-wrapper";
import { InboxTable } from "components/table/inbox-table/inbox-table";
import { H2, H3 } from "components/partials/typography/typography";
import Search from "components/new/custom-search/custom-search";
import FilterStatusForm from "components/new/filter-status-form/filter-status-form";

import { useCurrentUser } from "state/ducks/users";
import { Client } from "models";

import { hasPermission } from "utilities/user";
import { Route as AppRoute } from "utilities/app-routes";

import { emptyPaginatedResponse, PaginatedResponse } from "types/pagination";
import { Permission } from "types/auth";
import { InboxItem, InboxTableColumnId, InboxTableColumns, InboxFilter } from "types/inbox";

const ReviewsPage = () => {
  const { path } = useRouteMatch();
  const isApprovedPage = path === AppRoute.approved;

  // User State ---------------------------------------------------------------
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Filter and Search State -------------------------------------------------------------
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [currentSearchResults, setCurrentSearchResults] = useState<InboxItem[]>();
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  // Pagination & Sort State -------------------------------------------------
  const [paginationResponse, setPaginationResponse] =
    useState<PaginatedResponse<InboxItem>>(emptyPaginatedResponse);
  const paginationState = usePaginationState();

  const reviewTableColumns: InboxTableColumns = [
    { label: "Touchpoint version", id: "touchpointName", width: "30%" },
    { label: "Campaign name", id: "campaignName", width: "20%" },
    { label: "Audience", id: "campaignAudiences", isSortable: false, width: "12%" },
    { label: "Last updated", id: "lastModifiedDate" },
    {
      label: "Status",
      id: "touchpointVersionStatus",
      isSortable: false, // all items have the same status
    },
    {
      label: "Publish state",
      id: "publishLabel",
      isSortable: false, // all items on approved page are published
      isDisplayed: isApprovedPage,
    },
  ];
  const [tableSortedBy, setTableSortedBy] = useState<InboxTableColumnId>("lastModifiedDate");
  const [tableSortedAsc, setTableSortedAsc] = useState<boolean>(false);
  const refreshColumnSort = useCallback(() => {
    return `${tableSortedBy},${tableSortedAsc ? "asc" : "desc"}`;
  }, [tableSortedAsc, tableSortedBy]);
  const [currentSortOrder, setCurrentSortOrder] = useState(refreshColumnSort);

  // Updates query & sort filter options ------------------------------------
  const handleTableSort = (columnId: InboxTableColumnId) => {
    columnId !== tableSortedBy
      ? // Sort by column if new sort column selected
        setTableSortedBy(columnId)
      : // toggle sort direction if current sort column selected
        setTableSortedAsc((sortAsc) => !sortAsc);
  };

  // updates search term ----------------------------------------------------
  const handleSearchTermChange = (searchTerm: string) => {
    paginationState.setPage(0);
    setCurrentSearchTerm(searchTerm);
  };

  // This useEffect is called everytime the filters are updated ----------------
  const isInitialState = currentSearchResults === undefined;

  useEffect(() => {
    // initial state check before we start searching
    if (isInitialState) {
      setCurrentSearchResults([]);
      return;
    }

    const filters: InboxFilter = {
      touchpointVersionStatus: ["CLIENT_REVIEW"],
      touchpointType: filterStatus ? [filterStatus] : [],
    };

    if (isApprovedPage) {
      filters.touchpointVersionStatus = ["PUBLISHED", "APPROVED"];
    }

    setIsLoading(true);

    Client.getReviews(
      { ...paginationState, sort: currentSortOrder, page: 0 },
      { ...filters, q: currentSearchTerm }
    )
      .then((res) => {
        setCurrentSearchResults(res.data?.content);
        setPaginationResponse({
          items: res.data?.content,
          last: res.data?.last,
          page: res.data?.number,
          size: res.data?.size,
          totalItems: res.data?.totalElements,
          totalPages: res.data?.totalPages,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // TODO: What happens if we don't unmount here?
    return () => {};
    // eslint-disable-next-line
  }, [
    filterStatus,
    currentSearchTerm,
    currentSortOrder,
    currentUser,
    dispatch,
    isClientUser,
    isInitialState,
    isApprovedPage,
    paginationState,
  ]);

  useEffect(() => {
    // Refresh columns after clicking to sort or change direction
    setCurrentSortOrder(refreshColumnSort);
  }, [tableSortedBy, tableSortedAsc, refreshColumnSort]);

  //when redirecting to Approved or back to Reviews pages - set filter status as default
  useEffect(() => {
    setFilterStatus("");
    // eslint-disable-next-line
  }, [path]);

  return (
    <>
      <PageContentHeader backgroundColor="#e3e5e7">
        <Flex flexDirection="column" width="100%" px={8} pt={5}>
          <H2 mb={4}>{isApprovedPage ? "Approved touchpoints" : "Reviews"}</H2>
          <Search
            {...{
              search: currentSearchTerm,
              setSearch: handleSearchTermChange,
              placeholder: "Search by touchpoint name, subject line, or page title",
              customClass: "mb-6",
            }}
          />
          <FilterStatusForm status={filterStatus} onClick={setFilterStatus} />
        </Flex>
      </PageContentHeader>
      <PageContent showBreadcrumbs={false}>
        {currentSearchResults && (currentSearchResults.length > 0 || isLoading) ? (
          <PaginatedTableWrapper
            response={paginationResponse}
            paginationState={paginationState}
            isLoading={isLoading}>
            <InboxTable
              currentSearchResults={currentSearchResults}
              currentSortOrder={currentSortOrder}
              handleTableSort={handleTableSort}
              isClientUser={isClientUser}
              tableColumns={reviewTableColumns}
              isLoading={isLoading}
            />
          </PaginatedTableWrapper>
        ) : (
          <EmptyTable hasFilters={!!filterStatus} />
        )}
      </PageContent>
    </>
  );
};

export default ReviewsPage;

interface EmptyTableProps {
  hasFilters: boolean;
}

const EmptyTable = ({ hasFilters }: EmptyTableProps) => {
  return (
    <VStack>
      <H3 mt={4}>{hasFilters ? "No results" : "There's nothing to review right now"}</H3>
    </VStack>
  );
};
