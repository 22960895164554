import React from "react";
import { Flex, Stack } from "@chakra-ui/react";

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  Lead,
  P,
  Small,
  ExtraSmall,
  Span,
} from "components/partials/typography/typography";
import RouterLink from "components/partials/router-link/router-link";
import RouterLinkButton from "components/partials/router-link-button/router-link-button";

const HEADING_GROUPS = [
  { title: "<H1>", children: <H1>Display text</H1> },
  { title: "<H2>", children: <H2>Heading text</H2> },
  { title: "<H3>", children: <H3>Large subheading text</H3> },
  { title: "<H4>", children: <H4>Small subheading text</H4> },
  { title: "<H5>", children: <H5>Eyebrow Heading Text</H5> },
];

const TEXT_SAMPLE =
  "The promise of education is transformation; the power of education is built on relationships.";

const LINK_DEMO = <RouterLink to="#">Link text</RouterLink>;

const TEXT_GROUPS = [
  {
    title: "<Large>",
    children: (
      <Lead>
        {TEXT_SAMPLE} {LINK_DEMO}
      </Lead>
    ),
  },
  {
    title: "<P>",
    children: (
      <P>
        {TEXT_SAMPLE} {LINK_DEMO}
      </P>
    ),
  },
  {
    title: "<SPAN>",
    children: <Span>Put me inside another typography elements</Span>,
  },
  {
    title: "<Small>",
    children: (
      <Small>
        {TEXT_SAMPLE} {LINK_DEMO}
      </Small>
    ),
  },
  {
    title: "<XS>",
    children: (
      <ExtraSmall>
        {TEXT_SAMPLE} {LINK_DEMO}
      </ExtraSmall>
    ),
  },
  {
    title: "<Button>",
    children: <RouterLinkButton to="#">Button label</RouterLinkButton>,
  },
];

const UIKitTypography = () => {
  interface TextGroupProps {
    title: string;
    children: React.ReactChild;
  }
  const TextGroup = ({ title, children }: TextGroupProps) => (
    <Flex direction="column">
      <H5 mr={4}>{title}</H5>
      {children}
    </Flex>
  );

  return (
    <Stack spacing={8}>
      {HEADING_GROUPS.map((group) => (
        <TextGroup key={group.title} title={group.title} children={group.children} />
      ))}
      {TEXT_GROUPS.map((group) => (
        <TextGroup key={group.title} title={group.title} children={group.children} />
      ))}
    </Stack>
  );
};

export default UIKitTypography;
