import { Client } from "models";
import Model from "models/model";

import { generatePaginatedQueryString } from "utilities";

import { Permission, Role } from "types/auth";
import { PaginatedRequestOptions } from "types/pagination";

import { ADFS_LOGOUT_URL } from "utilities/constants";

interface UserAttributes {
  id: string;
  clients: Partial<Client>[];
  roles: Role[];
  permissions: Permission[];
  email: string;
}

class User extends Model<UserAttributes> implements UserAttributes {
  // -------- persistence methods ---------
  static all(options?: PaginatedRequestOptions) {
    let queryString: string = "";
    if (options) {
      queryString = generatePaginatedQueryString(options);
    }
    return this.connection.get(`/users${queryString}`).then((response) => {
      return response;
    });
  }

  static allTwoOceansTeamMembers({ clientId }: { clientId: string }): Promise<any> {
    return this.connection.get(`clients/${clientId}/two-ocean-team`).then((res) => {
      return res.data;
    });
  }

  static find(): Promise<User> {
    return this.connection.get(`/users/me`).then((response) => {
      // TODO - backend right now just returning `client` key, think that's gonna have to be
      //        clientIds...? idk, certainly i can have access to multiple. ignoring for now since
      //        all our testing is as the super admin anyway
      return generateUserFromApi(response.data);
    });
  }

  static logout() {
    return this.connection.post(`${ADFS_LOGOUT_URL}/logout`, undefined);
  }

  // --------- behavior (abstract implementations or custom) --------
  get attributes(): UserAttributes {
    return {
      id: this.id,
      clients: this.clients,
      roles: this.roles,
      permissions: this.permissions,
      email: this.email,
    };
  }

  // isSuperAdmin() {
  //   return this.roles.includes(Role.ROLE_SUPER_ADMIN);
  // }

  // -------- proxies to attributes ---------
  get id() {
    return this._attributes["id"];
  }

  get clients() {
    return this._attributes["clients"];
  }

  get roles() {
    return this._attributes["roles"];
  }

  get permissions() {
    return this._attributes["permissions"];
  }

  get email() {
    return this._attributes["email"];
  }
}

export default User;
export type { UserAttributes };

const generateUserFromApi = (api_data: any): User => {
  const clients = api_data.clients
    ? api_data.clients?.map((client: Client) => {
        return { id: client.id, name: client.name };
      })
    : [];

  return new User({
    ...api_data,
    clients: clients,
  });
};
