import { CloseButton, Modal, ModalContent, ModalOverlay, ModalProps } from "@chakra-ui/react";

import DevTools from "components/partials/dev-tools/dev-tools";

interface DevToolsModalProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
}

const DevToolsModal = ({ isOpen, onClose }: DevToolsModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <CloseButton
          variant="ghost"
          onClick={onClose}
          position="absolute"
          right={2}
          top={1}
          size="lg"
        />
        <DevTools />
      </ModalContent>
    </Modal>
  );
};

export default DevToolsModal;
