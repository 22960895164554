import classNames from "classnames";
import React from "react";
import { ToastContainer as ToastifyContainer, TypeOptions } from "react-toastify";

import ToastCloseButton from "./toast-close-button";

const toastStyles = {
  container: "sm:w-2/5 min-w-[356px] max-w-[500px]",
  component: "rounded-lg border-l-8 p-0",
  progressBar: "h-[3px]",
  body: "items-start pl-3 pr-0 py-4 [&>div:first-child]:block [&>div:first-child]:w-auto",
};

type ToastTypeStyles = typeof toastStyles;

const toastTypeStyles: Record<TypeOptions, Partial<ToastTypeStyles>> = {
  error: {
    component: `border-wrong ${toastStyles.component}`,
    progressBar: `bg-wrong/50 ${toastStyles.progressBar}`,
  },
  info: {
    component: `border-info ${toastStyles.component}`,
    progressBar: `bg-info/50 ${toastStyles.progressBar}`,
  },
  success: {
    component: `border-success ${toastStyles.component}`,
    progressBar: `bg-success/50 ${toastStyles.progressBar}`,
  },
  warning: {
    component: `border-warning ${toastStyles.component}`,
    progressBar: `bg-warning/50 ${toastStyles.progressBar}`,
  },
  default: {
    component: `border-none ${toastStyles.component}`,
    progressBar: `bg-warning/50 ${toastStyles.progressBar}`,
  },
};

const getToastTypeStyles = (
  defaultClassName: string = "",
  type: TypeOptions = "default",
  key: keyof ToastTypeStyles
) => {
  return classNames(defaultClassName, toastTypeStyles[type][key]);
};

const ToastContainer: React.FC = () => {
  return (
    <ToastifyContainer
      autoClose={5000}
      draggable
      position="top-right"
      hideProgressBar={false}
      pauseOnHover
      closeOnClick={false}
      closeButton={(props) => <ToastCloseButton {...props} />}
      className={toastStyles.container}
      toastClassName={(toast) =>
        getToastTypeStyles(toast?.defaultClassName, toast?.type, "component")
      }
      bodyClassName={toastStyles.body}
      progressClassName={(toast) =>
        getToastTypeStyles(toast?.defaultClassName, toast?.type, "progressBar")
      }
    />
  );
};

export default ToastContainer;
