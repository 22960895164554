import { FormHelperText, FormHelperTextProps } from "@chakra-ui/react";

export type HelpMessage = string | React.ReactNode;
interface HelperTextProps extends FormHelperTextProps {
  message?: HelpMessage;
}
const HelperText = ({ message, ...rest }: HelperTextProps) => {
  if (!message) {
    return null;
  }
  return (
    <FormHelperText data-testid="helper-text" {...rest}>
      {message}
    </FormHelperText>
  );
};

export default HelperText;
