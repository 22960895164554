import { Flex } from "@chakra-ui/react";

import BeeEditor from "containers/admin/clients/touchpoint/components/bee-editor/bee-editor";

import { useTouchpointBuilderContent } from "state/ducks/builder-content";

import { Email } from "models";

import { addAnnotationIdToElements, sanitizeBuilderJson } from "utilities";
import { BLANK_BEE_TEMPLATE } from "utilities/constants";

import { TouchpointType } from "types/touchpoint";

interface EmailBuilderDesignTabProps {
  onSave: (field: Record<string, any>) => Promise<void>;
  setIsSaved: (isSaved: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
}
const EmailBuilderDesignTab = ({ onSave, setIsSaved, setIsSaving }: EmailBuilderDesignTabProps) => {
  const { attributes: initialAttributes } = useTouchpointBuilderContent() as Email;

  const handleDesignChange = async (jsonFile: string) => {
    setIsSaving(true);
    try {
      const annotatedJson = addAnnotationIdToElements(jsonFile);
      const sanitizedJson = sanitizeBuilderJson(annotatedJson);

      const designPayload = {
        data: {
          htmlOutput: "<>",
          jsonOutput: sanitizedJson,
        },
        sourceType: "BEE",
      };

      await onSave(designPayload);
      setIsSaved(true);
    } catch (error) {
      console.log("error saving design", error);
      setIsSaved(false);
    }
    setIsSaving(false);
  };

  return (
    //margin-top must cancel out the padding-top of the Content component
    <Flex flex="1" mt={-6}>
      <BeeEditor
        editorType={TouchpointType.EMAIL}
        initialJson={initialAttributes.data?.jsonOutput ?? BLANK_BEE_TEMPLATE}
        onChange={handleDesignChange}
      />
    </Flex>
  );
};

export default EmailBuilderDesignTab;
