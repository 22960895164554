import { useEffect, useState } from "react";

function useOnScreen(ref: React.RefObject<HTMLElement>, rootMargin?: string, threshold?: number) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin, threshold }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [rootMargin, ref, threshold]);

  return isIntersecting;
}

export default useOnScreen;
