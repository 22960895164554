import { WarningIcon } from "@chakra-ui/icons";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import uniqid from "uniqid";

import Icons from "assets";

import {
  CustomBaseFormFieldProps,
  CustomHookFormError,
  CustomInputProps,
  CustomSelectProps,
  CustomTextareaProps,
} from "utilities/forms";

interface CustomFormFieldWrapperProps extends CustomBaseFormFieldProps {
  children: React.ReactNode;
  direction?: "row" | "column";
  required?: boolean;
}
const CustomFormFieldWrapper = ({
  direction,
  children,
  name,
  label,
  eyebrow,
  required,
  error,
  helperText,
}: CustomFormFieldWrapperProps) => {
  return (
    <div className={`flex gap-1.5 ${direction === "row" ? "flex-row" : "flex-col"}`}>
      <CustomFormLabel htmlFor={name} required={required} eyebrow={eyebrow}>
        {label}
      </CustomFormLabel>
      {children}
      <CustomAssistiveText id={`${name}-assistive-text`} error={error} helperText={helperText} />
    </div>
  );
};

export const CustomInput = ({
  addonStart,
  addonEnd,
  className,
  name,
  label,
  eyebrow,
  error,
  helperText,
  required,
  disabled,
  ...props
}: Omit<CustomInputProps, "component">) => {
  const roundedClassNames = classNames("rounded-md", {
    "rounded-r-md rounded-l-none": !!addonStart,
    "rounded-l-md rounded-r-none": !!addonEnd,
  });
  const inputClassNames = twMerge(formFieldClassNames, roundedClassNames, className);
  return (
    <CustomFormFieldWrapper
      component="input"
      name={name}
      label={label}
      eyebrow={eyebrow}
      required={required}
      error={error}
      helperText={helperText}>
      <div className="flex w-full">
        {!!addonStart && <CustomInputAddon position="left">{addonStart}</CustomInputAddon>}
        <input
          id={name}
          className={inputClassNames}
          aria-invalid={!!error}
          aria-describedby={`${name}-assistive-text`}
          required={required}
          disabled={disabled}
          {...props}
        />
        {!!addonEnd && <CustomInputAddon position="right">{addonEnd}</CustomInputAddon>}
      </div>
    </CustomFormFieldWrapper>
  );
};

export const CustomTextarea = ({
  name,
  label,
  eyebrow,
  error,
  helperText,
  required,
  disabled,
  className,
  ...props
}: Omit<CustomTextareaProps, "component">) => {
  const textareaClassNames = twMerge(formFieldClassNames, className);
  return (
    <CustomFormFieldWrapper
      component="textarea"
      name={name}
      label={label}
      eyebrow={eyebrow}
      required={required}
      error={error}
      helperText={helperText}>
      <textarea
        id={name}
        className={textareaClassNames}
        aria-invalid={!!error}
        required={required}
        disabled={disabled}
        {...props}
      />
    </CustomFormFieldWrapper>
  );
};

export const CustomSelect = ({
  options,
  placeholder,
  name,
  label,
  eyebrow,
  error,
  helperText,
  required,
  disabled,
  className,
  ...props
}: Omit<CustomSelectProps, "component">) => {
  const selectClassNames = twMerge(formFieldClassNames, className);
  return (
    <CustomFormFieldWrapper
      component="select"
      name={name}
      label={label}
      eyebrow={eyebrow}
      required={required}
      error={error}
      helperText={helperText}>
      <div className="relative">
        <select
          id={name}
          className={selectClassNames}
          aria-invalid={!!error}
          required={required}
          disabled={disabled}
          {...props}>
          {placeholder && <option value="">{placeholder}</option>}
          {options?.map((option) => (
            <option key={uniqid()} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2.5">
          <Icons.ChevronDown customClass="fill-current size-5" aria-hidden="true" />
        </div>
      </div>
    </CustomFormFieldWrapper>
  );
};

interface CustomFormLabelProps extends React.HTMLProps<HTMLLabelElement> {
  eyebrow?: string;
  htmlFor: string;
}
const CustomFormLabel = ({
  eyebrow,
  required,
  children,
  className,
  ...rest
}: CustomFormLabelProps) => {
  const labelClassNames = twMerge("flex justify-between items-end font-bold", className);
  return (
    <div className={labelClassNames}>
      <label {...rest}>
        {children}
        {required && (
          <span
            role="alert"
            aria-label="This field is required"
            className="text-scarlett-dark ml-1">
            *
          </span>
        )}
      </label>
      {eyebrow && <span className="text-sm text-neutral-2 font-normal">{eyebrow}</span>}
    </div>
  );
};

interface CustomAssistiveTextProps {
  id: string;
  error?: CustomHookFormError;
  helperText?: string;
}

const getErrorMessage = (error: CustomHookFormError): string => {
  if (!error) return "";
  if (typeof error.message === "string") return error.message;
  // Handle nested errors if needed
  if ("type" in error && error.type === "custom") {
    return String(error.message);
  }
  return "An error occurred";
};

const CustomAssistiveText = ({ id, error, helperText }: CustomAssistiveTextProps) => {
  const fontStyles = classNames("text-sm", {
    "text-wrong": !!error,
  });

  if (error) {
    const errorMessage = getErrorMessage(error);
    return (
      <p role="alert" id={id} className={`flex items-center ${fontStyles}`}>
        <WarningIcon boxSize={5} className="mr-1 p-0.5" />
        {errorMessage}
      </p>
    );
  }

  if (!error && helperText) {
    return (
      <p id={id} className={fontStyles}>
        {helperText}
      </p>
    );
  }

  return null;
};

interface CustomInputAddonProps extends React.HTMLProps<HTMLSpanElement> {
  position: "left" | "right";
}
const CustomInputAddon = ({ position, ...rest }: CustomInputAddonProps) => (
  <span
    className={classNames(
      "inline-flex flex-1 whitespace-nowrap items-center bg-gray-100 px-3 py-2 border border-gray-300",
      {
        "rounded-l-md border-r-0": position === "left",
        "rounded-r-md border-l-0": position === "right",
      }
    )}
    {...rest}
  />
);

const formFieldClassNames = `
  w-full 
  px-2.5 py-2 
  border border-neutral-4 
  rounded-md 
  text-neutral-1 
  placeholder-neutral-3 
  transition duration-200 ease-in-out 
  appearance-none 
  hover:border-aqua-dark 
  focus:border-aqua-dark 
  focus:outline-none 
  focus:ring-2 
  focus:ring-input-focus 
  aria-invalid:border-wrong-text 
  aria-invalid:focus:ring-input-focus-invalid 
  disabled:bg-neutral-4 
  disabled:border-neutral-4 
  disabled:text-neutral-3 
  disabled:cursor-not-allowed
`.trim();
