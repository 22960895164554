import { FormLabel, FormLabelProps } from "@chakra-ui/react";

export interface LabelProps extends FormLabelProps {
  value?: string;
}

const Label = ({ children, value, ...rest }: LabelProps) => {
  return <FormLabel {...rest}>{value || children}</FormLabel>;
};

export default Label;
