import { Route, Switch, useRouteMatch } from "react-router-dom";

import ClientsAdminPage from "containers/admin/clients/clients-admin-page";
import ClientNewPage from "containers/admin/clients/client/client-new-page/client-new-page";
import ClientRoutes from "containers/admin/clients/client/client-routes";
import ClientProvider from "containers/admin/clients/client/components/client-provider/client-provider";

import { Route as AppRoute } from "utilities/app-routes";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";

const ClientsRoutes = () => {
  const { path } = useRouteMatch();

  usePagePermission(Permission.PERM_CLIENT_READ);

  return (
    <Switch>
      <Route exact path={path}>
        <ClientsAdminPage />
      </Route>

      <Route path={`${path}${AppRoute.new}`}>
        <ClientNewPage />
      </Route>

      <Route path={`${path}/:clientId`}>
        <ClientProvider>
          <ClientRoutes />
        </ClientProvider>
      </Route>
    </Switch>
  );
};

export default ClientsRoutes;
