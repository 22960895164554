import { Object } from "ts-toolbelt";
import { AxiosInstance } from "axios";

import Connection from "models/connection";

import { OmitRecursively } from "types/utility";

abstract class Model<TAttributes> {
  private static _connection?: Connection;

  static get connection() {
    if (!this._connection) {
      throw new Error("uninitialized api connection");
    }

    if (!this._connection.isConnected) {
      throw new Error("api connection not ready");
    }

    return this._connection;
  }

  static async connect(axiosInstance: AxiosInstance) {
    this._connection = new Connection(axiosInstance);
    return this._connection.connect();
  }

  static isConnectionReady() {
    return this._connection?.isConnected;
  }

  static disconnect() {
    this._connection = undefined;
  }

  protected readonly _attributes: TAttributes;

  constructor(attributes: TAttributes) {
    this._attributes = attributes;
  }

  abstract get attributes(): TAttributes;

  toJSON(key: string) /* key is there to document the api js expects */ {
    return this.attributes;
  }
}

export default Model;
export type Unpersisted<T> = OmitRecursively<T, "id">;

// TODO - this isn't quite what i want, but it's the best ~i can do~ steal from someone else.
//        still missing features:
//          - preserve the type of T[K]["id"] -- this assumes it was number
//          - make it truly opitional, this requires the object has the key, but it
//            can be undefined
// ```
// let test: WithMaybePersisted<ClientAttributes, "primaryContact">;
// test = { id: 0, name: string, clientCode: 0 } // ok
// test = { ...client, primaryContact: { email: ''} } // not ok, but should be
// test = { ...client, primaryContact: { id: undefined, email: '' } } // ok
// ```
// prettier-ignore
export type WithMaybePersisted<T, K extends keyof T> =
  T extends object ?
    Object.P.Update<T, [K, "id"], number | undefined>
  : T
