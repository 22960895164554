import { Flex } from "@chakra-ui/react";

import Tooltip from "components/partials/tooltip/tooltip";
import UserAvatar from "components/partials/user-avatar/user-avatar";

interface UserBadgeProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
}

export const UserBadge = ({ id, firstName, lastName, imageUrl, ...rest }: UserBadgeProps) => {
  if (!firstName || !lastName) {
    return (
      <Flex data-testid={`${id}-value`} alignItems="center" {...rest}>
        <UserAvatar size="xs" mr={4} />
      </Flex>
    );
  }

  return (
    <Tooltip label={`${firstName} ${lastName}`}>
      <Flex data-testid={`${id}-value`} alignItems="center" {...rest}>
        <UserAvatar size="xs" mr={4} name={`${firstName} ${lastName}`} src={imageUrl} />
        {firstName} {lastName}
      </Flex>
    </Tooltip>
  );
};
