import { Flex, FlexProps, Spinner, SpinnerProps, useTheme } from "@chakra-ui/react";

import { contentGutter } from "components/partials/layout/layout";

export const LoadingScreen = ({ ...rest }: FlexProps) => {
  const theme = useTheme();
  return (
    <Flex
      sx={{
        backgroundColor: "rgba(0, 0, 0, .75)",
        position: "absolute",
        left: -contentGutter,
        top: -6,
        width: `calc(100% + ${theme.sizes[contentGutter * 2]})`,
        height: `calc(100% + ${theme.sizes[6]})`,
        zIndex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rest}>
      <LoadingSpinner />
    </Flex>
  );
};

export const LoadingSpinner = ({
  label = "Loading.",
  thickness = "4px",
  speed = "0.65s",
  emptyColor = "gray.200",
  color = "blue.500",
  size = "xl",
  ...rest
}: SpinnerProps) => {
  return (
    <Spinner
      label={label}
      thickness={thickness}
      speed={speed}
      emptyColor={emptyColor}
      color={color}
      size={size}
      {...rest}
    />
  );
};
