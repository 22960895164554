import omit from "lodash.omit";
import { Base64 } from "js-base64";

import Model from "models/model";
import { ClientId } from "models/client";

import { generatePaginatedQueryString } from "utilities";

import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";
import { ContentStatus, Image, TypekitFont, TypekitResponse } from "types/index";

export interface LockedBy {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

// N.B
// If you add a new field that can be updated via the builder,
// you MUST add it to the isEqualThemeFormPartial util function
// otherwise, that field change wont trigger autosave
interface ThemeAttributes {
  id: string;
  client?: ClientId;

  name: string;
  description: string;
  status: ContentStatus;

  version?: string;
  versionNotes: string;

  typekitId: string;

  pageBackgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  buttonAndLinkColor: string;

  formLabelColor: string;
  formInputBorderColor: string;
  formInputBackgroundColor: string;

  bodyColor: string;
  h1Color: string;
  h2Color: string;
  h3Color: string;
  inputTextColor: string;

  bodyFontFamily: string;
  h1FontFamily: string;
  h2FontFamily: string;
  h3FontFamily: string;
  inputTextFontFamily: string;

  bodyFontWeight: string;
  h1FontWeight: string;
  h2FontWeight: string;
  h3FontWeight: string;
  inputTextFontWeight: string;

  bodyLineHeight: string;
  h1LineHeight: string;
  h2LineHeight: string;
  h3LineHeight: string;

  defaultBorderRadius: string;
  defaultBoxShadow: string;
  defaultBorderWidth: string;

  colorPresets: string[];
  fontPresets: string[];

  lastModifiedDate: string;
  publishedAt: string | null;
  lockedBy: LockedBy | null;
  rootThemeId: string | null;
  headerLogoDesktop: Image | null;
  headerLogoMobile: Image | null;
  footerLogoDesktop: Image | null;
  footerLogoMobile: Image | null;
  favicon: Image | null;
  createdDate: string | null;

  typekitFonts: TypekitFont[];

  buttonFontColor: string;
  buttonBorderRadius: string;
  buttonBorderWidth: string;
  buttonBorderColor: string;
  buttonBorderStyle: string;
  buttonBoxShadow: string;
  buttonLineHeight: string;
  buttonPaddingHorizontal: string;
  buttonPaddingVertical: string;
}

/**
 * Encode Theme Payload
 * encode any attributes that will include any text or characters that will trigger a firewall rule
 *s
 * @param attributes attributes to be encoded
 */
const encodeThemePayload = (attributes: Partial<ThemeAttributes>) => {
  const payload: any = { ...attributes };

  const propsToEncode = [
    "bodyFontFamily",
    "h1FontFamily",
    "h2FontFamily",
    "h3FontFamily",
    "inputTextFontFamily",
    "fontPresets",
  ];

  propsToEncode.forEach((prop) => {
    const propKey = prop as keyof Partial<ThemeAttributes>;
    if (payload[propKey] && prop !== "fontPresets") {
      payload[prop] = Base64.encode(payload[propKey]);
    } else if (payload[propKey] && prop === "fontPresets") {
      payload[prop] = payload[prop].map((_: string) => Base64.encode(_));
    }
  });

  return payload;
};

class Theme extends Model<ThemeAttributes> implements ThemeAttributes {
  static all({
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
    clientId,
    status,
  }: {
    options?: PaginatedRequestOptions;
    clientId: string;
    status?: string;
  }): Promise<PaginatedResponse<Theme>> {
    let queryString = generatePaginatedQueryString(options);
    if (status) {
      queryString += `&status=${status}`;
    }
    return Theme.connection.get(`clients/${clientId}/themes${queryString}`).then((response) => {
      const items = response.data.content
        ? response.data.content.map((attributes: ThemeAttributes) => new Theme(attributes))
        : [];
      return {
        items: items,
        totalItems: response.data.totalElements,
        size: response.data.size,
        page: response.data.number,
        totalPages: response.data.totalPages,
        last: response.data.last,
      };
    });
  }

  static find({ clientId, id }: { clientId: string; id: string }): Promise<Theme> {
    return this.connection
      .get(`clients/${clientId}/themes${id ? `/${id}` : ""}`)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static create(attributes: {
    clientId: string;
    name: string;
    description: string;
    status: ContentStatus;
  }): Promise<Theme> {
    return this.connection
      .post(`clients/${attributes.clientId}/themes`, attributes)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static update({
    clientId,
    id,
    attributes,
  }: {
    clientId: string;
    id: string;
    attributes: Partial<ThemeAttributes>;
  }): Promise<Theme> {
    // Remove lastModifiedDate before PATCH
    const updateAttributes = omit(attributes, ["lastModifiedDate", "status", "client"]);

    return this.connection
      .patch(`clients/${clientId}/themes/${id}`, encodeThemePayload(updateAttributes))
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static replace({
    clientId,
    id,
    attributes,
  }: {
    clientId: string;
    id: string;
    attributes: ThemeAttributes;
  }): Promise<Theme> {
    // Remove lastModifiedDate before PATCH
    const updateAttributes = omit(attributes, ["lastModifiedDate", "client"]);
    return this.connection
      .put(`clients/${clientId}/themes/${id}`, encodeThemePayload(updateAttributes))
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static claimLock({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .put(`clients/${clientId}/themes/${id}/claim-lock`, undefined)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static releaseLock({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .put(`clients/${clientId}/themes/${id}/release-lock`, undefined)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static clone({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .post(`clients/${clientId}/themes/${id}/clone`, undefined)
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static publish({ id, clientId }: { id: string; clientId: string }): Promise<Theme> {
    return this.connection.put(`clients/${clientId}/themes/${id}/publish`, {}).then((response) => {
      return new Theme(response.data);
    });
  }

  static delete({ clientId, id }: { clientId: string; id: string }): Promise<void> {
    return this.connection.delete(`clients/${clientId}/themes/${id}`).then();
  }

  static versionHistory({
    clientId,
    id,
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
  }: {
    clientId: string;
    id: string;
    options: PaginatedRequestOptions;
  }): Promise<PaginatedResponse<Theme>> {
    const queryString = generatePaginatedQueryString(options);
    return Theme.connection
      .get(`/clients/${clientId}/themes/${id}/version-history${queryString}`)
      .then((response) => {
        return {
          items: response.data.content.map((attributes: ThemeAttributes) => new Theme(attributes)),
          totalItems: response.data.totalElements,
          size: response.data.size,
          page: response.data.number,
          totalPages: response.data.totalPages,
          last: response.data.last,
        };
      });
  }

  static archive({ clientId, id }: { clientId: string; id: string }) {
    return Theme.connection
      .put(`/clients/${clientId}/themes/${id}/archive`, {})
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static createDraft({
    clientId,
    id,
    version,
    versionNotes,
  }: {
    clientId: string;
    id: string;
    version: string;
    versionNotes: string;
  }) {
    return Theme.connection
      .put(`/clients/${clientId}/themes/${id}/create-draft`, {
        version: version,
        versionNotes: versionNotes,
      })
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static upload({ clientId, file }: { clientId: string; file: any }) {
    return Theme.connection
      .post(`/clients/${clientId}/theme-assets?type=image`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  static nextVersions({ clientId, id }: { clientId: string; id: string }) {
    return this.connection
      .get(`clients/${clientId}/themes/${id}/next-versions`)
      .then((response) => {
        return response;
      });
  }

  static editVersionDetails({
    clientId,
    id,
    version,
    versionNotes,
  }: {
    clientId: string;
    id: string;
    version: string;
    versionNotes: string;
  }) {
    return this.connection
      .put(`clients/${clientId}/themes/${id}/version-details`, {
        version: version,
        versionNotes: versionNotes,
      })
      .then((response) => {
        return new Theme(response.data);
      });
  }

  static getTypekitFonts({
    clientId,
    projectId,
  }: {
    clientId: string;
    projectId: string;
  }): Promise<TypekitResponse> {
    return this.connection
      .get(`clients/${clientId}/themes/typekit/${projectId}`)
      .then((response) => {
        return response.data;
      });
  }

  // --------- behavior (abstract implementations or custom) --------
  get attributes(): ThemeAttributes {
    return {
      id: this.id,
      client: this.client,
      name: this.name,
      description: this.description,
      status: this.status,
      version: this.version,
      versionNotes: this.versionNotes,
      typekitId: this.typekitId,
      pageBackgroundColor: this.pageBackgroundColor,
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      buttonAndLinkColor: this.buttonAndLinkColor,
      formLabelColor: this.formLabelColor,
      formInputBorderColor: this.formInputBorderColor,
      formInputBackgroundColor: this.formInputBackgroundColor,
      bodyColor: this.bodyColor,
      h1Color: this.h1Color,
      h2Color: this.h2Color,
      h3Color: this.h3Color,
      inputTextColor: this.inputTextColor,
      bodyFontFamily: this.bodyFontFamily,
      h1FontFamily: this.h1FontFamily,
      h2FontFamily: this.h2FontFamily,
      h3FontFamily: this.h3FontFamily,
      inputTextFontFamily: this.inputTextFontFamily,
      bodyFontWeight: this.bodyFontWeight,
      h1FontWeight: this.h1FontWeight,
      h2FontWeight: this.h2FontWeight,
      h3FontWeight: this.h3FontWeight,
      inputTextFontWeight: this.inputTextFontWeight,
      bodyLineHeight: this.bodyLineHeight,
      h1LineHeight: this.h1LineHeight,
      h2LineHeight: this.h2LineHeight,
      h3LineHeight: this.h3LineHeight,
      defaultBorderRadius: this.defaultBorderRadius,
      defaultBoxShadow: this.defaultBoxShadow,
      defaultBorderWidth: this.defaultBorderWidth,
      colorPresets: this.colorPresets,
      fontPresets: this.fontPresets,
      lastModifiedDate: this.lastModifiedDate,
      lockedBy: this.lockedBy,
      rootThemeId: this.rootThemeId,
      headerLogoDesktop: this.headerLogoDesktop,
      headerLogoMobile: this.headerLogoMobile,
      footerLogoDesktop: this.footerLogoDesktop,
      footerLogoMobile: this.footerLogoMobile,
      favicon: this.favicon,
      publishedAt: this.publishedAt,
      createdDate: this.createdDate,
      typekitFonts: this.typekitFonts,
      buttonFontColor: this.buttonFontColor,
      buttonBorderColor: this.buttonBorderColor,
      buttonBorderStyle: this.buttonBorderStyle,
      buttonBorderRadius: this.buttonBorderRadius,
      buttonBorderWidth: this.buttonBorderWidth,
      buttonBoxShadow: this.buttonBoxShadow,

      buttonLineHeight: this.buttonLineHeight,
      buttonPaddingHorizontal: this.buttonPaddingHorizontal,
      buttonPaddingVertical: this.buttonPaddingVertical,
    };
  }

  // -------- proxies to attributes ---------
  get id() {
    return this._attributes["id"];
  }

  get client() {
    return this._attributes["client"];
  }

  get name() {
    return this._attributes["name"];
  }

  get description() {
    return this._attributes["description"];
  }

  get status() {
    return this._attributes["status"];
  }

  get version() {
    return this._attributes["version"];
  }

  get versionNotes() {
    return this._attributes["versionNotes"];
  }

  get typekitId() {
    return this._attributes["typekitId"];
  }

  get pageBackgroundColor() {
    return this._attributes["pageBackgroundColor"];
  }

  get primaryColor() {
    return this._attributes["primaryColor"];
  }

  get secondaryColor() {
    return this._attributes["secondaryColor"];
  }

  get buttonAndLinkColor() {
    return this._attributes["buttonAndLinkColor"];
  }

  get formLabelColor() {
    return this._attributes["formLabelColor"];
  }

  get formInputBorderColor() {
    return this._attributes["formInputBorderColor"];
  }

  get formInputBackgroundColor() {
    return this._attributes["formInputBackgroundColor"];
  }

  get bodyColor() {
    return this._attributes["bodyColor"];
  }

  get h1Color() {
    return this._attributes["h1Color"];
  }

  get h2Color() {
    return this._attributes["h2Color"];
  }

  get h3Color() {
    return this._attributes["h3Color"];
  }

  get inputTextColor() {
    return this._attributes["inputTextColor"];
  }

  get bodyFontFamily() {
    return this._attributes["bodyFontFamily"];
  }

  get h1FontFamily() {
    return this._attributes["h1FontFamily"];
  }

  get h2FontFamily() {
    return this._attributes["h2FontFamily"];
  }

  get h3FontFamily() {
    return this._attributes["h3FontFamily"];
  }

  get inputTextFontFamily() {
    return this._attributes["inputTextFontFamily"];
  }

  get bodyFontWeight() {
    return this._attributes["bodyFontWeight"];
  }

  get h1FontWeight() {
    return this._attributes["h1FontWeight"];
  }

  get h2FontWeight() {
    return this._attributes["h2FontWeight"];
  }

  get h3FontWeight() {
    return this._attributes["h3FontWeight"];
  }

  get inputTextFontWeight() {
    return this._attributes["inputTextFontWeight"];
  }

  get bodyLineHeight() {
    return this._attributes["bodyLineHeight"];
  }

  get h1LineHeight() {
    return this._attributes["h1LineHeight"];
  }

  get h2LineHeight() {
    return this._attributes["h2LineHeight"];
  }

  get h3LineHeight() {
    return this._attributes["h3LineHeight"];
  }

  get defaultBorderRadius() {
    return this._attributes["defaultBorderRadius"];
  }

  get defaultBoxShadow() {
    return this._attributes["defaultBoxShadow"];
  }

  get defaultBorderWidth() {
    return this._attributes["defaultBorderWidth"];
  }

  get colorPresets() {
    return this._attributes["colorPresets"];
  }

  get fontPresets() {
    return this._attributes["fontPresets"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get lockedBy() {
    return this._attributes["lockedBy"];
  }

  get rootThemeId() {
    return this._attributes["rootThemeId"];
  }

  get headerLogoDesktop() {
    return this._attributes["headerLogoDesktop"];
  }

  get headerLogoMobile() {
    return this._attributes["headerLogoMobile"];
  }

  get footerLogoDesktop() {
    return this._attributes["footerLogoDesktop"];
  }

  get footerLogoMobile() {
    return this._attributes["footerLogoMobile"];
  }

  get favicon() {
    return this._attributes["favicon"];
  }

  get publishedAt() {
    return this._attributes["publishedAt"];
  }

  get createdDate() {
    return this._attributes["createdDate"];
  }

  get typekitFonts() {
    return this._attributes["typekitFonts"];
  }

  get buttonFontColor() {
    return this._attributes["buttonFontColor"];
  }

  get buttonBorderColor() {
    return this._attributes["buttonBorderColor"];
  }

  get buttonBorderStyle() {
    return this._attributes["buttonBorderStyle"];
  }

  get buttonBorderRadius() {
    return this._attributes["buttonBorderRadius"];
  }

  get buttonBorderWidth() {
    return this._attributes["buttonBorderWidth"];
  }

  get buttonBoxShadow() {
    return this._attributes["buttonBoxShadow"];
  }

  get buttonLineHeight() {
    return this._attributes["buttonLineHeight"];
  }

  get buttonPaddingHorizontal() {
    return this._attributes["buttonPaddingHorizontal"];
  }

  get buttonPaddingVertical() {
    return this._attributes["buttonPaddingVertical"];
  }
}

export { Theme as default };
export type { ThemeAttributes };
