import React, { useReducer, useState } from "react";
import { Box, HStack, useDisclosure } from "@chakra-ui/react";
import * as zod from "zod";

import Input from "components/forms/input/input";
import FormGroup from "components/forms/form-group/form-group";

import Button from "components/forms/button/button";
import Form from "components/forms/form/form";
import FormErrors from "components/partials/form-errors/form-errors";
import { MaskedInput } from "components/partials/masked-input/masked-input";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";

import { useCurrentUser } from "state/ducks";
import Client, { ClientAttributes } from "models/client";
import { WithMaybePersisted, Unpersisted } from "models/model";

import {
  deeplyTransformEmptyStringToUndefined,
  convertUrlToHttps,
  inputIsAlphanumeric,
  inputIsLowercase,
} from "utilities";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { ZodFormErrors } from "types";
import { Formify } from "types/utility";

interface CreateClientFormProps {
  client?: Client;
  onSubmit: (attributes: Unpersisted<ClientAttributes>) => void;
  onCancel: () => void;
}

interface UpdateClientFormProps {
  client: Client;
  onSubmit: (attributes: WithMaybePersisted<ClientAttributes, "primaryContact">) => void;
  onCancel: () => void;
}

type ClientFormProps = CreateClientFormProps | UpdateClientFormProps;

function isUpdatingClient(props: ClientFormProps): props is UpdateClientFormProps {
  return (props as UpdateClientFormProps).client !== undefined;
}

function BasicInfo(props: ClientFormProps) {
  const form = useClientFormState(props.client);
  const currentUser = useCurrentUser();
  const confirmCancelModal = useDisclosure();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { primaryContact, apolloDomainUrl, collegeUrl, privacyPolicyUrl } =
      form.values;

    try {
      const clientAttributes = schema.parse(
        deeplyTransformEmptyStringToUndefined({
          ...form.values,
          // TODO - what is the right way to handle this? like we only wanna attempt to validate
          //        primaryContact against the schema if it looks like the user provided one or
          //        more of its fields. otherwise, consider the whole thing just undefined.
          primaryContact:
            primaryContact && Object.values(primaryContact).every((s) => s === "")
              ? undefined
              : primaryContact,
          apolloDomainUrl: apolloDomainUrl && convertUrlToHttps(apolloDomainUrl),
          collegeUrl: collegeUrl && convertUrlToHttps(collegeUrl),
          privacyPolicyUrl: privacyPolicyUrl && convertUrlToHttps(privacyPolicyUrl),
        })
      );

      form.setErrors({});

      const clientWithTeam = {
        ...clientAttributes,
        twoOceanTeamMembers: props.client?.twoOceanTeamMembers,
      };

      if (isUpdatingClient(props)) {
        props.onSubmit(
          (function addBackIds(
            attributes: any
          ): WithMaybePersisted<ClientAttributes, "primaryContact"> {
            attributes.id = props.client.id;

            // TODO - because we're blanking it out and then not submitting it, idk that you
            //        can delete it. depends how the backend interpretes put without it.
            if (attributes.primaryContact) {
              attributes.primaryContact.id = props.client.primaryContact?.id;
            }

            return attributes;
          })(clientWithTeam)
        );
      } else {
        props.onSubmit(clientWithTeam);
      }
    } catch (error) {
      if (error instanceof zod.ZodError) {
        form.setErrors(error.flatten().fieldErrors as ZodFormErrors);
      }
    }
  }

  return (
    <Box id="client-form" data-testid="client-form">
      {Object.entries(form.errors).length > 0 && <FormErrors form={form} />}

      {/* <H2 mb={8}>{pageTitle}</H2> */}

      <Form
        onSubmit={handleSubmit}
        data-testid={props.client ? "edit-client-form" : "new-client-form"}>
        <FormGroup>
          <Input
            isRequired
            id="client-name"
            data-testid="client-name-input"
            label="Client name"
            value={form.values.name}
            onChange={form.handleChange("name")}
          />
          <Input
            isRequired
            id="program-iterable-id"
            label="Client Iterable project ID"
            value={form.values.iterableProjectId}
            onChange={form.handleChange("iterableProjectId")}
            helperText="Enter the unique code for this client. This will be used to map campaign creative to Iterable workflows."
          />
          <Input
            isRequired
            maxLength={4}
            id="client-code"
            data-testid="client-prefix-input"
            label="Client prefix"
            helperText="Select a unique 2–4 digit code for this client. This will be the prefix for content IDs for this client."
            value={form.values.clientCode}
            onChange={form.handleChange("clientCode")}
          />
          {props.client && (
            <MaskedInput
              id="iterable-api-key"
              data-testid="iterable-api-key-input"
              label="Iterable API key"
              helperText="Enter the API key required to connect this client to the associated Iterable project for management of campaign workflows."
              isLocked={!!props.client?.iterableApiKey}
              incomingValue={props.client?.iterableApiKey}
              clientId={props.client.id}
            />
          )}
          <Input
            isDisabled={!hasPermission(currentUser, Permission.PERM_CLIENT_ADVANCED)}
            id="Apollo-profile-code"
            data-testid="Apollo-profile-code-input"
            label="Apollo profile code"
            helperText="Enter the unique code for this client using all lowercase characters, no spaces. This will be used to capture user events using Tealium scripts and identify the CDN storage directory for landing pages."
            value={form.values.tealiumProfileCode}
            onChange={form.handleChange("tealiumProfileCode")}
          />
          <Input
            isRequired
            id="apollo-domain-url"
            data-testid="client-domain-url-input"
            label="Client apollo URL"
            helperText="Enter the domain URL for program digital assets."
            value={form.values.apolloDomainUrl}
            onChange={form.handleChange("apolloDomainUrl")}
          />
          <Input
            id="client-url"
            data-testid="client-url-input"
            label="Client website URL"
            value={form.values.collegeUrl}
            onChange={form.handleChange("collegeUrl")}
          />
          <Input
            id="client-privacy-url"
            data-testid="client-privacy-url-input"
            label="Client privacy policy URL"
            value={form.values.privacyPolicyUrl}
            onChange={form.handleChange("privacyPolicyUrl")}
          />
        </FormGroup>

        <HStack spacing={2}>
          <Button type="submit" data-testid="client-form-submit-button">
            Submit
          </Button>
          <Button
            variant="link"
            onClick={confirmCancelModal.onOpen}
            data-testid="client-form-cancel-button">
            Cancel
          </Button>
        </HStack>
        <ConfirmationModal
          {...confirmCancelModal}
          message="Are you sure you want to exit? All unsaved changes will be lost"
          cancelButtonText="No"
          confirmButtonText="Yes"
          onConfirm={props.onCancel}
          modalType="warning"
        />
      </Form>
    </Box>
  );
}

export default BasicInfo;

const schema = zod.object({
  clientCode: zod.string(),
  name: zod.string(),
  tealiumProfileCode: zod.string().refine(
    (val) => inputIsAlphanumeric(val) && inputIsLowercase(val) && !val.includes(" "),
    (val) => ({
      message: val.includes(" ")
        ? "The Client Apollo profile code cannot contain spaces - please remove them."
        : "The Client Apollo profile code must only include lower-case alphanumeric characters - please correct.",
    })
  ),

  primaryContact: zod
    .object({
      email: zod.string().email(),
      name: zod.string().optional(),
      jobTitle: zod.string().optional(),
    })
    .optional(),

  iterableProjectId: zod.string().optional(),
  privacyPolicyUrl: zod.string().optional(),
  collegeUrl: zod.string().optional(),
  apolloDomainUrl: zod.string().optional(),
  // twoOceanTeamMembers: zod.string().array().optional(),
});
type ClientAttributesSchema = zod.infer<typeof schema>;
type ClientAttributesFormState = Formify<ClientAttributesSchema>;
type ClientAttributesFormErrors = Partial<{ [k in keyof ClientAttributesSchema]: string }>;

function initialClientFormValues(client?: Client): ClientAttributesFormState {
  // prettier-ignore
  return {
    clientCode: client?.clientCode?.toString() || '',
    name:       client?.name || '',
    tealiumProfileCode: client?.tealiumProfileCode || '',

    primaryContact: {
      email:    client?.primaryContact?.email || '',
      name:     client?.primaryContact?.name || '',
      jobTitle: client?.primaryContact?.jobTitle || '',
    },

    privacyPolicyUrl: client?.privacyPolicyUrl || '',
    collegeUrl:          client?.collegeUrl || '',
    iterableProjectId: client?.iterableProjectId || '',
    apolloDomainUrl: client?.apolloDomainUrl || '',
    // twoOceanTeamMembers: client?.twoOceanTeamMembers || [],
  }
}

function useClientFormState(client?: Client) {
  const [values, dispatch] = useReducer(
    function reducer(state: ClientAttributesFormState, action: { field: string; value: string }) {
      switch (action.field) {
        case "clientCode":
        case "name":
        case "privacyPolicyUrl":
        case "collegeUrl":
        case "iterableProjectId":
        case "tealiumProfileCode":
        case "apolloDomainUrl":
          return { ...state, [action.field]: action.value };

        case "primaryContact.email":
        case "primaryContact.jobTitle":
        case "primaryContact.name":
          const [, field] = action.field.split(".");

          return {
            ...state,
            primaryContact: { ...state.primaryContact, [field]: action.value },
          };
        default:
          throw new Error();
      }
    },
    client,
    initialClientFormValues
  );

  function handleChange(field: string) {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      dispatch({ field, value: event.target.value });
    };
  }

  const [errors, setErrors] = useState<ClientAttributesFormErrors>({});

  function handleErrors(errors: ZodFormErrors): void {
    const invalidFields = Object.keys(errors) as Array<keyof ClientAttributesSchema>;
    setErrors(
      invalidFields.reduce((memo, field) => {
        memo[field] = errors[field].join(", ");
        return memo;
      }, {} as ClientAttributesFormErrors)
    );
  }

  return { values, handleChange, errors, setErrors: handleErrors };
}
