import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import PageContent from "components/partials/page-content/page-content";
import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import UIKitButtons from "containers/ui-kit-page/components/ui-kit-buttons/ui-kit-buttons";
import UIKitColorPalette from "containers/ui-kit-page/components/ui-kit-color-palette/ui-kit-color-palette";
import UIKitTypography from "containers/ui-kit-page/components/ui-kit-typography/ui-kit-typography";
import UIKitForms from "containers/ui-kit-page/components/ui-kit-forms/ui-kit-forms";

const UIKitPage = () => {
  let { path, url } = useRouteMatch();

  return (
    <>
      <SecondaryNav
        heading={"UI Kit"}
        links={[
          { to: url + "/color-palette", text: "Color Palette" },
          { to: url + "/typography", text: "Typography" },
          { to: url + "/buttons", text: "Buttons" },
          { to: url + "/forms", text: "Forms" },
          { to: url + "/navigation", text: "Navigation" },
          { to: url + "/messaging", text: "Messaging" },
          { to: url + "/lists-data", text: "Lists & Data" },
        ]}
      />
      <PageContent data-testid="ui-kit-page">
        <Switch>
          <Redirect exact from={path} to={path + "/color-palette"} />

          <Route exact path={path + "/color-palette"}>
            <UIKitColorPalette />
          </Route>

          <Route path={path + "/typography"}>
            <UIKitTypography />
          </Route>

          <Route path={path + "/buttons"}>
            <UIKitButtons />
          </Route>

          <Route path={path + "/forms"}>
            <UIKitForms />
          </Route>

          <Route path={path + "/navigation"}>
            <PageContent>Navigation Page</PageContent>
          </Route>

          <Route path={path + "/messaging"}>
            <PageContent>Messaging Page</PageContent>
          </Route>

          <Route path={path + "/lists-data"}>
            <PageContent>Lists and Data Page</PageContent>
          </Route>
        </Switch>
      </PageContent>
    </>
  );
};

export default UIKitPage;
