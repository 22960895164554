import { useContext } from "react";
import { Box } from "@chakra-ui/react";

import { CommentDisplay } from "containers/admin/clients/touchpoint/components/comments/comment-display/comment-display";
import { CommentLoader } from "containers/admin/clients/touchpoint/components/comments/comment-loader/comment-loader";

import CommentContext from "contexts/comment-context";

interface CommentsBodyProps {
  lastActiveCommentId: string | undefined;
  loadMoreComments: () => Promise<boolean>;
  isClientUser: boolean;
}

const CommentsBody = ({
  lastActiveCommentId,
  loadMoreComments,
  isClientUser,
}: CommentsBodyProps) => {
  const { commentData, commentFilter } = useContext(CommentContext);
  return (
    <Box data-no-padding>
      {commentData.content.map((comment) => (
        <CommentDisplay
          key={comment.id}
          highlight={comment.id === lastActiveCommentId}
          comment={comment}
          isClientUser={isClientUser}
        />
      ))}

      {/* key resets loading spinner upon filter change */}
      <CommentLoader runOnLoading={loadMoreComments} key={commentFilter.value} />
    </Box>
  );
};

export default CommentsBody;
