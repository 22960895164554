import axios from "axios";
import { env } from "env";
import { Route as AppRoute } from "utilities/app-routes";
import { msalInstance } from "index";
import { loginRequest } from "config/auth-config";

const axiosInstance = axios.create({
  baseURL: `${env.REACT_APP_HARBOR_API_BASE_URL}/api`,
});

axiosInstance.interceptors.request.use((config) => {
  return msalInstance.acquireTokenSilent(loginRequest).then((response) => {
    config.headers.Authorization = `Bearer ${response.accessToken}`;
    return config;
  });
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace(AppRoute.logout);
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
