import classNames from "classnames";
import { FieldErrors, FieldValues } from "react-hook-form";

import { FormErrorAlertAccordion } from "components/partials/form-error-alert-accordion/form-error-alert-accordion";

import { CustomFormField } from "utilities/forms";

interface BuilderFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  errors: FieldErrors<FieldValues>;
  formFields: CustomFormField[];
}

const BuilderForm = ({ children, errors, formFields, ...rest }: BuilderFormProps) => {
  const fieldsWithErrors = Object.keys(errors)
    .map((fieldName) => {
      const field = formFields.find((field) => field.name === fieldName);
      return field && field.component !== "hidden" ? field.label : undefined;
    })
    // Filter out undefined values
    .filter((label): label is string => Boolean(label));

  const errorAlertSpace = 3;
  const hasErrors = !!fieldsWithErrors.length;
  return (
    <form
      className={classNames("flex flex-col max-w-[37.5rem] mx-auto space-y-4 w-full", {
        [`pt-${errorAlertSpace}`]: hasErrors,
      })}
      {...rest}>
      {hasErrors && (
        <FormErrorAlertAccordion
          heading="Touchpoint settings are incomplete"
          errors={errors}
          fieldsWithErrors={fieldsWithErrors}
          marginTop={`-${errorAlertSpace}`}
        />
      )}
      {children}
    </form>
  );
};

export default BuilderForm;
