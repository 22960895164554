import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useDisclosure } from "@chakra-ui/react";

import { BuilderHeader } from "containers/admin/clients/touchpoint/components/builder/builder-header/builder-header";
import LockingModal from "components/modals/locking-modal/locking-modal";
import toast from "components/partials/toast/toast";
import {
  FullPageOverlay,
  FullPageOverlayProps,
} from "containers/admin/clients/touchpoint/components/full-page-overlay/full-page-overlay";

import { useCurrentUser } from "state/ducks";
import { setCurrentBuilderContent } from "state/ducks/builder-content";

import TouchpointVersion from "models/touchpoint-version";

import { isEditableStatus, isLocked } from "utilities";
import { Route as AppRoutes } from "utilities/app-routes";

import { Permission } from "types/auth";

import { TouchpointBuilderContent } from "types";
import { Theme } from "models";
import { useTouchpointIds } from "containers/admin/clients/touchpoint/components/builder/use-save-touchpoint";
import { TouchpointType } from "types/touchpoint";

interface BuilderProps extends FullPageOverlayProps {
  apiValue: TouchpointBuilderContent | Theme;
  contentName?: string;
  baseUrl: string;
  previewMode: boolean;
  permission: Permission;
  isSaved: boolean;
  setIsSaved: (isSaved: boolean) => void;
  isSaving: boolean;
}

const Builder = ({
  apiValue,
  contentName,
  baseUrl,
  previewMode,
  permission,
  isSaved,
  setIsSaved,
  isSaving,
  children,
  ...rest
}: BuilderProps) => {
  const currentUser = useCurrentUser();

  const dispatch = useDispatch();
  const [claimingLock, setClaimingLock] = useState(false);

  // Ensure locking modal appears when content is locked
  const lockingModal = useDisclosure();
  const isLockedByAnother = isLocked(apiValue, currentUser);

  const isReadonly = previewMode || isLockedByAnother || !isEditableStatus(apiValue?.status);

  const touchpointIds = useTouchpointIds();

  const isNotTheme = "touchpointId" in touchpointIds;

  const releaseLock = () => {
    // if touchpointIds contains a touchpointId
    if (isNotTheme) {
      TouchpointVersion.releaseLock(touchpointIds);
    } else {
      Theme.releaseLock(touchpointIds);
    }
  };

  /** Replaces refreshTouchpoint() */
  const handleClaimSuccess = (touchpoint: TouchpointVersion | Theme) => {
    const { attributes } = touchpoint;
    dispatch(setCurrentBuilderContent(attributes));
  };

  const claimLock = async () => {
    if (isNotTheme) {
      const claimedTouchpoint = await TouchpointVersion.claimLock(touchpointIds);
      handleClaimSuccess(claimedTouchpoint);
    } else {
      const claimedTheme = await Theme.claimLock(touchpointIds);
      handleClaimSuccess(claimedTheme);
    }
  };

  useEffect(() => {
    if (apiValue && !isLockedByAnother && !previewMode && !apiValue?.lockedBy && !claimingLock) {
      // Content is not owned by anyone, so claim it
      setClaimingLock(true);
      claimLock()
        .then(() => {
          toast.success({
            data: {
              title: "Content claimed",
              message: "You can edit the content until someone else claims it.",
            },
          });
        })
        .finally(() => {
          setClaimingLock(false);
        });
    } else if (apiValue && isLockedByAnother && !previewMode && !claimingLock) {
      lockingModal.onOpen();
    } else {
      lockingModal.onClose();
    }
    // eslint-disable-next-line
  }, [isLockedByAnother, previewMode, apiValue]);

  // Release lock if you own it when leaving the builder
  useEffect(function unlockOnUnmount() {
    return function cleanup() {
      if (!isLocked(apiValue, currentUser)) {
        releaseLock();
      }
    }; // eslint-disable-next-line
  }, []);

  const previewRoutes = {
    EMAIL: `${baseUrl}/${AppRoutes.emailPreview}`,
    LANDING_PAGE: `${baseUrl}/${apiValue.id}/${AppRoutes.lpPreview}`,
    THEME: `${baseUrl}/${AppRoutes.themePreview}/${apiValue.id}`,
  };

  const previewUrl = isNotTheme
    ? previewRoutes[(apiValue as TouchpointBuilderContent).type as TouchpointType]
    : previewRoutes[TouchpointType.THEME];

  return (
    <FullPageOverlay {...rest}>
      <LockingModal
        content={apiValue}
        redirectUrl={baseUrl}
        previewUrl={previewUrl}
        claimLock={claimLock}
        {...lockingModal}
      />
      <BuilderHeader
        apiValue={apiValue}
        contentName={contentName}
        baseUrl={baseUrl}
        readonly={isReadonly}
        permission={permission}
        isSaved={isSaved}
        setIsSaved={setIsSaved}
        isSaving={isSaving}
      />
      {children}
    </FullPageOverlay>
  );
};

export default Builder;
