import SecondaryNav from "components/partials/secondary-nav/secondary-nav";
import { useCurrentClient } from "state/ducks/clients";
import { Route as AppRoute } from "utilities/app-routes";
import { usePagePermission } from "hooks/use-page-permission";
import { Permission } from "types/auth";

const ClientSecondaryNav = ({ parentUrl }: { parentUrl: string }) => {
  const client = useCurrentClient();

  usePagePermission(Permission.PERM_CLIENT_READ);

  const {
    campaigns,
    profile,
    // themes
  } = AppRoute;

  const campaignsLink = { text: "Campaigns", to: parentUrl + campaigns };
  // const themesLink = { text: "Theme", to: parentUrl + themes };
  const profileLink = { text: "Profile", to: parentUrl + profile };

  const navLinks = [campaignsLink, profileLink];

  // uncomment when Theme work is started
  // if (hasPermission(currentUser, Permission.PERM_THEME_READ)) {
  //   navLinks.push(themesLink);
  // }

  return <SecondaryNav heading={client.name} links={navLinks} />;
};

export default ClientSecondaryNav;
