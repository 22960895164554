import Button, { ButtonProps } from "components/forms/button/button";

import { Route } from "utilities/app-routes";

const LogoutButton = ({ ...rest }: ButtonProps) => {
  const logout = () => {
    window.location.replace(Route.logout);
  };

  return (
    <Button onClick={logout} {...rest}>
      Logout
    </Button>
  );
};

export default LogoutButton;
