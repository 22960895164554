import { Stack } from "@chakra-ui/react";

import { H5 } from "components/partials/typography/typography";
import NavLink from "components/partials/nav-link/nav-link";

import { dashCase } from "utilities";

import { Link } from "types";

interface TertiaryNavGroupProps {
  navGroup: {
    heading?: string;
    links: Link[];
  };
}

const TertiaryNavGroup = ({ navGroup }: TertiaryNavGroupProps) => {
  return (
    <Stack data-testid="tertiary-nav-group" align="flex-start" spacing={0}>
      {navGroup.heading && (
        <H5 data-testid="navgroup-heading" ml={6} mb={2}>
          {navGroup.heading}
        </H5>
      )}
      {navGroup.links.map((link, index) => (
        <NavLink
          data-testid={`nav-link-${dashCase(link.text)}`}
          isStacked
          to={link.to}
          key={link.text + index}
          fontSize="sm">
          {link.text}
        </NavLink>
      ))}
    </Stack>
  );
};

export default TertiaryNavGroup;
