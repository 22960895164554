import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Stack } from "@chakra-ui/react";

import LPDesignPreview from "containers/admin/clients/touchpoint/components/lp-design-preview/lp-design-preview";
import EmptyDesignPreview from "containers/admin/clients/touchpoint/components/empty-design-preview/empty-design-preview";
import { BuilderHtmlPreview } from "containers/admin/clients/touchpoint/components/builder/builder-html-preview/builder-html-preview";
import {
  builderPreviewMenuOptions,
  BuilderPreviewPaneHeader,
  BuilderPreviewViews,
} from "containers/admin/clients/touchpoint/components/builder/builder-preview-pane-header/builder-preview-pane-header";

import { useCurrentTouchpoint } from "state/ducks";
import { setCurrentBuilderContent, useTouchpointBuilderContent } from "state/ducks/builder-content";
import LandingPage from "models/landing-page";
import TouchpointVersion from "models/touchpoint-version";

import { removeUserIdFromString } from "utilities/touchpoint";

import { useLocation } from "react-router";
import { Route as AppRoutes } from "utilities/app-routes";

const LPBuilderPreviewTab = ({ previewMode: isPreviewMode }: { previewMode: boolean }) => {
  const currentVersion = useTouchpointBuilderContent() as LandingPage;
  const currentTouchpoint = useCurrentTouchpoint();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCommentMode, setIsCommentMode] = useState(false);

  const { desktopView, mobileView } = builderPreviewMenuOptions;
  const [currentView, setCurrentView] = useState<BuilderPreviewViews>(mobileView);
  const isMobilePreview = currentView === mobileView;
  const isDesktopPreview = currentView === desktopView;
  const isDesignPreview = isDesktopPreview || isMobilePreview;

  useEffect(() => {
    if (isPreviewMode) {
      TouchpointVersion.find({
        clientId: currentTouchpoint.clientId,
        campaignId: currentTouchpoint.campaignId,
        touchpointId: currentTouchpoint.id,
        id: currentVersion.id,
      }).then((res) => {
        dispatch(setCurrentBuilderContent(res.attributes));
      });
    }
  }, [currentVersion, currentTouchpoint, isPreviewMode, dispatch]);

  const builderTestId = isDesktopPreview
    ? "lp-builder-desktop-previewType"
    : "lp-builder-mobile-previewType";

  const maxWidthClass = isMobilePreview ? "max-w-[25rem]" : `max-w-calc(100% - 0.5rem)`;

  const designTabRedirect = location.pathname.replace(AppRoutes.preview, AppRoutes.design);

  return (
    <Stack
      data-testid={builderTestId}
      alignItems="center"
      flex="1"
      marginX="auto"
      maxWidth="container.lg"
      spacing="0"
      width="full">
      <BuilderPreviewPaneHeader
        currentView={currentView}
        setCurrentView={setCurrentView}
        isCommentMode={isCommentMode}
        setIsCommentMode={setIsCommentMode}
        isTouchpointPreview={false}
      />
      <div className={`w-full ${maxWidthClass}`}>
        {isDesignPreview ? (
          currentVersion.finalHtml && currentVersion.data?.jsonOutput ? (
            <LPDesignPreview
              htmlContent={removeUserIdFromString(currentVersion.finalHtml)}
              htmlJSON={currentVersion.data?.jsonOutput}
              isCommentMode={isCommentMode}
              mobileView={isMobilePreview}
            />
          ) : (
            <EmptyDesignPreview redirectTo={designTabRedirect} />
          )
        ) : (
          <BuilderHtmlPreview
            data-testid="lp-builder-html"
            textareaTestId="lp-builder-advanced-textarea"
            value={currentVersion.finalHtml}
          />
        )}
      </div>
    </Stack>
  );
};

export default LPBuilderPreviewTab;
