import React, { useCallback, useState, useEffect } from "react";
import { Box, Flex, Grid } from "@chakra-ui/react";

import { Span } from "components/partials/typography/typography";
import {
  usePaginationState,
  LoadErrorMessage,
  PaginationRange,
  PaginationButtons,
} from "components/partials/paginated-table/paginated-table";

import { gridCols } from "utilities";

import {
  emptyPaginatedResponse,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";

interface PaginatedGridProps<T> {
  children: (rowData: T) => JSX.Element;
  headers: string[];
  fetchPage: (options: PaginatedRequestOptions) => Promise<PaginatedResponse<T>>;
}

function PaginatedGrid<T>(props: PaginatedGridProps<T>) {
  const paginationState = usePaginationState();
  const [response, setResponse] = useState<PaginatedResponse<T>>(emptyPaginatedResponse);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const { fetchPage: __fetchPage__ } = props;
  const fetchPage = useCallback(
    (paginationOptions: PaginatedRequestOptions) => {
      setIsLoading(true);
      __fetchPage__(paginationOptions)
        .then((res: PaginatedResponse<T>) => {
          setResponse(res);
          setErrorMessage(undefined);
        })
        .catch((err: Error) => {
          if (err.message) {
            setErrorMessage(err.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [__fetchPage__]
  );

  useEffect(() => {
    fetchPage(paginationState);
  }, [paginationState, fetchPage]);

  return (
    <Box data-testid="paginated-grid">
      <PaginationRange response={response} />
      {isLoading ? (
        <TableLoadingMessage />
      ) : response.totalItems === 0 ? (
        <Box>
          {errorMessage ? (
            <Box ml="4" mt="2">
              <LoadErrorMessage
                fetchPage={fetchPage}
                paginationOptions={paginationState}
                setError={setErrorMessage}
              />
              <Span>{!!errorMessage && errorMessage}</Span>
            </Box>
          ) : (
            <TableEmptyMessage />
          )}
        </Box>
      ) : (
        <Grid
          templateColumns={{
            sm: gridCols(1),
            md: gridCols(2),
            lg: gridCols(3),
            xl: gridCols(4),
          }}
          gridGap={6}>
          {response.items.map((item) => {
            // TODO - is the right way to provide a key for child-as-render-function?
            return (
              <React.Fragment key={JSON.stringify(item)}>{props.children(item)}</React.Fragment>
            );
          })}
        </Grid>
      )}
      <PaginationButtons response={response} paginationState={paginationState} />
    </Box>
  );
}

export default PaginatedGrid;

export const TableLoadingMessage = () => {
  return (
    <Flex height="10rem" fontSize="xl" justifyContent="center" alignItems="center">
      Loading...
    </Flex>
  );
};

export const TableEmptyMessage = () => {
  return (
    <Flex height="10rem" fontSize="xl" justifyContent="center" alignItems="center">
      No results found
    </Flex>
  );
};
