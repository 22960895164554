import { Image, ImageProps } from "@chakra-ui/react";

interface LogoProps extends ImageProps {
  isClientUser?: boolean;
  testid?: string;
}
export const Logo = ({
  height = 9,
  width = "150px",
  isClientUser,
  testid = "logo",
  ...rest
}: LogoProps) => {
  return (
    <Image
      data-testid={testid}
      src={`/${isClientUser ? "logo-two-ocean.svg" : "logo-apollo.svg"}`}
      alt={`${isClientUser ? "Two Ocean" : "Apollo"} logo`}
      h={height}
      w={width}
      {...rest}
    />
  );
};
