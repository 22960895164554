import React from "react";
import { Flex, SimpleGrid, Stack } from "@chakra-ui/react";

import Input from "components/forms/input/input";
import FormGroup from "components/forms/form-group/form-group";

import { CheckboxGroup } from "components/forms/checkbox/checkbox";
import { RadioGroup } from "components/forms/radio/radio";
import NumberInput from "components/forms/number-input/number-input";

const EXAMPLE_RADIOS = [
  { value: "1", label: "Radio choice" },
  { value: "2", label: "Radio choice" },
];

const EXAMPLE_CHECKBOXES = [
  { value: "1", label: "Checkbox choice" },
  { value: "2", label: "Checkbox choice" },
];

const UIKitForms = () => {
  return (
    <SimpleGrid templateColumns={{ md: "1fr 2fr" }} spacing={[0, 8]}>
      {/* Text Inputs */}
      <FormGroup legend="Text Inputs">
        <Input
          name="textInput"
          label="Basic Input"
          placeholder="Placeholder text"
          helperText="Helper text goes here"
        />
        <Input
          name="errorInput"
          label="Input with Error"
          placeholder="Placeholder text"
          helperText="Helper text goes here"
          errorMessage="Something is wrong"
        />

        <Input
          name="textInput"
          label="Disabled Input"
          placeholder="Placeholder text"
          helperText="Helper text goes here"
          isDisabled
        />
        <NumberInput
          name="numberInput" //
          label="Number Input"
          helperText="Helper text goes here"
        />
        <NumberInput
          name="numberInputError"
          label="Number Input"
          helperText="Helper text goes here"
          errorMessage="Something is wrong"
        />
        <NumberInput
          name="numberInputDisabled"
          label="Number Input"
          helperText="Helper text goes here"
          isDisabled
        />
      </FormGroup>

      {/* Checkboxes */}
      <Stack spacing={8}>
        <FormGroup justifyContent="space-between" legend="Checkboxes">
          <Flex>
            <CheckboxGroup
              label="Large Checkboxes"
              helperText="Helper text is helpful."
              checkboxes={EXAMPLE_CHECKBOXES}
              size="lg"
            />
            <CheckboxGroup
              label="Large Invalid"
              isInvalid
              checkboxes={EXAMPLE_CHECKBOXES}
              size="lg"
            />
            <CheckboxGroup
              label="Large Disabled"
              isDisabled
              checkboxes={EXAMPLE_CHECKBOXES}
              size="lg"
            />
          </Flex>
          <Flex>
            <CheckboxGroup
              label="Basic Checkboxes"
              helperText="Helper text is helpful."
              checkboxes={EXAMPLE_CHECKBOXES}
            />
            <CheckboxGroup label="Invalid Checkboxes" isInvalid checkboxes={EXAMPLE_CHECKBOXES} />
            <CheckboxGroup label="Disabled Checkboxes" isDisabled checkboxes={EXAMPLE_CHECKBOXES} />
          </Flex>
          <Flex>
            <CheckboxGroup
              label="Small Checkboxes"
              helperText="Helper text is helpful."
              checkboxes={EXAMPLE_CHECKBOXES}
              size="sm"
            />
            <CheckboxGroup
              label="Small Invalid"
              isInvalid
              checkboxes={EXAMPLE_CHECKBOXES}
              size="sm"
            />
            <CheckboxGroup
              label="Small Disabled"
              isDisabled
              checkboxes={EXAMPLE_CHECKBOXES}
              size="sm"
            />
          </Flex>
        </FormGroup>

        {/* Radio Buttons */}
        <FormGroup legend="Radio Buttons">
          <Flex>
            <RadioGroup
              name="radios-basic"
              label="Basic Radio Buttons"
              helperText="Helper text is helpful."
              options={EXAMPLE_RADIOS}
              size="lg"
            />
            <RadioGroup
              name="radios-invalid"
              label="Invalid Radio Buttons"
              isInvalid
              options={EXAMPLE_RADIOS}
              size="lg"
            />
            <RadioGroup
              name="radios-disabled"
              label="Disabled Radio Buttons"
              isDisabled
              options={EXAMPLE_RADIOS}
              size="lg"
            />
          </Flex>
          <Flex>
            <RadioGroup
              name="radios-basic"
              label="Basic Radio Buttons"
              helperText="Helper text is helpful."
              options={EXAMPLE_RADIOS}
            />
            <RadioGroup
              name="radios-invalid"
              label="Invalid Radio Buttons"
              isInvalid
              options={EXAMPLE_RADIOS}
            />
            <RadioGroup
              name="radios-disabled"
              label="Disabled Radio Buttons"
              isDisabled
              options={EXAMPLE_RADIOS}
            />
          </Flex>
          <Flex>
            <RadioGroup
              name="radios-basic"
              label="Small Radio Buttons"
              helperText="Helper text is helpful."
              options={EXAMPLE_RADIOS}
              size="sm"
            />
            <RadioGroup
              name="radios-invalid"
              label="Small Invalid"
              isInvalid
              options={EXAMPLE_RADIOS}
              size="sm"
            />
            <RadioGroup
              name="radios-disabled"
              label="Small Disabled"
              isDisabled
              options={EXAMPLE_RADIOS}
              size="sm"
            />
          </Flex>
        </FormGroup>
      </Stack>
    </SimpleGrid>
  );
};

export default UIKitForms;
