import { Box, Text, Stack } from "@chakra-ui/react";

import TruncatedText from "components/partials/truncated-text/truncated-text";
import { CommentDisplayByline } from "containers/admin/clients/touchpoint/components/comments/comment-display-by-line/comment-display-by-line";

import { dateToLocalTimestamp } from "utilities";

import { CommentReply } from "types/comments";

export const CommentReplyDisplay = ({ reply }: { reply: CommentReply }) => {
  const createdDate = new Date(reply.createdDate);

  return (
    <Box data-testid="comment-reply-display">
      <CommentDisplayByline user={reply.user} />
      <Stack spacing={2} fontSize="xs" ml={6} letterSpacing="wide">
        <Text>
          {createdDate.toLocaleDateString()} {dateToLocalTimestamp(reply.createdDate)}
        </Text>
        <TruncatedText text={reply.text} noOfLines={4} initialTruncate={true} />
      </Stack>
    </Box>
  );
};
