import { Link } from "react-router-dom";

import { CustomLinkType } from "components/new/custom-link/custom-link.types";

const CustomLink = ({ className, to = "", label }: CustomLinkType) => {
  return (
    <Link to={to} className={className}>
      {label}
    </Link>
  );
};

export default CustomLink;
