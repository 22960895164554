import StatusIndicator from "components/partials/status-indicator/status-indicator";

import { ClientReviewStatusType } from "components/partials/status-indicator/status-indicator.type";

const ClientReviewStatusIndicator = ({ clientVersionStatuses }: ClientReviewStatusType) => {
  return (
    <div className="w-full ml-6 text-sm mt-0.5">
      <span className="text-neutral-3">Client Review Status</span>
      <StatusIndicator clientVersionStatuses={clientVersionStatuses} />
    </div>
  );
};

export default ClientReviewStatusIndicator;
