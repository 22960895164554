import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";

import PreviewTab from "containers/admin/clients/touchpoint/tab-components/preview-tab/preview-tab";
import DetailsTab from "containers/admin/clients/touchpoint/tab-components/details-tab/details-tab";
import VersionHistoryTab from "containers/admin/clients/touchpoint/tab-components/version-history-tab/version-history-tab";
import EmailEditDetailsPage from "containers/admin/clients/touchpoint/email/email-edit-details-page/email-edit-details-page";
import LPEditDetailsPage from "containers/admin/clients/touchpoint/lp/lp-edit-details-page/lp-edit-details-page";

import TouchpointPageHeader from "containers/admin/clients/touchpoint/components/touchpoint-page-header/touchpoint-page-header";
import { GeneralCommentsFAB } from "components/partials/general-comments-fab/general-comments-fab";

import { CommentContextProvider } from "contexts/comment-context";

import {
  setTouchpointVersions,
  touchpointVersionsSelector,
  useCurrentTouchpoint,
} from "state/ducks/touchpoint";
import { useCurrentCampaign, useCurrentClient, useCurrentUser } from "state/ducks";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";
import { Touchpoint } from "models";

import { Route as AppRoute } from "utilities/app-routes";
import { hasPermission } from "utilities/user";
import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import { PaginatedResponse, PaginatedRequestOptions } from "types/pagination";
import { Comment, NewComment } from "types/comments";
import { TouchpointType } from "types/touchpoint";

const TouchpointPage = ({ creativeIndexUrl }: { creativeIndexUrl: string }) => {
  const [selectedVersion, setSelectedVersion] = useState<TouchpointVersionAttributes>();
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(false);

  const { path, url } = useRouteMatch();
  const currentTouchpoint = useCurrentTouchpoint();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentUser = useCurrentUser();

  const touchpointVersions = useSelector(touchpointVersionsSelector);

  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);
  const isEmail = currentTouchpoint.type === TouchpointType.EMAIL;

  usePagePermission(Permission.PERM_TOUCHPOINT_READ);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedVersion) {
      const latestVersion = touchpointVersions.items[0];
      setSelectedVersion(latestVersion);
    }
  }, [selectedVersion, touchpointVersions]);

  const loadTouchpointComments = (options?: PaginatedRequestOptions) => {
    return Touchpoint.allComments({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
      options: {
        sort: "comment.createdDate,desc",
        ...options,
      },
    });
  };

  const loadTouchpointCommentsSummary = () => {
    setIsSummaryLoading(true);

    return Touchpoint.commentsSummary({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
    }).finally(() => {
      setIsSummaryLoading(false);
    });
  };

  const submitNewTouchpointComment = (comment: NewComment) => {
    let parsedData = { ...comment, versionId: selectedVersion?.id };
    if (isClientUser) {
      parsedData = { ...parsedData, hidden: false };
    }

    return Touchpoint.createComment({
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
      data: parsedData,
    });
  };

  const updateTouchpointComment = (comment: Comment) => {
    return Touchpoint.replaceComment({
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
      data: comment,
    });
  };

  const loadTouchpointCommentReplies = (comment: Comment) => {
    return Touchpoint.allReplies({
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
      parentId: comment.id,
      options: {
        sort: "createdDate,asc",
      },
    });
  };

  const submitNewTouchpointCommentReply = (newComment: NewComment) => {
    return Touchpoint.createReply({
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
      parentId: newComment.parentId,
      text: newComment.text,
    });
  };

  useEffect(() => {
    const loadVersionHistory = (): Promise<PaginatedResponse<TouchpointVersion>> => {
      return TouchpointVersion.all({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
      }).then((results) => {
        const resultsAttributes = results.items.map((v) => v.attributes);
        dispatch(setTouchpointVersions({ ...results, items: resultsAttributes }));
        return results as PaginatedResponse<TouchpointVersion>;
      });
    };

    loadVersionHistory();
  }, [currentTouchpoint, currentClient, currentCampaign, dispatch]);

  return (
    <Switch>
      {/* Edit Touchpoint Details from Details tab */}
      <Route path={path + AppRoute.edit}>
        {isEmail ? (
          <EmailEditDetailsPage creativeIndexUrl={creativeIndexUrl} baseCreativeUrl={url} />
        ) : (
          <LPEditDetailsPage baseCreativeUrl={url} />
        )}
      </Route>
      <CommentContextProvider
        loadComments={loadTouchpointComments}
        loadCommentsSummary={loadTouchpointCommentsSummary}
        submitNewComment={submitNewTouchpointComment}
        updateComment={updateTouchpointComment}
        loadCommentReplies={loadTouchpointCommentReplies}
        submitNewCommentReply={submitNewTouchpointCommentReply}>
        <TouchpointPageHeader parentUrl={url} />
        {!isClientUser ? (
          <Switch>
            <Redirect exact from={path} to={path + AppRoute.preview} />
            <Route path={path + AppRoute.preview}>
              <PreviewTab
                isSummaryLoading={isSummaryLoading}
                baseCreativeUrl={url}
                selectedVersion={selectedVersion}
                setSelectedVersion={setSelectedVersion}
              />
            </Route>
            <Route path={path + AppRoute.details}>
              <DetailsTab parentUrl={url} />
            </Route>
            <Route path={path + AppRoute.versions}>
              <VersionHistoryTab baseCreativeUrl={url} />
            </Route>
          </Switch>
        ) : (
          <Box mt={8}>
            <Redirect to={url + AppRoute.preview} />
            <PreviewTab
              baseCreativeUrl={url}
              selectedVersion={selectedVersion}
              setSelectedVersion={setSelectedVersion}
            />
            <GeneralCommentsFAB />
          </Box>
        )}
      </CommentContextProvider>
    </Switch>
  );
};

export default TouchpointPage;
