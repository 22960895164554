import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { currentClientSelector, setCurrentClient } from "state/ducks/clients";
import Client from "models/client";

import { Route } from "utilities/app-routes";

const ClientProvider = ({ children }: React.PropsWithChildren) => {
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();
  const client = useSelector(currentClientSelector);
  const dispatch = useDispatch();

  useEffect(
    function mountClient() {
      if (clientId && client?.id !== clientId) {
        Client.find(clientId)
          .then((client) => dispatch(setCurrentClient(client.attributes)))
          .catch(() => history.push(Route.notFound));
      }
    },
    [clientId, history, dispatch, client]
  );

  useEffect(() => {
    return () => {
      dispatch(setCurrentClient(null));
    };
    // eslint-disable-next-line
  }, []);

  return <>{client && client.id === clientId ? children : null}</>;
};

export default ClientProvider;
