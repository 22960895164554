import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
} from "@chakra-ui/react";

import { InputControlProps, useInputProps } from "components/forms/input-control/input-control";
import { InputControl } from "components/forms/input-control/input-control";

interface NumberInputProps extends ChakraNumberInputProps, InputControlProps {
  ref?: React.RefObject<HTMLInputElement>;
}

const NumberInput = ({ ref, ...props }: NumberInputProps) => {
  const { controlProps, inputProps } = useInputProps(props);
  return (
    <InputControl {...controlProps}>
      <ChakraNumberInput ref={ref} {...inputProps}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </ChakraNumberInput>
    </InputControl>
  );
};

export default NumberInput;
