import { BrowserCacheLocation, Configuration, RedirectRequest } from "@azure/msal-browser";
import { env } from "env";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: env.REACT_APP_AUTH_CLIENTID,
        authority: env.REACT_APP_AUTH_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },};

// Add here scopes for token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [env.REACT_APP_AUTH_SCOPES]
};


