import { useForm, Controller, FieldValues } from "react-hook-form";

import CustomRadioInput from "components/new/custom-radio-input/custom-radio-input";
import { CLIENTS_STATUSES_FORM_FIELDS as statuses } from "components/new/filter-status-form/filter-status-form.constants";

interface FilterStatusFormType {
  status: string;
  onClick: (value: string) => void;
}

const FilterStatusForm = ({ status, onClick }: FilterStatusFormType) => {
  const { control } = useForm<FieldValues>({ mode: "onChange" });

  return (
    <div className="flex items-center py-1.5 mb-2">
      <p className="mr-2 md:mr-8 font-semibold text-sm md:text-base">Filter by touchpoint type</p>

      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <>
            {statuses.map(({ label, valueForEdit, radioValue }) => (
              <CustomRadioInput
                key={label}
                label={label}
                radioValue={radioValue}
                valueForEdit={valueForEdit}
                setAction={onClick}
                customStylesLabel="mb-0"
                {...field}
                value={status}
              />
            ))}
          </>
        )}
      />
    </div>
  );
};

export default FilterStatusForm;
