import { Route, Switch, useRouteMatch } from "react-router-dom";

import EmailBuilder from "containers/admin/clients/touchpoint/components/email-builder/email-builder";
import LPBuilder from "containers/admin/clients/touchpoint/components/lp-builder/lp-builder";
import BuilderProvider from "containers/admin/clients/touchpoint/components/builder/builder-provider/builder-provider";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";
import { Email, LandingPage } from "models";
import TouchpointVersion from "models/touchpoint-version";

import { Route as AppRoute } from "utilities/app-routes";

import { ChildPageProps } from "types";

const TouchpointVersionRoutes = ({ parentUrl }: ChildPageProps) => {
  const { path } = useRouteMatch();
  const currentCampaign = useCurrentCampaign();
  const currentClient = useCurrentClient();
  const currentTouchpoint = useCurrentTouchpoint();

  const findTouchpointVersion = (id: string) => {
    return TouchpointVersion.find({
      id,
      campaignId: currentCampaign.id,
      clientId: currentClient.id,
      touchpointId: currentTouchpoint.id,
    }) as Promise<Email | LandingPage>;
  };

  return (
    <BuilderProvider findBuilderValue={findTouchpointVersion}>
      <Switch>
        <Route path={path + AppRoute.emailBuilder}>
          <EmailBuilder baseEmailUrl={parentUrl} previewMode={false} />
        </Route>
        <Route path={path + AppRoute.emailPreview}>
          <EmailBuilder baseEmailUrl={parentUrl} previewMode={true} />
        </Route>
        <Route path={path + AppRoute.lpBuilder}>
          <LPBuilder baseLandingPageUrl={parentUrl} previewMode={false} />
        </Route>
        <Route path={path + AppRoute.lpPreview}>
          <LPBuilder baseLandingPageUrl={parentUrl} previewMode={true} />
        </Route>
      </Switch>
    </BuilderProvider>
  );
};

export default TouchpointVersionRoutes;
