// use-lp-builder-form-fields.tsx
import { useEffect, useState } from "react";

import { useCurrentCampaign, useCurrentClient, useCurrentTouchpoint } from "state/ducks";

import Touchpoint from "models/touchpoint";

import { CustomFormField } from "utilities/forms";

import { TouchpointType } from "types/touchpoint";

const useLPSettingsFields = () => {
  const currentLp = useCurrentTouchpoint();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();

  const pageUrlParts = [
    currentClient.apolloDomainUrl,
    currentCampaign.cdnSubdirectoryName,
    "", // Adds final forward slash
  ];

  /** Shows complete page URL with domain and subdirectory */
  const pageUrlHelperText = pageUrlParts.join("/");

  interface RedirectionOption {
    id: string;
    name: string;
    [key: string]: any;
  }
  const [redirectOptions, setRedirectOptions] = useState<RedirectionOption[]>([]);
  const [contentSettingsFields, setContentSettingsFields] = useState<CustomFormField[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchRedirectOptions = async () => {
      try {
        const touchpointOptions = await Touchpoint.all({
          clientId: currentClient.id,
          campaignId: currentCampaign.id,
          type: TouchpointType.LANDING_PAGE,
          options: { size: 100, sort: "name" },
        });

        setRedirectOptions(touchpointOptions.items.filter((option) => option.id !== currentLp.id));
      } catch (e) {
        console.error("Error while fetching redirection options", e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRedirectOptions();
  }, [currentCampaign.id, currentClient.id, currentLp.id]);

  useEffect(() => {
    if (!isLoading) {
      setContentSettingsFields([
        {
          name: "pageTitle",
          component: "input",
          label: "Page Title",
          defaultValue: "[Page Title]",
          validations: { isRequired: true },
        },
        {
          name: "url",
          component: "input",
          label: "Page URL",
          defaultValue: "sample",
          helperText: "Enter the root folder of your landing page",
          placeholder: "folder-name",
          addonStart: pageUrlHelperText,
          validations: { isRequired: true },
        },
        {
          name: "pageDescription",
          component: "textarea",
          label: "Page Description",
          defaultValue: "[Page Description]",
          validations: { isRequired: true },
        },
        {
          name: "redirectDestinationSelect",
          component: "select",
          label: "Redirect destination",
          defaultValue: "custom-url",
          placeholder: "Select destination",
          validations: { isRequired: true },
          options: [
            { name: "Custom URL", value: "custom-url" },
            ...redirectOptions.map((option) => ({ name: option.name, value: option.id })),
          ],
        },
        {
          name: "redirectDestination",
          component: "input",
          label: "Custom redirect URL",
          // TODO: Implement addonStart while still displaying and passing
          // the correct values that exclude and include it respectively.
          // addonStart: "https://",
          defaultValue: "https://www.example.edu/landing-page",
          validations: {
            // TODO: Fix conditional type validation. It can only be one or the other right now.
            // type: (watch) => (watch.redirectDestinationSelect === "custom-url" ? "url" : "text"),

            isRequired: true,
          },
          // field will only be displayed if redirectDestinationSelect value is "custom-url"
          condition: (watch) => watch.redirectDestinationSelect === "custom-url",
        },
        {
          name: "redirectDestinationType",
          component: "hidden",
          defaultValue: "URL",
        },
        {
          name: "searchVisibility",
          component: "hidden",
          defaultValue: true,
        },
        {
          name: "disablePrefill",
          component: "hidden",
          defaultValue: true,
        },
        {
          name: "advancedHeadSnippet",
          component: "textarea",
          label: "Head Snippet",
          helperText: "Scripts are applied directly before the closing </head> tag",
        },
        {
          name: "advancedBodySnippet",
          component: "textarea",
          label: "Body Snippet",
          helperText: "Scripts are applied directly before the closing </body> tag",
        },
      ]);
    }
  }, [redirectOptions, isLoading, pageUrlHelperText]);

  return contentSettingsFields;
};

export default useLPSettingsFields;
