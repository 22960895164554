import { useEffect, useReducer, useState } from "react";
import * as zod from "zod";

import { Theme } from "models";

import { Formify } from "types/utility";
import { ZodFormErrors } from "types";

export const themeSchema = zod.object({
  typekitId: zod.string().optional(),

  pageBackgroundColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  primaryColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  secondaryColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  buttonAndLinkColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  formLabelColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  formInputBorderColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$|^(transparent)$/)
    .optional(),
  formInputBackgroundColor: zod
    .string()
    // Removed requirement because this attribute is currently not being used
    // .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),

  h1FontFamily: zod.string().optional(),
  h2FontFamily: zod.string().optional(),
  h3FontFamily: zod.string().optional(),
  bodyFontFamily: zod.string().optional(),
  inputTextFontFamily: zod.string().optional(),

  h1FontWeight: zod.string().optional(),
  h2FontWeight: zod.string().optional(),
  h3FontWeight: zod.string().optional(),
  bodyFontWeight: zod.string().optional(),
  inputTextFontWeight: zod.string().optional(),

  h1LineHeight: zod.string().optional(),
  h2LineHeight: zod.string().optional(),
  h3LineHeight: zod.string().optional(),
  bodyLineHeight: zod.string().optional(),

  h1Color: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  h2Color: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  h3Color: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  bodyColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),
  inputTextColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$/)
    .optional(),

  headerLogoDesktop: zod
    .object({
      id: zod.string().optional(),
      name: zod.string().optional(),
      type: zod.string().optional(),
      url: zod.string().optional(),
      lastModifiedDate: zod.string().optional(),
      byteSize: zod.number().optional(),
    })
    .optional(),
  headerLogoMobile: zod
    .object({
      id: zod.string().optional(),
      name: zod.string().optional(),
      type: zod.string().optional(),
      url: zod.string().optional(),
      lastModifiedDate: zod.string().optional(),
      byteSize: zod.number().optional(),
    })
    .optional(),
  footerLogoDesktop: zod
    .object({
      id: zod.string().optional(),
      name: zod.string().optional(),
      type: zod.string().optional(),
      url: zod.string().optional(),
      lastModifiedDate: zod.string().optional(),
      byteSize: zod.number().optional(),
    })
    .optional(),
  footerLogoMobile: zod
    .object({
      id: zod.string().optional(),
      name: zod.string().optional(),
      type: zod.string().optional(),
      url: zod.string().optional(),
      lastModifiedDate: zod.string().optional(),
      byteSize: zod.number().optional(),
    })
    .optional(),
  favicon: zod
    .object({
      id: zod.string().optional(),
      name: zod.string().optional(),
      type: zod.string().optional(),
      url: zod.string().optional(),
      lastModifiedDate: zod.string().optional(),
      byteSize: zod.number().optional(),
    })
    .optional(),

  defaultBorderRadius: zod.string().optional(),
  defaultBoxShadow: zod.string().optional(),
  defaultBorderWidth: zod.string().optional(),

  colorPresets: zod.string().array().optional(),
  fontPresets: zod.string().array().optional(),
  lastModifiedDate: zod.string().optional(),
  typekitFonts: zod
    .object({
      id: zod.string().optional(),
      name: zod.string().optional(),
      defaultFontWeight: zod.string().optional(),
      boldFontWeight: zod.string().optional(),
      fallbackFont: zod.string().optional(),
      slug: zod.string().optional(),
      css_names: zod.array(zod.string()).optional(),
      css_stack: zod.string().optional(),
      variations: zod.array(zod.string()).optional(),
    })
    .array()
    .optional(),

  buttonFontColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$|^(transparent)$/)
    .optional(),
  buttonBorderColor: zod
    .string()
    .regex(/^#([0-9A-Fa-f]{3}){1,2}$|^(transparent)$/)
    .optional(),
  buttonBorderStyle: zod
    .string()
    .regex(/^(dotted|dashed|solid)$/)
    .optional(),
  buttonBoxShadow: zod.string().optional(),
  buttonBorderRadius: zod
    .string()
    .regex(/^([0-9]|[1-5][0-9]|60)px$/)
    .optional(),
  buttonBorderWidth: zod
    .string()
    .regex(/^([0-9]|[1-2][0-9]|30)px$/)
    .optional(),
  buttonLineHeight: zod.string().optional(),
  buttonPaddingHorizontal: zod
    .string()
    .regex(/^([0-9]|[1-5][0-9]|60)px$/)
    .optional(),
  buttonPaddingVertical: zod
    .string()
    .regex(/^([0-9]|[1-5][0-9]|60)px$/)
    .optional(),
});

type ThemeBuilderAttributesSchema = zod.infer<typeof themeSchema>;
export type ThemeBuilderAttributesFormState = Formify<ThemeBuilderAttributesSchema>;
type ThemeBuilderAttributesFormErrors = Partial<{
  [k in keyof ThemeBuilderAttributesSchema]: string;
}>;

export const initialThemeValues = {
  backgroundColor: "#ffffff",
  primaryColor: "#000000",
  secondaryColor: "#551A8B",
  linkColor: "#3AAEE0",
  fontFamily: "'Figtree', sans-serif",
  lineHeight: "200%",
  normalFontWeight: "400",
  boldFontWeight: "700",
  headerLogoDesktop: {},
  buttonFontColor: "#ffffff",
};

function initialThemeBuilderValues(theme?: Theme): ThemeBuilderAttributesFormState {
  return {
    typekitId: theme?.typekitId || "",

    pageBackgroundColor: theme?.pageBackgroundColor || initialThemeValues.backgroundColor,
    primaryColor: theme?.primaryColor || initialThemeValues.primaryColor,
    secondaryColor: theme?.secondaryColor || initialThemeValues.secondaryColor,
    buttonAndLinkColor: theme?.buttonAndLinkColor || initialThemeValues.linkColor,

    formLabelColor: theme?.formLabelColor || initialThemeValues.primaryColor,
    formInputBorderColor: theme?.formInputBorderColor || initialThemeValues.primaryColor,
    formInputBackgroundColor: theme?.formInputBackgroundColor || initialThemeValues.backgroundColor,

    bodyColor: theme?.bodyColor || theme?.primaryColor || initialThemeValues.primaryColor,
    h1Color: theme?.h1Color || theme?.bodyColor || initialThemeValues.primaryColor,
    h2Color: theme?.h2Color || theme?.bodyColor || initialThemeValues.primaryColor,
    h3Color: theme?.h3Color || theme?.bodyColor || initialThemeValues.primaryColor,
    inputTextColor: theme?.inputTextColor || theme?.primaryColor || initialThemeValues.primaryColor,

    bodyFontFamily: theme?.bodyFontFamily || initialThemeValues.fontFamily,
    h1FontFamily: theme?.h1FontFamily || theme?.bodyFontFamily || initialThemeValues.fontFamily,
    h2FontFamily: theme?.h2FontFamily || theme?.bodyFontFamily || initialThemeValues.fontFamily,
    h3FontFamily: theme?.h3FontFamily || theme?.bodyFontFamily || initialThemeValues.fontFamily,
    inputTextFontFamily: theme?.inputTextFontFamily || initialThemeValues.fontFamily,

    bodyFontWeight: theme?.bodyFontWeight || initialThemeValues.normalFontWeight,
    h1FontWeight: theme?.h1FontWeight || initialThemeValues.boldFontWeight,
    h2FontWeight: theme?.h2FontWeight || initialThemeValues.boldFontWeight,
    h3FontWeight: theme?.h3FontWeight || initialThemeValues.boldFontWeight,
    inputTextFontWeight: theme?.inputTextFontWeight || initialThemeValues.normalFontWeight,

    bodyLineHeight: theme?.bodyLineHeight || initialThemeValues.lineHeight,
    h1LineHeight: theme?.h1LineHeight || "1.0",
    h2LineHeight: theme?.h2LineHeight || theme?.bodyLineHeight || initialThemeValues.lineHeight,
    h3LineHeight: theme?.h3LineHeight || theme?.bodyLineHeight || initialThemeValues.lineHeight,

    defaultBorderRadius: theme?.defaultBorderRadius || "0px",
    defaultBoxShadow: theme?.defaultBoxShadow || "",
    defaultBorderWidth: theme?.defaultBorderWidth || "1px",

    //@ts-ignore
    headerLogoDesktop: theme?.headerLogoDesktop || {},
    //@ts-ignore
    headerLogoMobile: theme?.headerLogoMobile || {},
    //@ts-ignore
    footerLogoDesktop: theme?.footerLogoDesktop || {},
    //@ts-ignore
    footerLogoMobile: theme?.footerLogoMobile || {},
    //@ts-ignore
    favicon: theme?.favicon || {},

    lastModifiedDate: theme?.lastModifiedDate || "",
    colorPresets: theme?.colorPresets || [],
    fontPresets: theme?.fontPresets || [],
    //@ts-ignore
    typekitFonts: theme?.typekitFonts || [],

    buttonFontColor: theme?.buttonFontColor || initialThemeValues.buttonFontColor,
    buttonBorderRadius: theme?.buttonBorderRadius || "4px",
    buttonBorderWidth: theme?.buttonBorderWidth || "0px",
    buttonBorderStyle: theme?.buttonBorderStyle || "solid",
    buttonBorderColor: theme?.buttonBorderColor || "transparent",
    buttonBoxShadow: theme?.buttonBoxShadow || "",
    buttonLineHeight: theme?.buttonLineHeight || initialThemeValues.lineHeight,
    buttonPaddingHorizontal: theme?.buttonPaddingHorizontal || "20px",
    buttonPaddingVertical: theme?.buttonPaddingVertical || "5px",
  };
}

export type ThemeBuilderForm = {
  values: ThemeBuilderAttributesFormState;
  handleChange: (
    field: string
  ) => (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  errors: ThemeBuilderAttributesFormErrors;
  handleComplexChange: (field: string, updatedValue: any) => void;
  setErrors: (errors: ZodFormErrors) => void;
};

export function useThemeBuilderFormState(theme?: Theme): ThemeBuilderForm {
  const [values, dispatch] = useReducer(
    function reducer(
      state: ThemeBuilderAttributesFormState,
      action: { type?: string; field?: string; value?: string; payload?: Theme }
    ) {
      if (action.type === "theme_change") {
        return initialThemeBuilderValues(action.payload);
      }
      switch (action.field) {
        case "typekitId":
        case "pageBackgroundColor":
        case "primaryColor":
        case "secondaryColor":
        case "buttonAndLinkColor":
        case "formLabelColor":
        case "formInputBorderColor":
        case "formInputBackgroundColor":
        case "bodyColor":
        case "h1Color":
        case "h2Color":
        case "h3Color":
        case "inputTextColor":
        case "bodyFontFamily":
        case "h1FontFamily":
        case "h2FontFamily":
        case "h3FontFamily":
        case "inputTextFontFamily":
        case "bodyFontWeight":
        case "h1FontWeight":
        case "h2FontWeight":
        case "h3FontWeight":
        case "inputTextFontWeight":
        case "bodyLineHeight":
        case "h1LineHeight":
        case "h2LineHeight":
        case "h3LineHeight":
        case "defaultBorderRadius":
        case "defaultBoxShadow":
        case "defaultBorderWidth":
        case "colorPresets":
        case "fontPresets":
        case "headerLogoDesktop":
        case "headerLogoMobile":
        case "footerLogoDesktop":
        case "footerLogoMobile":
        case "favicon":
        case "typekitFonts":
        case "buttonFontColor":
        case "buttonBorderColor":
        case "buttonBorderStyle":
        case "buttonBorderRadius":
        case "buttonBorderWidth":
        case "buttonBoxShadow":
        case "buttonLineHeight":
        case "buttonPaddingHorizontal":
        case "buttonPaddingVertical":
        case "lastModifiedDate":
          return { ...state, [action.field]: action.value };
        default:
          throw new Error(`Error setting ${action.field} ${action.value}`);
      }
    },
    theme,
    initialThemeBuilderValues
  );

  useEffect(() => {
    // If the themes id changed, we want to refresh
    // Otherwise, the form values should show rather than any api values
    dispatch({ type: "theme_change", payload: theme });
    // eslint-disable-next-line
  }, [theme?.id]);

  //function for simple onchange handler events
  function handleChange(field: string) {
    return function (
      event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) {
      dispatch({ field, value: event.target.value });
    };
  }

  //dispatch any value change regardless of type
  function handleComplexChange(field: string, value: any) {
    dispatch({ field, value: value });
  }

  const [errors, setErrors] = useState<ThemeBuilderAttributesFormErrors>({});

  function handleErrors(errors: ZodFormErrors): void {
    // it's kinda lame that zod doesn't give us back an object with the same keys as our schema -- it
    // just gives { [string] => string[] }. we'll cast it ourselves.
    //
    const invalidFields = Object.keys(errors) as Array<keyof ThemeBuilderAttributesSchema>;
    setErrors(
      invalidFields.reduce((memo, field) => {
        memo[field] = errors[field].join(", ");
        return memo;
      }, {} as ThemeBuilderAttributesFormErrors)
    );
  }

  return { values, handleChange, handleComplexChange, errors, setErrors: handleErrors };
}
