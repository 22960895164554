import { BoxProps, Box } from "@chakra-ui/react";

import Tooltip from "components/partials/tooltip/tooltip";

export type Color = {
  hex: string;
  label: string;
};

interface ColorDisplayProps extends BoxProps {
  color: Color;
}

const ColorDisplay = ({ color, ...rest }: ColorDisplayProps) => {
  return (
    <Tooltip label={color.label}>
      <Box
        bg={color.hex}
        border="2px solid transparent"
        _hover={{
          borderColor: "white",
        }}
        transition="border 0.2s"
        height="100%"
        {...rest}
      />
    </Tooltip>
  );
};

export default ColorDisplay;
