import { useState } from "react";
import {
  BoxProps,
  Stack,
  Input,
  InputGroup,
  ButtonGroup as ChakraButtonGroup,
  CheckboxProps,
} from "@chakra-ui/react";

import Button, { ButtonProps } from "components/forms/button/button";
import Checkbox from "components/forms/checkbox/checkbox";
import Textarea from "components/forms/textarea/textarea";

import { Comment, CommentReply, NewComment } from "types/comments";

interface AnnotationCommentInputProps extends BoxProps {
  commentType?: "main" | "reply";
  parentId?: string;
  submitNewComment: (comment: NewComment) => Promise<Comment | CommentReply | void>;
  isClientUser?: boolean;
  onCancel: () => void;
  setReplies: React.Dispatch<React.SetStateAction<CommentReply[]>>;
  commentInputRef: React.RefObject<HTMLTextAreaElement>;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}

export const AnnotationCommentInput = ({
  commentType = "main",
  parentId,
  submitNewComment,
  isClientUser,
  onCancel,
  setReplies,
  commentInputRef,
  isExpanded,
  setIsExpanded,
  isDisabled = false,
  ...rest
}: AnnotationCommentInputProps) => {
  const [comment, setComment] = useState<string>("");
  const [isActionItem, setIsActionItem] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function setInitialState() {
    setIsExpanded(false);
    setComment("");
    setIsActionItem(true);
    setIsHidden(false);
    setIsLoading(false);
  }

  function handleSubmit() {
    setIsLoading(true);

    let payload: NewComment = {
      text: comment,
    };

    if (commentType === "main") {
      payload.actionItem = !isClientUser ? isActionItem : true;
      payload.hidden = !isHidden;
    } else {
      payload.parentId = parentId;
    }

    submitNewComment(payload).finally(() => {
      setInitialState();
      setIsLoading(false);
    });
  }

  function handleCancel() {
    onCancel();
    setTimeout(() => {
      setInitialState();
    }, 100);
  }

  const isFirstComment = commentType === "main";
  const isReplyToComment = !isFirstComment;

  const inputPlaceholder = isFirstComment ? "Add a comment..." : "Add a reply...";

  return !isExpanded && isReplyToComment ? (
    <InputGroup {...rest}>
      <Input
        placeholder={inputPlaceholder}
        autoComplete="off"
        onFocus={() => setIsExpanded((isExpanded) => !isExpanded)}
        data-testid="comment-input-collapsed"
        isDisabled={isDisabled}
      />
    </InputGroup>
  ) : (
    <Stack spacing={4} {...rest}>
      <Textarea
        isDisabled={isDisabled}
        ref={commentInputRef}
        rows={4}
        resize="none"
        placeholder={inputPlaceholder}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        data-testid="comment-input-main"
        autoComplete="off"
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
          }
        }}
      />
      {isFirstComment && !isClientUser && (
        <Stack spacing={1}>
          <IsVisibleToClientCheckbox
            isChecked={isHidden}
            isDisabled={isDisabled}
            onChange={(e) => setIsHidden(e.target.checked)}
          />
          <IsActionItemCheckbox
            isChecked={isActionItem}
            isDisabled={isDisabled}
            onChange={(e) => setIsActionItem(e.target.checked)}
          />
        </Stack>
      )}
      <ChakraButtonGroup spacing={4} justifyContent="end">
        <CancelButton onClick={handleCancel} />
        <SubmitButton
          onClick={handleSubmit}
          isLoading={isLoading}
          isDisabled={comment === "" || isDisabled}
        />
      </ChakraButtonGroup>
    </Stack>
  );
};

const IsVisibleToClientCheckbox = ({ ...rest }: CheckboxProps) => {
  const labelText = "Visible to client";
  return (
    <Checkbox data-testid="comment-input-checkbox-hidden" label={labelText} size="sm" {...rest} />
  );
};

const IsActionItemCheckbox = ({ ...rest }: CheckboxProps) => {
  const labelText = "Flag as action item";
  return (
    <Checkbox
      data-testid="comment-input-checkbox-actionitem"
      label={labelText}
      size="sm"
      {...rest}
    />
  );
};

const CancelButton = ({ ...rest }: ButtonProps) => {
  const buttonText = "Cancel";
  return (
    <Button data-testid="comment-input-cancel-button" size="sm" variant="tertiary" {...rest}>
      {buttonText}
    </Button>
  );
};

const SubmitButton = ({ ...rest }: ButtonProps) => {
  const buttonText = "Submit";
  return (
    <Button data-testid="comment-input-submit-button" size="sm" {...rest}>
      {buttonText}
    </Button>
  );
};
