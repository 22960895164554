import { useCallback, useEffect, useState } from "react";
import { RepeatIcon } from "@chakra-ui/icons";
import { Flex, Box, Text } from "@chakra-ui/react";

import Card, { CardHeader, CardBody } from "components/partials/card/card";
import { H3 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import AlertBox from "components/partials/alert-box/alert-box";
import Toggle from "components/partials/toggle/toggle";

import Touchpoint from "models/touchpoint";
import Client from "models/client";

interface IAlert {
  status: "success" | "error" | "info" | "warning";
  title: string;
  message: string;
}
const ALERT_DATA: { [key: string]: IAlert } = {
  default: { status: "info", title: "", message: "" },
  success: {
    status: "success",
    title: "Sync is kicked off",
    message: "Please refresh the page in a bit",
  },
  error: { status: "error", title: "Something went wrong", message: "Please, try again" },
};

const EmailTypesTab = ({ client }: { client: Client }) => {
  const [emailTypes, setEmailTypes] = useState([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
  const [alertData, setAlertData] = useState<IAlert>(ALERT_DATA["default"]);
  const [defaultEmailType, setDefaultEmailType] = useState("");

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 8000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showAlert]);

  const fetchEmailTypes = useCallback(async () => {
    setLoading(true);
    try {
      const emailTypes = await Touchpoint.getEmailTypes({
        clientId: client.id,
      });

      setEmailTypes(emailTypes);
      const curentDefaultEmail = emailTypes.filter((el: any) => el.defaultForClient);

      setDefaultEmailType(curentDefaultEmail?.id);
    } catch (e) {
      console.error("Error during fetching email types.EmailTypes", e);
    } finally {
      setLoading(false);
    }
  }, [client.id]);

  useEffect(() => {
    fetchEmailTypes();
  }, [fetchEmailTypes]);

  const handleEmailTypesSync = async () => {
    setSyncLoading(true);

    try {
      await Touchpoint.syncEmailTypes();
      setAlertData(ALERT_DATA["success"]);
      setShowAlert(true);
      if (!showRefreshButton) {
        setShowRefreshButton(true);
      }
    } catch (e) {
      console.error("Error during syncing email types.handleEmailTypesSync", e);
      setAlertData(ALERT_DATA["error"]);
      setShowAlert(true);
    } finally {
      setSyncLoading(false);
    }
  };

  const handleDefaultEmailTypeChange = async (emailTypeId: string) => {
    try {
      const newDefaultEmailType = await Touchpoint.setDefaultEmailType({
        clientId: client.id,
        emailTypeId: emailTypeId,
      });
      setDefaultEmailType(newDefaultEmailType?.id);
    } catch (e) {
      console.error("Error during setting new email type", e);
      setAlertData(ALERT_DATA["error"]);
      setShowAlert(true);
    }
  };

  return (
    <Box position="relative">
      <Flex
        gap={{
          base: "0",
          lg: "6",
        }}
        flexWrap="wrap"
        mt="-1"
        alignItems="flex-start">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pb="10"
          sx={{ maxWidth: "630px", width: "630px" }}>
          <Flex alignItems="center" gap="3">
            <H3>Email types</H3>
            {showRefreshButton && (
              <Button
                variant="secondary"
                padding="0"
                height="30px"
                isLoading={loading}
                onClick={fetchEmailTypes}>
                <RepeatIcon sx={{ width: "24px", height: "20px", color: "#0D736A" }} />
              </Button>
            )}
          </Flex>

          <Button
            variant="secondary"
            size="sm"
            onClick={handleEmailTypesSync}
            loadingText="Synchronizing"
            isLoading={syncLoading}>
            Sync email types
          </Button>
        </Flex>
        {showAlert && (
          <Box sx={{ maxWidth: "630px" }} width={{ base: "630px", lg: "340px" }}>
            <AlertBox
              maxWidth="630px"
              pt="3"
              pb="2"
              mb="4"
              alert={{
                status: alertData.status,
                title: alertData.title,
                message: alertData.message,
              }}
              showCloseButton={true}
              handleClose={() => setShowAlert(false)}
            />
          </Box>
        )}
      </Flex>
      <Box sx={{ maxWidth: "630px" }}>
        {emailTypes.length > 0
          ? emailTypes.map(
              (
                {
                  id,
                  externalId,
                  externalName,
                  defaultForClient,
                }: {
                  id: string;
                  externalId: string;
                  externalName: string;
                  defaultForClient: boolean;
                },
                ind
              ) => (
                <Card key={id} mb="4">
                  <Flex align="center" justify="space-between" width="100%" paddingRight="6">
                    <Box>
                      <CardHeader heading={externalName} paddingBottom="1.5" />
                      <CardBody paddingTop="0" color="gray.300">{`ID: ${externalId}`}</CardBody>
                    </Box>
                    <Toggle
                      toggleName="Set as default"
                      uncheckedTextColor="gray.300"
                      isChecked={!!defaultEmailType ? defaultEmailType === id : defaultForClient}
                      setIsChecked={() => {
                        handleDefaultEmailTypeChange(id);
                      }}
                    />
                  </Flex>
                </Card>
              )
            )
          : !loading && (
              <Card padding="7">
                <Text textAlign="center" fontSize="20px" fontWeight="600">
                  There are no Email Types created yet.
                </Text>
              </Card>
            )}
      </Box>
    </Box>
  );
};

export default EmailTypesTab;
