import { AxiosResponse } from "axios";
import { IToken } from "@mailupinc/bee-plugin/dist/types/bee";

import Model from "models/model";
import { TouchpointVersionAttributes } from "models/touchpoint-version";
import { DEFAULT_TOUCHPOINT_DATA } from "models/touchpoint";

import { LpRedirectionType } from "types/touchpoint";

// N.B
// If you add a new field that can be updated via the builder,
// you MUST add it to the isEqualLpPartial util function
// otherwise, that field change wont trigger autosave
interface LandingPageAttributes extends TouchpointVersionAttributes {
  pageTitle?: string;
  pageDescription?: string;
  url?: string;
  searchVisibility?: boolean;
  redirectDestination?: string;
  redirectDestinationType?: LpRedirectionType;
  disablePrefill?: boolean;
  visibleToClient?: boolean;
}

export const DEFAULT_LP_DATA = DEFAULT_TOUCHPOINT_DATA;

class LandingPage extends Model<LandingPageAttributes> implements LandingPageAttributes {
  // --------- behavior (abstract implementations or custom) --------
  get attributes(): LandingPageAttributes {
    return {
      url: this.url,
      pageDescription: this.pageDescription,
      pageTitle: this.pageTitle,
      searchVisibility: this.searchVisibility,
      redirectDestination: this.redirectDestination,
      redirectDestinationType: this.redirectDestinationType,
      disablePrefill: this.disablePrefill,
      advancedBodySnippet: this.advancedBodySnippet,
      advancedHeadSnippet: this.advancedHeadSnippet,
      archiveLocation: this.archiveLocation,
      data: this.data,
      draftedFromVersion: this.draftedFromVersion,
      finalHtml: this.finalHtml,
      id: this.id,
      lastModifiedDate: this.lastModifiedDate,
      linkParams: this.linkParams,
      lockedBy: this.lockedBy,
      name: this.name,
      preprocessedHtml: this.preprocessedHtml,
      publishedAt: this.publishedAt,
      sourceType: this.sourceType,
      status: this.status,
      theme: this.theme,
      themeId: this.themeId,
      touchpointId: this.touchpointId,
      type: this.type,
      version: this.version,
      versionNotes: this.versionNotes,
      visibleToClient: this.visibleToClient,
    };
  }
  /**
   * Fetches a BEE token specifically for landing pages.
   * @returns A promise that resolves with the full token object.
   */
  static getToken(clientId: string): Promise<IToken> {
    const endpoint = `/bee/clients/${clientId}/token/lp`;

    return LandingPage.connection
      .get(endpoint)
      .then((response: AxiosResponse<IToken>) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Failed to fetch landing page token:", error);
        throw error;
      });
  }

  // -------- proxies to attributes ---------

  get pageDescription() {
    return this._attributes["pageDescription"];
  }

  get pageTitle() {
    return this._attributes["pageTitle"];
  }

  get searchVisibility() {
    return this._attributes["searchVisibility"];
  }

  get url() {
    return this._attributes["url"];
  }

  get redirectDestination() {
    return this._attributes["redirectDestination"];
  }

  get redirectDestinationType() {
    return this._attributes["redirectDestinationType"];
  }

  get disablePrefill() {
    return this._attributes["disablePrefill"];
  }

  get advancedBodySnippet() {
    return this._attributes["advancedBodySnippet"];
  }

  get advancedHeadSnippet() {
    return this._attributes["advancedHeadSnippet"];
  }

  get archiveLocation() {
    return this._attributes["archiveLocation"];
  }

  get data() {
    return this._attributes["data"];
  }

  get draftedFromVersion() {
    return this._attributes["draftedFromVersion"];
  }

  get finalHtml() {
    return this._attributes["finalHtml"];
  }

  get id() {
    return this._attributes["id"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get linkParams() {
    return this._attributes["linkParams"];
  }

  get lockedBy() {
    return this._attributes["lockedBy"];
  }

  get name() {
    return this._attributes["name"];
  }

  get preprocessedHtml() {
    return this._attributes["preprocessedHtml"];
  }

  get publishedAt() {
    return this._attributes["publishedAt"];
  }

  get sourceType() {
    return this._attributes["sourceType"];
  }

  get status() {
    return this._attributes["status"];
  }

  get theme() {
    return this._attributes["theme"];
  }

  get themeId() {
    return this._attributes["themeId"];
  }

  get touchpointId() {
    return this._attributes["touchpointId"];
  }

  get type() {
    return this._attributes["type"];
  }

  get version() {
    return this._attributes["version"];
  }

  get versionNotes() {
    return this._attributes["versionNotes"];
  }

  get visibleToClient() {
    return this._attributes["visibleToClient"];
  }
}

export default LandingPage;
export type { LandingPageAttributes };
