import React from "react";

import Button from "components/forms/button/button";
import RouterLink, { RouterLinkProps } from "components/partials/router-link/router-link";

interface RouterLinkButtonProps extends RouterLinkProps {
  styles?: {};
}

const RouterLinkButton = ({ to, styles, children, ...rest }: RouterLinkButtonProps) => {
  return (
    <RouterLink data-testid="router-link-button" to={to} textDecoration="none" {...rest}>
      <Button variant="link" py={0} {...styles}>
        {children}
      </Button>
    </RouterLink>
  );
};

export default RouterLinkButton;
