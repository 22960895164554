const typography = {
  lineHeights: {
    none: 1,
    shorter: 1.125,
    short: 1.25,
    base: 1.4,
    tall: 1.625,
  },

  fonts: {
    heading: `'Figtree', sans-serif;`,
    body: `'Figtree', sans-serif;`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },

  fontWeights: {
    normal: 300,
    semibold: 400,
    bold: 700,
  },

  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.5rem",
    "2xl": "2rem",
    "3xl": "4rem",
  },
};

export default typography;
