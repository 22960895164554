import { extendTheme } from "@chakra-ui/react";

// Foundational style overrides
import breakpoints from "themes/apollo/breakpoints";
import colors from "themes/apollo/colors";
import components from "themes/apollo/components";
import shadows from "themes/apollo/shadows";
import typography from "themes/apollo/typography";
import zIndices from "themes/apollo/z-index";

// Global style overrides
import styles from "themes/apollo/styles";

const config = {
  initialColorMode: "light",
};

const theme = extendTheme({
  config,
  styles,
  breakpoints,
  colors,
  shadows,
  zIndices,
  ...typography,
  components,
});

export default theme;
