import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, Stack, Icon, Text, StackDivider } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { Span } from "components/partials/typography/typography";
import TruncatedText from "components/partials/truncated-text/truncated-text";
import { CommentIcons } from "containers/admin/clients/touchpoint/components/comments/comment-icons/comment-icons";
import { CommentInput } from "containers/admin/clients/touchpoint/components/comments/comment-input/comment-input";
import { CommentReplyDisplay } from "containers/admin/clients/touchpoint/components/comments/comment-reply-display/comment-reply-display";
import Button, { ButtonProps } from "components/forms/button/button";
import { CommentDisplayByline } from "containers/admin/clients/touchpoint/components/comments/comment-display-by-line/comment-display-by-line";
import { CommentPopoverButton } from "containers/admin/clients/touchpoint/components/comments/comment-popover-button/comment-popover-button";

import CommentContext from "contexts/comment-context";

import { useCurrentUser } from "state/ducks";

import { dateToLocalTimestamp } from "utilities";
import { hasPermission } from "utilities/user";
import { Route } from "utilities/app-routes";

import { Permission } from "types/auth";
import { Comment, NewComment, CommentReply } from "types/comments";

interface CommentDisplayProps {
  comment: Comment;
  highlight: boolean;
  isClientUser: boolean;
}

export const CommentDisplay = ({ comment, highlight, isClientUser }: CommentDisplayProps) => {
  const { submitNewCommentReply, loadCommentReplies, updateComment } = useContext(CommentContext);
  const [replyInputOpen, setReplyInputOpen] = useState<boolean>(false);
  const [showReplies, setShowReplies] = useState<boolean>(false);
  const [replies, setReplies] = useState<CommentReply[]>([]);
  const currentUser = useCurrentUser();
  const canComment = hasPermission(currentUser, Permission.PERM_COMMENT_WRITE);
  const currentLocation = useLocation().pathname.split("/");
  const canAnnotate = `/${currentLocation[currentLocation.length - 1]}` === Route.preview;

  const handleSubmitReply = (newComment: NewComment): Promise<CommentReply | void> => {
    return submitNewCommentReply(newComment).then((res) => {
      // if replies is not open, then load replies. otherwise, add to replies
      if (!showReplies) {
        setShowReplies(true);
        loadCommentReplies(comment).then((res) => {
          setReplies(res.content);
        });
      } else {
        if (res) {
          setReplies((prevReplies) => [...prevReplies, res]);
        }
      }
    });
  };

  const toggleOpenComment = () => {
    const isFlagged = comment.open ? !comment.actionItem : false;
    updateComment({ ...comment, open: !comment.open, actionItem: isFlagged });
  };
  const toggleFlagComment = () => {
    updateComment({ ...comment, actionItem: !comment.actionItem });
  };
  const toggleHideComment = () => {
    updateComment({ ...comment, hidden: !comment.hidden });
  };

  const handleReplyComment = () => {
    // toggle reply subform open/close
    setReplyInputOpen(!replyInputOpen);
  };

  const toggleShowReplies = () => {
    if (!showReplies) {
      loadCommentReplies(comment).then((res) => setReplies(res.content));
    }
    setShowReplies((showReplies) => !showReplies);
  };

  const createdAtConvertedToDate = new Date(comment.createdDate);

  const Separator = () => <Span mx={1}>•</Span>;

  const CommentDisplayHeader = () => (
    <Flex
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      fontSize="xs"
      letterSpacing="wide"
      py={comment.annotationId ? 2 : 3} //
    >
      {comment.annotationId && comment.badgeNumber && (
        <CommentPopoverButton
          isDisabled={!canAnnotate}
          comment={comment}
          position="relative"
          top={0}
          left={0}
          mr={2}
          onClick={() => () => setShowReplies(false)}
        />
      )}

      <Text color="gray.400">
        {comment.version && `v${comment.version}`}
        {comment.version && <Separator />}
        {createdAtConvertedToDate.toLocaleDateString()} {dateToLocalTimestamp(comment.createdDate)}
      </Text>

      <CommentIcons
        canOpen={canComment && !isClientUser}
        canFlag={canComment && comment.open}
        isChecked={!comment.open}
        isFlagged={comment.actionItem}
        ml="auto"
        toggleFlag={toggleFlagComment}
        toggleCheck={toggleOpenComment}
      />
    </Flex>
  );

  const CommentVisibilityStatus = ({
    isHidden,
    canToggleVisibility,
  }: {
    isHidden?: boolean;
    canToggleVisibility?: boolean;
  }) => {
    const displayStatus = `${isHidden ? "Hidden from client" : "Visible to client"}`;
    return (
      <Text display="flex" alignItems="center" mb={1} fontSize="xs">
        <Icon as={isHidden ? ViewOffIcon : ViewIcon} fontSize="md" mr={1} />
        {displayStatus}
        {canToggleVisibility && <Separator />}
        {canToggleVisibility && (
          <Button
            variant="link"
            textDecoration="underline"
            display="inline"
            cursor="pointer"
            fontSize="inherit"
            fontWeight="normal"
            padding={0}
            height="auto"
            data-testid="hidden-comment-trigger"
            onClick={toggleHideComment}>
            {isHidden ? "Unhide" : "Hide"}
          </Button>
        )}
      </Text>
    );
  };

  const CommentActionTrigger = ({ ...rest }: ButtonProps) => (
    <Button
      variant="link"
      textDecoration="underline"
      display="inline"
      cursor="pointer"
      fontSize="inherit"
      fontWeight="normal"
      padding={0}
      height="auto"
      {...rest}
    />
  );

  return (
    <Box
      data-testid="comment-display"
      // px={6}
      pb={4}
      borderBottom="1px solid"
      borderBottomColor="gray.300"
      backgroundColor={highlight ? "gray.100" : "white"}>
      <Box paddingX={6}>
        <CommentDisplayHeader />

        <Box opacity={comment.open ? 1 : 0.5}>
          <CommentDisplayByline user={comment.user} />
          <Stack
            spacing={2}
            divider={<StackDivider borderColor="gray.200" />}
            pl={6}
            className="content">
            <TruncatedText text={comment.text} noOfLines={4} initialTruncate={true} />

            <Box>
              {/* Status: is comment visible to client? */}
              {!isClientUser && (
                <CommentVisibilityStatus
                  canToggleVisibility={canComment}
                  isHidden={comment.hidden}
                />
              )}

              {/* Reply, Flag and Close Buttons */}
              <HStack spacing={1} fontSize="xs" divider={<Separator />}>
                {canComment && (
                  <CommentActionTrigger
                    onClick={handleReplyComment}
                    children={"Reply"}
                    data-testid="reply-comment-trigger"
                  />
                )}
                {canComment && (
                  <CommentActionTrigger
                    isDisabled={!comment.open}
                    onClick={toggleFlagComment}
                    children={`${comment.actionItem ? "Unflag" : "Flag"} action item`}
                    data-testid="flag-comment-trigger"
                  />
                )}
                {canComment && (
                  <CommentActionTrigger
                    onClick={toggleOpenComment}
                    children={comment.open ? "Close" : "Open"}
                    isDisabled={isClientUser}
                    data-testid="open-comment-trigger"
                  />
                )}
              </HStack>
            </Box>

            {/* Add a Comment Input */}
            {replyInputOpen && (
              <CommentInput
                parentId={comment.id}
                submitNewComment={handleSubmitReply}
                commentType="text-only"
                isClientUser={isClientUser}
                isDisabled={!canComment || !comment.open}
              />
            )}

            {/* Comment Replies */}
            {comment.replyCount > 0 && (
              <Box>
                <AnimatePresence>
                  {showReplies && (
                    <motion.div exit={{ opacity: 0, height: 0 }} transition={{ type: "tween" }}>
                      <Stack spacing={2} mb={2}>
                        {replies.map((reply: CommentReply) => (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: "auto" }}
                            transition={{ type: "tween" }}
                            key={reply.id}>
                            <CommentReplyDisplay key={reply.id} reply={reply} />
                          </motion.div>
                        ))}
                      </Stack>
                    </motion.div>
                  )}
                </AnimatePresence>
                {/* Show / Hide Comment Replies */}
                <CommentActionTrigger
                  as={Flex}
                  alignItems="center"
                  fontWeight="bold"
                  textDecoration="none"
                  fontSize="sm"
                  onClick={toggleShowReplies}>
                  {showReplies ? "Hide" : "Show"} {comment.replyCount} Replies
                  <Icon as={showReplies ? FiChevronUp : FiChevronDown} ml={2} />
                </CommentActionTrigger>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
