import { useHistory, useRouteMatch } from "react-router-dom";
import { HStack } from "@chakra-ui/react";

import PaginatedTable from "components/partials/paginated-table/paginated-table";
import RouterLink from "components/partials/router-link/router-link";
import {
  TableEditButton,
  TableViewButton,
} from "components/table/table-icon-button/table-icon-button";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

import { useCurrentUser } from "state/ducks/users";
import Client from "models/client";

import { Route } from "utilities/app-routes";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { PaginatedRequestOptions } from "types/pagination";

const ClientTable = () => {
  let { url } = useRouteMatch();

  const fetchClients = (options: PaginatedRequestOptions) => {
    return Client.all({ ...options, sort: "name" });
  };

  const renderRow = (client: Client) => (
    <TableRow>
      <TableCell>
        <RouterLink data-testid={`table-link-${client.id}`} to={`${url}/${client.id}`}>
          {client.name}
        </RouterLink>
      </TableCell>
      <TableCell>
        <ClientActions id={client.id} />
      </TableCell>
    </TableRow>
  );

  return (
    <PaginatedTable
      headers={["Client Name", "Actions"]}
      fetchPage={fetchClients}
      renderRow={renderRow}
    />
  );
};

export default ClientTable;

const ClientActions = ({ id }: { id: string }) => {
  let { path } = useRouteMatch();
  const currentUser = useCurrentUser();
  const history = useHistory();

  return (
    <HStack spacing={5}>
      <TableViewButton
        data-testid={`table-action-view-client-${id}`}
        title="View client"
        aria-label="View client"
        onClick={() => history.push(`${path}/${id}`)}
      />
      {hasPermission(currentUser, Permission.PERM_CLIENT_WRITE) && (
        <TableEditButton
          data-testid={`table-action-edit-client-${id}`}
          title="Edit client"
          aria-label="Edit client"
          onClick={() => history.push(`${path}/${id}${Route.profile}${Route.edit}`)}
        />
      )}
    </HStack>
  );
};
