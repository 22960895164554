import { forwardRef, ReactElement } from "react";

import classNames from "classnames";

import { CustomTextInputType } from "./custom-text-input.types";

import Icons from "assets";

const CustomTextInput = forwardRef<HTMLInputElement, CustomTextInputType>(
  (
    {
      customClass = "",
      label = "",
      labelFor = "",
      errorsNames = {},
      helperText = "",
      domainText = "",
      type = "text",
      placeholder = "Type...",
      disabledClassName,
      inputCustomClass = "",
      inputWrapperCustomClass = "",
      labelInTop = false,
      disabledBgColor,
      required,
      disabled,
      ...rest
    },
    ref
  ): ReactElement<any> => {
    const isValid = Object.keys(errorsNames).length === 0;

    const inputStyles = classNames(
      "border border-solid rounded-md p-2.5 focus:outline-none",
      {
        "border-red-01": !isValid,
        "border-gray-05": isValid,
      },
      disabledClassName ? `disabled:${disabledClassName}` : "disabled:bg-transparent",
      inputCustomClass
    );

    const labelStyles = classNames("font-family font-semibold", customClass, {
      "w-32 mr-14 mb-5": !labelInTop,
      "flex mb-1 mt-3": labelInTop,
    });

    return (
      <div className="flex flex-col w-full">
        <div
          className={classNames("flex", {
            "flex-col items-start": labelInTop,
            "items-center": !labelInTop,
          })}>
          {label && (
            <label htmlFor={labelFor} className={labelStyles}>
              {label}
              {required ? (
                <Icons.RequiredIndicator customClass="w-[7px] h-[7px] ml-1 mt-1" />
              ) : null}
            </label>
          )}
          <div className={`flex flex-col w-full ${inputWrapperCustomClass}`}>
            <input
              aria-labelledby="custom-input"
              id={labelFor}
              className={inputStyles}
              ref={ref}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              {...rest}
            />
            {helperText && !disabled && isValid && (
              <span id="custom-input" className="text-sm pt-1 bg-white">
                {`${helperText} ${domainText}`}
              </span>
            )}
            {!isValid && !disabled && (
              <span className="text-sm pt-1 text-red-1 bg-white">{errorsNames.message}</span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomTextInput;
