import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import { AiFillCheckCircle, AiFillFlag } from "react-icons/ai";

export interface CommentIconsProps extends FlexProps {
  canFlag?: boolean;
  canOpen?: boolean;
  isChecked?: boolean;
  isFlagged?: boolean;
  toggleFlag: () => void;
  toggleCheck: () => void;
}

export const CommentIcons = ({
  canFlag,
  canOpen,
  isChecked,
  isFlagged,
  toggleCheck,
  toggleFlag,
  ...rest
}: CommentIconsProps) => {
  const handleToggleOpen = () => {
    canOpen && toggleCheck();
  };

  const handleToggleFlag = () => {
    canFlag && toggleFlag();
  };

  return (
    <Flex fontSize="sm" {...rest}>
      <Icon
        data-testid="comment-icons-flag"
        as={AiFillFlag}
        color={isChecked ? "gray.200" : (isFlagged && "scarlett.dark") || "gray.200"}
        onClick={handleToggleFlag}
        cursor={canFlag ? "pointer" : "not-allowed"}
      />
      <Icon
        data-testid="comment-icons-check"
        as={AiFillCheckCircle}
        color={isChecked ? "success.100" : "gray.200"}
        ml={3}
        onClick={handleToggleOpen}
        cursor={canOpen ? "pointer" : "not-allowed"}
      />
    </Flex>
  );
};
