export interface PaginatedResponse<T> {
  items: T[];
  totalItems: number;
  size: number;
  page: number;
  totalPages: number;
  last: boolean;
}

// TODO some data returns data inside content instead of items
export interface PaginatedContentResponse<T> {
  content: T[];
  totalItems: number;

  size: number;
  page: number;
  totalPages: number;
  last: boolean;
}

export const emptyPaginatedResponse: PaginatedResponse<any> = {
  items: [],
  totalItems: 0,
  size: 0,
  page: 0,
  totalPages: 0,
  last: true,
};

export const emptyPaginatedContentResponse: PaginatedContentResponse<any> = {
  content: [],
  totalItems: 0,
  size: 0,
  page: 0,
  totalPages: 0,
  last: true,
};

export interface PaginatedRequestOptions {
  page?: number;
  size?: number;
  sort?: string;
  field?: string;
  fieldId?: string;
  filter?: string;
  search?: string;
}

export const DEFAULT_PAGINATED_REQUEST_OPTIONS = {
  page: 0,
  size: 25,
};
