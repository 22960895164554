import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { LoadingScreen } from "components/partials/loading-screen/loading-screen";

import { useCurrentClient } from "state/ducks/clients";
import {
  currentBuilderContentSelector,
  setCurrentBuilderContent,
} from "state/ducks/builder-content";

import { Route } from "utilities/app-routes";

import { BuilderContent } from "types";

interface BuilderProviderProps extends React.PropsWithChildren {
  findBuilderValue: (id: string) => Promise<BuilderContent>;
}

const BuilderProvider: React.FC<BuilderProviderProps> = ({ findBuilderValue, children }) => {
  const client = useCurrentClient();
  const { builderId } = useParams<{ builderId: string }>();
  const history = useHistory();
  const builderContent = useSelector(currentBuilderContentSelector);
  const dispatch = useDispatch();

  useEffect(
    function mountBuilder() {
      if (builderId && builderContent?.id !== builderId) {
        findBuilderValue(builderId)
          .then((newContent) => dispatch(setCurrentBuilderContent(newContent.attributes)))
          .catch(() => history.push(Route.notFound));
      }
    },
    // eslint-disable-next-line
    [client, builderId, history, dispatch, builderContent]
  );

  useEffect(() => {
    return () => {
      dispatch(setCurrentBuilderContent(null));
    };
    // eslint-disable-next-line
  }, []);

  return <>{builderContent && builderId === builderContent.id ? children : <LoadingScreen />}</>;
};

export default BuilderProvider;
