import { useState } from "react";
import { useDispatch } from "react-redux";
import { Flex, Box, SimpleGrid, ButtonGroup, Stack, Grid, BoxProps } from "@chakra-ui/react";
import { MdFilterAlt } from "react-icons/md";
import { CheckIcon } from "@chakra-ui/icons";

import { H5 } from "components/partials/typography/typography";
import Button from "components/forms/button/button";
import Link from "components/partials/link/link";
import CheckboxMultiselect from "containers/admin/inbox/components/checkbox-multiselect/checkbox-multiselect";
import ActiveFilterChip from "containers/admin/inbox/components/active-filter-chip/active-filter-chip";
import AsyncCheckboxMultiselect from "containers/admin/inbox/components/async-checkbox-multiselect/async-checkbox-multiselect";
import { PaginationState } from "components/partials/paginated-table/paginated-table";
import { CheckboxOption } from "components/forms/checkbox/checkbox";

import {
  setAllFilters,
  setQuickFilters,
  useAllFilters,
  useQuickFilters,
} from "state/ducks/inbox-filters";
import { useCurrentUser } from "state/ducks";
import { CampaignType } from "models/campaign";
import { Client } from "models";

import {
  createCheckboxArray,
  createCreativeTypeCheckboxArray,
  toCapitalCase,
  toSentenceCase,
} from "utilities";
import { hasPermission } from "utilities/user";

import { StatusesForInboxFilter } from "types";
import { ContentTypeLabels, TouchpointType } from "types/touchpoint";
import { Permission } from "types/auth";
import { FilterTypes, ClientFilterTypes, QuickFilterTypes } from "types/inbox";

interface InboxAllFiltersProps {
  isLoading: boolean;
  paginationState: PaginationState;
}

export const clientUserOptions = [
  FilterTypes.creativeType,
  ClientFilterTypes.programType,
  ClientFilterTypes.campaign,
];

const asyncFilters = [FilterTypes.clients, ClientFilterTypes.campaign, ClientFilterTypes.theme];

export type AllFilter = {
  filterType: FilterTypes | ClientFilterTypes;
  appliedFilters: CheckboxOption[];
};

export default function InboxAllFilters({ isLoading, paginationState }: InboxAllFiltersProps) {
  const dispatch = useDispatch();
  const activeAllFilters = useAllFilters();
  const activeQuickFilters = useQuickFilters();
  const setActiveAllFilters = (updatedValue: AllFilter[]) => {
    paginationState.setPage(0);
    dispatch(setAllFilters(updatedValue));
  };
  const setActiveQuickFilters = (updatedValue: QuickFilterTypes[]) => {
    paginationState.setPage(0);
    dispatch(setQuickFilters(updatedValue));
  };

  const [showMoreFilters, setShowMoreFilters] = useState(false);
  // TODO: implement this
  // const [currentUserOptionsPage, setCurrentUserOptionsPage] = useState<number>(0);

  // Below variables are for the PERM_CLIENT_USER view
  // TODO: Consider breaking this file up into client and staff views.
  // and/or Quick Filters, Applied Filters, All Filters
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);
  const isStaffUser = !isClientUser;

  const canUseFilter = (requirement: FilterTypes | ClientFilterTypes) => {
    return (isClientUser && clientUserOptions.includes(requirement)) || isStaffUser;
  };

  const handleQuickFilterClick = (quickFilter: QuickFilterTypes) => {
    !activeQuickFilters.includes(quickFilter)
      ? // If not filtered by clicked filter, then filter by it
        setActiveQuickFilters([...activeQuickFilters, quickFilter])
      : // If already filtered by clicked filter, remove it
        setActiveQuickFilters(activeQuickFilters.filter((f) => f !== quickFilter));
  };

  const handleClearAll = () => {
    isClientUser
      ? setActiveAllFilters(activeAllFilters.filter((f) => f.filterType === FilterTypes.clients))
      : setActiveAllFilters([]);

    setActiveQuickFilters([]);
  };

  const handleFilterSelect = (
    filterType: FilterTypes | ClientFilterTypes,
    updatedFilterValues: CheckboxOption[]
  ) => {
    const filterExists = activeAllFilters.find((f) => f.filterType === filterType);
    if (filterExists) {
      if (updatedFilterValues.length === 0) {
        if (filterType === FilterTypes.clients) {
          setActiveAllFilters(
            activeAllFilters.filter((f) => {
              return (
                f.filterType !== filterType &&
                f.filterType !== ClientFilterTypes.programType &&
                f.filterType !== ClientFilterTypes.campaign &&
                f.filterType !== ClientFilterTypes.theme
              );
            })
          );
        } else {
          setActiveAllFilters(activeAllFilters.filter((f) => f.filterType !== filterType));
        }
      } else {
        const updatedFilter = {
          filterType: filterType,
          appliedFilters: updatedFilterValues,
        };
        setActiveAllFilters(
          activeAllFilters.map((f) => (f.filterType === filterType ? updatedFilter : f))
        );
      }
    } else {
      setActiveAllFilters([
        ...activeAllFilters,
        { filterType: filterType, appliedFilters: updatedFilterValues },
      ]);
    }
  };

  const findFilterOptions = (filterType: FilterTypes | ClientFilterTypes): CheckboxOption[] => {
    switch (filterType) {
      case FilterTypes.contentTypes:
        return createCheckboxArray(ContentTypeLabels);
      case FilterTypes.clientReviewStatus:
        return createCheckboxArray(StatusesForInboxFilter);
      case FilterTypes.creativeType:
        // Includes only emails and landing pages in the Creative Type filter
        return createCreativeTypeCheckboxArray(TouchpointType).filter(
          (item) => item.value !== TouchpointType.THEME
        );
      case FilterTypes.comments:
        return [
          { label: "Has Open Comments", value: "has-open-comments" },
          { label: "Has Action Items", value: "has-action-items" },
        ];
      case ClientFilterTypes.programType:
        return createCheckboxArray(CampaignType);
      default:
        return [];
    }
  };

  const handleLoadMoreOptions = (filter: FilterTypes | ClientFilterTypes) => {
    return new Promise<{ isEnd: boolean; data: CheckboxOption[] }>((resolve) => {
      setTimeout(() => {
        resolve({
          isEnd: true,
          data: [],
        });
      }, 1000);
    });
  };

  const getOptions = (filterType: FilterTypes | ClientFilterTypes) => {
    switch (filterType) {
      case FilterTypes.clients:
        return () => {
          return new Promise<CheckboxOption[]>((resolve) => {
            Client.all({
              page: 0,
              size: 100,
              sort: "name",
            }).then((res) => {
              resolve(res.items.map((client) => ({ label: client.name, value: client.id })));
            });
          });
        };
      default:
        return () => {
          return new Promise<CheckboxOption[]>((resolve) => {
            setTimeout(() => {
              resolve([]);
            }, 1000);
          });
        };
    }
  };

  const showAppliedFilters = activeQuickFilters.length > 0 || activeAllFilters.length > 0;

  const gridRowGap = 6;

  const usableFilterTypes = Object.values(FilterTypes).filter((filter) => canUseFilter(filter));

  return (
    <Grid
      gridTemplateColumns="max-content 1fr"
      alignItems="baseline"
      rowGap={gridRowGap / 2}
      columnGap={6}
      paddingY={4}>
      <FiltersRow label="Quick Filters">
        {/* TODO: Buttons below need their color fixed*/}
        <Stack spacing={4}>
          <Flex justifyContent="space-between">
            <ButtonGroup spacing={4}>
              {Object.values(QuickFilterTypes).map((filter) => {
                const isToReviewFilter = filter === QuickFilterTypes.toReview;

                // TODO: This feels very error-prone. Separate these cases better.
                const showFilterButton =
                  // ClientUser only sees "To review"
                  (isClientUser && isToReviewFilter) ||
                  // StaffUser does not see "To review"
                  (isStaffUser && !isToReviewFilter);

                if (!showFilterButton) return null;

                const isActiveQuickFilter = activeQuickFilters.includes(filter);
                const filterLabel = toSentenceCase(filter);

                return (
                  <Button
                    data-testid={`${filter}-button`}
                    isDisabled={isLoading}
                    key={filter}
                    leftIcon={isActiveQuickFilter ? <CheckIcon /> : undefined}
                    onClick={() => handleQuickFilterClick(filter)}
                    size="sm"
                    // TODO: Change "navy" to solid and set "navy" as colorScheme
                    variant={isActiveQuickFilter ? "navy" : "secondary"}
                    sx={{
                      "& > .chakra-button__icon": {
                        marginInlineEnd: 1,
                        transform: "auto",
                        translateX: "-2px",
                      },
                    }}>
                    {filterLabel}
                  </Button>
                );
              })}
            </ButtonGroup>
            <Button
              data-testid="all-filters-button"
              boxShadow={showMoreFilters ? "lg" : "sm"}
              variant="tertiary"
              size="sm"
              marginLeft={4}
              leftIcon={<MdFilterAlt />}
              onClick={() => setShowMoreFilters((showMoreFilters) => !showMoreFilters)}>
              All Filters
            </Button>
          </Flex>

          {showMoreFilters && (
            <SimpleGrid
              minChildWidth="200px"
              // gridTemplateColumns="repeat(auto-fit, minmax(200px, 300px))"
              justifyContent="end"
              spacing={4}>
              {usableFilterTypes.length > 0 &&
                usableFilterTypes.map((filter) => (
                  <Box key={filter + "-filter"} data-testid={filter + "-field"} position="relative">
                    {asyncFilters.includes(filter) ? (
                      <AsyncCheckboxMultiselect
                        label={toCapitalCase(filter)}
                        getOptions={getOptions(filter)}
                        loadMoreOptions={() => handleLoadMoreOptions(filter)}
                        selectedOptions={
                          activeAllFilters.find(
                            (selectedFilter) => selectedFilter.filterType === filter
                          )?.appliedFilters || []
                        }
                        setSelectedOptions={(selectedTestOptions) =>
                          handleFilterSelect(filter, selectedTestOptions)
                        }
                      />
                    ) : (
                      <CheckboxMultiselect
                        label={toCapitalCase(filter)}
                        options={findFilterOptions(filter)}
                        selectedOptions={
                          activeAllFilters.find(
                            (selectedFilter) => selectedFilter.filterType === filter
                          )?.appliedFilters || []
                        }
                        setSelectedOptions={(selectedTestOptions) =>
                          handleFilterSelect(filter, selectedTestOptions)
                        }
                      />
                    )}
                  </Box>
                ))}
              {usableFilterTypes.length > 0 &&
                usableFilterTypes.length < 5 &&
                [...Array(5 - usableFilterTypes.length)].map((_) => <Box />)}
            </SimpleGrid>
          )}
        </Stack>
      </FiltersRow>
      {showAppliedFilters && (
        <FiltersRow label="Applied Filters">
          <Flex
            flexWrap="wrap"
            alignItems="baseline"
            sx={{ "& > *": { marginTop: gridRowGap / 2, marginRight: 6 } }}>
            <Link
              data-testid="clear-all-filters"
              textDecoration="underline"
              onClick={handleClearAll}
              whiteSpace="nowrap">
              Clear All
            </Link>
            {activeQuickFilters.map((filter) => (
              <ActiveFilterChip
                key={filter + `-quickfilter`}
                filterType={filter}
                onClick={() => handleQuickFilterClick(filter)}
              />
            ))}
            {activeAllFilters.map((filter) =>
              filter.appliedFilters.map((appliedFilter) => {
                if (canUseFilter(filter.filterType)) {
                  return (
                    <ActiveFilterChip
                      key={filter.filterType + appliedFilter.label}
                      filterType={filter.filterType}
                      appliedFilter={toCapitalCase(appliedFilter.label)}
                      onClick={() =>
                        handleFilterSelect(filter.filterType, [
                          ...filter.appliedFilters.filter((f) => f !== appliedFilter),
                        ])
                      }
                    />
                  );
                }
                return null;
              })
            )}
          </Flex>
        </FiltersRow>
      )}
    </Grid>
  );
}

interface FiltersRowProps extends BoxProps {
  label: string;
}
const FiltersRow = ({ label, children }: FiltersRowProps) => (
  <>
    <H5>{label}</H5>
    {children}
  </>
);
