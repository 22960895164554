import { Button } from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";

import { toSentenceCase } from "utilities";

interface ActiveFilterChipProps {
  filterType: string;
  appliedFilter?: string;
  onClick: () => void;
}

const ActiveFilterChip = ({
  filterType,
  appliedFilter,
  onClick,
  ...rest
}: ActiveFilterChipProps) => {
  return (
    <Button
      data-testid="remove-filter-button"
      onClick={onClick}
      size="sm"
      borderRadius="lg"
      px={2}
      height={6}
      rightIcon={<IoMdClose />}
      boxShadow="sm"
      {...rest}>
      {toSentenceCase(filterType)} {appliedFilter && `: ${appliedFilter}`}
    </Button>
  );
};

export default ActiveFilterChip;
