import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";

import { H4, H5, Small, Span } from "components/partials/typography/typography";
import RouterLink from "components/partials/router-link/router-link";
import { StatusDisplay } from "components/partials/status-display/status-display";

import { Route } from "utilities/app-routes";

const cardBorderRadius = "lg";

interface CardProps extends BoxProps {
  dark?: boolean;
}
const Card = ({ dark = false, children, ...rest }: CardProps) => {
  return (
    <Box
      data-testid="card"
      width="full"
      rounded={cardBorderRadius}
      boxShadow="outer"
      bg={dark ? "bg.dark" : "bg.light"}
      fontSize="sm"
      {...rest}>
      {children}
    </Box>
  );
};

export const CardShowcase = ({ ...rest }: FlexProps) => (
  <Flex data-testid="card-showcase" roundedTop={cardBorderRadius} {...rest} />
);
interface CardHeaderProps extends FlexProps {
  heading: string;
  eyebrow?: string;
  subscript?: string;
  status?: string;
  centered?: boolean;
  editLink?: string;
  statusLiveText?: string;
  statusArchivedText?: string;
  statusDraftText?: string;
  statusArchivedDraftText?: string;
  statusCreatedText?: string;
}
export const CardHeader = ({
  heading,
  eyebrow,
  subscript,
  status,
  centered,
  editLink,
  statusLiveText,
  statusArchivedText,
  statusDraftText,
  statusArchivedDraftText,
  statusCreatedText,
  ...rest
}: CardHeaderProps) => {
  return (
    <Flex
      data-testid="card-header"
      justifyContent={centered ? "center" : "space-between"}
      p={4}
      pb="1.125rem"
      {...rest}>
      <Flex overflow="hidden" flexDirection="column">
        {eyebrow && (
          <H5 data-testid="eyebrow" mb={1}>
            {eyebrow}
          </H5>
        )}
        <Flex>
          <H4 mr={2} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {heading}
          </H4>
          {subscript && (
            <Small data-testid="subscript" fontWeight="normal">
              {subscript}
            </Small>
          )}
        </Flex>
      </Flex>
      {status && (
        <StatusDisplay
          status={status}
          liveText={statusLiveText}
          archivedText={statusArchivedText}
          draftText={statusDraftText}
          archivedDraftText={statusArchivedDraftText}
          createdText={statusCreatedText}
        />
      )}

      {editLink && (
        <RouterLink fontWeight="bold" to={editLink || Route.notFound}>
          Edit
        </RouterLink>
      )}
    </Flex>
  );
};

export const CardBody = ({ ...rest }: BoxProps) => {
  return <Box data-testid="card-body" p={4} {...rest} />;
};

export const CardFooter = ({ ...rest }: BoxProps) => {
  return (
    <Box data-testid="card-footer" py={2} mx={4} borderTop="1px" borderColor="gray.300" {...rest} />
  );
};

export interface CardRowProps extends BoxProps {
  title: string;
  value?: React.ReactNode;
}
export const CardRow = ({ title, value, mb = "4", ...rest }: CardRowProps) => {
  return (
    <Flex data-testid="card-row" justifyContent="space-between" mb={mb} {...rest}>
      <Span fontWeight="bold">{title}</Span>
      {Array.isArray(value) ? (
        <Flex flexDirection="column" alignItems="end">
          {value.map((v, ind) => (
            <Span key={ind} data-testid={`${title}-value`}>
              {v || "undefined"}
            </Span>
          ))}
        </Flex>
      ) : (
        <Span data-testid={`${title}-value`}>{value || "None"}</Span>
      )}
    </Flex>
  );
};

export default Card;
