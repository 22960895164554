import { useEffect, useRef, useState } from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";

import useOnScreen from "hooks/use-on-screen";

interface CommentLoaderProps {
  runOnLoading: () => Promise<boolean>;
}

export const CommentLoader = ({ runOnLoading }: CommentLoaderProps) => {
  const [isEnd, setIsEnd] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (!isEnd && isVisible) {
      runOnLoading().then((isEnd) => {
        setIsEnd(!isEnd);
      });
    }
  }, [isVisible, isEnd, runOnLoading]);

  return (
    <Flex justifyContent="center" alignItems="center" height="200px" ref={ref}>
      {isEnd ? (
        <Text fontSize="xl" fontWeight="bold">
          No more comments
        </Text>
      ) : (
        <Spinner />
      )}
    </Flex>
  );
};
