import { useEffect, useState } from "react";
import { Switch, useRouteMatch, Route, Redirect, useLocation, useHistory } from "react-router-dom";

import Builder from "containers/admin/clients/touchpoint/components/builder/builder";
import LPBuilderDesignTab from "containers/admin/clients/touchpoint/components/lp-builder/lp-builder-design-tab/lp-builder-design-tab";
import LPBuilderContentSettingsTab from "containers/admin/clients/touchpoint/components/lp-builder/lp-builder-content-settings-tab/lp-builder-content-settings-tab";
import LPBuilderPreviewTab from "containers/admin/clients/touchpoint/components/lp-builder/lp-builder-preview-tab/lp-builder-preview-tab";
import {
  BuilderNavTab,
  builderRoutes,
  BuilderTabNav,
} from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";
import { useSaveTouchpoint } from "containers/admin/clients/touchpoint/components/builder/use-save-touchpoint";
import useLPSettingsFields from "containers/admin/clients/touchpoint/components/lp-builder/use-lp-builder-form-fields";

import { Content, ContentWrapper } from "components/partials/layout/layout";
import Form, { FormProps } from "components/forms/form/form";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";

import { useTouchpointBuilderContent } from "state/ducks/builder-content";
import { useCurrentTouchpoint } from "state/ducks";
import LandingPage from "models/landing-page";

import { addAnnotationIdToElements } from "utilities";
import { BLANK_BEE_TEMPLATE } from "utilities/constants";

import { Permission } from "types/auth";

interface LPBuilderProps {
  baseLandingPageUrl: string;
  previewMode: boolean;
}
/**
 * Extends Builder.tsx
 */
const LPBuilder = ({ baseLandingPageUrl, previewMode }: LPBuilderProps) => {
  const touchpointContent = useTouchpointBuilderContent() as LandingPage;
  const currentTouchpoint = useCurrentTouchpoint();

  const formFields = useLPSettingsFields();
  const [invalidTabs, setInvalidTabs] = useState<BuilderNavTab[]>([]);

  const [isNewTouchpoint, setIsNewTouchpoint] = useState(false);
  const saveTouchpoint = useSaveTouchpoint();
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [navigateTo, setNavigateTo] = useState<string>("");

  const history = useHistory();

  useEffect(
    function detectNewTouchpoint() {
      if (!touchpointContent.data?.jsonOutput) {
        console.log("New touchpoint detected");
        setIsNewTouchpoint(true);
      }
    },
    [touchpointContent]
  );

  useEffect(
    function triggerSetInitialValues() {
      if (isNewTouchpoint) {
        handleSetInitialValues();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNewTouchpoint]
  );

  const handleConfirm = () => {
    history.push(navigateTo);
    setShowModal(false);
    setInvalidTabs([]);
    setNavigateTo("");
  };

  async function handleSetInitialValues() {
    setIsSaving(true);

    try {
      const defaultFormValues = formFields.reduce((acc, field) => {
        if (field.defaultValue === undefined || field.defaultValue === null) {
          acc[field.name] = "";
        } else {
          acc[field.name] = String(field.defaultValue);
        }
        return acc;
      }, {} as Record<string, string>);

      const newTouchpoint = {
        data: {
          htmlOutput: "<>",
          jsonOutput: addAnnotationIdToElements(BLANK_BEE_TEMPLATE),
        },
        sourceType: "BEE",
        ...defaultFormValues,
      };

      await saveTouchpoint(newTouchpoint);
      setIsSaved(true);
      setIsNewTouchpoint(false);
    } catch (error) {
      console.log("error saving design", error);
      setIsSaved(false);
    }

    setIsSaving(false);
  }

  const location = useLocation();
  const { path, url } = useRouteMatch();
  const routes = {
    design: `${path}/${builderRoutes.design}`,
    contentSettings: `${path}/${builderRoutes.contentSettings}`,
    preview: `${path}/${builderRoutes.preview}`,
  };

  const lastPathSegment = location.pathname.split("/").pop();

  const currentTab = Object.values(builderRoutes).find(
    (route) => route === lastPathSegment
  ) as BuilderNavTab;

  return (
    <Builder
      data-testid="lp-builder"
      apiValue={touchpointContent}
      contentName={currentTouchpoint.name}
      baseUrl={baseLandingPageUrl}
      previewMode={previewMode}
      permission={Permission.PERM_TOUCHPOINT_WRITE}
      isSaved={isSaved}
      setIsSaved={setIsSaved}
      isSaving={isSaving}>
      <BuilderTabNav
        data-testid="lp-builder--tab-nav"
        isPreviewMode={previewMode}
        url={url}
        invalidTabs={invalidTabs}
        setShowConfirmation={() => setShowModal(true)}
        currentTab={currentTab}
        setNavigateTo={setNavigateTo}
      />

      <ContentWrapper data-testid="lp-builder--content-wrapper">
        <Content
          data-testid="lp-builder--content"
          backgroundColor="bg.content"
          showBreadcrumbs={false}>
          <Switch>
            {!previewMode ? (
              <Redirect exact from={path} to={routes.design} />
            ) : (
              <Redirect exact from={path} to={routes.preview} />
            )}
            {!previewMode && (
              <Route path={routes.design}>
                <LPBuilderDesignTab
                  onSave={saveTouchpoint}
                  setIsSaved={setIsSaved}
                  setIsSaving={setIsSaving}
                />
              </Route>
            )}

            <Route path={routes.contentSettings}>
              <LPBuilderContentSettingsTab
                previewMode={previewMode}
                formFields={formFields}
                onSave={saveTouchpoint}
                setIsSaved={setIsSaved}
                setIsSaving={setIsSaving}
                setInvalidTabs={setInvalidTabs}
              />
            </Route>

            <Route path={routes.preview}>
              <LPBuilderPreviewTab previewMode={previewMode} />
            </Route>
          </Switch>
          <ConfirmationModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            headline="Are you sure you want to leave the tab?"
            message="All invalid fields won't be saved. The form will be saved with the last valid values."
            cancelButtonText="Stay on the tab"
            confirmButtonText="Leave the tab"
            onConfirm={handleConfirm}
            modalType="warning"
          />
        </Content>
      </ContentWrapper>
    </Builder>
  );
};

export default LPBuilder;

export const LPBuilderTabForm = ({ children }: FormProps) => (
  <Form width="2xl" isCentered>
    {children}
  </Form>
);
