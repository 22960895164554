import {
  Flex,
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SystemStyleObject,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export interface PopoverProps extends Omit<ChakraPopoverProps, "children"> {
  arrow?: boolean;
  children?: ReactNode;
  padding?: number;
  triggerElement: ReactNode;
  triggerPosition?: SystemStyleObject;
  width?: string | number;
}

const Popover = ({
  arrow = true,
  children,
  padding = 3,
  placement = "bottom",
  triggerElement,
  triggerPosition,
  width,
  ...rest
}: PopoverProps) => {
  return (
    <ChakraPopover placement={placement} arrowSize={13} {...rest}>
      <PopoverTrigger>
        <Flex sx={triggerPosition}>{triggerElement}</Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent boxShadow="md" width={width}>
          {arrow && <PopoverArrow />}
          <PopoverBody padding={padding} textAlign="center">
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </ChakraPopover>
  );
};

export default Popover;
