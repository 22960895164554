import { useEffect } from "react";

interface useSetPageTitleProps {
  /**
   * Client users will see "Two Ocean" and non-client users will see "Apollo"
   */
  isClientUser: boolean;
  /**
   * Optionally add a page title to the site title:
   * useSetPageTitle({ isClientUser, pageTitle: "Login" }) will return "Two Ocean | Login"
   */
  pageTitle?: string;
}
function useSetPageTitle({ isClientUser, pageTitle }: useSetPageTitleProps) {
  useEffect(() => {
    document.title = (isClientUser ? "Two Ocean" : "Apollo") + (pageTitle ? ` | ${pageTitle}` : "");
  }, [isClientUser, pageTitle]);
}

export default useSetPageTitle;
