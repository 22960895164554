import React from "react";

import {
  forwardRef,
  useTheme,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

import { lightenDarkenColor } from "utilities";

export interface ButtonProps extends ChakraButtonProps {
  variant?: "primary" | "danger" | "secondary" | "tertiary" | "link" | "navy";
}

/** We've gotten better since creating this button and this file now exports a new version. The default export will remain the "LegacyButton" and the named export is the new "Button".
 * ```
 * // Imports `LegacyButton`. Most of the app uses this.
 * import Button from "./lib/Button"
 *
 * // Imports new `Button`, which should be easier to use.
 * import { Button } from "./lib/Button";
 * ``` */
const LegacyButton = ({ variant = "primary", size = "md", ...rest }: ButtonProps): JSX.Element => {
  const theme = useTheme();
  const colorStylesForVariant = (() => {
    return Object.fromEntries(
      Object.entries({
        color: theme.colors.buttons[variant].text,
        backgroundColor: theme.colors.buttons[variant].bg,
        borderColor: theme.colors.buttons[variant][variant === "secondary" ? "link" : "bg"],
      }).filter(([_, color]) => color) // remove color styles not defined in the theme for this variant
    );
  })();
  // for now, one in the same and letting the variable communicate that
  const activeStyles = { ...colorStylesForVariant, boxShadow: variant === "link" ? "none" : "lg" };

  const hoverStyles = {
    ...Object.fromEntries(
      Object.entries(colorStylesForVariant).map(([property, color]) => {
        return [property, lightenDarkenColor(color, 25)];
      })
    ),
    textDecoration: variant === "link" ? "underline" : "none",
  };

  return (
    <ChakraButton
      data-testid="button"
      border="2px solid"
      boxShadow={variant === "link" ? "none" : "sm"}
      fontWeight="bold"
      fontSize={size === "sm" ? "sm" : "md"}
      px={size === "sm" ? 4 : 8}
      py={size === "sm" ? "0.625rem" : "1.5rem"}
      {...colorStylesForVariant}
      _hover={rest.disabled ? {} : { ...hoverStyles }}
      _focus={rest.disabled ? {} : { ...hoverStyles }}
      _active={activeStyles}
      {...rest}
    />
  );
};

export default LegacyButton;

// -------------------------------------------------------
/** New Button below. To use:
 * ```
 * import { Button } from "./lib/Button"
 * ```  */
const BaseButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  return (
    <ChakraButton
      boxShadow={rest.variant !== "link" ? "sm" : undefined}
      colorScheme="aqua"
      fontWeight="bold"
      ref={ref}
      {...rest}
    />
  );
});

const SmallButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  return <BaseButton fontSize="sm" px={4} py={"0.625rem"} ref={ref} {...rest} />;
});

const LargeButton = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  return <BaseButton fontSize="md" px={8} py={6} ref={ref} {...rest} />;
});

export const Button = forwardRef<ChakraButtonProps, "button">(({ ...rest }, ref) => {
  if (rest.size === "sm") {
    return <SmallButton ref={ref} {...rest} />;
  }
  if (rest.size === "lg") {
    return <LargeButton ref={ref} {...rest} />;
  }
  return <BaseButton ref={ref} {...rest} />;
});
