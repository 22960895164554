import { Box, BoxProps, Flex } from "@chakra-ui/react";

import { Span } from "components/partials/typography/typography";
import StatusIndicator from "components/table/table-status-indicator/table-status-indicator";

import { useCurrentUser } from "state/ducks/users";

import { getDisplayStatus } from "utilities";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

interface CardStatusProps extends BoxProps {
  status?: string | null;
  liveText?: string;
  archivedText?: string;
  draftText?: string;
  archivedDraftText?: string;
  createdText?: string;
}

export const StatusDisplay = ({
  status,
  liveText,
  archivedText,
  draftText,
  archivedDraftText,
  createdText,
  color,
  ...rest
}: CardStatusProps) => {
  const currentUser = useCurrentUser();
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  return (
    <Box data-testid="card-status">
      <Flex alignItems="center" {...rest}>
        <StatusIndicator status={status} />
        <Span color={color}>
          {getDisplayStatus({
            status,
            liveText,
            archivedText,
            draftText,
            archivedDraftText,
            createdText,
            isClientUser,
          })}
        </Span>
      </Flex>
    </Box>
  );
};
