import { useState } from "react";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

import AlertBox from "components/partials/alert-box/alert-box";
import { AlertMessage } from "components/partials/flash/flash";
import Input from "components/forms/input/input";

import Form from "components/forms/form/form";
import Label from "components/forms/label/label";
import Select from "components/forms/select/select";
import {
  SidebarGroup,
  ThemeSidebarProps,
} from "containers/admin/clients/client/tab-components/themes-tab/components/theme-builder/theme-builder-sidebars/theme-builder-sidebars";

import { useCurrentClient } from "state/ducks";
import { Theme } from "models";

import {
  editTypekitFonts,
  parseFontWeightOptions,
  parseTypekitResponse,
  WEB_SAFE_FONTS,
} from "utilities/theme";

import { TypekitFont } from "types";

const AdobeFontSidebarGroup = ({ form, readonly }: ThemeSidebarProps) => {
  const currentClient = useCurrentClient();
  const [typekitAlert, setTypekitAlert] = useState<AlertMessage>();

  function dismissTypekitAlert() {
    setTypekitAlert(undefined);
  }

  function handleTypekitProjectIdCall(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    Theme.getTypekitFonts({ clientId: currentClient.id, projectId: form.values.typekitId })
      .then((res) => {
        if (res.kit.families.length <= 0) {
          setTypekitAlert({
            status: "warning",
            title: "We detected 0 fonts",
            message: "Update Adobe to add fonts",
          });
        } else {
          setTypekitAlert({
            status: "success",
            title: `We detected ${res.kit.families.length} fonts.`,
          });
          form.handleComplexChange(
            "typekitFonts",
            parseTypekitResponse(form.values.typekitFonts, res)
          );
        }
      })
      .catch(() => {
        setTypekitAlert({
          status: "error",
          title: "Couldn't load fonts",
          message: "Please check your Project ID and try again",
        });
      });
  }

  function handleFontChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    field: "defaultFontWeight" | "boldFontWeight" | "fallbackFont",
    font: TypekitFont
  ) {
    const newValue = e.target.value;
    const newTypekitFonts = editTypekitFonts(form.values.typekitFonts, field, font, newValue);
    form.handleComplexChange("typekitFonts", newTypekitFonts);
  }

  // test id that is connected to an RTS adobe typekit project: vmf6kwe
  return (
    <SidebarGroup header="Adobe Fonts" testid="adobe-fonts">
      <HStack mb={8} spacing={2}>
        <Form onSubmit={(e) => handleTypekitProjectIdCall(e)}>
          <Input
            label="Adobe Fonts Project ID"
            value={form.values.typekitId}
            onChange={form.handleChange("typekitId")}
            isDisabled={readonly}
            inputRightElement={
              <IconButton
                ml={4}
                aria-label="Submit Typekit Project ID"
                icon={<CheckIcon />}
                type="submit"
                color="green"
              />
            }
          />
          {typekitAlert && (
            <AlertBox mb={8} alert={typekitAlert} handleClose={dismissTypekitAlert} />
          )}
        </Form>
      </HStack>
      {form.values.typekitFonts.map((font) => {
        return (
          <Box key={font.name}>
            <Label value={font.name} />
            <HStack mb={4}>
              <Select
                label="Default weight"
                value={font.defaultFontWeight}
                onChange={(e) => handleFontChange(e, "defaultFontWeight", font)}>
                {parseFontWeightOptions(font.variations).map((weight) => {
                  return (
                    <option key={`font-weight-option-${weight}`} value={weight}>
                      {weight}
                    </option>
                  );
                })}
              </Select>
              <Select
                label="Bold weight"
                value={font.boldFontWeight}
                onChange={(e) => handleFontChange(e, "boldFontWeight", font)}>
                {parseFontWeightOptions(font.variations).map((weight) => {
                  return (
                    <option key={`font-weight-option-${weight}`} value={weight}>
                      {weight}
                    </option>
                  );
                })}
              </Select>
            </HStack>
            <Select
              label="Fallback"
              value={font.fallbackFont}
              onChange={(e) => handleFontChange(e, "fallbackFont", font)}>
              {WEB_SAFE_FONTS.map((font) => {
                return (
                  <option key={font} value={font} data-testid={`option-${font}`}>
                    {font}
                  </option>
                );
              })}
            </Select>
          </Box>
        );
      })}
    </SidebarGroup>
  );
};

export default AdobeFontSidebarGroup;
