import { LabelValuePair, TeamMember } from "types";

export type CommentActionItem = boolean;
export type CommentBadgeNumber = string;
export interface Comment {
  actionItem: CommentActionItem;
  badgeNumber?: CommentBadgeNumber;
  createdDate: Date;
  hidden: boolean; // Hidden from client
  id: string;
  open?: boolean;
  replyCount: number;
  text: string; // Comment text
  user: Partial<TeamMember>;
  version?: string;
  annotationId?: string;
}

export interface CommentFilterOption extends LabelValuePair {}

export interface CommentsSummary {
  commentCount: number;
  actionItemCount: number;
  hiddenCount: number;
  openCount: number;
}

export interface NewComment {
  text: string;
  actionItem?: boolean;
  hidden?: boolean;
  parentId?: string;
  annotationId?: string;
}

export interface CommentReply {
  id?: string;
  user: Partial<TeamMember>;
  text: string; // Comment text
  createdDate: Date;
  parentCommentId: string;
}

export const emptyCommentSummary: CommentsSummary = {
  commentCount: 0,
  actionItemCount: 0,
  hiddenCount: 0,
  openCount: 0,
};

export const commentFilterOptions: CommentFilterOption[] = [
  {
    label: "Open comments",
    value: "open=true",
  },
  {
    label: "Action items only",
    value: "actionItem=true",
  },
  {
    label: "Closed only",
    value: "open=false",
  },
  {
    label: "Visible to client only",
    value: "hidden=false",
  },
  {
    label: "All comment history",
    value: "",
  },
];
