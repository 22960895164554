import { ModalProps } from "@chakra-ui/react";

import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";

import { getDisplayTouchpointType, getTouchpointType } from "utilities";
import { ThemeAttributes } from "models/theme";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";

interface ConfirmArchiveModalProps {
  currentTouchpoint?: TouchpointVersion | TouchpointVersionAttributes | ThemeAttributes;
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onSubmit: () => void;
}

const ConfirmArchiveModal = ({
  currentTouchpoint,
  isOpen,
  onClose,
  onSubmit,
}: ConfirmArchiveModalProps) => {
  if (!currentTouchpoint) return null;

  const touchpointType = getTouchpointType(currentTouchpoint as TouchpointVersion);
  const formattedTouchpointType = getDisplayTouchpointType(touchpointType).toLowerCase();

  const MESSAGE = `Are you sure you want to archive ${formattedTouchpointType} draft version ${currentTouchpoint.version}?`;

  const ACCEPT_BUTTON_TEXT = "Yes, archive draft";
  const REJECT_BUTTON_TEXT = "Nevermind, keep it";

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      headline={MESSAGE}
      message=""
      cancelButtonText={REJECT_BUTTON_TEXT}
      cancelButtonVariant="primary"
      confirmButtonText={ACCEPT_BUTTON_TEXT}
      onConfirm={onSubmit}
      modalType="danger"
    />
  );
};

export default ConfirmArchiveModal;
