import { useContext, useEffect } from "react";
import parse, {
  DOMNode,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";

import CommentableModule from "containers/admin/clients/touchpoint/components/comments/commentable-module/commentable-module";
import { DesignPreviewSandbox } from "containers/admin/clients/touchpoint/components/design-preview-sandbox/design-preview-sandbox";
import { EmailHeaders } from "containers/admin/clients/touchpoint/components/email-builder/email-header-preview/email-header-preview";

import { Table, TableBody, TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

import CommentContext from "contexts/comment-context";

import { BuilderJsonObject, Module, findModules } from "utilities";
import { BEE_EMAIL_SELECTOR } from "utilities/constants";

import { TouchpointPreviewProps } from "types/touchpoint";
import { Element } from "domhandler/lib/node";

interface EmailDesignPreviewProps extends TouchpointPreviewProps {
  emailHeaders: EmailHeaders;
}

function openPreviewLinksInNewTab(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  doc.querySelectorAll("a").forEach((anchor) => {
    const currentTarget = anchor.getAttribute("target");
    if (currentTarget !== "_blank") {
      anchor.setAttribute("target", "_blank");
    }
  });

  return new XMLSerializer().serializeToString(doc);
}

const EmailDesignPreview = ({
  htmlContent,
  htmlJSON,
  isCommentMode = false,
  mobileView = false,
  emailHeaders,
}: EmailDesignPreviewProps) => {
  const parsedJson: BuilderJsonObject = JSON.parse(htmlJSON);

  const modules: Module[] = findModules(parsedJson);
  const { commentData } = useContext(CommentContext);

  const beeEmailBodyTag = htmlContent.match(/<body[^>]*>/g)[0];
  const beeEmailBodyTagIndex = htmlContent.indexOf(beeEmailBodyTag) + beeEmailBodyTag.length;
  const beeBodyEnd = htmlContent.indexOf("</body>");
  const beeEmailWrapperOpen = beeEmailBodyTag.replace("body", "div id='bee-email'");
  const beeEmailWrapperClose = "</div>";

  const beeHeadStart = htmlContent.indexOf("<head>") + "<head>".length;
  const beeHeadEnd = htmlContent.indexOf("</head>");

  useEffect(() => {
    let beeHead = htmlContent.substring(beeHeadStart, beeHeadEnd);
    beeHead = beeHead.replaceAll("body", BEE_EMAIL_SELECTOR);

    if (mobileView) {
      beeHead = beeHead.replace("@media (max-width:520px){", "").replace("}}", "}");
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(beeHead, "text/html");

    const styleTags = doc.querySelectorAll("style");

    styleTags.forEach((style) => {
      document.head.appendChild(style);
    });

    return () => {
      styleTags.forEach((style) => {
        if (document.head.contains(style)) {
          document.head.removeChild(style);
        }
      });
    };
  }, [beeHeadStart, beeHeadEnd, htmlContent, mobileView]);

  const isElement = (domNode: DOMNode): domNode is Element => {
    const isTag = domNode.type === "tag";
    const hasAttributes = (domNode as Element).attribs !== undefined;
    return isTag && hasAttributes;
  };

  /**
   * These are the classNames appended to modules in `EmailBuilderDesignTab` that a user can leave comments on. Spacer modules cannot receive comments. */
  const moduleClassNames = [
    "button_block",
    "heading_block",
    "divider_block",
    "paragraph_block",
    "html_block",
    "icons_block",
    "image_block",
    "menu_block",
    "social_block",
    "text_block",
    "video_block",
    "fromEmail",
    "list_block",
  ];

  let count = 0;

  const CommentableModules: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        !isElement(domNode) ||
        (isElement(domNode) && (!domNode.attribs || !domNode.attribs.class))
      ) {
        return;
      }

      const nodeClassNames = domNode.attribs.class.split(" ");

      if (moduleClassNames.some((className) => nodeClassNames.includes(className))) {
        const props = attributesToProps(domNode.attribs);
        const children = domToReact(domNode.children, CommentableModules);
        const moduleId = modules[count].descriptor.id;
        count++;
        const comment = commentData.content.filter(
          (comment) => comment.annotationId === moduleId
        )[0];

        return (
          <Table className="relative">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Table className="relative pointer" {...props}>
                    {children}
                  </Table>
                  <CommentableModule moduleId={moduleId} comment={comment} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        );
      }
    },
  };

  const beeEmailContent = htmlContent.substring(beeEmailBodyTagIndex, beeBodyEnd);
  const modifiedHtmlContent = openPreviewLinksInNewTab(beeEmailContent);

  const designPreviewContent = parse(
    beeEmailWrapperOpen + modifiedHtmlContent + beeEmailWrapperClose,
    isCommentMode ? CommentableModules : undefined
  );

  return (
    <DesignPreviewSandbox
      data-testid="email-builder__preview-sandbox"
      isCommentMode={isCommentMode}
      isMobileView={mobileView}
      emailHeaders={emailHeaders}>
      {designPreviewContent}
    </DesignPreviewSandbox>
  );
};

export default EmailDesignPreview;
