import React, { useEffect, useRef } from "react";

import {
  PaginationButtons,
  PaginationRange,
  usePaginationState,
} from "components/partials/paginated-table/paginated-table";

import { InboxItem } from "types/inbox";
import { PaginatedResponse } from "types/pagination";
import classNames from "classnames";

interface PaginatedTableWrapperProps extends React.PropsWithChildren<{}> {
  response: PaginatedResponse<InboxItem>;
  paginationState: ReturnType<typeof usePaginationState>;
  isLoading: boolean;
}
export default function PaginatedTableWrapper({
  children,
  response,
  paginationState,
  isLoading,
}: Readonly<PaginatedTableWrapperProps>) {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    tableRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [isLoading, tableRef]);

  const wrapperClasses = classNames("flex", { "flex-1": isLoading });

  return (
    <>
      <PaginationRange response={response} />

      <div className={wrapperClasses}>{children}</div>

      <PaginationButtons response={response} paginationState={paginationState} />
    </>
  );
}
