import { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { RouteMatchTypes } from "containers/admin/clients/campaign/campaign-settings-page/campaign-settings-page";
import { H3 } from "components/partials/typography/typography";
import ThemeDetails from "containers/admin/clients/client/tab-components/themes-tab/components/theme-details/theme-details";
import Breadcrumbs from "components/new/beadcrumbs/breadcrumbs";
import { useCurrentClient } from "state/ducks/clients";

import { usePagePermission } from "hooks/use-page-permission";

import { Permission } from "types/auth";
import { ContentStatus } from "types";

const ThemeNewPage = () => {
  let { params }: RouteMatchTypes = useRouteMatch();
  const currentClient = useCurrentClient();

  usePagePermission(Permission.PERM_THEME_WRITE);

  const [localValues, setLocalValues] = useState({
    name: "",
    description: "",
    status: ContentStatus.DRAFT,
  });

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { label: "Clients", href: "/clients" },
          { label: currentClient?.name, href: `/clients/${params?.clientId}/campaigns` },
          { label: "Theme Configuration", href: `/clients/${params?.clientId}/themes` },
          { label: "Add New Theme", isActive: true },
        ]}
      />
      <H3>Add new theme</H3>
      <ThemeDetails localValues={localValues} setLocalValues={setLocalValues} />
    </>
  );
};

export default ThemeNewPage;
