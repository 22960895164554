import { useEffect, useRef, useState } from "react";
import { InputGroup, BoxProps, Stack, Flex } from "@chakra-ui/react";

import LegacyButton from "components/forms/button/button";
import Input from "components/forms/input/input";

import Checkbox from "components/forms/checkbox/checkbox";
import Form from "components/forms/form/form";
import Textarea from "components/forms/textarea/textarea";

import { Comment, CommentReply, NewComment } from "types/comments";

interface CommentInputProps extends BoxProps {
  openOnDefault?: boolean;
  commentType?: "full" | "text-only";
  parentId?: string;
  submitNewComment: (comment: NewComment) => Promise<Comment | CommentReply | void>;
  isClientUser: boolean;
  isDisabled?: boolean;
}

export const CommentInput = ({
  openOnDefault = false,
  commentType = "full",
  parentId,
  submitNewComment,
  isClientUser,
  isDisabled = false,
  ...rest
}: CommentInputProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(openOnDefault);
  const [comment, setComment] = useState<string>("");
  const [isActionItem, setIsActionItem] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    commentType === "text-only" ? handleTextOnlySubmit() : handleNewCommentSubmit();
  };

  const handleNewCommentSubmit = () => {
    setIsLoading(true);

    const payload: NewComment = {
      actionItem: isActionItem,
      hidden: !isHidden,
      text: comment,
    };

    if (comment.length <= 0) {
      setIsLoading(false);
    }

    submitNewComment(payload);
    setInitialState();
  };

  const handleTextOnlySubmit = () => {
    setIsLoading(true);
    const payload: NewComment = {
      text: comment,
      actionItem: isActionItem,
      parentId: parentId,
    };

    submitNewComment(payload).finally(() => {
      setInitialState();
      setIsLoading(false);
    });
  };

  const setInitialState = () => {
    setIsExpanded(false);
    setComment("");
    setIsActionItem(true);
    setIsHidden(false);
    setIsLoading(false);
  };

  useEffect(() => {
    isExpanded && inputRef.current?.focus();
  }, [isExpanded]);

  return !isExpanded ? (
    <InputGroup {...rest}>
      <Input
        placeholder={commentType === "text-only" ? "Add a reply" : "Add a comment"}
        autoComplete="off"
        onFocus={() => setIsExpanded((isExpanded) => !isExpanded)}
        data-testid="comment-input-collapsed"
        isDisabled={isDisabled}
      />
    </InputGroup>
  ) : (
    <Flex as={Form} spacing={4} flex={1} onSubmit={handleSubmit} {...rest}>
      <Textarea
        ref={inputRef}
        rows={4}
        resize="none"
        isDisabled={isDisabled}
        placeholder={commentType === "text-only" ? "Add a reply" : "Add a comment"}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        data-testid={commentType === "text-only" ? "comment-input-reply" : "comment-input-main"}
        autoComplete="off"
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            handleSubmit(e);
          }
        }}
      />
      {commentType === "full" && !isClientUser && (
        <Stack spacing={1}>
          <Checkbox
            isChecked={isHidden}
            isDisabled={isDisabled}
            onChange={(e) => setIsHidden(e.target.checked)}
            size="sm"
            label="Visible to client"
            data-testid="comment-input-checkbox-hidden"
          />
          <Checkbox
            isChecked={isActionItem}
            isDisabled={isDisabled}
            onChange={(e) => setIsActionItem(e.target.checked)}
            size="sm"
            label="Flag as action item"
            data-testid="comment-input-checkbox-actionitem"
          />
        </Stack>
      )}
      <Flex justifyContent="flex-end" pb={4}>
        <LegacyButton
          size="sm"
          onClick={setInitialState}
          mr={4}
          variant="tertiary"
          data-testid="comment-input-cancel-LegacyButton">
          Cancel
        </LegacyButton>
        <LegacyButton
          size="sm"
          isLoading={isLoading}
          isDisabled={comment === "" || isDisabled}
          data-testid="comment-input-submit-LegacyButton"
          type="submit">
          Submit
        </LegacyButton>
      </Flex>
    </Flex>
  );
};
