import { Id, ToastOptions, toast as toastify, ToastContentProps } from "react-toastify";

import Icons from "assets";

/** Requires *at least* one of `title` or `message` */
type ToastMessaging =
  | {
      title: string;
      message?: string;
    }
  | {
      title?: string;
      message: string;
    };

export type ToastProps = {
  data: ToastMessaging;
} & React.PropsWithChildren;

export const Toast = ({ data: { title, message }, children }: ToastProps) => {
  return (
    <div className="space-y-4">
      <div className="text-default space-y-1 leading-6">
        {title && <p className="font-bold">{title}</p>}
        {message && <p>{message}</p>}
      </div>
      {children && <div>{children}</div>}
    </div>
  );
};

type CustomToastOptions = Omit<ToastOptions, "render"> & {
  data: ToastMessaging;
  render?: (props: ToastContentProps<ToastMessaging>) => React.ReactNode;
};

type ToastService = {
  (options: CustomToastOptions): Id;
  error(options: CustomToastOptions): Id;
  success(options: CustomToastOptions): Id;
  info(options: CustomToastOptions): Id;
  warning(options: CustomToastOptions): Id;
} & Omit<typeof toastify, "error" | "success" | "info" | "warning">;

const toast = ((options: CustomToastOptions): Id => {
  const { render, ...toastOptions } = options;
  return toastify(render ? render : (props) => <Toast data={props.data} />, toastOptions);
}) as ToastService;

Object.assign(toast, toastify);

toast.error = (options: CustomToastOptions): Id => {
  const { render, ...toastOptions } = options;
  return toastify.error(render ? render : (props) => <Toast data={props.data} />, {
    ...toastOptions,
    icon: <Icons.ErrorIndicator />,
  });
};

toast.info = (options: CustomToastOptions): Id => {
  const { render, ...toastOptions } = options;
  return toastify.info(render ? render : (props) => <Toast data={props.data} />, {
    ...toastOptions,
    icon: <Icons.InfoIndicator />,
  });
};

toast.success = (options: CustomToastOptions): Id => {
  const { render, ...toastOptions } = options;
  return toastify.success(render ? render : (props) => <Toast data={props.data} />, {
    ...toastOptions,
    icon: <Icons.SuccessIndicator />,
  });
};

toast.warning = (options: CustomToastOptions): Id => {
  const { render, ...toastOptions } = options;
  return toastify.warning(render ? render : (props) => <Toast data={props.data} />, {
    ...toastOptions,
    icon: <Icons.WarningIndicator />,
  });
};

export default toast;
