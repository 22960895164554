import { useContext } from "react";

import Select from "components/forms/select/select";
import { H4 } from "components/partials/typography/typography";
import { CommentInput } from "containers/admin/clients/touchpoint/components/comments/comment-input/comment-input";
import {
  FABDivider,
  FABHeader,
} from "components/partials/general-comments-fab/general-comments-fab";

import CommentContext from "contexts/comment-context";

import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { CommentFilterOption, commentFilterOptions } from "types/comments";

interface CommentsHeaderProps {
  isClientUser: boolean;
}

export const CommentsHeader = ({ isClientUser }: CommentsHeaderProps) => {
  const currentUser = useCurrentUser();
  const canComment = hasPermission(currentUser, Permission.PERM_COMMENT_WRITE);

  const { commentsSummary, commentFilter, setCommentFilter, submitNewComment } =
    useContext(CommentContext);
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    commentFilterOptions.forEach((option) => {
      if (option.value === e.target.value) {
        setCommentFilter(option);
      }
    });
  };

  return (
    <>
      <FABHeader actionItemCount={commentsSummary.actionItemCount}>
        <Select
          value={commentFilter?.value}
          onChange={handleSelect}
          data-testid="comment-filter-select">
          {commentFilterOptions.map((option: CommentFilterOption) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <H4 color="body" fontSize="sm">
          Viewing {commentsSummary.openCount} open comment
          {commentsSummary.openCount === 1 ? "" : "s"}
        </H4>
      </FABHeader>

      {canComment && (
        <>
          <FABDivider />
          <CommentInput submitNewComment={submitNewComment} isClientUser={isClientUser} />
        </>
      )}
    </>
  );
};
