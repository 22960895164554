import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Button as ChakraButton,
  ChakraProvider,
  Flex,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { Dict } from "@chakra-ui/utils";

import { H5, H1, H2, H3, P } from "components/partials/typography/typography";
import Card, { CardBody, CardShowcase } from "components/partials/card/card";
import Input from "components/forms/input/input";

import { dashCase } from "utilities";

interface PreviewPaneProps {
  theme: Dict<any>;
  readonly: boolean;
}

const PreviewPaneHeader = ({ readonly }: { readonly: boolean }) => (
  <Box data-testid="preview-pane-header">
    {readonly && (
      <Alert status="info" display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Flex>
          <AlertIcon />
          You are in preview mode
        </Flex>
      </Alert>
    )}
    <Flex justify="space-between" align="center" mb={4}>
      <H5 marginRight="auto">Preview</H5>
      <HStack spacing="0">
        {/* <Button variant="link" leftIcon={<DownloadIcon />}>
        Download JSON
      </Button>
      <Button variant="link" leftIcon={<CalendarIcon />} mr={4}>
        Print
      </Button>
      <ButtonGroup>
        <Button size="sm" variant="secondary" isActive>
          All elements
        </Button>
        <Button size="sm" variant="secondary" disabled>
          Landing page
        </Button>
        <Button size="sm" variant="secondary" disabled>
          Email
        </Button>
      </ButtonGroup> */}
      </HStack>
    </Flex>
  </Box>
);

const PreviewPane = ({ theme, readonly }: PreviewPaneProps) => {
  return (
    <>
      <PreviewPaneHeader readonly={readonly} />
      <Box data-testid="preview-pane" boxShadow="inner" flex="1" overflow="auto">
        <ChakraProvider theme={theme}>
          <Box paddingX={6} paddingY={8} bg="pageBackgroundColor">
            <PreviewGroup heading="Typography">
              <Stack spacing={6}>
                <H1
                  fontSize="4rem"
                  fontFamily="h1FontFamily"
                  fontWeight="h1FontWeight"
                  lineHeight="h1LineHeight"
                  color="h1Color">
                  Heading level 1
                </H1>
                <H2
                  fontSize="2rem"
                  fontFamily="h2FontFamily"
                  fontWeight="h2FontWeight"
                  lineHeight="h2LineHeight"
                  color="h2Color">
                  Heading level 2
                </H2>
                <H3
                  fontSize="1.5rem"
                  fontFamily="h3FontFamily"
                  fontWeight="h3FontWeight"
                  lineHeight="h3LineHeight"
                  color="h3Color">
                  Heading level 3
                </H3>
                <P
                  fontSize="1.125rem"
                  fontFamily="bodyFontFamily"
                  fontWeight="bodyFontWeight"
                  lineHeight="bodyLineHeight"
                  color="bodyColor">
                  Here is a paragraph of body copy. Lorem ipsum dolor sit amet. Here is an inline
                  link. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque a consectetur
                  ultrices pellentesque dignissim consequat pretium praesent.
                </P>
              </Stack>
            </PreviewGroup>

            <PreviewGroup heading="Form Fields">
              <HStack spacing={4}>
                <Input
                  width="33%"
                  label="Field label"
                  labelColor="formLabelColor"
                  borderColor="formInputBorderColor"
                  backgroundColor="formInputBackgroundColor"
                  helperText="Helper text goes here"
                />
              </HStack>
            </PreviewGroup>

            <PreviewGroup heading="Colors">
              <Flex>
                {[
                  { colorKey: "primaryColor", label: "Primary" },
                  { colorKey: "secondaryColor", label: "Secondary" },
                  { colorKey: "buttonAndLinkColor", label: "Buttons and Links" },
                  { colorKey: "buttonFontColor", label: "Button Font" },
                ].map(({ colorKey, label }) => (
                  <Card key={colorKey} boxShadow="md" flex="0 1 150px" mr={6}>
                    <CardShowcase bg={colorKey} height="100px" />
                    <CardBody>{label}</CardBody>
                  </Card>
                ))}
              </Flex>
            </PreviewGroup>

            <PreviewGroup heading="Buttons &amp; Links">
              <HStack spacing={4}>
                {/* TODO: Get these values matching the theme */}
                <ChakraButton variant="BeeEditor">Button</ChakraButton>
                <ChakraButton size="lg" variant="link" color="buttonAndLinkColor">
                  Text Link
                </ChakraButton>
              </HStack>
            </PreviewGroup>
          </Box>
        </ChakraProvider>
      </Box>
    </>
  );
};

export default PreviewPane;

// -------------------------------------------------------------

interface PreviewGroupProps extends BoxProps {
  heading: string;
}
const PreviewGroup = ({ heading, children }: PreviewGroupProps) => (
  <Box data-testid={`preview-group-${dashCase(heading)}`} _notFirst={{ mt: 16 }}>
    <H5 color="#5B6470" mb="1.5rem">
      {heading}
    </H5>
    {children}
  </Box>
);
