import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Theme, { ThemeAttributes } from "models/theme";

interface ThemesSlice {
  current: ThemeAttributes | null;
}

const initialState: ThemesSlice = { current: null };

export const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    setCurrentTheme: (state, action: PayloadAction<ThemeAttributes | null>) => {
      state.current = action.payload;
    },
  },
});

export const currentThemeSelector = (state: { themes: ThemesSlice }) => state.themes.current;

export const { setCurrentTheme } = themesSlice.actions;
export default themesSlice.reducer;

export function useCurrentTheme(): Theme {
  const attributes = useSelector(currentThemeSelector);

  if (!attributes) {
    throw new Error("useCurrentTheme: called before a theme was stored in redux");
  }

  return useMemo(() => new Theme(attributes), [attributes]);
}

export type { ThemesSlice };
