import { Flex } from "@chakra-ui/react";

import BeeEditor from "containers/admin/clients/touchpoint/components/bee-editor/bee-editor";

import { useCurrentBuilderContent } from "state/ducks/builder-content";
import LandingPage from "models/landing-page";

import { addAnnotationIdToElements, sanitizeBuilderJson } from "utilities";

import { TouchpointType } from "types/touchpoint";
import { BLANK_BEE_TEMPLATE } from "utilities/constants";

interface LandingPageBuilderDesignTabProps {
  onSave: (field: Record<string, any>) => Promise<void>;
  setIsSaved: (isSaved: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
}

const LPBuilderDesignTab = ({
  onSave,
  setIsSaved,
  setIsSaving,
}: LandingPageBuilderDesignTabProps) => {
  const { attributes: initialAttributes } = useCurrentBuilderContent() as LandingPage;

  const handleDesignChange = async (jsonFile: string) => {
    setIsSaving(true);
    try {
      const annotatedJson = addAnnotationIdToElements(jsonFile);
      const sanitizedJson = sanitizeBuilderJson(annotatedJson);

      const designPayload = {
        data: {
          htmlOutput: "<>",
          jsonOutput: sanitizedJson,
        },
        sourceType: "BEE",
      };

      await onSave(designPayload);
      setIsSaved(true);
    } catch (error) {
      console.log("error saving design", error);
      setIsSaved(false);
    }
    setIsSaving(false);
  };

  return (
    <Flex flex="1" mt={-6}>
      <BeeEditor
        editorType={TouchpointType.LANDING_PAGE}
        initialJson={initialAttributes.data?.jsonOutput ?? BLANK_BEE_TEMPLATE}
        onChange={handleDesignChange}
      />
    </Flex>
  );
};

export default LPBuilderDesignTab;
