import { Base64 } from "js-base64";

import Model, { Unpersisted, WithMaybePersisted } from "models/model";
import ClientContact, { ClientContactAttributes } from "models/client-contact";

import { generatePaginatedQueryString, generateFilterQueryString } from "utilities";

import { TeamMember } from "types";
import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";
import { InboxFilter } from "types/inbox";

export type ClientId = { id: string };

interface ClientDetails {
  apolloDomainUrl?: string;
  clientCode: string;
  collegeUrl?: string;
  name: string;
  primaryContact?: ClientContactAttributes;
  privacyPolicyUrl?: string;
  iterableApiKey?: string;
  iterableProjectId?: string;
  tealiumProfileCode?: string;
  twoOceanTeamMembers?: TeamMember[];
}

interface ClientAttributes extends ClientId, ClientDetails {}

/**
 * Encode Client Payload
 * encode any attributes that will include any text or characters that will trigger a firewall rule
 *s
 * @param attributes attributes to be encoded
 */
const encodeClientPayload = (
  attributes: Partial<ClientAttributes> | Unpersisted<ClientAttributes>
) => {
  const payload: any = { ...attributes };

  const propsToEncode = [
    "bodyFontFamily",
    "h1FontFamily",
    "h2FontFamily",
    "h3FontFamily",
    "inputTextFontFamily",
    "privacyPolicyUrl",
    "collegeUrl",
    "apolloDomainUrl",
  ];

  propsToEncode.forEach((prop) => {
    const propKey = prop as keyof Partial<ClientAttributes>;
    if (payload[propKey]) {
      payload[prop] = Base64.encode(payload[propKey]);
    }
  });

  return payload;
};

class Client extends Model<ClientAttributes> implements ClientAttributes {
  // -------- persistence methods ---------
  static all(
    options: PaginatedRequestOptions = DEFAULT_PAGINATED_REQUEST_OPTIONS
  ): Promise<PaginatedResponse<Client>> {
    const queryString = generatePaginatedQueryString(options);
    return Client.connection.get(`/clients${queryString}`).then((response) => {
      const items = response.data.content
        ? response.data.content.map((attributes: ClientAttributes) => new Client(attributes))
        : [];
      return {
        items: items,
        totalItems: response.data.totalElements,
        size: response.data.size,
        page: response.data.number,
        totalPages: response.data.totalPages,
        last: response.data.last,
      };
    });
  }

  static find(id: string): Promise<Client> {
    return this.connection.get(`/clients/${id}`).then((response) => {
      return new Client(response.data);
    });
  }

  static create(attributes: Unpersisted<ClientAttributes>): Promise<Client> {
    return this.connection.post(`/clients`, encodeClientPayload(attributes)).then((response) => {
      return new Client(response.data);
    });
  }

  static edit({
    id,
    attributes,
  }: {
    id: string;
    attributes: Partial<ClientAttributes>;
  }): Promise<Client> {
    return this.connection
      .patch(`clients/${id}`, encodeClientPayload(attributes))
      .then((response) => {
        return new Client(response.data);
      });
  }

  static replace(
    attributes: WithMaybePersisted<ClientAttributes, "primaryContact">
  ): Promise<Client> {
    return this.connection
      .put(`/clients/${attributes.id}`, encodeClientPayload(attributes))
      .then((response) => {
        return new Client(response.data);
      });
  }

  static delete({ clientId }: { clientId: string }): Promise<void> {
    return this.connection.delete(`clients/${clientId}`).then();
  }

  static updateIterableApiKey(clientId: string, iterableApiKey: string): Promise<Client> {
    return this.connection
      .put(`/clients/${clientId}/iterable-api-key`, { iterableApiKey: iterableApiKey })
      .then((response) => {
        return new Client(response.data);
      });
  }

  static getReviews(
    options: PaginatedRequestOptions = DEFAULT_PAGINATED_REQUEST_OPTIONS,
    filters: InboxFilter
  ): Promise<any> {
    const queryString = generatePaginatedQueryString(options);
    const filtersQuery = generateFilterQueryString(filters);

    return this.connection
      .get(`/touchpoint-version-history${queryString}${filtersQuery}`)
      .then((response) => response);
  }

  static getInbox(
    options: PaginatedRequestOptions = DEFAULT_PAGINATED_REQUEST_OPTIONS,
    filters: InboxFilter
  ): Promise<any> {
    const queryString = generatePaginatedQueryString(options);
    return this.connection.post(`/inbox${queryString}`, filters).then((response) => response);
  }

  // --------- behavior (abstract implementations or custom) --------
  get attributes(): ClientAttributes {
    return {
      id: this.id,
      clientCode: this.clientCode,
      name: this.name,
      primaryContact: this.primaryContact?.attributes,
      privacyPolicyUrl: this.privacyPolicyUrl,
      collegeUrl: this.collegeUrl,
      iterableProjectId: this.iterableProjectId,
      iterableApiKey: this.iterableApiKey,
      apolloDomainUrl: this.apolloDomainUrl,
      tealiumProfileCode: this.tealiumProfileCode,
      twoOceanTeamMembers: this.twoOceanTeamMembers,
    };
  }

  // -------- proxies to attributes ---------
  get id() {
    return this._attributes["id"];
  }

  get clientCode() {
    return this._attributes["clientCode"];
  }

  get name() {
    return this._attributes["name"];
  }

  private _primaryContact?: ClientContact;
  get primaryContact(): ClientContact | undefined {
    if (!this._primaryContact && this._attributes["primaryContact"]) {
      this._primaryContact = new ClientContact(this._attributes["primaryContact"]);
    }

    return this._primaryContact;
  }

  get privacyPolicyUrl() {
    return this._attributes["privacyPolicyUrl"];
  }

  get tealiumProfileCode() {
    return this._attributes["tealiumProfileCode"];
  }

  get collegeUrl() {
    return this._attributes["collegeUrl"];
  }

  get iterableProjectId() {
    return this._attributes["iterableProjectId"];
  }

  get iterableApiKey() {
    return this._attributes["iterableApiKey"];
  }

  get apolloDomainUrl() {
    return this._attributes["apolloDomainUrl"];
  }

  get twoOceanTeamMembers() {
    return this._attributes["twoOceanTeamMembers"];
  }
}

export default Client;
export type { ClientAttributes };
