import { useState, useContext, useEffect } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import { CommentPopover } from "containers/admin/clients/touchpoint/components/comments/comment-popover/comment-popover";
import { CommentPopoverButtonProps } from "containers/admin/clients/touchpoint/components/comments/comment-popover-button/comment-popover-button";

import CommentContext from "contexts/comment-context";

import { BEE_COMMENT_WRAPPER_CLASSNAME } from "utilities/constants";

import { Comment } from "types/comments";

export interface CommentableComponentProps extends BoxProps {
  moduleId: string;
  comment: Comment | undefined;
  commentTriggerProps?: CommentPopoverButtonProps;
}

const CommentableModule = ({
  moduleId,
  comment,
  commentTriggerProps,
}: CommentableComponentProps) => {
  const {
    openCommentAnnotationId,
    setOpenCommentAnnotationId,
    newCommentModuleId,
    setNewCommentModuleId,
  } = useContext(CommentContext);

  const [isCommentPopoverOpen, setisCommentPopoverOpen] = useState(false);
  const [isModuleHovered, setIsModuleHovered] = useState(false);

  /* A user is interacting with this module.
   * It's hovered or the comment box is open. */
  const [isModuleActive, setIsModuleActive] = useState(false);

  // useEffect(() => {
  //   // Focus on comment input comment is expanded
  //   isCommentInputExpanded && commentInputRef.current?.focus();
  // }, [isCommentInputExpanded]);

  const boxStyles: BoxProps = {
    // cursor: "pointer",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    outline: "2px solid",
    outlineColor: isModuleActive ? "blue.500" : "transparent",
    // If there are overlap conflicts, set zIndex="overlay"
    zIndex: "docked",
  };

  function closeCommentPopover() {
    if (openCommentAnnotationId) {
      setOpenCommentAnnotationId(undefined);
    }
    if (newCommentModuleId) {
      setNewCommentModuleId(undefined);
    }
  }

  function toggleCommentPopover() {
    if (openCommentAnnotationId || newCommentModuleId) {
      closeCommentPopover();
    }
    /** Setting these context values will trigger `setIsCommentOpen` via useEffect() */
    return comment?.annotationId
      ? setOpenCommentAnnotationId(comment.annotationId)
      : setNewCommentModuleId(moduleId);
  }

  /** If `isCommentPopoverOpen`, Chakra Popover's `onClose()` will be used. */
  function handleModuleClick() {
    if (!isCommentPopoverOpen) {
      toggleCommentPopover();
    }
  }

  useEffect(
    /** `CommentPopover` will show or hide based on the values in `CommentContext` */
    function handleCommentPopoverVisibility() {
      /** Modules with comments are managed here. */
      if (comment) {
        // A brand new comment has just been born.
        if (newCommentModuleId) {
          setOpenCommentAnnotationId(newCommentModuleId);
        }
        return setisCommentPopoverOpen(openCommentAnnotationId === comment?.annotationId);
      }

      /** Modules that don't yet have comments are managed here. */
      setisCommentPopoverOpen(newCommentModuleId === moduleId);
    },
    [comment, moduleId, newCommentModuleId, openCommentAnnotationId, setOpenCommentAnnotationId]
  );

  useEffect(() => {
    /**
     * The user is interacting with this module. Use `isModuleActive` to connect any styles or behaviors to this moment. */
    setIsModuleActive(isCommentPopoverOpen || isModuleHovered);
  }, [isModuleHovered, isCommentPopoverOpen]);

  return (
    <Box
      className={BEE_COMMENT_WRAPPER_CLASSNAME}
      onClick={handleModuleClick}
      onMouseOver={() => setIsModuleHovered(true)}
      onMouseLeave={() => setIsModuleHovered(false)}
      pointerEvents={isCommentPopoverOpen ? "none" : "all"}
      {...boxStyles}>
      <CommentPopover
        moduleId={moduleId}
        comment={comment}
        isModuleHovered={isModuleHovered}
        isCommentPopoverOpen={isCommentPopoverOpen}
        closeCommentPopover={closeCommentPopover}
        toggleCommentPopover={toggleCommentPopover}
        commentTriggerProps={{
          ...commentTriggerProps,
          pointerEvents: "all",
          isTriggerActive: isModuleActive,
        }}
      />
    </Box>
  );
};

export default CommentableModule;
