import { StackProps, Spinner, Stack } from "@chakra-ui/react";

import { Strong } from "components/partials/typography/typography";
import { TableRow } from "components/table/table";
import { TableCell } from "components/table/table-cell/table-cell";

interface TableLoaderProps extends StackProps {
  colSpan: number;
}

export const TableLoader = ({ colSpan, ...rest }: TableLoaderProps) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className="relative p-0">
        <Stack alignItems="center" color="text.link" {...rest}>
          <Spinner size="xl" thickness="5px" emptyColor="bg.dark" speed="0.65s" />
          <Strong>Loading...</Strong>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
