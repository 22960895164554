import { FormErrorMessage, FormErrorMessageProps } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import { Span } from "components/partials/typography/typography";
import { HelpMessage } from "components/forms/helper-text/helper-text";

interface ErrorMessageProps extends FormErrorMessageProps {
  message?: HelpMessage;
}
const ErrorMessage = ({ message, ...rest }: ErrorMessageProps) => {
  if (!message) {
    return null;
  }
  return (
    <FormErrorMessage data-testid="error-message" color="wrong.text" lineHeight="none" {...rest}>
      <WarningIcon mr={1} />
      <Span>{message}</Span>
    </FormErrorMessage>
  );
};

export default ErrorMessage;
