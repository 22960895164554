// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import { init as initFullStory } from "@fullstory/browser";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "config/auth-config";
import { App } from "app";
import * as serviceWorker from "serviceWorker";

import "styles/index.css";

initFullStory({ orgId: "o-1CBCVA-na1" });

export const msalInstance = new PublicClientApplication(msalConfig);

// Ensure proper async initialization
const initializeApp = async () => {
  await msalInstance.initialize();

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const rootElement = document.getElementById("root");
  if (!rootElement) throw new Error("Root element not found");

  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App publicClientApplication={msalInstance} />
      </MsalProvider>
    </React.StrictMode>
  );
};

// Initialize with error handling
initializeApp().catch((error) => {
  console.error("Failed to initialize the application:", error);
});

serviceWorker.unregister();
