import { Box } from "@chakra-ui/react";

import { ContentStatus, ClientReviewStatus } from "types";

interface StatusIndicatorProps {
  status?: string | null;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const getBackgroundColor = () => {
    switch (status) {
      case ContentStatus.CREATED:
      case ContentStatus.DRAFT:
      case ClientReviewStatus.DRAFT:
      case ClientReviewStatus.INTERNAL_REVIEW:
      case ClientReviewStatus.PROOFING:
      case ClientReviewStatus.CLIENT_REVIEW:
      case ClientReviewStatus.QA:
        return "warning.100";
      case ContentStatus.PUBLISHED:
      case ClientReviewStatus.PUBLISHED:
      case ClientReviewStatus.APPROVED:
        return "success.100";
      default:
        return "gray.300";
    }
  };

  return <Box borderRadius="full" bgColor={getBackgroundColor()} width={4} height={4} mr={1} />;
};

export default StatusIndicator;
