import { H4 } from "components/partials/typography/typography";
import { TableCell } from "components/table/table-cell/table-cell";

import { ContentStatus } from "types";

interface StatusColumnProps {
  id: string;
  status?: string;
  version?: string;
  lastModifiedDate: string;
}

export const StatusColumn = ({ id, status, version, lastModifiedDate }: StatusColumnProps) => {
  const getTouchpointVersionText = () => {
    if (status === ContentStatus.ARCHIVED) {
      return "Archived";
    }
    if (status === ContentStatus.ARCHIVED_DRAFT) {
      return "Draft Archived";
    }
    if (status === ContentStatus.DRAFT) {
      return "Last edited";
    }
    if (status === ContentStatus.PUBLISHED) {
      return "Published";
    }
    // This will probably never be shown since versions with status "CREATED" aren't technically drafts.
    if (status === ContentStatus.CREATED) {
      return "Created";
    }
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(new Date(lastModifiedDate));

  return (
    <TableCell>
      <div className="flex flex-col">
        {version && <H4 data-testid={`${id}-table-version`}>v{version}</H4>}
        <span>{getTouchpointVersionText()}</span>
        <span data-testid={`${id}-table-last-modified`}>{formattedDate}</span>
      </div>
    </TableCell>
  );
};
