import { AddIcon } from "@chakra-ui/icons";

import Button, { ButtonProps } from "components/forms/button/button";

export const AddButton = (props: ButtonProps) => {
  return (
    <Button data-testid="add-button" leftIcon={<AddIcon />} {...props}>
      Add new
    </Button>
  );
};
