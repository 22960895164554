import { useEffect, useRef, useState } from "react";
import { Flex, Spinner } from "@chakra-ui/react";

import useOnScreen from "hooks/use-on-screen";

interface OptionLoaderProps {
  runOnLoading: () => Promise<boolean>;
}

export const OptionLoader = ({ runOnLoading }: OptionLoaderProps) => {
  const [isEnd, setIsEnd] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (!isEnd && isVisible) {
      runOnLoading().then((isEnd) => {
        setIsEnd(!isEnd);
      });
    }
  }, [isVisible, isEnd, runOnLoading]);

  return (
    <Flex justifyContent="center" hidden={isEnd} alignItems="center" height="200px" ref={ref}>
      {!isEnd && <Spinner />}
    </Flex>
  );
};
