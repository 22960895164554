import { Flex, FlexProps } from "@chakra-ui/react";

export interface FullPageOverlayProps extends FlexProps {}

export const FullPageOverlay = ({ ...rest }: FullPageOverlayProps) => {
  return (
    <Flex
      direction="column"
      height="full"
      width="full"
      zIndex="overlay"
      position="absolute"
      top={0}
      left={0}
      {...rest}
    />
  );
};
