import React from "react";
import { Stack, StackProps } from "@chakra-ui/react";

import Legend from "components/partials/legent/legend";

interface FormGroupProps extends StackProps {
  legend?: string;
  legendSize?: "sm" | "lg";
  spacing?: number;
  direction?: "column" | "row";
  children: React.ReactNode;
}

const FormGroup = ({
  legend,
  legendSize = "lg",
  spacing = 8,
  direction = "column",
  children,
  ...rest
}: FormGroupProps) => {
  const headerMargin = spacing / 2;
  return (
    <Stack as="fieldset" spacing={spacing} direction={direction} {...rest}>
      {legend && (
        <Legend size={legendSize} mb={direction === "column" ? -headerMargin : headerMargin}>
          {legend}
        </Legend>
      )}
      {children}
    </Stack>
  );
};

export default FormGroup;
