import { useState, useEffect, useCallback } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

import PageContentHeader from "components/partials/page-content-header/page-content-header";
import { Content } from "components/partials/layout/layout";
import ClientSecondaryNav from "containers/admin/clients/client/components/client-secondary-nav/client-secondary-nav";
import { AddButton } from "components/partials/add-button/add-button";
import PaginatedTable, {
  usePaginationState,
} from "components/partials/paginated-table/paginated-table";
import { H2 } from "components/partials/typography/typography";
import Search from "components/new/custom-search/custom-search";
import RadioGroup from "components/partials/TW/radio-group/radio-group";
import CampaignRow from "containers/admin/clients/client/tab-components/campaigns-tab/campaign-row";

import { useCurrentUser } from "state/ducks/users";
import { useCurrentClient } from "state/ducks/clients";
import Campaign, { CampaignType } from "models/campaign";

import { hasPermission } from "utilities/user";
import { getCampaignType, getDisplayCampaignType } from "utilities";
import { usePermissionBlacklist } from "hooks/use-permission-blacklist";

import { PaginatedRequestOptions, PaginatedResponse } from "types/pagination";
import { Permission } from "types/auth";
import { ChildPageProps } from "types";
import { ITableColumn } from "components/table/table-header/table-header";

interface ClientCampaignsIndexProps extends ChildPageProps {}

const defaultSortingParams = "lastModifiedDate,desc";

export type campaignTableColumn = "name" | "programType" | "lastModifiedDate" | "actions";

const headers: ITableColumn<campaignTableColumn>[] = [
  { id: "name", label: "Campaign name", isSortable: true },
  { id: "programType", label: "Program Type", isSortable: true },
  { id: "lastModifiedDate", label: "Last updated", isSortable: true },
  { id: "actions", label: "Actions", isSortable: false },
];

const CampaignsTab = ({ parentUrl }: ClientCampaignsIndexProps) => {
  const [shouldRefreshTable, setShouldRefreshTable] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [tableSortedBy, setTableSortedBy] = useState<campaignTableColumn>("lastModifiedDate");
  const [tableSortedAsc, setTableSortedAsc] = useState<boolean>(false);
  const [currentSortOrder, setCurrentSortOrder] = useState(defaultSortingParams);
  const paginationState = usePaginationState();

  const { url } = useRouteMatch();
  const currentUser = useCurrentUser();
  const currentClient = useCurrentClient();
  const [campaignDisplayType, setCampaignDisplayType] = useState<CampaignType>();
  usePermissionBlacklist([Permission.PERM_CLIENT_USER]);

  const campaignTypeOptions = [
    { label: "All", value: "All" },
    { label: getDisplayCampaignType(CampaignType.A2A), value: CampaignType.A2A },
    { label: getDisplayCampaignType(CampaignType.A2E), value: CampaignType.A2E },
  ];

  const handleTableSort = (columnId: campaignTableColumn) => {
    columnId !== tableSortedBy
      ? setTableSortedBy(columnId)
      : setTableSortedAsc((sortAsc) => !sortAsc);
  };

  const refreshColumnSort = useCallback(() => {
    return `${tableSortedBy},${tableSortedAsc ? "asc" : "desc"}`;
  }, [tableSortedAsc, tableSortedBy]);

  function handleProgramTypeToggle(option: string) {
    if (option === "All") {
      setCampaignDisplayType(undefined);
    } else if (getCampaignType(option) === CampaignType.A2A) {
      setCampaignDisplayType(CampaignType.A2A);
    } else if (getCampaignType(option) === CampaignType.A2E) {
      setCampaignDisplayType(CampaignType.A2E);
    }
  }

  const fetchCampaigns = (
    options: PaginatedRequestOptions
  ): Promise<PaginatedResponse<Campaign>> => {
    return Campaign.all({
      options: { ...options, search, sort: currentSortOrder },
      clientId: currentClient.id,
      programType: campaignDisplayType,
    });
  };

  useEffect(() => {
    setCurrentSortOrder(refreshColumnSort());
  }, [tableSortedBy, tableSortedAsc, refreshColumnSort]);

  useEffect(() => {
    if (paginationState.page !== 0) {
      paginationState.setPage(0); // Reset to first page when filters or search term changes
    } else {
      setShouldRefreshTable(true);
    }
    // eslint-disable-next-line
  }, [search, campaignDisplayType, currentSortOrder]);

  const renderRow = (campaign: Campaign) => {
    return <CampaignRow campaign={campaign} />;
  };

  return (
    <>
      <ClientSecondaryNav parentUrl={parentUrl} />
      <Content data-testid="campaigns-tab" showBreadcrumbs={false}>
        <PageContentHeader direction="column">
          <div className="flex justify-between w-full mb-4">
            <H2>Campaigns</H2>
            <Link to={url + "/new"}>
              {hasPermission(currentUser, Permission.PERM_CAMPAIGN_WRITE) && (
                <AddButton
                  leftIcon={<AddIcon />}
                  data-testid="add-theme-button"
                  sx={{ py: "20px", px: "28px" }}>
                  Add new
                </AddButton>
              )}
            </Link>
          </div>

          <Search
            {...{
              search,
              setSearch,
              customClass: "mb-4 w-full hidden", // Remove "hidden" when BE will be ready
            }}
          />
        </PageContentHeader>

        <RadioGroup
          options={campaignTypeOptions}
          groupName="campaignType"
          groupLabel="Filter by touchpoint type"
          onChange={handleProgramTypeToggle}
          defaultSelectedValue={campaignTypeOptions[0].value}
        />

        <PaginatedTable<Campaign, campaignTableColumn>
          headers={headers}
          fetchPage={fetchCampaigns}
          shouldRefresh={shouldRefreshTable}
          setShouldRefresh={setShouldRefreshTable}
          currentSortOrder={currentSortOrder}
          handleTableSort={handleTableSort}
          renderRow={renderRow}
        />
      </Content>
    </>
  );
};

export default CampaignsTab;
