import { useState, useContext } from "react";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";

import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import Button from "components/forms/button/button";
import Textarea from "components/forms/textarea/textarea";

import CommentContext from "contexts/comment-context";

import { ClientReviewStatus } from "types";

interface TouchpointApproveRejectProps {
  isSendBtnLoading: boolean;
  approvePublishLoading: boolean;
  onClick: (status: string) => void;
}

const TouchpointApproveSendFeedback = ({
  onClick,
  approvePublishLoading,
  isSendBtnLoading,
}: TouchpointApproveRejectProps) => {
  return (
    <Flex>
      <Box mr="4">
        <TouchpointSendFeedbackButton onSendFeedback={onClick} isLoading={isSendBtnLoading} />
      </Box>
      <TouchpointApproveButton onApprove={onClick} isLoading={approvePublishLoading} />
    </Flex>
  );
};

export default TouchpointApproveSendFeedback;

interface TouchpointSendFeedbackButtonProps {
  onSendFeedback: (status: string) => void;
  isLoading: boolean;
}

const TouchpointSendFeedbackButton = ({
  onSendFeedback,
  isLoading,
}: TouchpointSendFeedbackButtonProps) => {
  const confirmModal = useDisclosure();
  const [comment, setComment] = useState("");
  const { submitNewComment } = useContext(CommentContext);

  const handleSendFeedback = () => {
    onSendFeedback(ClientReviewStatus.REJECTED);

    if (!!comment) {
      const payload = {
        actionItem: true,
        text: comment,
      };

      submitNewComment(payload).catch((err: any) => {
        console.log(err);
      });
    }

    confirmModal.onClose();
  };

  const handleChange = (event: any) => {
    setComment(event.target.value);
  };

  return (
    <>
      <Button
        isLoading={isLoading}
        variant="link"
        padding={1}
        onClick={() => {
          confirmModal.onOpen();
        }}
        aria-label="Send Feedback">
        Send Feedback
      </Button>
      <ConfirmationModal
        {...confirmModal}
        headline="Ready to send feedback?"
        message="If you have any additional comments, please add it here. Once our team reviews your feedback, we'll send a revised version back for your team's approval."
        cancelButtonText="Cancel"
        confirmButtonText="Send feedback"
        onConfirm={handleSendFeedback}
        onClose={() => {
          confirmModal.onClose();
        }}
        modalType="warning">
        <Textarea
          isReadOnly={false}
          label=""
          helperText=""
          value={comment}
          placeholder="Type..."
          onChange={(e) => {
            handleChange(e);
          }}
          data-testid="send-feedback-teaxtarea"
        />
      </ConfirmationModal>
    </>
  );
};

interface TouchpointApproveButtonProps {
  onApprove: (status: string) => void;
  isLoading: boolean;
}

const TouchpointApproveButton = ({ onApprove, isLoading }: TouchpointApproveButtonProps) => {
  const confirmModal = useDisclosure();

  const openConfirmationModal = () => confirmModal.onOpen();
  const [comment, setComment] = useState<string>("");
  const { submitNewComment } = useContext(CommentContext);

  const handleChange = (event: any) => {
    setComment(event.target.value);
  };

  const handleApprove = () => {
    onApprove(ClientReviewStatus.APPROVED);

    if (!!comment) {
      const payload = {
        actionItem: true,
        text: comment,
      };
      submitNewComment(payload).catch((err: any) => {
        console.log(err);
      });
    }

    confirmModal.onClose();
  };

  return (
    <>
      <Button size="sm" variant="primary" onClick={openConfirmationModal} isLoading={isLoading}>
        Approve
      </Button>
      <ConfirmationModal
        {...confirmModal}
        headline="Ready for final approval?"
        message="If you have any additional comments or feedback before this creative goes live, please add it here. Once we receive this approval, our team will move this through our QA and deployment process."
        cancelButtonText="Cancel"
        confirmButtonText="Approve"
        onConfirm={handleApprove}
        onClose={() => {
          confirmModal.onClose();
        }}
        modalType="warning">
        <Textarea
          isReadOnly={false}
          label=""
          helperText=""
          value={comment}
          placeholder="Type..."
          onChange={(e) => {
            handleChange(e);
          }}
          data-testid="send-feedback-teaxtarea"
          // need to add ref to the input to focus on it
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleApprove();
            }
          }}
        />
      </ConfirmationModal>
    </>
  );
};
