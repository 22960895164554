import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, Stack } from "@chakra-ui/react";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import AppRoutesWrapper from "containers/app-routes-wrapper";
import Fonts from "components/partials/fonts/fonts";
import ToastContainer from "components/partials/toast/toast-container";

import store from "state";

import theme from "themes/apollo";

type AppProps = {
  publicClientApplication: IPublicClientApplication;
};

export const App = ({ publicClientApplication }: AppProps) => {
  return (
    <MsalProvider instance={publicClientApplication}>
      <ReduxProvider store={store}>
        <ToastContainer />
        <ChakraProvider theme={theme}>
          <Stack height="full" spacing={0}>
            <Fonts />
            <BrowserRouter>
              <AppRoutesWrapper />
            </BrowserRouter>
          </Stack>
        </ChakraProvider>
      </ReduxProvider>
    </MsalProvider>
  );
};
