import { useState } from "react";
import { Box } from "@chakra-ui/react";

import RouterLink from "components/partials/router-link/router-link";
import UserAvatar from "components/partials/user-avatar/user-avatar";
import Popover from "components/partials/popover/popover";

import { Route as AppRoute } from "utilities/app-routes";

const UserMenu = () => {
  const logout = () => {
    window.location.replace(AppRoute.logout);
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Popover
      data-testid="user-menu"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      width="200px"
      placement="bottom-start"
      triggerElement={
        <Box onClick={toggleIsOpen} role="button" _hover={{ cursor: "pointer" }}>
          <UserAvatar as={Box} name="" src="" w={8} h={8} />
        </Box>
      }>
      <RouterLink onClick={logout} to="#" fontWeight="bold" textDecoration="none" px={4}>
        Sign Out
      </RouterLink>
    </Popover>
  );
};

export default UserMenu;
