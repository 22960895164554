import { useRouteMatch, useHistory } from "react-router-dom";
import { VStack } from "@chakra-ui/react";

import ClientTable from "containers/admin/clients/clients-admin-table";
import PageContent from "components/partials/page-content/page-content";
import { AddButton } from "components/partials/add-button/add-button";
import { H2 } from "components/partials/typography/typography";
import PageContentHeader from "components/partials/page-content-header/page-content-header";

import { useCurrentUser } from "state/ducks/users";

import { Route as AppRoute } from "utilities/app-routes";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

const ClientsAdminPage = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const { path } = useRouteMatch();

  if (hasPermission(currentUser, Permission.PERM_CLIENT_USER)) {
    return null;
  }
  return (
    <PageContent showBreadcrumbs={false}>
      <VStack align="stretch">
        <PageContentHeader marginY={0}>
          <H2>Clients</H2>
          {hasPermission(currentUser, Permission.PERM_CLIENT_WRITE) && (
            <AddButton
              onClick={() => history.push(`${path}${AppRoute.new}`)}
              sx={{ py: "20px", px: "28px" }}
            />
          )}
        </PageContentHeader>
        <ClientTable />
      </VStack>
    </PageContent>
  );
};

export default ClientsAdminPage;
