import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  ModalProps,
  Modal,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  HStack,
} from "@chakra-ui/react";

import Input from "components/forms/input/input";

import Textarea from "components/forms/textarea/textarea";
import { H3, H5, Span } from "components/partials/typography/typography";
import { Button } from "components/forms/button/button";

import { useCurrentUser } from "state/ducks";

import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";

export interface onSubmitFormProps {
  id?: string;
  version: string;
  versionNotes: string;
  visibleToClient?: boolean;
}

interface FormData {
  versionNotes: string;
}

export enum FormType {
  NEW = "new",
  EDIT = "edit",
  NEW_VERSION = "newVersion",
}

export enum ReleaseType {
  MAJOR = "Major",
  MINOR = "Minor",
}

interface VersionEditModalProps {
  baseUrl: string;
  parentName?: string;
  formType: FormType;
  helperText?: string;
  id?: string;
  isOpen: ModalProps["isOpen"];
  name?: string;
  nextMinorMajorVersions?: string[];
  onClose: ModalProps["onClose"];
  onSubmit?: ({ id, version, versionNotes, visibleToClient }: onSubmitFormProps) => void;
  placeholderNotes?: string;
  saveText?: string;
  status?: string;
  version: string;
  visibleToClient?: boolean;
}

const VersionEditModal = ({
  isOpen,
  onClose,
  version,
  formType,
  name,
  onSubmit,
  nextMinorMajorVersions,
  placeholderNotes = "",
  helperText,
  saveText,
  status,
  parentName,
  id,
  visibleToClient: api_visibleToClient = false,
}: VersionEditModalProps) => {
  const currentUser = useCurrentUser();
  const [visibleToClient, setVisibleToClient] = useState<boolean>(api_visibleToClient);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      versionNotes: placeholderNotes,
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        versionNotes: placeholderNotes || "",
      });
    }
  }, [isOpen, placeholderNotes, reset]);

  useEffect(() => {
    setVisibleToClient(api_visibleToClient);
  }, [api_visibleToClient]);

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        const textarea = document.querySelector(
          '[data-testid="version-modal-input-description"]'
        ) as HTMLTextAreaElement;
        if (textarea) {
          textarea.focus();
        }
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [isOpen]);

  const isNewFormType = formType === FormType.NEW;
  const isEditFormType = formType === FormType.EDIT;
  const isNewVersionFormType = formType === FormType.NEW_VERSION;
  const isClientUser = hasPermission(currentUser, Permission.PERM_CLIENT_USER);

  const [releaseType, setReleaseType] = useState<ReleaseType>(
    isNewFormType ? ReleaseType.MAJOR : ReleaseType.MINOR
  );
  const isMajorRelease = releaseType === ReleaseType.MAJOR;
  const isMinorRelease = releaseType === ReleaseType.MINOR;

  const handleCloseModal = () => {
    setReleaseType(isNewFormType ? ReleaseType.MAJOR : ReleaseType.MINOR);
    reset();
    onClose();
  };

  const handleFormSubmit = (data: FormData) => {
    if (onSubmit) {
      if (isNewFormType || isEditFormType) {
        onSubmit({
          id,
          version,
          versionNotes: data.versionNotes,
          visibleToClient,
        });
      }

      if (isNewVersionFormType && nextMinorMajorVersions && (isMinorRelease || isMajorRelease)) {
        onSubmit({
          id,
          version: nextMinorMajorVersions[isMinorRelease ? 0 : 1],
          versionNotes: data.versionNotes,
          visibleToClient,
        });
      }
    }
    reset();
  };

  const saveButtonText = saveText || "Save draft & continue";

  const isSubmitDisabled = isClientUser || !isValid;

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent borderStyle="solid" borderColor="transparent" borderLeftWidth={8}>
        <Box>
          <ModalHeader pt={6} pb={2}>
            <H3 data-testid="version-modal-header">
              {isNewFormType
                ? "Create a new draft"
                : isEditFormType
                ? "Edit version details"
                : isNewVersionFormType && `Create a new draft from v${version}`}
            </H3>
            <ModalCloseButton />
            <H5 mt={4}>
              {parentName && <Span data-testid="version-modal-campaign-name">{parentName} | </Span>}
              <Span data-testid="version-modal-name" ml={1}>
                {name ? name : ""}
              </Span>
            </H5>
          </ModalHeader>

          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <ModalBody>
              {helperText ? (
                <Span data-testid="version-modal-helper-text">{helperText}</Span>
              ) : (
                <Span>
                  Enter a description below to create a new version in draft mode. you'll be able to
                  edit the description or publish this version later.
                </Span>
              )}

              <HStack mt={6} mb={8} justify="space-between">
                <Input
                  data-testid="version-modal-release-type"
                  isDisabled
                  isRequired
                  label="Type of release"
                  value={releaseType}
                />
                <Spacer />
                <Input
                  data-testid="version-modal-input-version"
                  isDisabled
                  isRequired
                  label="Version number"
                  value={
                    isNewFormType || isEditFormType
                      ? version
                      : isNewVersionFormType && isMinorRelease
                      ? nextMinorMajorVersions && nextMinorMajorVersions[0]
                      : nextMinorMajorVersions && nextMinorMajorVersions[1]
                  }
                />
              </HStack>

              <Controller
                name="versionNotes"
                control={control}
                rules={{
                  required:
                    "Description is required. Please write a short description for this version.",
                }}
                render={({ field, fieldState: { error } }) => (
                  <Textarea
                    {...field}
                    data-testid="version-modal-input-description"
                    helperText="Write a short description for this version"
                    isDisabled={isClientUser}
                    isRequired
                    label="Description"
                    resize="none"
                    rows={2}
                    errorMessage={error?.message}
                    autoFocus
                  />
                )}
              />
            </ModalBody>

            <ModalFooter pb={8} pt={2}>
              <Button
                data-testid="version-modal-cancel-button"
                size="lg"
                variant="ghost"
                mr={5}
                onClick={handleCloseModal}
                type="button">
                Cancel
              </Button>
              <Button
                data-testid="version-modal-save-button"
                size="lg"
                isDisabled={isSubmitDisabled}
                title={
                  isSubmitDisabled ? "A description is required to create a new draft" : undefined
                }
                type="submit">
                {saveButtonText}
              </Button>
            </ModalFooter>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default VersionEditModal;
