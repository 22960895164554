import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import Email, { EmailAttributes } from "models/email";

import { useTouchpointBuilderContent } from "state/ducks/builder-content";

import BuilderForm from "containers/admin/clients/touchpoint/components/builder/builder-form/builder-form";
import {
  CustomInput,
  CustomSelect,
  CustomTextarea,
} from "components/new/forms/custom-form-inputs/custom-form-inputs";
import { BuilderNavTab } from "containers/admin/clients/touchpoint/components/builder/builder-tab-nav/builder-tab-nav";

import { createZodSchemaFromFormFields, CustomFormField } from "utilities/forms";

interface EmailBuilderContentSettingsTabProps {
  previewMode: boolean;
  formFields: CustomFormField[];
  onSave: (field: Record<string, any>) => Promise<void>;
  setIsSaved: (isSaved: boolean) => void;
  setIsSaving: (isSaving: boolean) => void;
  setInvalidTabs: React.Dispatch<React.SetStateAction<BuilderNavTab[]>>;
}

const EmailBuilderContentSettingsTab = ({
  previewMode = false,
  formFields,
  onSave,
  setIsSaved,
  setIsSaving,
  setInvalidTabs,
}: EmailBuilderContentSettingsTabProps) => {
  const { attributes: initialAttributes } = useTouchpointBuilderContent() as Email;

  const zodSchema = createZodSchemaFromFormFields(formFields);

  type FormValues = z.infer<typeof zodSchema>;

  const [hasChanges, setHasChanges] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setValue,
    reset,
    getValues,
  } = useForm<FormValues>({
    resolver: zodResolver(zodSchema),
    mode: "onChange",
  });

  useEffect(
    function setInitialValues() {
      formFields.forEach((field) => {
        const fieldName = field.name as keyof EmailAttributes;
        const initialValue = initialAttributes[fieldName] || field.defaultValue;
        setValue(fieldName, initialValue ? `${initialValue}` : "");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formFields]
  );

  const hasErrors = !!Object.keys(errors).length;

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await handleSubmit(onSave)();

      setIsSaved(true);
    } catch (error) {
      console.error("Failed to save", error);
      setIsSaved(false);
    } finally {
      setIsSaving(false);
      reset(getValues());
    }
  };

  /** Saves on blur if field value has been changed */
  const handleFieldBlur = async () => {
    if (hasChanges && isValid) {
      try {
        await handleSave();
      } catch {
        console.error("Field blurred, failed to save", { hasChanges, isValid });
      }
    }
  };

  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    /** Autosave after 2 second debounce if a change has been made and the form is valid */
    function autosave() {
      if (hasChanges && isValid) {
        saveTimeoutRef.current = setTimeout(() => {
          handleSave();
        }, 2000);
      }

      return () => {
        if (saveTimeoutRef.current) {
          clearTimeout(saveTimeoutRef.current);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasChanges, isValid]
  );

  useEffect(() => {
    setHasChanges(!isSubmitting && isDirty);
  }, [isDirty, isSubmitting, setHasChanges]);

  useEffect(() => {
    const thisTab = "Content Settings";
    setInvalidTabs((prev) =>
      hasErrors ? [...prev, thisTab] : prev.filter((tab) => tab !== thisTab)
    );
  }, [hasErrors, setInvalidTabs]);

  return (
    <BuilderForm errors={errors} formFields={formFields} onSubmit={() => handleSave()} noValidate>
      {formFields.map((field) => {
        if (field.component === "hidden") {
          return null;
        }
        return (
          <div key={`${field.name}-${field.component}`}>
            <Controller
              control={control}
              name={field.name}
              render={({ field: { onChange, onBlur, value } }) => {
                const commonProps = {
                  disabled: previewMode,
                  error: errors[field.name],
                  eyebrow: field.eyebrow,
                  helperText: field.helperText,
                  label: field.label,
                  name: field.name,
                  onBlur: () => {
                    onBlur();
                    handleFieldBlur();
                  },
                  onChange,
                  placeholder: field.placeholder,
                  required: !!field.validations?.isRequired,
                  value: value ?? "",
                };

                switch (field.component) {
                  case "select":
                    return <CustomSelect options={field.options} {...commonProps} />;
                  case "textarea":
                    return <CustomTextarea {...commonProps} />;

                  default:
                    return (
                      <CustomInput
                        type="text"
                        addonStart={field.addonStart}
                        addonEnd={field.addonEnd}
                        {...commonProps}
                      />
                    );
                }
              }}
            />
          </div>
        );
      })}
    </BuilderForm>
  );
};

export default EmailBuilderContentSettingsTab;
