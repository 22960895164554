import { Base64 } from "js-base64";

import { Email, Touchpoint } from "models";
import LandingPage from "models/landing-page";
import Model, { Unpersisted } from "models/model";
import { LockedBy, ThemeAttributes } from "models/theme";

import { generatePaginatedQueryString, generateTouchpointFromAttributes } from "utilities";

import { BeeContent } from "types";
import {
  DEFAULT_PAGINATED_REQUEST_OPTIONS,
  PaginatedRequestOptions,
  PaginatedResponse,
} from "types/pagination";
import { TouchpointType } from "types/touchpoint";

export interface TouchpointVersionsType {
  status: string;
  clientReviewStatusName: string;
  versionStatusName: string;
}

interface TouchpointVersionAttributes {
  advancedBodySnippet?: string;
  advancedHeadSnippet?: string;
  archiveLocation?: string;
  data?: BeeContent;
  draftedFromVersion?: string;
  finalHtml?: string;
  id: string;
  name: string;
  lastModifiedDate: string;
  linkParams?: string;
  lockedBy?: LockedBy | null;
  preprocessedHtml?: string;
  publishedAt?: string;
  sourceType: string;
  status?: string;
  theme?: ThemeAttributes | null;
  themeId?: string;
  touchpointId: string;
  type?: TouchpointType;
  version?: string;
  versionNotes?: string;
  visibleToClient?: boolean;
  isSummaryLoading?: boolean;
}

export type TouchpointResponse = TouchpointVersion | Email | LandingPage;

export type TouchpointIds = {
  campaignId: string;
  clientId: string;
  id: string;
  touchpointId: string;
};

export type ThemeIds = {
  clientId: string;
  id: string;
};

/**
 * Encode Payload
 * encode any attributes that will include html or javascript
 *
 * @param attributes attributes to be encoded
 */
function encodeVersionPayload(
  attributes:
    | TouchpointVersionAttributes
    | Unpersisted<TouchpointVersionAttributes>
    | Partial<TouchpointVersionAttributes>
) {
  //this is to decouple the attribute payload from it's initial type
  const payload: { [k: string]: string } = JSON.parse(JSON.stringify(attributes));

  if (payload.data) {
    payload.data = Base64.encode(JSON.stringify(attributes.data));
  }

  const propsToEncode = [
    "advancedBodySnippet",
    "advancedHeadSnippet",
    "finalHtml",
    "preprocessedHtml",
    "subjectLine",
    "preheader",
    "url",
    "pageTitle",
    "pageDescription",
    "redirectDestination",
  ];

  propsToEncode.forEach((prop) => {
    if (payload[prop]) {
      payload[prop] = Base64.encode(payload[prop]);
    }
  });

  return payload;
}

class TouchpointVersion
  extends Model<TouchpointVersionAttributes>
  implements TouchpointVersionAttributes
{
  static all({
    clientId,
    campaignId,
    touchpointId,
    options = DEFAULT_PAGINATED_REQUEST_OPTIONS,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    options?: PaginatedRequestOptions;
  }): Promise<PaginatedResponse<TouchpointResponse>> {
    const queryString = generatePaginatedQueryString(options);
    return Touchpoint.connection
      .get(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions${queryString}`
      )
      .then((response) => {
        const items = response.data.content
          ? response.data.content.map(generateTouchpointFromAttributes)
          : [];
        return {
          items: items,
          totalItems: response.data.totalElements,
          size: response.data.size,
          page: response.data.number,
          totalPages: response.data.totalPages,
          last: response.data.last,
        };
      });
  }

  static create({
    clientId,
    campaignId,
    touchpointId,
    version,
    versionNotes,
    visibleToClient,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    version: string;
    versionNotes: string;
    visibleToClient?: boolean;
  }): Promise<TouchpointResponse> {
    return this.connection
      .post(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions`, {
        version,
        versionNotes,
        visibleToClient,
      })
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static find({
    clientId,
    campaignId,
    touchpointId,
    id,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    id: string;
  }): Promise<TouchpointResponse> {
    return this.connection
      .get(`clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}`)
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static edit({
    clientId,
    campaignId,
    touchpointId,
    id,
    attributes,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    id: string;
    attributes: Partial<TouchpointVersionAttributes>;
  }): Promise<TouchpointResponse> {
    return this.connection
      .patch(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}`,
        encodeVersionPayload(attributes)
      )
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static replace({
    clientId,
    campaignId,
    touchpointId,
    id,
    attributes,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    id: string;
    attributes: Unpersisted<TouchpointVersionAttributes>;
  }): Promise<TouchpointResponse> {
    return this.connection
      .put(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}`,
        encodeVersionPayload(attributes)
      )
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static claimLock({
    clientId,
    campaignId,
    touchpointId,
    id,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    id: string;
  }): Promise<TouchpointVersion> {
    return this.connection
      .put(
        `/clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}/claim-lock`,
        undefined
      )
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static releaseLock({
    clientId,
    campaignId,
    touchpointId,
    id,
  }: {
    clientId: string;
    campaignId: string;
    touchpointId: string;
    id: string;
  }): Promise<TouchpointVersion> {
    return this.connection
      .put(
        `/clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}/release-lock`,
        undefined
      )
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static createDraft({
    campaignId,
    clientId,
    touchpointId,
    id,
    version,
    versionNotes,
    visibleToClient,
  }: {
    campaignId: string;
    clientId: string;
    touchpointId: string;
    id: string;
    version: string;
    versionNotes: string;
    visibleToClient?: boolean;
  }) {
    return this.connection
      .put(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}/create-draft`,
        {
          version: version,
          versionNotes: versionNotes,
          visibleToClient: visibleToClient,
        }
      )
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static clone({
    campaignId,
    clientId,
    touchpointId,
    id,
    name,
  }: {
    campaignId: string;
    clientId: string;
    touchpointId: string;
    id: string;
    name?: string;
  }) {
    return this.connection
      .post(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${id}/clone`,
        { name, campaignId }
      )
      .then((response) => {
        return generateTouchpointFromAttributes(response.data);
      });
  }

  static getStatusesDictionary(): Promise<TouchpointVersionsType[]> {
    return this.connection.get("/dictionary/touchpoint-version-statuses").then((response) => {
      return response.data;
    });
  }

  static patchTouchpointStatus({
    clientId,
    campaignId,
    touchpointId,
    touchpointVersionId,
    status,
  }: {
    clientId?: string;
    campaignId?: string;
    touchpointId?: string;
    touchpointVersionId?: string;
    status: string;
  }): Promise<TouchpointVersion> {
    return this.connection
      .patch(
        `clients/${clientId}/campaigns/${campaignId}/touchpoints/${touchpointId}/versions/${touchpointVersionId}`,
        { status }
      )
      .then((response) => {
        return new TouchpointVersion(response.data);
      });
  }

  get attributes(): TouchpointVersionAttributes {
    return {
      advancedBodySnippet: this.advancedBodySnippet,
      advancedHeadSnippet: this.advancedHeadSnippet,
      archiveLocation: this.archiveLocation,
      data: this.data,
      draftedFromVersion: this.draftedFromVersion,
      finalHtml: this.finalHtml,
      id: this.id,
      name: this.name,
      lastModifiedDate: this.lastModifiedDate,
      linkParams: this.linkParams,
      lockedBy: this.lockedBy,
      preprocessedHtml: this.preprocessedHtml,
      publishedAt: this.publishedAt,
      sourceType: this.sourceType,
      status: this.status,
      theme: this.theme,
      themeId: this.themeId,
      touchpointId: this.touchpointId,
      type: this.type,
      version: this.version,
      versionNotes: this.versionNotes,
      visibleToClient: this.visibleToClient,
    };
  }

  get advancedBodySnippet() {
    return this._attributes["advancedBodySnippet"];
  }

  get advancedHeadSnippet() {
    return this._attributes["advancedHeadSnippet"];
  }

  get archiveLocation() {
    return this._attributes["archiveLocation"];
  }

  get data() {
    return this._attributes["data"];
  }

  get draftedFromVersion() {
    return this._attributes["draftedFromVersion"];
  }

  get finalHtml() {
    return this._attributes["finalHtml"];
  }

  get id() {
    return this._attributes["id"];
  }

  get lastModifiedDate() {
    return this._attributes["lastModifiedDate"];
  }

  get linkParams() {
    return this._attributes["linkParams"];
  }

  get lockedBy() {
    return this._attributes["lockedBy"];
  }

  get name() {
    return this._attributes["name"];
  }

  get publishedAt() {
    return this._attributes["publishedAt"];
  }

  get preprocessedHtml() {
    return this._attributes["preprocessedHtml"];
  }

  get sourceType() {
    return this._attributes["sourceType"];
  }

  get status() {
    return this._attributes["status"];
  }

  get theme() {
    return this._attributes["theme"];
  }

  get themeId() {
    return this._attributes["themeId"];
  }

  get version() {
    return this._attributes["version"];
  }

  get versionNotes() {
    return this._attributes["versionNotes"];
  }

  get visibleToClient() {
    return this._attributes["visibleToClient"];
  }

  get touchpointId() {
    return this._attributes["touchpointId"];
  }

  get type() {
    return this._attributes["type"];
  }
}

export default TouchpointVersion;
export type { TouchpointVersionAttributes };
