import React from "react";
import { twMerge } from "tailwind-merge";

export const TABLE_CELL_PADDING = "py-2 pl-0 pr-7 last:pl-7 last:pr-0 last:w-px";

export interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {}

export const TableCell: React.FC<TableCellProps> = ({ children, className, ...rest }) => {
  const mergedClasses = twMerge(`text-sm align-center ${TABLE_CELL_PADDING}`, className);

  return (
    <td className={mergedClasses} {...rest}>
      {children}
    </td>
  );
};
