import { useContext } from "react";
import { Box, Flex, HStack, Stack, Icon, Text, StackDivider } from "@chakra-ui/react";
import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { AnimatePresence, motion } from "framer-motion";

import { Span } from "components/partials/typography/typography";
import TruncatedText from "components/partials/truncated-text/truncated-text";
import { CommentIcons } from "containers/admin/clients/touchpoint/components/comments/comment-icons/comment-icons";
import { CommentReplyDisplay } from "containers/admin/clients/touchpoint/components/comments/comment-reply-display/comment-reply-display";
import Button, { ButtonProps } from "components/forms/button/button";
import { CommentDisplayByline } from "containers/admin/clients/touchpoint/components/comments/comment-display-by-line/comment-display-by-line";

import CommentContext from "contexts/comment-context";

import { useCurrentUser } from "state/ducks";

import { dateToLocalTimestamp } from "utilities";
import { hasPermission } from "utilities/user";

import { Permission } from "types/auth";
import { Comment, CommentReply } from "types/comments";

interface CommentDisplayProps {
  comment: Comment;
  isClientUser?: boolean;
  replies: CommentReply[];
  // toggleReplyInput: () => void;
}

export const AnnotationCommentDisplay = ({
  comment,
  isClientUser,
  replies,
}: // toggleReplyInput,
CommentDisplayProps) => {
  const { updateComment } = useContext(CommentContext);
  const currentUser = useCurrentUser();
  const canComment = hasPermission(currentUser, Permission.PERM_COMMENT_WRITE);

  const toggleOpenComment = () => {
    const isFlagged = comment.open ? !comment.actionItem : false;
    updateComment({ ...comment, open: !comment.open, actionItem: isFlagged });
  };
  const toggleFlagComment = () => {
    updateComment({ ...comment, actionItem: !comment.actionItem });
  };
  const toggleHideComment = () => {
    updateComment({ ...comment, hidden: !comment.hidden });
  };

  const createdAtConvertedToDate = new Date(comment.createdDate);

  const CommentDisplayHeader = () => (
    <Flex alignItems="center" fontSize="xs" letterSpacing="wide" py={3}>
      <Text>
        {comment.version && `v${comment.version}`}
        {comment.version && <Separator />}
        {createdAtConvertedToDate.toLocaleDateString()} {dateToLocalTimestamp(comment.createdDate)}
      </Text>

      <CommentIcons
        canOpen={canComment && !isClientUser}
        canFlag={canComment && comment.open}
        isChecked={!comment.open}
        isFlagged={comment.actionItem}
        ml="auto"
        toggleFlag={toggleFlagComment}
        toggleCheck={toggleOpenComment}
      />
    </Flex>
  );

  const CommentActionTrigger = ({ children, label, ...rest }: ButtonProps & { label?: string }) => (
    <Button
      variant="link"
      textDecoration="underline"
      fontSize="xs"
      fontWeight="light"
      padding={0}
      height="auto"
      {...rest}>
      {children || label}
    </Button>
  );

  const CommentVisibilityStatus = ({
    isHidden,
    canToggleVisibility,
  }: {
    isHidden?: boolean;
    canToggleVisibility?: boolean;
  }) => {
    return (
      <Text display="flex" alignItems="center" mb={1} fontSize="xs">
        <Icon as={isHidden ? ViewOffIcon : ViewIcon} fontSize="md" mr={1} />
        {isHidden ? "Hidden from client" : "Visible to client"}

        {canToggleVisibility && (
          <>
            <Separator />
            <Button
              variant="link"
              textDecoration="underline"
              display="inline"
              cursor="pointer"
              fontSize="inherit"
              fontWeight="normal"
              padding={0}
              height="auto"
              data-testid="hidden-comment-trigger"
              onClick={toggleHideComment}>
              {isHidden ? "Unhide" : "Hide"}
            </Button>
          </>
        )}
      </Text>
    );
  };

  return (
    <Box px={2} pb={0}>
      <CommentDisplayHeader />

      <Box opacity={comment.open ? 1 : 0.5}>
        <CommentDisplayByline user={comment.user} />
        <Stack
          spacing={2}
          divider={<StackDivider borderColor="gray.200" />}
          pl={6}
          className="content">
          <TruncatedText text={comment.text} noOfLines={4} initialTruncate={true} />

          <Box>
            {/* Status: is comment visible to client? */}
            {!isClientUser && (
              <CommentVisibilityStatus canToggleVisibility={canComment} isHidden={comment.hidden} />
            )}

            {/* Reply, Flag and Close Buttons */}
            {/* TODO: Wrap `canComment` around the HStack */}
            <HStack spacing={1} fontSize="xs" divider={<Separator />}>
              {canComment && (
                <>
                  <CommentActionTrigger
                    data-testid="reply-comment-trigger"
                    isDisabled={!canComment}
                    label="Reply"
                    // onClick={toggleReplyInput}
                  />

                  <CommentActionTrigger
                    data-testid="flag-comment-trigger"
                    isDisabled={!canComment || !comment.open}
                    onClick={toggleFlagComment}
                    label={`${comment.actionItem ? "Unflag" : "Flag"} action item`}
                  />

                  <CommentActionTrigger
                    data-testid="open-comment-trigger"
                    isDisabled={isClientUser}
                    onClick={toggleOpenComment}
                    label={comment.open ? "Close" : "Open"}
                  />
                </>
              )}
            </HStack>
          </Box>

          {/* Comment Replies */}
          {comment.replyCount > 0 && (
            <Box>
              <AnimatePresence>
                <motion.div exit={{ opacity: 0, height: 0 }} transition={{ type: "tween" }}>
                  <Stack spacing={2} mb={2}>
                    {replies.map((reply: CommentReply) => (
                      <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        transition={{ type: "tween" }}
                        key={reply.id}>
                        <CommentReplyDisplay key={reply.id} reply={reply} />
                      </motion.div>
                    ))}
                  </Stack>
                </motion.div>
              </AnimatePresence>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const Separator = () => <Span mx={1}>•</Span>;
